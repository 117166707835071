import * as React from "react";
import "./news.scss";
import { Button, Form, Input } from "antd";
import { INewsState } from "./news-types";
import * as NewsStore from "./news-reducer";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { FormInstance } from "antd/lib/form";

const { Item } = Form;

type NewsProps = INewsState & typeof NewsStore.actions;
class SubscriptionBlock extends React.Component<NewsProps> {
    formRef = React.createRef<FormInstance>();

    onFinish = (values: any) => {
        this.props.subscribe(values);
        this.formRef.current.resetFields();
    };

    public render() {
        return (
            <div className="subscription-block">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="subscription-info">
                            <h3>Latest News & Articles</h3>
                            <p>
                                Get the knowledge and inspiration you need to
                                build a profitable <br /> business - straight to
                                your inbox
                            </p>
                            <div className="subscription-form">
                                <Form
                                    name="subscribe_to_news"
                                    onFinish={this.onFinish}
                                    layout={"inline"}
                                    ref={this.formRef}
                                >
                                    <Item name={"email"} rules={[{ type: 'email', required: true }]}>
                                        <Input
                                            placeholder="Enter your email address"
                                            className="subscribe-input"
                                        />
                                    </Item>
                                    <Item>
                                        <Button className="subscribe-button" htmlType="submit">
                                            Subscribe
                                        </Button>
                                    </Item>
                                </Form>
                                <small>*No charge. Unsubscribe anytime.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.news,
    NewsStore.actions)(SubscriptionBlock);
