import * as React from 'react';
import { IMapModel, ISelectValue } from "./upload-types";
import { Table, Form, Input, Button, Tabs } from 'antd';
import { stepSize } from './upload-constats';
import _ from 'lodash';
import { IMappingProfile } from './upload-service';
import * as UploadStore from "./reducer";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';

const { TabPane } = Tabs;

interface ISaveMappingProfileStoreProps {
    mapModels: IMapModel[];
    current: number;
}

type ISaveMappingProfileProps = ISaveMappingProfileStoreProps &
    typeof UploadStore.uploadActions;

const columns = [
    {
        title: 'Colomn header from file',
        dataIndex: 'fileColumn',
        key: 'fileColumn',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
];

const mapHeaders = (selectedHeaderValues: { [key: string]: ISelectValue }) => {
    return _.keys(selectedHeaderValues).map(key => ({
        key: key,
        fileColumn: key,
        name: selectedHeaderValues[key].name
    }));
}
class SaveMappingProfile extends React.Component<ISaveMappingProfileProps>{
    onFinish = async (values: any, index: number, mapModel: IMapModel) => {
        const { saveMapper, getUserMappings } = this.props;

        await saveMapper(index, { name: values.mappingName, mapping: mapModel.selectedHeaderValues } as IMappingProfile);
        getUserMappings();
    }

    render() {
        const { mapModels, current } = this.props;
        return (
            <React.Fragment>
                <Tabs tabPosition='left'>
                    {
                        mapModels.map((mapModel, index) => mapModel.isNewMapping && (
                            <TabPane key={`map_save_${index}`} tab={mapModel.fileName}>
                                <div className="container">
                                    <Form layout='horizontal' onFinish={(values) => this.onFinish(values, index, mapModel)}>
                                        <div className="d-flex w-100 justify-content-start">
                                            <Form.Item initialValue={mapModel.isSaved ? mapModel.name : null} className='mapping-input mr-3' name="mappingName">
                                                <Input disabled={mapModel.isSaved} placeholder="Please input your mapping profile name" />
                                            </Form.Item>
                                            <Button loading={mapModel.isLoading} disabled={mapModel.isSaved} className='save-btn' type="primary" htmlType="submit">Save</Button>
                                        </div>
                                    </Form>
                                    <Table columns={columns} dataSource={mapHeaders(mapModel.selectedHeaderValues)} pagination={false} scroll={{ x: true }} />
                                </div>
                            </TabPane>
                        ))
                    }
                </Tabs>
                <div className="d-flex justify-content-end mt-3 mb-3">
                    <div className="row">
                        <Button onClick={() => this.props.setCurrentStep(current - stepSize)} className="back_btn" shape="round" type="text">Back</Button>
                        <Button onClick={() => this.props.setCurrentStep(current + stepSize)} shape="round" type="primary">Next</Button>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            mapModels: state.upload.mapModels,
            current: state.upload.current,
        }
    },
    UploadStore.uploadActions
)(SaveMappingProfile);