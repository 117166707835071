import { ADMIN_ROLE, LIST_OWNER_ROLE, SUPER_ADMIN_ROLE, EDITOR_ROLE } from "../../assets/constants/roles";

export const PUBLIC_ROUTE_ROLES = [LIST_OWNER_ROLE, ADMIN_ROLE, SUPER_ADMIN_ROLE];
export const ADMIN_ROUTE_ROLES = [ADMIN_ROLE, SUPER_ADMIN_ROLE];
export const EDITOR_ROUTE_ROLES = [SUPER_ADMIN_ROLE, EDITOR_ROLE];

export enum ACCESS_STATUS {
    NON_AUTHORIZED,
    FORBIDDEN,
    ACCESSIBLE
}

export const ROLE_NAMES = {
    listOwner: "User",
    admin: "Admin",
    superAdmin: "Super Admin",
    companyOwner: "Company Owner"
}