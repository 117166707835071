import * as React from "react";
import { IFAQQuestion } from './landing-types';

export const FAQQuestions: IFAQQuestion[] = [{
    question: 'Do I need to comply with the CCPA?',
    answer: (<span>
        The CCPA applies to any for profit business that collects consumers’ personal data, that does business in California, and satisfies one of the following thresholds:
        <ul>
            <li>Has annual gross revenues in excess of $25 million</li>
            <li>Do you annually buy, receive for commercial purposes, sell or share for commercial purposes personal information of 50,000 or more California consumers, households or devices?</li>
            <li className='or'>Or</li>
            <li>Derives 50% or more of its’ annual revenue from the sale of consumer personal information.</li>
        </ul>
    </span>)
}, {
    question: 'Does GDPR compliance cover CCPA compliance?',
    answer: <span>No. While some components are similar, CCPA requires specific compliance standards which are not part of GDPR.</span>
}, {
    question: 'If the company we buy data from is CCPA compliant, do we need to be?',
    answer: <span>Yes. If you are a covered company under CCPA, you are required to become CCPA compliant.</span>
}, {
    question: 'How does your service ensure compliance?',
    answer: <span><a href="/">myDataPrivacy.com</a> covers each of these compliance items and more. It provides consumers with an easy-access, verified request portal to access their personal information. It’s the only turnkey, fully-automated consumer-facing CCPA solution for SMB’s and Data Compilers/Brokers, Agencies and marketing services companies who maintain databases and/or rent prospect data.</span>
}, {
    question: 'If my company just serves as a data processing company for mailers that market to California, am I required to be compliant?',
    answer: <span>Consult with an attorney regarding your specific situation. There are some instances in which a services provider who doesn’t store or maintain consumer data will not be required to comply.</span>
}, {
    question: 'How does it work?',
    answer: <span><a href="/">myDataPrivacy.com</a> provides a cost effective, easy-to-implement solution for List Owners and Mailers subject to the CCPA, providing comprehensive data access and disclosure compliance. This offers a central repository for List Owners and Mailers to maintain CCPA-related data points and transactions, relieving them of the burden of managing compliance in-house. Suppression and deletion files are continuously updated and available for download at any time.</span>
}, {
    question: 'Is this service just for California or can it be used for residents in other states as well?',
    answer: <span><a href="/">myDataPrivacy.com</a> can be used for residents throughout the USA, and we recommend doing so. You’ll be prepared for pending Privacy legislation that has been introduced in multiple state legislatures and which, we believe, will eventually lead to the passing of nationwide Privacy legislation.</span>
}, {
    question: <span>Where are <a href="/">myDataPrivacy.com</a>’s servers hosted?/ Where is my data hosted and maintained?</span>,
    answer: <span>All data is hosted at Digital (digitalocean.com) data centers and stored in a private cloud environment with automatic backups and infrastructure redundancies, allowing guaranteed 99.99% uptime. Certifications include ISO/IEC 27001:2013, EU-U and Swiss-US Privacy Shield Certification – <a href="https://www.digitalocean.com/legal/certifications">https://www.digitalocean.com/legal/certifications</a>.</span>
}, {
    question: 'How do I know my data will be secure?',
    answer: <span>Our infrastructure is secured through a defense-in-depth layered approach. Access to the management network infrastructure is provided through multi-factor authentication points, which restrict network-level access to infrastructure based on job function, utilizing the principle of least privilege. Our proprietary architecture permits only single, encrypted queries from our web-facing portal, to your database. Personally identifiable information (PII) is not stored on <a href="/">myDataPrivacy.com</a>.</span>
}, {
    question: 'Are we able to broker this site to our clients for profit?',
    answer: <span>For legal and insurance purposes, no. However, we do have a lead referral program available only to current clients. Call your salesperson to learn more.</span>
}];