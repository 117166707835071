import RestUtilities from "../../helpers/rest-utilities";
import { IEditedProfile } from "./edit-profile-types";

export default class EditProfioleService {
    getProfile() {
        return RestUtilities.get<IEditedProfile>(`/api/profile`);
    }

    editProfile(profile: IEditedProfile) {
        return RestUtilities.post("/api/profile/edit", profile);
    }

    checkCompanyName(companyName: string) {
        return RestUtilities.get<boolean>(`/api/profile/check-company-name/${companyName}`);
    }
}