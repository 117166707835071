import { IRestResponse } from './rest-utilities';
import { notification } from "antd";
import * as _ from 'lodash';

export const showErrorNotification = (response: IRestResponse<any>, defaultErrorMessage: string = 'Smth went wrong') => {
    if (response && response.error_content) {
        const { errors, message } = response.error_content;

        _.values({ ...errors }).forEach(msgs => {
            if (Array.isArray(msgs)) {
                msgs.forEach(m => notification.error({ message: m }));
            } else {
                notification.error({ message: msgs.toString() });
            }
        })

        if (message) {
            notification.error({ message });
        }

        if (!errors && !message) {
            if (response.isBadRequest) {
                _.values({ ...response.error_content }).forEach(msgs => {
                    if (Array.isArray(msgs)) {
                        msgs.forEach(m => notification.error({ message: m }));
                    } else {
                        notification.error({ message: msgs.toString() });
                    }
                })
            } else {
                notification.error({ message: defaultErrorMessage });
            }
        }
    }
}

export const showErrorNotificationCallback = (response: IRestResponse<any>, defaultErrorMessage: string = 'Smth went wrong', callback: (param?: any) => void = () => ({}), withParam = false) => {
    if (response && response.error_content) {
        const { errors, message } = response.error_content;

        withParam ? callback({ ...errors, message }) : callback();
        showErrorNotification(response, defaultErrorMessage);
    }
}