// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._36NiRmHOZsAqhymEdrjwVi {\n  margin: 0 auto;\n  margin-top: 50px;\n  width: 650px;\n  border-radius: 4px;\n  box-shadow: 0 11px 38px -5px rgba(1,39,88,0.18);\n  background-color: #fff;\n  padding: 40px;\n}\n._13arRjuui3AwgVVHI8z9xz {\n  margin-top: 20px;\n}\n._2gDrdEP-e455tWI6C3MoWi ._2JbLOfJJEYDm0oJHJ5KkgV,\n._2gDrdEP-e455tWI6C3MoWi .DCKa1YGW6xzfGoHIQC8_M {\n  margin-bottom: 10px;\n}\n._2gDrdEP-e455tWI6C3MoWi .DCKa1YGW6xzfGoHIQC8_M {\n  font-weight: normal;\n}\n._2gDrdEP-e455tWI6C3MoWi ._2yzjG-ihyQVE6U14zcVrMc {\n  position: relative;\n  height: auto;\n  -webkit-box-sizing: border-box;\n  box-sizing: border-box;\n  padding: 10px;\n  font-size: 16px;\n}\n._2gDrdEP-e455tWI6C3MoWi ._2yzjG-ihyQVE6U14zcVrMc:focus {\n  z-index: 2;\n}\n._2gDrdEP-e455tWI6C3MoWi input[type=\"email\"] {\n  margin-bottom: -1px;\n  border-bottom-right-radius: 0;\n  border-bottom-left-radius: 0;\n}\n._2gDrdEP-e455tWI6C3MoWi input[type=\"password\"] {\n  margin-bottom: 10px;\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"auth": "_36NiRmHOZsAqhymEdrjwVi",
	"authEtc": "_13arRjuui3AwgVVHI8z9xz",
	"formAuth": "_2gDrdEP-e455tWI6C3MoWi",
	"form-signin-heading": "_2JbLOfJJEYDm0oJHJ5KkgV",
	"checkbox": "DCKa1YGW6xzfGoHIQC8_M",
	"form-control": "_2yzjG-ihyQVE6U14zcVrMc"
};
module.exports = exports;
