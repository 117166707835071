import { Reducer } from "redux";
import { typedAction } from "../../helpers/action-helper";
import { AppThunkAction } from '../../store';
import { SetPasswordActionTypes } from "./set-password-constants";
import { IPasswordModel, ISetPasswordActions, ISetPasswordState } from "./set-password-types";
import SetPasswordService from "./set-password-service"
import { showErrorNotification } from "../../helpers/notification-helper";
import { notification } from "antd";
import { history } from "../../helpers/history-helper";
import { PAGE_LINK } from "../../assets/constants/page-links";

const setPasswordService = new SetPasswordService();

const setIsLoading = (isLoading: boolean) => typedAction(SetPasswordActionTypes.setIsLoading, isLoading);
const setIsSuccess = (isSuccess: boolean) => typedAction(SetPasswordActionTypes.setIsSuccess, isSuccess);

const setPassword = (model: IPasswordModel): AppThunkAction<SetPasswordAction> => (dispatch) => {
    dispatch(setIsLoading(true));

    setPasswordService.setPassword(model)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setIsSuccess(true));
                notification.success({ message: "Email confirmed!" });
                history.push(PAGE_LINK.LOGIN);
            } else {
                dispatch(setIsSuccess(false));
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

type SetPasswordAction = ReturnType<typeof typedAction>

const unloadedState: ISetPasswordState = {
    isLoading: false,
    isSuccess: false
}

export const setPasswordActions: ISetPasswordActions = {
    setIsLoading,
    setIsSuccess,
    setPassword
}

export const reducer: Reducer<ISetPasswordState, SetPasswordAction> = (state: ISetPasswordState = unloadedState, action: SetPasswordAction): ISetPasswordState => {
    switch (action.type) {
        case SetPasswordActionTypes.setIsLoading:
            return {
                ...state,
                isLoading: action.payload as boolean
            }
        case SetPasswordActionTypes.setIsSuccess:
            return {
                ...state,
                isSuccess: action.payload as boolean
            }
        default: return state;
    }
}