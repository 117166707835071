import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import { connect } from 'react-redux';
import { withLayout } from '../../common/layout';
import { isEmail, isPhone, isRequired, PhoneInput, withoutDigits } from '../../helpers/validate-helper';
import { ApplicationState } from '../../store';
import { FORM_NAMES } from '../landing/landing-constants';
import { ILandingActions, ILandingProps, IPotentialClient } from '../landing/landing-types';
import "./ccpa-text.scss";
import "../landing/landing.scss";
import { landingActions } from "../landing/landing-reducer";
import operator from '../../assets/images/operator.svg';
import questions from '../../assets/images/questions.jpg';
import { Helmet } from 'react-helmet';

const { Item } = Form;

type FormProps = ILandingProps & ILandingActions;

class CCPAText extends React.Component<FormProps> {
    formRef = React.createRef<FormInstance>();

    public onFinish = (values: IPotentialClient, formName: string) => {
        values.formName = formName;
        this.props.sendForm(values);
        this.formRef.current.resetFields();
    };
    public render() {
        const validateMessages = {
            types: {
                email: '${label} is not validate email!',
            }
        };

        return (
            <React.Fragment>
                <Helmet>
                    <title>Full CCPA Text</title>
                    <meta name="description"
                        content="The following is the full text of the California Consumer Privacy Act after it was amended." />
                </Helmet>
                <div className="ccpa-text-header ">
                    <div className="container px-5">
                        <h1>
                            FULL CCPA TEXT
                        </h1>
                        <h2>
                            The following is the full text of the California Consumer Privacy Act after it was amended.
                        </h2>
                    </div>
                </div>
                <div className="container">
                    <div className="float-lg-right p-3">
                        <div className="d-flex flex-column ccpa-form p-4 pb-0">
                            <h3 className="mb-3">GET TURNKEY CCPA COMPLIANCE</h3>
                            <Form name="nest-messages" ref={this.formRef} className='d-flex flex-column w-100' onFinish={(values: IPotentialClient) => this.onFinish(values, FORM_NAMES.ccpaText)} validateMessages={validateMessages}>
                                <Item name={'firstName'}
                                    rules={[isRequired('First Name'), withoutDigits('First Name')]}>
                                    <Input placeholder="First Name*" />
                                </Item>
                                <Item name={'lastName'}
                                    rules={[isRequired('Last Name'), withoutDigits('Last Name')]}>
                                    <Input placeholder="Last Name*" />
                                </Item>
                                <Item name={'email'}

                                    rules={[isRequired('Email Address'), isEmail('Email Address')]}>
                                    <Input placeholder="Email Address*" />
                                </Item>
                                <Item name={'phone'} rules={[isPhone('Phone Number')]}>
                                    <PhoneInput label='Phone Number' />
                                </Item>
                                <Item name={'company'} >
                                    <Input placeholder="Company Name" />
                                </Item>
                                <Item className="px-5">
                                    <Button loading={this.props.formLoading} type="primary" htmlType="submit">
                                        SUBMIT
                                    </Button>
                                </Item>
                                <Item className="px-5 mb-0">
                                    <span className="info">
                                        All the fields with * mark are mandatory
                                    </span>
                                </Item>
                            </Form>
                        </div>
                    </div>
                    <div className="ccpa-text-info pt-3">
                        <h3>Section 1798.110.</h3>
                        <p>
                            (a) A consumer shall have the right to request that a business that collects personal information about the consumer disclose to the consumer the following:
                        </p>
                        <p>
                            (1) The categories of personal information it has collected about that consumer.
                        </p>
                        <p>(2) The categories of sources from which the personal information is collected.</p>
                        <p>(3) The business or commercial purpose for collecting or selling personal information.</p>
                        <p>(4) The categories of third parties with whom the business shares personal information.</p>
                        <p>(5) The specific pieces of personal information it has collected about that consumer.</p>
                        <p>(b) A business that collects personal information about a consumer shall disclose to the consumer, pursuant to paragraph (3) of subdivision (a) of Section 1798.130, the information specified in subdivision (a) upon receipt of a verifiable consumer request from the consumer.</p>
                        <input type="radio" name='expander' id='expander' />
                        <label htmlFor="expander" className="btn btn-primary show-all-text">expand to read more</label>
                        <span className="content">
                            <p>(c) A business that collects personal information about consumers shall disclose, pursuant to subparagraph (B) of paragraph (5) of subdivision (a) of Section 1798.130:</p>
                            <p>(1) The categories of personal information it has collected about that consumer.</p>
                            <p>(2) The categories of sources from which the personal information is collected.</p>
                            <p>(3) The business or commercial purpose for collecting or selling personal information.</p>
                            <p>(4) The categories of third parties with whom the business shares personal information.</p>
                            <p>(5) The specific pieces of personal information the business has collected about that consumer.</p>
                            <p>(d) This section does not require a business to do the following:</p>
                            <p>(1) Retain any personal information about a consumer collected for a single one-time transaction if, in the ordinary course of business, that information about the consumer is not retained.</p>
                            <p>(2) Reidentify or otherwise link any data that, in the ordinary course of business, is not maintained in a manner that would be considered personal information.</p>
                            <h3>Section 1798.115.</h3>
                            <p>(a) A consumer shall have the right to request that a business that sells the consumer’s personal information, or that discloses it for a business purpose, disclose to that consumer:</p>
                            <p>(1) The categories of personal information that the business collected about the consumer.</p>
                            <p>(2) The categories of personal information that the business sold about the consumer and the categories of third parties to whom the personal information was sold, by category or categories of personal information for each third party to whom the personal information was sold.</p>
                            <p>(3) The categories of personal information that the business disclosed about the consumer for a business purpose.</p>
                            <p>(b) A business that sells personal information about a consumer, or that discloses a consumer’s personal information for a business purpose, shall disclose, pursuant to paragraph (4) of subdivision (a) of Section 1798.130, the information specified in subdivision (a) to the consumer upon receipt of a verifiable consumer request from the consumer.</p>
                            <p>
                                (c) A business that sells consumers’ personal information, or that discloses consumers’ personal information for a business purpose, shall disclose, pursuant to subparagraph (C) of paragraph (5) of subdivision (a) of Section 1798.130:
                            </p>
                            <p>
                                (1) The category or categories of consumers’ personal information it has sold, or if the business has not sold consumers’ personal information, it shall disclose that fact.
                            </p>
                            <p>
                                (2) The category or categories of consumers’ personal information it has disclosed for a business purpose, or if the business has not disclosed the consumers’ personal information for a business purpose, it shall disclose that fact.
                            </p>
                            <p>
                                (d) A third party shall not sell personal information about a consumer that has been sold to the third party by a business unless the consumer has received explicit notice and is provided an opportunity to exercise the right to opt-out pursuant to Section 1798.120.
                            </p>
                            <h3>Section 1798.120.</h3>
                            <p>
                                (a) A consumer shall have the right, at any time, to direct a business that sells personal information about the consumer to third parties not to sell the consumer’s personal information. This right may be referred to as the right to opt-out.
                            </p>
                            <p>
                                (a) A consumer shall have the right, at any time, to direct a business that sells personal information about the consumer to third parties not to sell the consumer’s personal information. This right may be referred to as the right to opt-out.
                            </p>
                            <p>
                                (c) Notwithstanding subdivision (a), a business shall not sell the personal information of consumers if the business has actual knowledge that the consumer is less than 16 years of age, unless the consumer, in the case of consumers between 13 and 16 years of age, or the consumer’s parent or guardian, in the case of consumers who are less than 13 years of age, has affirmatively authorized the sale of the consumer’s personal information. A business that willfully disregards the consumer’s age shall be deemed to have had actual knowledge of the consumer’s age. This right may be referred to as the “right to opt-in.”
                            </p>
                            <p>
                                (d) A business that has received direction from a consumer not to sell the consumer’s personal information or, in the case of a minor consumer’s personal information has not received consent to sell the minor consumer’s personal information shall be prohibited, pursuant to paragraph (4) of subdivision (a) of Section 1798.135, from selling the consumer’s personal information after its receipt of the consumer’s direction, unless the consumer subsequently provides express authorization for the sale of the consumer’s personal information.
                            </p>
                            <h3>Section 1798.125.</h3>
                            <p>
                                (a) (1) A business shall not discriminate against a consumer because the consumer exercised any of the consumer’s rights under this title, including, but not limited to, by:
                            </p>
                            <p>
                                (A) Denying goods or services to the consumer.
                            </p>
                            <p>
                                (B) Charging different prices or rates for goods or services, including through the use of discounts or other benefits or imposing penalties.
                            </p>
                            <p>
                                (C) Providing a different level or quality of goods or services to the consumer.
                            </p>
                            <p>
                                (D) Suggesting that the consumer will receive a different price or rate for goods or services or a different level or quality of goods or services.
                            </p>
                            <p>
                                (2) Nothing in this subdivision prohibits a business from charging a consumer a different price or rate, or from providing a different level or quality of goods or services to the consumer, if that difference is reasonably related to the value provided to the consumer by the consumer’s data.
                            </p>
                            <p>
                                (b) (1) A business may offer financial incentives, including payments to consumers as compensation, for the collection of personal information, the sale of personal information, or the deletion of personal information. A business may also offer a different price, rate, level, or quality of goods or services to the consumer if that price or difference is directly related to the value provided to the consumer by the consumer’s data.
                            </p>
                            <p>
                                (2) A business that offers any financial incentives pursuant to subdivision (a), shall notify consumers of the financial incentives pursuant to Section 1798.135.
                            </p>
                            <p>
                                (3) A business may enter a consumer into a financial incentive program only if the consumer gives the business prior opt-in consent pursuant to Section 1798.135 which clearly describes the material terms of the financial incentive program, and which may be revoked by the consumer at any time.
                            </p>
                            <p>
                                (4) A business shall not use financial incentive practices that are unjust, unreasonable, coercive, or usurious in nature.
                            </p>
                            <h3>Section 1798.130.</h3>
                            <p>
                                (a) In order to comply with Sections 1798.100, 1798.105, 1798.110, 1798.115, and 1798.125, a business shall, in a form that is reasonably accessible to consumers:
                            </p>
                            <p>
                                (1) Make available to consumers two or more designated methods for submitting requests for information required to be disclosed pursuant to Sections 1798.110 and 1798.115, including, at a minimum, a toll-free telephone number, and if the business maintains an Internet Web site, a Web site address.
                            </p>
                            <p>
                                (2) Disclose and deliver the required information to a consumer free of charge within 45 days of receiving a verifiable consumer request from the consumer. The business shall promptly take steps to determine whether the request is a verifiable consumer request, but this shall not extend the business’s duty to disclose and deliver the information within 45 days of receipt of the consumer’s request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary, provided the consumer is provided notice of the extension within the first 45-day period. The disclosure shall cover the 12-month period preceding the business’s receipt of the verifiable consumer request and shall be made in writing and delivered through the consumer’s account with the business, if the consumer maintains an account with the business, or by mail or electronically at the consumer’s option if the consumer does not maintain an account with the business, in a readily useable format that allows the consumer to transmit this information from one entity to another entity without hindrance. The business shall not require the consumer to create an account with the business in order to make a verifiable consumer request.
                            </p>
                            <p>
                                (3) For purposes of subdivision (b) of Section 1798.110:
                            </p>
                            <p>
                                (A) To identify the consumer, associate the information provided by the consumer in the verifiable consumer request to any personal information previously collected by the business about the consumer.
                            </p>
                            <p>
                                (B) Identify by category or categories the personal information collected about the consumer in the preceding 12 months by reference to the enumerated category or categories in subdivision (c) that most closely describes the personal information collected.
                            </p>
                            <p>
                                (4) For purposes of subdivision (b) of Section 1798.115:
                            </p>
                            <p>
                                (A) Identify the consumer and associate the information provided by the consumer in the verifiable consumer request to any personal information previously collected by the business about the consumer.
                            </p>
                            <p>
                                (B) Identify by category or categories the personal information of the consumer that the business sold in the preceding 12 months by reference to the enumerated category in subdivision (c) that most closely describes the personal information, and provide the categories of third parties to whom the consumer’s personal information was sold in the preceding 12 months by reference to the enumerated category or categories in subdivision (c) that most closely describes the personal information sold. The business shall disclose the information in a list that is separate from a list generated for the purposes of subparagraph (C).
                            </p>
                            <p>
                                (C) Identify by category or categories the personal information of the consumer that the business disclosed for a business purpose in the preceding 12 months by reference to the enumerated category or categories in subdivision (c) that most closely describes the personal information, and provide the categories of third parties to whom the consumer’s personal information was disclosed for a business purpose in the preceding 12 months by reference to the enumerated category or categories in subdivision (c) that most closely describes the personal information disclosed. The business shall disclose the information in a list that is separate from a list generated for the purposes of subparagraph (B).
                            </p>
                            <p>
                                (5) Disclose the following information in its online privacy policy or policies if the business has an online privacy policy or policies and in any California-specific description of consumers’ privacy rights, or if the business does not maintain those policies, on its Internet Web site, and update that information at least once every 12 months:
                            </p>
                            <p>
                                (A) A description of a consumer’s rights pursuant to Sections 1798.110, 1798.115, and 1798.125 and one or more designated methods for submitting requests.
                            </p>
                            <p>
                                (B) For purposes of subdivision (c) of Section 1798.110, a list of the categories of personal information it has collected about consumers in the preceding 12 months by reference to the enumerated category or categories in subdivision (c) that most closely describe the personal information collected.
                            </p>
                            <p>
                                (C) For purposes of paragraphs (1) and (2) of subdivision (c) of Section 1798.115, two separate lists:
                            </p>
                            <p>
                                (i) A list of the categories of personal information it has sold about consumers in the preceding 12 months by reference to the enumerated category or categories in subdivision (c) that most closely describe the personal information sold, or if the business has not sold consumers’ personal information in the preceding 12 months, the business shall disclose that fact.
                            </p>
                            <p>
                                (ii) A list of the categories of personal information it has disclosed about consumers for a business purpose in the preceding 12 months by reference to the enumerated category in subdivision (c) that most closely describe the personal information disclosed, or if the business has not disclosed consumers’ personal information for a business purpose in the preceding 12 months, the business shall disclose that fact.
                            </p>
                            <p>
                                (6) Ensure that all individuals responsible for handling consumer inquiries about the business’s privacy practices or the business’s compliance with this title are informed of all requirements in Sections 1798.110, 1798.115, 1798.125, and this section, and how to direct consumers to exercise their rights under those sections.
                            </p>
                            <p>
                                (7) Use any personal information collected from the consumer in connection with the business’s verification of the consumer’s request solely for the purposes of verification.
                            </p>
                            <p>
                                (b) A business is not obligated to provide the information required by Sections 1798.110 and 1798.115 to the same consumer more than twice in a 12-month period.
                            </p>
                            <p>
                                (c) The categories of personal information required to be disclosed pursuant to Sections 1798.110 and 1798.115 shall follow the definition of personal information in Section 1798.140.
                            </p>
                            <h3>Section 1798.135.</h3>
                            <p>
                                (a) A business that is required to comply with Section 1798.120 shall, in a form that is reasonably accessible to consumers:
                            </p>
                            <p>
                                (1) Provide a clear and conspicuous link on the business’s Internet homepage, titled “Do Not Sell My Personal Information,” to an Internet Web page that enables a consumer, or a person authorized by the consumer, to opt-out of the sale of the consumer’s personal information. A business shall not require a consumer to create an account in order to direct the business not to sell the consumer’s personal information.
                            </p>
                            <p>
                                (2) Include a description of a consumer’s rights pursuant to Section 1798.120, along with a separate link to the “Do Not Sell My Personal Information” Internet Web page in:
                            </p>
                            <p>
                                (A) Its online privacy policy or policies if the business has an online privacy policy or policies.
                            </p>
                            <p>
                                (B) Any California-specific description of consumers’ privacy rights.
                            </p>
                            <p>
                                (3) Ensure that all individuals responsible for handling consumer inquiries about the business’s privacy practices or the business’s compliance with this title are informed of all requirements in Section 1798.120 and this section and how to direct consumers to exercise their rights under those sections.
                            </p>
                            <p>
                                (4) For consumers who exercise their right to opt-out of the sale of their personal information, refrain from selling personal information collected by the business about the consumer.
                            </p>
                            <p>
                                (5) For a consumer who has opted-out of the sale of the consumer’s personal information, respect the consumer’s decision to opt-out for at least 12 months before requesting that the consumer authorize the sale of the consumer’s personal information.
                            </p>
                            <p>
                                (5) For a consumer who has opted-out of the sale of the consumer’s personal information, respect the consumer’s decision to opt-out for at least 12 months before requesting that the consumer authorize the sale of the consumer’s personal information.
                            </p>
                            <p>
                                (b) Nothing in this title shall be construed to require a business to comply with the title by including the required links and text on the homepage that the business makes available to the public generally, if the business maintains a separate and additional homepage that is dedicated to California consumers and that includes the required links and text, and the business takes reasonable steps to ensure that California consumers are directed to the homepage for California consumers and not the homepage made available to the public generally.
                            </p>
                            <p>
                                (c) A consumer may authorize another person solely to opt-out of the sale of the consumer’s personal information on the consumer’s behalf, and a business shall comply with an opt-out request received from a person authorized by the consumer to act on the consumer’s behalf, pursuant to regulations adopted by the Attorney General.
                            </p>
                            <h3>Section 1798.140.</h3>
                            <p>
                                For purposes of this title:
                            </p>
                            <p>
                                (a) “Aggregate consumer information” means information that relates to a group or category of consumers, from which individual consumer identities have been removed, that is not linked or reasonably linkable to any consumer or household, including via a device. “Aggregate consumer information” does not mean one or more individual consumer records that have been de¬identified.
                            </p>
                            <p>
                                (b) “Biometric information” means an individual’s physiological, biological or behavioral characteristics, including an individual’s deoxyribonucleic acid (DNA), that can be used, singly or in combination with each other or with other identifying data, to establish individual identity. Biometric information includes, but is not limited to, imagery of the iris, retina, fingerprint, face, hand, palm, vein patterns, and voice recordings, from which an identifier template, such as a faceprint, a minutiae template, or a voiceprint, can be extracted, and keystroke patterns or rhythms, gait patterns or rhythms, and sleep, health, or exercise data that contain identifying information.
                            </p>
                            <p>
                                (c) “Business” means:
                            </p>
                            <p>
                                (1) A sole proprietorship, partnership, limited liability company, corporation, association, or other legal entity that is organized or operated for the profit or financial benefit of its shareholders or other owners, that collects consumers’ personal information, or on the behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers’ personal information, that does business in the State of California, and that satisfies one or more of the following thresholds:
                            </p>
                            <p>
                                (A) Has annual gross revenues in excess of twenty-five million dollars ($25,000,000), as adjusted pursuant to paragraph (5) of subdivision (a) of Section 1798.185.
                            </p>
                            <p>
                                (B) Alone or in combination, annually buys, receives for the business’s commercial purposes, sells, or shares for commercial purposes, alone or in combination, the personal information of 50,000 or more consumers, households, or devices.
                            </p>
                            <p>
                                (C) Derives 50 percent or more of its annual revenues from selling consumers’ personal information.
                            </p>
                            <p>
                                (2) Any entity that controls or is controlled by a business, as defined in paragraph (1), and that shares common branding with the business. “Control” or “controlled” means ownership of, or the power to vote, more than 50 percent of the outstanding shares of any class of voting security of a business; control in any manner over the election of a majority of the directors, or of individuals exercising similar functions; or the power to exercise a controlling influence over the management of a company. “Common branding” means a shared name, servicemark, or trademark.
                            </p>
                            <p>
                                (d) “Business purpose” means the use of personal information for the business’s or a service provider’s operational purposes, or other notified purposes, provided that the use of personal information shall be reasonably necessary and proportionate to achieve the operational purpose for which the personal information was collected or processed or for another operational purpose that is compatible with the context in which the personal information was collected. Business purposes are:
                            </p>
                            <p>
                                (1) Auditing related to a current interaction with the consumer and concurrent transactions, including, but not limited to, counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing compliance with this specification and other standards.
                            </p>
                            <p>
                                (2) Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.
                            </p>
                            <p>
                                (3) Debugging to identify and repair errors that impair existing intended functionality.
                            </p>
                            <p>
                                (4) Short-term, transient use, provided the personal information that is not disclosed to another third party and is not used to build a profile about a consumer or otherwise alter an individual consumer’s experience outside the current interaction, including, but not limited to, the contextual customization of ads shown as part of the same interaction.
                            </p>
                            <p>
                                (5) Performing services on behalf of the business or service provider, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing financing, providing advertising or marketing services, providing analytic services, or providing similar services on behalf of the business or service provider.
                            </p>
                            <p>
                                (6) Undertaking internal research for technological development and demonstration.
                            </p>
                            <p>
                                (7) Undertaking activities to verify or maintain the quality or safety of a service or device that is owned, manufactured, manufactured for, or controlled by the business, and to improve, upgrade, or enhance the service or device that is owned, manufactured, manufactured for, or controlled by the business.
                            </p>
                            <p>
                                (e) “Collects,” “collected,” or “collection” means buying, renting, gathering, obtaining, receiving, or accessing any personal information pertaining to a consumer by any means. This includes receiving information from the consumer, either actively or passively, or by observing the consumer’s behavior.
                            </p>
                            <p>
                                (f) “Commercial purposes” means to advance a person’s commercial or economic interests, such as by inducing another person to buy, rent, lease, join, subscribe to, provide, or exchange products, goods, property, information, or services, or enabling or effecting, directly or indirectly, a commercial transaction. “Commercial purposes” do not include for the purpose of engaging in speech that state or federal courts have recognized as noncommercial speech, including political speech and journalism.
                            </p>
                            <p>
                                (g) “Consumer” means a natural person who is a California resident, as defined in Section 17014 of Title 18 of the California Code of Regulations, as that section read on September 1, 2017, however identified, including by any unique identifier.
                            </p>
                            <p>
                                (h) “Deidentified” means information that cannot reasonably identify, relate to, describe, be capable of being associated with, or be linked, directly or indirectly, to a particular consumer, provided that a business that uses deidentified information:
                            </p>
                            <p>
                                (1) Has implemented technical safeguards that prohibit reidentification of the consumer to whom the information may pertain.
                            </p>
                            <p>
                                (2) Has implemented business processes that specifically prohibit reidentification of the information.
                            </p>
                            <p>
                                (3) Has implemented business processes to prevent inadvertent release of deidentified information.
                            </p>
                            <p>
                                (4) Makes no attempt to reidentify the information.
                            </p>
                            <p>
                                (i) “Designated methods for submitting requests” means a mailing address, email address, Internet Web page, Internet Web portal, toll-free telephone number, or other applicable contact information, whereby consumers may submit a request or direction under this title, and any new, consumer-friendly means of contacting a business, as approved by the Attorney General pursuant to Section 1798.185.
                            </p>
                            <p>
                                (j) “Device” means any physical object that is capable of connecting to the Internet, directly or indirectly, or to another device.
                            </p>
                            <p>
                                (k) “Health insurance information” means a consumer’s insurance policy number or subscriber identification number, any unique identifier used by a health insurer to identify the consumer, or any information in the consumer’s application and claims history, including any appeals records, if the information is linked or reasonably linkable to a consumer or household, including via a device, by a business or service provider.
                            </p>
                            <p>
                                (l) “Homepage” means the introductory page of an Internet Web site and any Internet Web page where personal information is collected. In the case of an online service, such as a mobile application, homepage means the application’s platform page or download page, a link within the application, such as from the application configuration, “About,” “Information,” or settings page, and any other location that allows consumers to review the notice required by subdivision (a) of Section 1798.145, including, but not limited to, before downloading the application.
                            </p>
                            <p>
                                (m) “Infer” or “inference” means the derivation of information, data, assumptions, or conclusions from facts, evidence, or another source of information or data.
                            </p>
                            <p>
                                (n) “Person” means an individual, proprietorship, firm, partnership, joint venture, syndicate, business trust, company, corporation, limited liability company, association, committee, and any other organization or group of persons acting in concert.
                            </p>
                            <p>
                                (o) (1) “Personal information” means information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household. Personal information includes, but is not limited to, the following if it identifies, relates to, describes, is capable of being associated with, or could be reasonably linked, directly or indirectly, with a particular consumer or household:
                            </p>
                            <p>
                                (A) Identifiers such as a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers.
                            </p>
                            <p>
                                (B) Any categories of personal information described in subdivision (e) of Section 1798.80.
                            </p>
                            <p>
                                (C) Characteristics of protected classifications under California or federal law.
                            </p>
                            <p>
                                (D) Commercial information, including records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.
                            </p>
                            <p>
                                (E) Biometric information.
                            </p>
                            <p>
                                (F) Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement.
                            </p>
                            <p>
                                (G) Geolocation data.
                            </p>
                            <p>
                                (H) Audio, electronic, visual, thermal, olfactory, or similar information.
                            </p>
                            <p>
                                (I) Professional or employment-related information.
                            </p>
                            <p>
                                (J) Education information, defined as information that is not publicly available personally identifiable information as defined in the Family Educational Rights and Privacy Act (20 U.S.C. section 1232g, 34 C.F.R. Part 99).
                            </p>
                            <p>
                                (K) Inferences drawn from any of the information identified in this subdivision to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
                            </p>
                            <p>
                                (2) “Personal information” does not include publicly available information. For these purposes, “publicly available” means information that is lawfully made available from federal, state, or local government records, if any conditions associated with such information. “Publicly available” does not mean biometric information collected by a business about a consumer without the consumer’s knowledge. Information is not “publicly available” if that data is used for a purpose that is not compatible with the purpose for which the data is maintained and made available in the government records or for which it is publicly maintained. “Publicly available” does not include consumer information that is deidentified or aggregate consumer information.
                            </p>
                            <p>
                                (p) “Probabilistic identifier” means the identification of a consumer or a device to a degree of certainty of more probable than not based on any categories of personal information included in, or similar to, the categories enumerated in the definition of personal information.
                            </p>
                            <p>
                                (q) “Processing” means any operation or set of operations that are performed on personal data or on sets of personal data, whether or not by automated means.
                            </p>
                            <p>
                                (r) “Pseudonymize” or “Pseudonymization” means the processing of personal information in a manner that renders the personal information no longer attributable to a specific consumer without the use of additional information, provided that the additional information is kept separately and is subject to technical and organizational measures to ensure that the personal information is not attributed to an identified or identifiable consumer.
                            </p>
                            <p>
                                (s) “Research” means scientific, systematic study and observation, including basic research or applied research that is in the public interest and that adheres to all other applicable ethics and privacy laws or studies conducted in the public interest in the area of public health. Research with personal information that may have been collected from a consumer in the course of the consumer’s interactions with a business’s service or device for other purposes shall be:
                            </p>
                            <p>
                                (1) Compatible with the business purpose for which the personal information was collected.
                            </p>
                            <p>
                                (2) Subsequently pseudonymized and deidentified, or deidentified and in the aggregate, such that the information cannot reasonably identify, relate to, describe, be capable of being associated with, or be linked, directly or indirectly, to a particular consumer.
                            </p>
                            <p>
                                (3) Made subject to technical safeguards that prohibit reidentification of the consumer to whom the information may pertain.
                            </p>
                            <p>
                                (4) Subject to business processes that specifically prohibit reidentification of the information.
                            </p>
                            <p>
                                (5) Made subject to business processes to prevent inadvertent release of deidentified information.
                            </p>
                            <p>
                                (6) Protected from any reidentification attempts.
                            </p>
                            <p>
                                (7) Used solely for research purposes that are compatible with the context in which the personal information was collected.
                            </p>
                            <p>
                                (8) Not be used for any commercial purpose.
                            </p>
                            <p>
                                (9) Subjected by the business conducting the research to additional security controls limit access to the research data to only those individuals in a business as are necessary to carry out the research purpose.
                            </p>
                            <p>
                                (t) (1) “Sell,” “selling,” “sale,” or “sold,” means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a consumer’s personal information by the business to another business or a third party for monetary or other valuable consideration.
                            </p>
                            <p>
                                (2) For purposes of this title, a business does not sell personal information when:
                            </p>
                            <p>
                                (A) A consumer uses or directs the business to intentionally disclose personal information or uses the business to intentionally interact with a third party, provided the third party does not also sell the personal information, unless that disclosure would be consistent with the provisions of this title. An intentional interaction occurs when the consumer intends to interact with the third party, via one or more deliberate interactions. Hovering over, muting, pausing, or closing a given piece of content does not constitute a consumer’s intent to interact with a third party.
                            </p>
                            <p>
                                (B) The business uses or shares an identifier for a consumer who has opted out of the sale of the consumer’s personal information for the purposes of alerting third parties that the consumer has opted out of the sale of the consumer’s personal information.
                            </p>
                            <p>
                                (C) The business uses or shares with a service provider personal information of a consumer that is necessary to perform a business purpose if both of the following conditions are met:
                            </p>
                            <p>
                                (i) The business has provided notice that information being used or shared in its terms and conditions consistent with Section 1798.135.
                            </p>
                            <p>
                                (ii) The service provider does not further collect, sell, or use the personal information of the consumer except as necessary to perform the business purpose.
                            </p>
                            <p>
                                (D) The business transfers to a third party the personal information of a consumer as an asset that is part of a merger, acquisition, bankruptcy, or other transaction in which the third party assumes control of all or part of the business, provided that information is used or shared consistently with Sections 1798.110 and 1798.115. If a third party materially alters how it uses or shares the personal information of a consumer in a manner that is materially inconsistent with the promises made at the time of collection, it shall provide prior notice of the new or changed practice to the consumer. The notice shall be sufficiently prominent and robust to ensure that existing consumers can easily exercise their choices consistently with Section 1798.120. This subparagraph does not authorize a business to make material, retroactive privacy policy changes or make other changes in their privacy policy in a manner that would violate the Unfair and Deceptive Practices Act (Chapter 5 (commencing with Section 17200) of Part 2 of Division 7 of the Business and Professions Code).
                            </p>
                            <p>
                                (u) “Service” or “services” means work, labor, and services, including services furnished in connection with the sale or repair of goods.
                            </p>
                            <p>
                                (v) “Service provider” means a sole proprietorship, partnership, limited liability company, corporation, association, or other legal entity that is organized or operated for the profit or financial benefit of its shareholders or other owners, that processes information on behalf of a business and to which the business discloses a consumer’s personal information for a business purpose pursuant to a written contract, provided that the contract prohibits the entity receiving the information from retaining, using, or disclosing the personal information for any purpose other than for the specific purpose of performing the services specified in the contract for the business, or as otherwise permitted by this title, including retaining, using, or disclosing the personal information for a commercial purpose other than providing the services specified in the contract with the business.
                            </p>
                            <p>
                                (w) “Third party” means a person who is not any of the following:
                            </p>
                            <p>
                                (1) The business that collects personal information from consumers under this title.
                            </p>
                            <p>
                                (2) (A) A person to whom the business discloses a consumer’s personal information for a business purpose pursuant to a written contract, provided that the contract:
                            </p>
                            <p>
                                (i) Prohibits the person receiving the personal information from:
                            </p>
                            <p>
                                (I) Selling the personal information.
                            </p>
                            <p>
                                (II) Retaining, using, or disclosing the personal information for any purpose other than for the specific purpose of performing the services specified in the contract, including retaining, using, or disclosing the personal information for a commercial purpose other than providing the services specified in the contract.
                            </p>
                            <p>
                                (III) Retaining, using, or disclosing the information outside of the direct business relationship between the person and the business.
                            </p>
                            <p>
                                (ii) Includes a certification made by the person receiving the personal information that the person understands the restrictions in subparagraph (A) and will comply with them.
                            </p>
                            <p>
                                (B) A person covered by this paragraph that violates any of the restrictions set forth in this title shall be liable for the violations. A business that discloses personal information to a person covered by this paragraph in compliance with this paragraph shall not be liable under this title if the person receiving the personal information uses it in violation of the restrictions set forth in this title, provided that, at the time of disclosing the personal information, the business does not have actual knowledge, or reason to believe, that the person intends to commit such a violation.
                            </p>
                            <p>
                                (x) “Unique identifier” or “Unique personal identifier” means a persistent identifier that can be used to recognize a consumer, a family, or a device that is linked to a consumer or family, over time and across different services, including, but not limited to, a device identifier; an Internet Protocol address; cookies, beacons, pixel tags, mobile ad identifiers, or similar technology; customer number, unique pseudonym, or user alias; telephone numbers, or other forms of persistent or probabilistic identifiers that can be used to identify a particular consumer or device. For purposes of this subdivision, “family” means a custodial parent or guardian and any minor children over which the parent or guardian has custody.
                            </p>
                            <p>
                                (y) “Verifiable consumer request” means a request that is made by a consumer, by a consumer on behalf of the consumer’s minor child, or by a natural person or a person registered with the Secretary of State, authorized by the consumer to act on the consumer’s behalf, and that the business can reasonably verify, pursuant to regulations adopted by the Attorney General pursuant to paragraph (7) of subdivision (a) of Section 1798.185 to be the consumer about whom the business has collected personal information. A business is not obligated to provide information to the consumer pursuant to Sections 1798.110 and 1798.115 if the business cannot verify, pursuant this subdivision and regulations adopted by the Attorney General pursuant to paragraph (7) of subdivision (a) of Section 1798.185, that the consumer making the request is the consumer about whom the business has collected information or is a person authorized by the consumer to act on such consumer’s behalf.
                            </p>
                            <h3>Section 1798.145.</h3>
                            <p>
                                (a) The obligations imposed on businesses by this title shall not restrict a business’s ability to:
                            </p>
                            <p>
                                (1) Comply with federal, state, or local laws.
                            </p>
                            <p>
                                (2) Comply with a civil, criminal, or regulatory inquiry, investigation, subpoena, or summons by federal, state, or local authorities.
                            </p>
                            <p>
                                (3) Cooperate with law enforcement agencies concerning conduct or activity that the business, service provider, or third party reasonably and in good faith believes may violate federal, state, or local law.
                            </p>
                            <p>
                                (4) Exercise or defend legal claims.
                            </p>
                            <p>
                                (6) Collect or sell a consumer’s personal information if every aspect of that commercial conduct takes place wholly outside of California. For purposes of this title, commercial conduct takes place wholly outside of California if the business collected that information while the consumer was outside of California, no part of the sale of the consumer’s personal information occurred in California, and no personal information collected while the consumer was in California is sold. This paragraph shall not permit a business from storing, including on a device, personal information about a consumer when the consumer is in California and then collecting that personal information when the consumer and stored personal information is outside of California.
                            </p>
                            <p>
                                (6) Collect or sell a consumer’s personal information if every aspect of that commercial conduct takes place wholly outside of California. For purposes of this title, commercial conduct takes place wholly outside of California if the business collected that information while the consumer was outside of California, no part of the sale of the consumer’s personal information occurred in California, and no personal information collected while the consumer was in California is sold. This paragraph shall not permit a business from storing, including on a device, personal information about a consumer when the consumer is in California and then collecting that personal information when the consumer and stored personal information is outside of California.
                            </p>
                            <p>
                                (b) The obligations imposed on businesses by Sections 1798.110 to 1798.135, inclusive, shall not apply where compliance by the business with the title would violate an evidentiary privilege under California law and shall not prevent a business from providing the personal information of a consumer to a person covered by an evidentiary privilege under California law as part of a privileged communication.
                            </p>
                            <p>
                                (c) (1) This title shall not apply to any of the following:
                            </p>
                            <p>
                                (A) Medical information governed by the Confidentiality of Medical Information Act (Part 2.6 (commencing with Section 56) of Division 1) or protected health information that is collected by a covered entity or business associate governed by the privacy, security, and breach notification rules issued by the United States Department of Health and Human Services, Parts 160 and 164 of Title 45 of the Code of Federal Regulations, established pursuant to the Health Insurance Portability and Accountability Act of 1996 (Public Law 104-191) and the Health Information Technology for Economic and Clinical Health Act (Public Law 111-5).
                            </p>
                            <p>
                                (B) A provider of health care governed by the Confidentiality of Medical Information Act (Part 2.6 (commencing with Section 56) of Division 1) or a covered entity governed by the privacy, security, and breach notification rules issued by the United States Department of Health and Human Services, Parts 160 and 164 of Title 45 of the Code of Federal Regulations, established pursuant to the Health Insurance Portability and Accountability Act of 1996 (Public Law 104-191), to the extent the provider or covered entity maintains patient information in the same manner as medical information or protected health information as described in subparagraph (A) of this section.
                            </p>
                            <p>
                                (C) Information collected as part of a clinical trial subject to the Federal Policy for the Protection of Human Subjects, also known as the Common Rule, pursuant to good clinical practice guidelines issued by the International Council for Harmonisation or pursuant to human subject protection requirements of the United States Food and Drug Administration.
                            </p>
                            <p>
                                (2) For purposes of this subdivision, the definitions of “medical information” and “provider of health care” in Section 56.05 shall apply and the definitions of “business associate,” “covered entity,” and “protected health information” in Section 160.103 of Title 45 of the Code of Federal Regulations shall apply.
                            </p>
                            <p>
                                (d) This title shall not apply to the sale of personal information to or from a consumer reporting agency if that information is to be reported in, or used to generate, a consumer report as defined by subdivision (d) of Section 1681a of Title 15 of the United States Code, and use of that information is limited by the federal Fair Credit Reporting Act (15 U.S.C. Sec. 1681 et seq.).
                            </p>
                            <p>
                                (e) This title shall not apply to personal information collected, processed, sold, or disclosed pursuant to the federal Gramm-Leach-Bliley Act (Public Law 106-102), and implementing regulations, or the California Financial Information Privacy Act (Division 1.4 (commencing with Section 4050) of the Financial Code). This subdivision shall not apply to Section 1798.150.
                            </p>
                            <p>
                                (f) This title shall not apply to personal information collected, processed, sold, or disclosed pursuant to the Driver’s Privacy Protection Act of 1994 (18 U.S.C. Sec. 2721 et seq.). This subdivision shall not apply to Section 1798.150.
                            </p>
                            <p>
                                (g) Notwithstanding a business’s obligations to respond to and honor consumer rights requests pursuant to this title:
                            </p>
                            <p>
                                (1) A time period for a business to respond to any verified consumer request may be extended by up to 90 additional days where necessary, taking into account the complexity and number of the requests. The business shall inform the consumer of any such extension within 45 days of receipt of the request, together with the reasons for the delay.
                            </p>
                            <p>
                                (1) A time period for a business to respond to any verified consumer request may be extended by up to 90 additional days where necessary, taking into account the complexity and number of the requests. The business shall inform the consumer of any such extension within 45 days of receipt of the request, together with the reasons for the delay.
                            </p>
                            <p>
                                (3) If requests from a consumer are manifestly unfounded or excessive, in particular because of their repetitive character, a business may either charge a reasonable fee, taking into account the administrative costs of providing the information or communication or taking the action requested, or refuse to act on the request and notify the consumer of the reason for refusing the request. The business shall bear the burden of demonstrating that any verified consumer request is manifestly unfounded or excessive.
                            </p>
                            <p>
                                (h) A business that discloses personal information to a service provider shall not be liable under this title if the service provider receiving the personal information uses it in violation of the restrictions set forth in the title, provided that, at the time of disclosing the personal information, the business does not have actual knowledge, or reason to believe, that the service provider intends to commit such a violation. A service provider shall likewise not be liable under this title for the obligations of a business for which it provides services as set forth in this title.
                            </p>
                            <p>
                                (i) This title shall not be construed to require a business to reidentify or otherwise link information that is not maintained in a manner that would be considered personal information.
                            </p>
                            <p>
                                (j) The rights afforded to consumers and the obligations imposed on the business in this title shall not adversely affect the rights and freedoms of other consumers.
                            </p>
                            <p>
                                (k) The rights afforded to consumers and the obligations imposed on any business under this title shall not apply to the extent that they infringe on the noncommercial activities of a person or entity described in subdivision (b) of Section 2 of Article I of the California Constitution.
                            </p>
                            <h3>Section 1798.150.</h3>
                            <p>
                                (a) (1) Any consumer whose nonencrypted or nonredacted personal information, as defined in subparagraph (A) of paragraph (1) of subdivision (d) of Section 1798.81.5, is subject to an unauthorized access and exfiltration, theft, or disclosure as a result of the business’s violation of the duty to implement and maintain reasonable security procedures and practices appropriate to the nature of the information to protect the personal information may institute a civil action for any of the following:
                            </p>
                            <p>
                                (A) To recover damages in an amount not less than one hundred dollars ($100) and not greater than seven hundred and fifty ($750) per consumer per incident or actual damages, whichever is greater.
                            </p>
                            <p>
                                (B) Injunctive or declaratory relief.
                            </p>
                            <p>
                                (C) Any other relief the court deems proper.
                            </p>
                            <p>
                                (2) In assessing the amount of statutory damages, the court shall consider any one or more of the relevant circumstances presented by any of the parties to the case, including, but not limited to, the nature and seriousness of the misconduct, the number of violations, the persistence of the misconduct, the length of time over which the misconduct occurred, the willfulness of the defendant’s misconduct, and the defendant’s assets, liabilities, and net worth.
                            </p>
                            <p>
                                (b) Actions pursuant to this section may be brought by a consumer if, prior to initiating any action against a business for statutory damages on an individual or class-wide basis, a consumer provides a business 30 days’ written notice identifying the specific provisions of this title the consumer alleges have been or are being violated. In the event a cure is possible, if within the 30 days the business actually cures the noticed violation and provides the consumer an express written statement that the violations have been cured and that no further violations shall occur, no action for individual statutory damages or class-wide statutory damages may be initiated against the business. No notice shall be required prior to an individual consumer initiating an action solely for actual pecuniary damages suffered as a result of the alleged violations of this title. If a business continues to violate this title in breach of the express written statement provided to the consumer under this section, the consumer may initiate an action against the business to enforce the written statement and may pursue statutory damages for each breach of the express written statement, as well as any other violation of the title that postdates the written statement.
                            </p>
                            <p>
                                (c) The cause of action established by this section shall apply only to violations as defined in subdivision (a) and shall not be based on violations of any other section of this title. Nothing in this title shall be interpreted to serve as the basis for a private right of action under any other law. This shall not be construed to relieve any party from any duties or obligations imposed under other law or the United States or California Constitution.
                            </p>
                            <h3>Section 1798.155.</h3>
                            <p>
                                (a) Any business or third party may seek the opinion of the Attorney General for guidance on how to comply with the provisions of this title.
                            </p>
                            <p>
                                (b) A business shall be in violation of this title if it fails to cure any alleged violation within 30 days after being notified of alleged noncompliance. Any business, service provider, or other person that violates this title shall be subject to an injunction and liable for a civil penalty of not more than two thousand five hundred dollars ($2,500) for each violation or seven thousand five hundred dollars ($7,500) for each intentional violation, which shall be assessed and recovered in a civil action brought in the name of the people of the State of California by the Attorney General. The civil penalties provided for in this section shall be exclusively assessed and recovered in a civil action brought in the name of the people of the State of California by the Attorney General.
                            </p>
                            <p>
                                (c) Any civil penalty assessed for a violation of this title, and the proceeds of any settlement of an action brought pursuant to subdivision (b), shall be deposited in the Consumer Privacy Fund, created within the General Fund pursuant to subdivision (a) of Section 1798.160 with the intent to fully offset any costs incurred by the state courts and the Attorney General in connection with this title.
                            </p>
                            <h3>Section 1798.160</h3>
                            <p>
                                (a) A special fund to be known as the “Consumer Privacy Fund” is hereby created within the General Fund in the State Treasury, and is available upon appropriation by the Legislature to offset any costs incurred by the state courts in connection with actions brought to enforce this title and any costs incurred by the Attorney General in carrying out the Attorney General’s duties under this title.
                            </p>
                            <p>
                                (b) Funds transferred to the Consumer Privacy Fund shall be used exclusively to offset any costs incurred by the state courts and the Attorney General in connection with this title. These funds shall not be subject to appropriation or transfer by the Legislature for any other purpose, unless the Director of Finance determines that the funds are in excess of the funding needed to fully offset the costs incurred by the state courts and the Attorney General in connection with this title, in which case the Legislature may appropriate excess funds for other purposes.
                            </p>
                            <h3>Section 1798.175</h3>
                            <p>
                                This title is intended to further the constitutional right of privacy and to supplement existing laws relating to consumers’ personal information, including, but not limited to, Chapter 22 (commencing with Section 22575) of Division 8 of the Business and Professions Code and Title 1.81 (commencing with Section 1798.80). The provisions of this title are not limited to information collected electronically or over the Internet, but apply to the collection and sale of all personal information collected by a business from consumers. Wherever possible, law relating to consumers’ personal information should be construed to harmonize with the provisions of this title, but in the event of a conflict between other laws and the provisions of this title, the provisions of the law that afford the greatest protection for the right of privacy for consumers shall control.
                            </p>
                            <h3>Section 1798.180</h3>
                            <p>
                                This title is a matter of statewide concern and supersedes and preempts all rules, regulations, codes, ordinances, and other laws adopted by a city, county, city and county, municipality, or local agency regarding the collection and sale of consumers’ personal information by a business.
                            </p>
                            <h3>Section 1798.185.</h3>
                            <p>
                                (a) On or before July 1, 2020, the Attorney General shall solicit broad public participation and adopt regulations to further the purposes of this title, including, but not limited to, the following areas:
                            </p>
                            <p>
                                (1) Updating as needed additional categories of personal information to those enumerated in subdivision (c) of Section 1798.130 and subdivision (o) of Section 1798.140 in order to address changes in technology, data collection practices, obstacles to implementation, and privacy concerns.
                            </p>
                            <p>
                                (2) Updating as needed the definition of unique identifiers to address changes in technology, data collection, obstacles to implementation, and privacy concerns, and additional categories to the definition of designated methods for submitting requests to facilitate a consumer’s ability to obtain information from a business pursuant to Section 1798.130.
                            </p>
                            <p>
                                (3) Establishing any exceptions necessary to comply with state or federal law, including, but not limited to, those relating to trade secrets and intellectual property rights, within one year of passage of this title and as needed thereafter.
                            </p>
                            <p>
                                (4) Establishing rules and procedures for the following:
                            </p>
                            <p>
                                (A) To facilitate and govern the submission of a request by a consumer to opt-out of the sale of personal information pursuant to paragraph (1) of subdivision (a) of Section 1798.145.
                            </p>
                            <p>
                                (B) To govern business compliance with a consumer’s opt-out request.
                            </p>
                            <p>
                                (C) For the development and use of a recognizable and uniform opt-out logo or button by all businesses to promote consumer awareness of the opportunity to opt-out of the sale of personal information.
                            </p>
                            <p>
                                (5) Adjusting the monetary threshold in subparagraph (A) of paragraph (1) of subdivision (c) of Section 1798.140 in January of every odd-numbered year to reflect any increase in the Consumer Price Index.
                            </p>
                            <p>
                                (5) Adjusting the monetary threshold in subparagraph (A) of paragraph (1) of subdivision (c) of Section 1798.140 in January of every odd-numbered year to reflect any increase in the Consumer Price Index.
                            </p>
                            <p>
                                (7) Establishing rules and procedures to further the purposes of Sections 1798.110 and 1798.115 and to facilitate a consumer’s or the consumer’s authorized agent’s ability to obtain information pursuant to Section 1798.130, with the goal of minimizing the administrative burden on consumers, taking into account available technology, security concerns, and the burden on the business, to govern a business’s determination that a request for information received by a consumer is a verifiable consumer request, including treating a request submitted through a password-protected account maintained by the consumer with the business while the consumer is logged into the account as a verifiable consumer request and providing a mechanism for a consumer who does not maintain an account with the business to request information through the business’s authentication of the consumer’s identity, within one year of passage of this title and as needed thereafter.
                            </p>
                            <p>
                                (b) The Attorney General may adopt additional regulations as necessary to further the purposes of this title.
                            </p>
                            <p>
                                (c) The Attorney General shall not bring an enforcement action under this title until six months after the publication of the final regulations issued pursuant to this section or July 1, 2020, whichever is sooner.
                            </p>
                            <h3>Section 1798.190</h3>
                            <p>
                                If a series of steps or transactions were component parts of a single transaction intended from the beginning to be taken with the intention of avoiding the reach of this title, including the disclosure of information by a business to a third party in order to avoid the definition of sell, a court shall disregard the intermediate steps or transactions for purposes of effectuating the purposes of this title.
                            </p>
                            <h3>Section 1798.192.</h3>
                            <p>
                                Any provision of a contract or agreement of any kind that purports to waive or limit in any way a consumer’s rights under this title, including, but not limited to, any right to a remedy or means of enforcement, shall be deemed contrary to public policy and shall be void and unenforceable. This section shall not prevent a consumer from declining to request information from a business, declining to opt-out of a business’s sale of the consumer’s personal information, or authorizing a business to sell the consumer’s personal information after previously opting out.
                            </p>
                            <h3>Section 1798.194</h3>
                            <p>
                                This title shall be liberally construed to effectuate its purposes.
                            </p>
                            <h3>Section 1798.196.</h3>
                            <p>
                                This title is intended to supplement federal and state law, if permissible, but shall not apply if such application is preempted by, or in conflict with, federal law or the United States or California Constitution.
                            </p>
                            <h3>Section 1798.198.</h3>
                            <p>
                                (a) Subject to limitation provided in subdivision (b), and in Section 1798.199, this title shall be operative January 1, 2020.
                            </p>
                            <p>
                                (b) This title shall become operative only if initiative measure No. 17-0039, The Consumer Right to Privacy Act of 2018, is withdrawn from the ballot pursuant to Section 9604 of the Elections Code.
                            </p>
                            <h3>Section 1798.199.</h3>
                            <p>
                                Notwithstanding Section 1798.198, Section 1798.180 shall be operative on the effective date of the act adding this section.
                            </p>
                        </span>

                    </div>
                </div>
                <div style={{ minHeight: "auto" }} className="d-flex align-items-center flex-column info-block questions p-5">
                    <div className="d-flex questions-info flex-column align-items-start p-5 m-5">
                        <img src={operator} alt={"operator"} className='m-2' />
                        <div className="area-title m-2">Questions?</div>
                        <div className="description m-2">Chat with one of our compliance specialists.</div>
                        <div className="schedule-time m-2">(866) 371-5002</div>
                    </div>
                    <img src={questions} alt={"questions"} className='back img-fluid' />
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => state.landing

export default connect(mapStateToProps, landingActions)(withLayout(CCPAText));