import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { withFormLayout } from "../../common/form-layout";
import message from '../../assets/images/message.svg';
import * as AuthStore from "./reducer";
import { IAuthState } from "./auth-types";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Spin } from 'antd';
import { DEFAULT_ZERO_VALUE, STRING_EMPTY } from "../../assets/constants/general";
import * as _ from 'lodash';

type AuthProps = IAuthState
    & typeof AuthStore.registerActions
    & RouteComponentProps<any>;

class AccountConfirmedPage extends React.Component<AuthProps, {}> {
    componentDidMount() {
        const { confirmUser, location } = this.props;
        const params = new URLSearchParams(location.search);
        const userId = params.get('userId') || STRING_EMPTY;
        const code = params.get('code') || STRING_EMPTY;

        confirmUser(userId, code);
    }

    render() {
        const { errors, isLoading } = this.props;
        const errorsList = errors && _.values(errors);

        return (
            <div className="d-flex flex-column align-items-center main-block">
                <h2>Account is Confirmed!</h2>
                <img src={message} alt='Message' />
                <Spin className="mt-3" spinning={isLoading} />
                {
                    errors && errorsList.length > DEFAULT_ZERO_VALUE && (
                        <div className="alert alert-danger d-flex flex-column m-3" role="alert">
                            {errorsList.map((error, i) => <div key={`registration_error_${i}`}>{error}</div>)}
                        </div>
                    )
                }
            </div>
        );
    }
}

const connectedAccountConfirmedPage = connect((state: ApplicationState) => state.auth, AuthStore.registerActions)(withFormLayout(withRouter(AccountConfirmedPage)));
export { connectedAccountConfirmedPage as AccountConfirmedPage };