import * as React from "react";
import { IFAQQuestion, ILandingProps } from "./landing-types";
import { landingActions } from "./landing-reducer";
import { withLayout } from "../../common/layout";
import { connect } from "react-redux";
import LoadingOverlay from 'react-loading-overlay';
import { Button, Col, Collapse, Form, Input, Row } from "antd";
import { InfoCard } from "../../assets/global/info-card";
import easyData from '../../assets/images/easy-data.svg';
import risk from '../../assets/images/risk.svg';
import compliance from '../../assets/images/compliance.svg';
import upload from '../../assets/images/upload.svg';
import fast from '../../assets/images/fast.svg';
import cost from '../../assets/images/cost.svg';
import step1 from '../../assets/images/order-step-1.jpg';
import step11 from '../../assets/images/upload-step-1.jpg';
import step2 from '../../assets/images/map-step-2.jpg';
import step3 from '../../assets/images/opt-outs-step-3.jpg';
import schedule from '../../assets/images/schedule.svg';
import questions from '../../assets/images/questions.jpg';
import operator from '../../assets/images/operator.svg';
import faq from '../../assets/images/faq.svg';
import { PAGE_LINK } from "../../assets/constants/page-links";
import { FAQQuestions } from "./landing-helper";
import { CollapseProps } from "antd/lib/collapse";
import { MinusOutlined, PlusOutlined, PauseCircleTwoTone, PlayCircleTwoTone } from '@ant-design/icons';
import { Link } from "react-router-dom";
import _ from "lodash";
import { ApplicationState } from "../../store";
import landingVideo from '../../assets/video/landing.mp4';
import ccpa from '../../assets/docs/ccpa.pdf';
import { isEmail, isPhone, isRequired, PhoneInput, withoutDigits } from '../../helpers/validate-helper';
import ModalForm from "./modal-form";
import './landing.scss';
import { FORM_NAMES } from "./landing-constants";
import Search from "../search";

const { Item } = Form;
const { Panel } = Collapse;

class LandingPage extends React.Component<ILandingProps> {
    private videoRef: React.RefObject<HTMLVideoElement>;

    constructor(props: ILandingProps) {
        super(props);
        this.videoRef = React.createRef();
    }

    state = {
        showFormModal: false,
        formName: "",
        showPause: false
    }

    componentDidMount() {
        const { load } = this.props;

        setTimeout(() => {
            load(true);
        }, 200);
    }

    play = () => {
        const { setVideoPlaying } = this.props;

        setVideoPlaying(true);
        this.videoRef.current.play();
    }

    stop = () => {
        const { setVideoPlaying } = this.props;

        setVideoPlaying(false);
        this.videoRef.current.pause();
    }

    componentWillUnmount() {
        this.videoRef.current.pause();
    }

    showFormModal = (formName: string) => {
        this.setState({
            formName,
            showFormModal: true
        });
    }

    showPause = async () => {
        if (!this.state.showPause) {
            await this.setState({ showPause: true });
            await setTimeout(() => this.setState({ showPause: false }), 2000);
        }
    }

    public render() {
        const {
            isLoaded, firstName, lastName, email, company, isPlaying,
            setFirstName, setLastName, setEmail, setCompany, setPhone,
            downloadGuide
        } = this.props;
        const { formName, showFormModal, showPause } = this.state;
        const collapseProps: CollapseProps = {
            bordered: false,
            className: 'm-2',
            expandIconPosition: "right",
            expandIcon: ({ isActive }) => isActive
                ? <MinusOutlined style={{ color: "#2966F4" }} />
                : <PlusOutlined style={{ color: "#2966F4" }} />
        };

        return (
            <div className="d-flex">
                <ModalForm formName={formName} isVisible={showFormModal} onClose={() => this.setState({ showFormModal: false })} />
                <LoadingOverlay className="landing-page w-100" active={false} spinner>
                    <div className="d-flex flex-column" >
                        <div className="slogan" onClick={() => { isPlaying ? this.stop() : null }} >
                            <video onMouseMove={() => { isPlaying ? this.showPause() : null }} ref={this.videoRef} className={`w-100 ${!isPlaying && 'hidden-video'}`} src={landingVideo} muted onScroll={this.stop} onEnded={this.stop} />
                            <div className={`title text-center w-100 ${isPlaying && !showPause && 'd-none'} `} >
                                <span >
                                    <p className="text-uppercase mb-4">Turnkey CCPA Compliance</p>
                                    <p className="subtitle mb-1">My Data Privacy covers each compliance item of the CCPA and more.</p>
                                    <p className="subtitle mb-1">Our robust and effective solution is perfect for list owners, compilers and mailers.</p>
                                </span>
                                {
                                    isPlaying
                                        ? <Button type='link' size='large' className='m-5 play-btn' onClick={this.stop} icon={<PauseCircleTwoTone twoToneColor='#959CAC' style={{ fontSize: '70px' }} />} />
                                        : <Button type='link' size='large' className='m-5 play-btn' onClick={this.play} icon={<PlayCircleTwoTone twoToneColor='#959CAC' style={{ fontSize: '70px' }} />} />
                                }
                                <Link className="search-button m-5" to={PAGE_LINK.LIST_OWNERS_AND_MAILERS}>SCHEDULE DEMO</Link>
                            </div>
                        </div>

                        <div className="d-flex align-items-center flex-column info-block bordered mt-2">
                            <Search />
                        </div>

                        <div className="d-flex align-items-center flex-column info-block p-4 h-100">
                            <div className="area-title">Protect Your Business</div>
                            <div className="main-title">Manage consumer data with ease</div>
                            <div className="description">
                                <p>My Data Privacy provides turnkey CCPA compliance for small to medium-sized businesses.</p>
                                <p>Our solution is simple, cost-effective and 100% outsourced.</p>
                            </div>
                            <Button className='action-button rounded-button m-2 mb-5' onClick={() => this.showFormModal(FORM_NAMES.tryItFree)}>Try it FREE for 30 days!</Button>
                            <div className="d-flex features">
                                <Row>
                                    <Col className='d-flex' xs={24} sm={8} md={8} lg={8}>
                                        <InfoCard className="w-50" icon={cost} alt='cost' description='Cost effective' />
                                        <InfoCard icon={easyData} alt='easyData' description='Easy Data Mapping' className='align-self-end w-50' />
                                    </Col>
                                    <Col className='d-flex' xs={24} sm={8} md={8} lg={8}>
                                        <InfoCard className="w-50" icon={risk} alt='risk' description='Built-in risk management' />
                                        <InfoCard icon={compliance} alt='compliance' description='Automated compliance' className='align-self-end w-50' />
                                    </Col>
                                    <Col className='d-flex' xs={24} sm={8} md={8} lg={8}>
                                        <InfoCard className="w-50" icon={upload} alt='upload' description='Drag and drop file uploads' />
                                        <InfoCard icon={fast} alt='fast' description='Fast and effective setup' className='align-self-end w-50' />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-column info-block bordered">
                            <div className="area-title">the evolution</div>
                            <div className="main-title">We Make CCPA Compliance Easy</div>
                            <div className="description">
                                <p><strong>myDataPrivacy.com</strong> is a centralized platform for businesses,</p>
                                <p>list owners and marketers to maintain and comply with the data requirements of the CCPA.</p>
                                <p>(California Consumer Privacy Act). Avoid CCPA fines and penalties with our turnkey solution.</p>
                            </div>
                            <div className="d-flex steps m-5">
                                <Row className='align-items-center'>
                                    <Col xs={24} md={12} lg={12}>
                                        <div className="step-image step-image1-1">
                                            <img className='img-fluid' src={step1} alt={"step1"} />
                                        </div>
                                        <div className="step-image step-image1-2">
                                            <img className='img-fluid' src={step11} alt={"step1"} />
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12} lg={12}>
                                        <div className="d-flex flex-column step-block">
                                            <div className="step-title">Step 1</div>
                                            <div className="title">Easily Manage & Upload Data Files</div>
                                            <ul>
                                                <li>Each list used or sold is uploaded to your account within your personal dashboard.</li>
                                                <li>Easy click navigation and drop down menus guide you through the order creation and data upload process.</li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="d-flex steps m-5">
                                <Row className='align-items-center'>
                                    <Col xs={24} md={12} lg={12}>
                                        <div className="d-flex flex-column step-block">
                                            <div className="step-title">Step 2</div>
                                            <div className="title">Manage Consumer Records & Requests</div>
                                            <ul>
                                                <li>A user-friendly interface makes it simple to manage consumer information. </li>
                                                <li>Just choose your selections, and conveniently download your consumer records within seconds.</li>
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12} lg={12} className="">
                                        <div className="step-image px-md-5">
                                            <img className='img-fluid' src={step2} alt={"step2"} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="d-flex steps m-5">
                                <Row className='align-items-center'>
                                    <Col xs={24} md={12} lg={12} className="d-flex flex-row-reverse">
                                        <div className="step-image px-md-5 ">
                                            <img className='img-fluid' src={step3} alt={"step3"} />
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12} lg={12}>
                                        <div className="d-flex flex-column step-block">
                                            <div className="step-title">Step 3</div>
                                            <div className="title">Protect Your Business and Your Consumers</div>
                                            <ul>
                                                <li>Our platform covers each CCPA compliant item and more. </li>
                                                <li>Notifications, access, disclosure, reporting, opt-out and deletion requests are all handled within My Data Privacy.</li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="d-flex sub-description">
                                My Data Privacy provides consumers an easy way to verify their requests in a safe and secure portal. It offers online reporting to satisfy disclosure requirements along with opt-out and deletion request options.
                            </div>
                            <div className="d-flex m-5 w-100 justify-content-center ">
                                <Row className='align-items-center'>
                                    <Col xs={24} sm={12} md={16} lg={18}>
                                        <div className="d-flex align-items-center  justify-content-center flex-row">
                                            <img className='img-fluid' src={schedule} alt={"schedule"} />
                                            <div className="d-flex flex-column">
                                                <div className="schedule-title">Setup Takes Just</div>
                                                <div className="schedule-time">24 Hours!</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={6} className='d-flex align-items-center justify-content-center'>
                                        <Button className='action-button rounded-button' onClick={() => this.showFormModal(FORM_NAMES.scheduleDemo)}>Schedule a Demo</Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-column info-block questions p-5">
                            <div className="d-flex questions-info flex-column align-items-start p-5 m-5">
                                <img src={operator} alt={"operator"} className='m-2' />
                                <div className="area-title m-2">Questions?</div>
                                <div className="description m-2">Chat with one of our compliance specialists.</div>
                                <div className="schedule-time m-2">(866) 371-5002</div>
                            </div>
                            <img src={questions} alt={"questions"} className='back img-fluid' />
                        </div>
                        <div className="d-flex align-items-center flex-column info-block p-4">
                            <div className="area-title">Featured product</div>
                            <div className="main-title">Download Our CCPA Guide</div>
                            <div className="d-flex steps mt-5">
                                <Row className='justify-content-center'>
                                    <Col xs={24} md={24} lg={12} style={{ maxWidth: '650px' }}>
                                        <Form layout={"vertical"}
                                            className='p-md-4 m-4'
                                            scrollToFirstError={true}
                                        >
                                            <Item name={'firstName'} rules={[isRequired('First Name'), withoutDigits('First Name')]}>
                                                <Input placeholder='First Name *' value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                                            </Item>
                                            <Item name={'lastName'} rules={[isRequired('Last Name'), withoutDigits('Last Name')]}>
                                                <Input placeholder='Last Name *' value={lastName} onChange={(event) => setLastName(event.target.value)} />
                                            </Item>
                                            <Item name={'email'} rules={[isRequired('Email Address'), isEmail('Email Address')]}>
                                                <Input placeholder='Email Address *' value={email} onChange={(event) => setEmail(event.target.value)} />
                                            </Item>
                                            <Item name={'phone'} rules={[isPhone('Phone Number')]}>
                                                <PhoneInput label='Phone Number' onChange={setPhone} />
                                            </Item>
                                            <Item name={'company'}>
                                                <Input placeholder='Company Name' value={company} onChange={(event) => setCompany(event.target.value)} />
                                            </Item>
                                            <Item>
                                                <div className="d-flex flex-column flex-md-row align-items-center mt-3">
                                                    <Button disabled={_.isEmpty(firstName) && _.isEmpty(lastName) && _.isEmpty(email)}
                                                        loading={!isLoaded}
                                                        onClick={downloadGuide}
                                                        className='action-button download'
                                                        block htmlType="submit">
                                                        <a target="_blank" rel="noopener noreferrer" href={ccpa}>Download</a>
                                                    </Button>
                                                    <span className='download-info'>All the fields with * mark are mandatory</span>
                                                </div>
                                            </Item>
                                        </Form>
                                    </Col>
                                    <Col xs={24} md={24} lg={12}>
                                        <div className="d-flex flex-column p-4">
                                            <div className="title ccpa-title">What is the CCPA?</div>
                                            <div className="description">
                                                <p className='text-left mb-2'><strong>The CCPA (California Consumer Privacy Act), is a bill meant to enhance privacy rights and consumer protection for residents of California.</strong></p>
                                                <p className='text-left mb-1'><strong className='bold-guide'>In our FREE guide,</strong> we break down the basics of the CCPA, including:</p>
                                            </div>
                                            <ul>
                                                <li>What marketers need to know about the CCPA</li>
                                                <li>Affected parties and potential penalties</li>
                                                <li>Consumer 'rights' under the CCPA</li>
                                                <li>What steps to take in order to protect your business</li>
                                            </ul>
                                            <div className="d-flex mt-3">
                                                <Link className='button-link' to={PAGE_LINK.LIST_OWNERS_AND_MAILERS}><Button className='action-button learn-more-button'>Learn More</Button></Link>
                                                <div className='d-flex align-items-center ccpa-faqs'>
                                                    <Link to={PAGE_LINK.FAQ}>
                                                        <img src={faq} alt="FAQ" />
                                                        <span className='faq-info'>CCPA FAQ's</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-column info-block bordered p-4 pb-5">
                            <div className="area-title">FAQ</div>
                            <div className="main-title">Questions & Answers</div>
                            <Row className='d-flex w-100 questions-collapse'>
                                <Col xs={24} md={24} lg={12}>
                                    <Collapse {...collapseProps}>
                                        {
                                            FAQQuestions.slice(0, 5).map((faq: IFAQQuestion, index) => (
                                                <Panel header={faq.question} key={`faq_${index}`}>{faq.answer}</Panel>
                                            ))
                                        }
                                    </Collapse>
                                </Col>
                                <Col xs={24} md={24} lg={12}>
                                    <Collapse {...collapseProps}>
                                        {
                                            FAQQuestions.slice(5).map((faq: IFAQQuestion, index) => (
                                                <Panel header={faq.question} key={`faq_${index}`}>{faq.answer}</Panel>
                                            ))
                                        }
                                    </Collapse>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </LoadingOverlay>
            </div >
        )
    }
}

const connectedLandingPage = connect((state: ApplicationState) => ({ ...state.landing }), landingActions)(withLayout(LandingPage));
export { connectedLandingPage as LandingPage };