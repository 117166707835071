import * as React from "react";
import { Button } from 'antd';
import bigLogo from '../assets/images/big-logo.svg';
import { PAGE_LINK } from "../assets/constants/page-links";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import AuthStore from '../local-store/auth';

const isPrerender = navigator.userAgent === "ReactSnap";

export const withFormLayout = <P extends object>(Form: React.ComponentType<P>) => {
    class FormLayout extends React.Component {
        render() {
            const user = AuthStore.getUser();

            return (
                <div className="d-flex flex-column align-items-center p-2 form-layout">
                    {
                        isPrerender ? null :
                        !user && (
                            <CookieConsent location="bottom"
                                buttonText="ACCEPT"
                                cookieName="MDPCookie"
                                hideOnAccept={true}
                                style={{ background: "#f7f8f9", color: "#2F2F2F", fontSize: "15px", fontWeight: 'bold', minHeight: '70px', alignItems: 'center', borderTop: 'solid 3px #2966f4' }}
                                buttonStyle={{ background: '#2966f4', color: "#fff", fontSize: "14px", fontWeight: 'bold', width: '130px', height: '38px', borderRadius: '50px' }}>
                                <div style={{ margin: '0 50px' }}>We use cookies on this site to enhance your user experience.</div>
                            </CookieConsent>
                        )
                    }
                    <Link to={PAGE_LINK.LANDING}>
                        <Button type='link' className='link-bottom-button d-flex flex-column align-items-center h-100 m-3'>
                            <img src={bigLogo} alt="Logo" className='m-3' />
                            <span className="text-uppercase logo-title">My Data Privacy</span>
                        </Button>
                    </Link>
                    <div style={{ maxWidth: "650px", width: "100%" }}>
                        <Form {...this.props as P} />
                    </div>
                </div>
            );
        }
    };

    return FormLayout;
}