import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { PAGE_LINK } from '../../../assets/constants/page-links';
import { ApplicationState } from '../../../store';
import { IAddAgreementState, IAgreementModel } from './add-agreement-types';
import * as AddAgreementStore from "./reducer";
import { withUserLayout } from "../../../common/user-layout";
import { Button, Card, DatePicker, Form, notification, Select, Upload } from 'antd';
import { DeleteOutlined, InboxOutlined, FilePdfOutlined } from '@ant-design/icons';
import { UploadProps } from 'antd/lib/upload';
import { Link } from 'react-router-dom';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import "./add-agreement.scss";

type AddAgreementProps = RouteComponentProps<any>
    & IAddAgreementState
    & typeof AddAgreementStore.addAgreementActions

class AddAgreement extends React.Component<AddAgreementProps> {

    state = {
        file: {} as File,
        fileName: null as string,
        isNewAgreement: true
    };

    formRef = React.createRef<FormInstance>();

    onSubmit = (values: IAgreementModel) => {
        const { file, fileName, isNewAgreement } = this.state;
        const { userId } = this.props.match.params;

        if (fileName || isNewAgreement) {
            this.props.addOrEditAgreement({ ...values, agreement: file, userId } as IAgreementModel)
        } else if (!fileName) {
            this.props.deleteAgreement(userId);
        } else {
            notification.warning({ message: "Please select adgreement" });
        }
    }

    componentDidMount() {
        const { userId } = this.props.match.params;
        this.props.getAgreement(userId);
    }

    componentDidUpdate(prevProps: AddAgreementProps) {
        if (this.props.editedAgreement !== prevProps.editedAgreement) {
            const { editedAgreement, editedAgreement: { fileName, agreementDate, id } } = this.props;

            editedAgreement.agreementDate && this.formRef.current.setFieldsValue({ ...editedAgreement, agreementDate: moment(agreementDate) });

            this.props.editedAgreement.fileName && this.setState({ fileName })

            id && this.setState({ isNewAgreement: !id });
        }
    }

    render() {
        const { isLoading } = this.props;
        const { file, fileName } = this.state;

        const uploadProps: UploadProps = {
            multiple: false,
            onRemove: () => {
                this.setState({ file: {} as File, fileName: null });
            },
            beforeUpload: file => {
                this.setState({ file, fileName: file.name });
                return false;
            },
            showUploadList: false,
            accept: ".pdf"
        }

        return (
            <LoadingOverlay active={isLoading} spinner>
                <div className="container" >
                    <Form onFinish={this.onSubmit} ref={this.formRef} validateTrigger="onBlur">
                        <div className="d-flex w-100 justify-content-center mb-5">
                            <h2>Type & Agreement</h2>
                        </div>
                        <div className="d-flex w-100 justify-content-between double-inputs">
                            <Form.Item name="type" rules={[{ required: true, message: "Type of Agreement is required" }]}>
                                <Select allowClear placeholder="Type of Agreement">
                                    <Select.Option value="default">Default</Select.Option>
                                    <Select.Option value="annual">Annual</Select.Option>
                                    <Select.Option value="transactional">Transactional</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="agreementDate" rules={[{ required: true, message: "Date is required" }]}>
                                <DatePicker format={"L"}></DatePicker>
                            </Form.Item>
                        </div>
                        {!fileName ? (
                            <Upload.Dragger height={300} {...uploadProps} className="mb-5">
                                <div>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                        .PDF files only
                                    </p>
                                </div>
                            </Upload.Dragger>
                        ) : (
                                <Card>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="row">
                                            <FilePdfOutlined style={{ fontSize: "36px", color: "#007bff" }} />
                                            <h5>{fileName}</h5>
                                        </div>
                                        <DeleteOutlined style={{ fontSize: "32px", color: "red" }} onClick={() => this.setState({ file: {}, fileName: null })} />
                                    </div>
                                </Card>
                            )}
                        <Form.Item>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Link className="btn btn-outline-primary btn-lg button-round" to={PAGE_LINK.LIST_OF_CLIENTS}>Cancel</Link>
                                </div>
                                <div>
                                    <Button htmlType="submit" loading={isLoading} className="btn btn-primary button-round">Save</Button>
                                </div>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </LoadingOverlay>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.addAgreement,
    AddAgreementStore.addAgreementActions
)(withUserLayout(AddAgreement as any, PAGE_LINK.ADD_AGREEMENT));