import * as React from 'react';
import { Button, Card, Col, Form, Input, Checkbox, Row, Select } from 'antd';
import UploadTable from "./upload-table";
import { IFileData, IMapModel, ISelectValue, OptionalFields } from './upload-types';
import { headerField, mapUndefIndex, stepSize, suportedField, zeroNumber } from './upload-constats';
import { IUploadFile, IUserMapping } from './upload-service';
import _ from 'lodash';
import { FormInstance } from 'antd/lib/form';
import * as UploadStore from "./reducer"
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { ExclamationCircleOutlined } from "@ant-design/icons"
import "./upload.scss";

const { Option } = Select;

interface IMatchHeadersStateProps {
    fileData: IFileData;
    supportedFields: [];
    optionalFields: string[];
    userMappings: IUserMapping[];
    fieldValue: Object
    mapModel: IMapModel;
    current: number;
    canSubmit: boolean;
    currentFileIndex: number;
};

interface IMatchHeadersOwnProps {
    fileIndex: number;
    setTabWithError: (fileIndex: number) => void;
}


type ComponentProps = IMatchHeadersStateProps & IMatchHeadersOwnProps & typeof UploadStore.uploadActions;

export class MatchHeaders extends React.Component<ComponentProps>{
    state = {
        mappingForAllFiles: false,
        selectedMappingProfile: '',
    }

    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        const { mapModel: { name, isNewMapping } } = this.props;
        if (!name && isNewMapping) {
            this.setHeadersAuto()
        }
    }

    componentDidUpdate(prevState: IMatchHeadersStateProps) {
        const { fieldValue } = this.props;

        if (fieldValue && Object.keys(fieldValue).length != 0 && prevState.fieldValue !== fieldValue) {
            this.formRef.current.resetFields();
            this.formRef.current.setFieldsValue(fieldValue);
        }
    }

    shouldComponentUpdate(prevState: IMatchHeadersOwnProps) {
        if (this.props.currentFileIndex !== prevState.fileIndex) {
            return false;
        }

        return true;
    }

    componentWillUnmount() {
        const { fileData: { fileId, fileName, recordsCount }, mapModel: { selectedHeaderValues, canSave }, fileIndex, canSubmit } = this.props;

        if (!canSave) {
            this.props.setTabWithError(fileIndex);
        } else if (canSubmit) {
            let mapping: { [key: string]: ISelectValue } = {};

            _.keys(selectedHeaderValues)
                .filter((key) => !!selectedHeaderValues[key].name)
                .forEach(key => {
                    mapping[selectedHeaderValues[key]?.name] = { name: key, type: selectedHeaderValues[key]?.type }
                });
            let uploadFile = {
                fileId,
                fileName,
                recordsCount,
                mapping
            } as IUploadFile;

            this.props.addFileToOrder(uploadFile);
        } else {
            this.props.setTabWithError(mapUndefIndex);
        }
    }

    setHeadersAuto = () => {
        const { fileData, supportedFields, mapModel } = this.props;
        let selectedHeaderValues: { [key: string]: ISelectValue } = mapModel.selectedHeaderValues;

        _.uniqBy(fileData.headers, h => h.outputColumn)
            .forEach(h => {
                const type = supportedFields.find(x => _.toLower(x) === _.toLower(h.type)) ? suportedField : headerField;
                const fileColumn = fileData.fileColumns.find(fColumn => _.toLower(fColumn) === _.toLower(h.outputColumn));

                if (fileColumn) {
                    selectedHeaderValues = {
                        ...selectedHeaderValues,
                        [fileColumn]: {
                            name: type === suportedField ? h.type : h.outputColumn,
                            friendlyName: type === headerField && h.friendlyOutputColumn,
                            type
                        } as ISelectValue
                    };
                }
            });

        supportedFields.forEach(sField => {
            const fileColumn = fileData.fileColumns.find(fColumn => _.toLower(fColumn) === _.toLower(sField));
            if (fileColumn) {
                selectedHeaderValues = {
                    ...selectedHeaderValues,
                    [fileColumn]: {
                        name: sField,
                        type: suportedField
                    } as ISelectValue
                }
            }
        });

        let fieldsValue = new Object() as { [key: string]: string };

        Object.keys(selectedHeaderValues).forEach(key => {
            fieldsValue[key] = (selectedHeaderValues[key].type === suportedField || selectedHeaderValues[key].type === headerField) ? selectedHeaderValues[key].name : selectedHeaderValues[key].type
        })

        this.formRef.current.setFieldsValue(fieldsValue);

        this.props.addNewMappProfile({
            ...mapModel,
            selectedHeaderValues
        })
    }

    onValueSelect = (key: string, name: string, type: string, friendlyName?: string) => {

        const { mapModel } = this.props;
        const value: ISelectValue = !_.isEmpty(friendlyName) ? { name, friendlyName, type } : { name, type };

        if (type) {
            this.props.addNewMappProfile({
                ...mapModel,
                isNewMapping: true,
                name: null,
                isSaved: false,
                isAllFiles: false,
                selectedHeaderValues: {
                    ...mapModel.selectedHeaderValues,
                    [key]: value
                }
            });
        } else {
            this.props.addNewMappProfile({
                ...mapModel,
                isNewMapping: true,
                name: null,
                isSaved: false,
                isAllFiles: false,
                selectedHeaderValues:
                    _.omit(mapModel.selectedHeaderValues, key),
            });
        }
    }

    mappingSelectClear = async () => {
        const { mapModel, } = this.props;
        await this.props.addNewMappProfile({
            ...mapModel,
            isNewMapping: false,
            selectedHeaderValues: {},
            name: null,
            isAllFiles: false,
            isSaved: false
        });
        this.formRef.current.resetFields();
    }

    onValueSelectChange = (key: string) => (value: string) => {
        let type = value;
        let name = key;
        let friendlyName;

        const { fileData: { headers }, supportedFields, } = this.props;

        const header = headers.find(x => x.outputColumn === value);
        if (header) {
            const supported = supportedFields.find(x => x === header.type);

            type = supported ? suportedField : headerField;
            name = supported ? header.type : header.outputColumn;
            friendlyName = header.friendlyOutputColumn;
        } else if (supportedFields.find(x => x === value)) {
            type = suportedField;
            name = value;
        }

        this.onValueSelect(key, name, type, friendlyName);
    }

    onInputChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { selectedHeaderValues } = this.props.mapModel;

        selectedHeaderValues[key] && selectedHeaderValues[key].type && this.onValueSelect(key, event.target.value ? event.target.value : key, selectedHeaderValues[key].type);
    }

    onCheckBoxChange = (event: any) => {
        const { selectedMappingProfile } = this.state;
        const { fileIndex, selectMapping } = this.props;

        this.setState({
            mappingForAllFiles: event.target.checked
        });

        if (selectedMappingProfile && selectedMappingProfile.length !== zeroNumber) {
            selectMapping(this.state.selectedMappingProfile, event.target.checked, fileIndex);
        }
    }

    mappingSelect = (selectedValue: any) => {
        const { mappingForAllFiles } = this.state;
        const { fileIndex, selectMapping } = this.props;

        this.setState({
            selectedMappingProfile: selectedValue
        });

        selectMapping(selectedValue, mappingForAllFiles, fileIndex);
    }

    render() {

        const { supportedFields,
            userMappings,
            optionalFields,
            current,
            fileIndex,
            fileData: { headers, fileColumns },
            mapModel } = this.props;
        const { selectedHeaderValues } = this.props.mapModel;

        const selectedItems = _.values(selectedHeaderValues);

        const selectValues = supportedFields.map((item: string) => {
            const hiddenItem = selectedItems.find(x => x?.name === item);
            return !hiddenItem && <Option key={`${fileIndex}_${item}`} value={item}>{item}</Option>
        })

        const mappingProfileValues = userMappings.map((item) => {
            return <Option key={`${fileIndex}_${item.id}`} value={item.name}>{item.name}</Option>
        });

        const optionalValues = optionalFields.map((item) => {
            return <Option key={`${fileIndex}_${item}`} value={item}>{item}</Option>
        });

        const headerValues = headers.map(({ id, friendlyOutputColumn, outputColumn }) => {
            const hiddenItem = selectedItems.find(x => x.friendlyName === friendlyOutputColumn || x.name === outputColumn);
            return !hiddenItem && <Option key={`${fileIndex}_${id}`} value={outputColumn}>{friendlyOutputColumn}</Option>
        });

        const mappFieldsContent = fileColumns.map((item) => {
            let value = null;
            const selectedItem = selectedHeaderValues[item] || {} as ISelectValue;
            const { type, name, friendlyName } = selectedItem;

            if (selectedItem) {
                if (type === headerField || type === suportedField) {
                    value = name;
                } else {
                    value = type;
                }
            }

            return (
                <Form.Item key={`${fileIndex}_${item}`}>
                    <Row>
                        <Col md={6} className="px-2 pr-5">
                            <h5 style={{ marginTop: "0.7rem", textAlign: "right", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{item}: </h5>
                        </Col>
                        <Col md={9} className="px-2">
                            <Form.Item name={item} rules={[{ required: true }]} initialValue={value}>
                                <Select showSearch={true}
                                    placeholder="---"
                                    value={value}
                                    onChange={this.onValueSelectChange(item)}
                                    allowClear
                                    onFocus={() => {
                                        document.getElementById(item)
                                            .setAttribute(
                                                'autocomplete',
                                                'chrome-off'
                                            )
                                    }}>
                                    {optionalValues}
                                    {selectValues}
                                    {headerValues}
                                </Select>
                            </Form.Item>
                        </Col>
                        {
                            selectedItem && type != OptionalFields.Ignore &&
                            value && (
                                <Col md={9} className="px-2">
                                    <Form.Item>
                                        {
                                            (type === suportedField || type === headerField) ?
                                                <Input disabled
                                                    onBlur={this.onInputChange(item)}
                                                    value={friendlyName || name} /> :
                                                <Input onBlur={this.onInputChange(item)}
                                                    autoComplete="chrome-off"
                                                    defaultValue={name}
                                                    placeholder={item}
                                                />
                                        }
                                    </Form.Item>
                                </Col>
                            )
                        }
                    </Row>
                </Form.Item>
            )
        });

        return (
            <div className="container-fluid">
                <Row >
                    <Col sm={24} md={{ span: 9, offset: 6 }} className="p-2 ">
                        <Card title="Select saved mapping profile" className="mb-3 mappings">
                            <Select size="large" onClear={() => { this.mappingSelectClear() }} value={mapModel && mapModel.name && mapModel.name} style={{ width: "100%" }} placeholder="Mapping profile" allowClear={!!mapModel.name} onChange={this.mappingSelect}>
                                {mappingProfileValues}
                            </Select>

                            <Checkbox checked={this.state.mappingForAllFiles} onChange={this.onCheckBoxChange}>
                                Apply this mapping profile to all files?
                            </Checkbox>
                        </Card>
                    </Col>
                    <Col sm={24} md={{ span: 9 }} className="p-2">
                        <Card className="alert-card mb-3" title={<><ExclamationCircleOutlined className="mr-1" style={{ color: "#faad14" }} />Please pay attention</>}>
                            For the file to be accepted it needs to contain full name and full address (including address, city, state and zip). Please match those properties with columns in the uploaded CSV.
                        </Card>
                    </Col>
                </Row>
                {
                    fileColumns && fileColumns.length > 0 && supportedFields && supportedFields.length > 0 && (
                        <React.Fragment>
                            <Row className="my-4">
                                <Col sm={6}><h4>Column header from your file upload</h4></Col>
                                <Col sm={9}><h4>Match the Dropdowns Below to Your Column Headers</h4></Col>
                                <Col sm={9}><h4>Consumer Facing Column Headers. </h4><h5>Note, You May Edit "Category" Fields</h5></Col>
                            </Row>
                            <Form ref={this.formRef} autoComplete="chrome-off">
                                {mappFieldsContent}
                            </Form>
                        </React.Fragment>
                    )
                }
                <div className="d-flex justify-content-end mb-3">
                    <div className="row">
                        <Button onClick={() => this.props.setCurrentStep(current - stepSize)} className="back_btn" shape="round" type="text">Back</Button>
                        <Button onClick={() => this.props.setCurrentStep(current + stepSize)} className="next_btn" shape="round" type="primary">Next</Button>
                    </div>
                </div>
                <UploadTable fileIndex={this.props.fileIndex} />
            </div >
        )
    }
}

export default connect<IMatchHeadersStateProps, typeof UploadStore.uploadActions, IMatchHeadersOwnProps>(
    (state: ApplicationState, ownProps: IMatchHeadersOwnProps) => {
        return {
            mapModel: state.upload.mapModels[ownProps.fileIndex],
            fileData: state.upload.filesData[ownProps.fileIndex],
            supportedFields: state.upload.dataForUpload.supportedFields,
            optionalFields: state.upload.dataForUpload.optionalFields,
            userMappings: state.upload.userMappings,
            fieldValue: state.upload.fieldsValues[ownProps.fileIndex],
            current: state.upload.current,
            currentFileIndex: state.upload.currentFileIndex,
            canSubmit: state.upload.mapModels.filter(x => x.canSave).length == state.upload.filesData.length
        }
    },
    UploadStore.uploadActions
)(MatchHeaders);