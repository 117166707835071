import { Button, Divider, Form, Input, notification, Select, Upload } from 'antd';
import { FormInstance } from 'antd/lib/form';
import * as React from 'react';
import { IEditedProfile, IEditProfileState } from './edit-profile-types';
import * as EditProfileStore from "./reducer";
import { STATE_OPTIONS } from '../../assets/constants/states';
import { phoneRegexp } from "../../helpers/regexp";
import { PAGE_LINK } from '../../assets/constants/page-links';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { withUserLayout } from '../../common/user-layout';
import LoadingOverlay from 'react-loading-overlay';
import { MaskedInput } from 'antd-mask-input';
import { passwordRegexp, passwordRegexpMessage } from "../../helpers/regexp";
import EditProfioleService from "./edit-profile-service";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import AuthStore from "../../local-store/auth"

const editProfioleService = new EditProfioleService();
const { Item } = Form;
const { Option } = Select;

function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        notification.error({ message: 'You can only upload JPG/PNG file!' });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        notification.error({ message: 'Image must smaller than 2MB!' });
    }
    return isJpgOrPng && isLt2M;
}

type EditProfileProps = IEditProfileState
    & typeof EditProfileStore.editProfileActions

class EditProfile extends React.Component<EditProfileProps> {
    state = {
        isCompanyUnique: true,
        blockSave: false,
        checkTimeOut: 0,
        loading: false,
        imageUrl: "",
        imageName: ""
    };

    handleChange = (info: any) => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl: any) =>
                this.setState({
                    imageUrl,
                    imageName: info.file.response,
                    loading: false,
                }),
            );
        }
    };
    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        this.props.getProfile();
    }

    componentDidUpdate(prevProps: EditProfileProps) {
        if (this.props.editedProfile !== prevProps.editedProfile) {
            const { editedProfile } = this.props;
            this.formRef.current.setFieldsValue(editedProfile);
        }
    }

    onFinish = async (values: IEditedProfile) => {
        const { editProfile, getProfile } = this.props;
        if (this.state.imageName && this.state.imageName != "") {
            values.avatar = this.state.imageName;
        }
        await editProfile(values);
        await getProfile();
        this.formRef.current.resetFields();
    }

    checkCompanyName = (companyName: string) => {
        if (companyName === this.props.editedProfile.company) {
            this.setState({ isCompanyUnique: true, blockSave: false });
        } else {
            editProfioleService.checkCompanyName(companyName)
                .then((response) => {
                    this.setState({ isCompanyUnique: response.content, blockSave: !response.content });
                })
        }
    }

    changeCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;

        if (this.state.checkTimeOut) {
            clearTimeout(this.state.checkTimeOut);
        }
        this.setState({
            blockSave: true,
            checkTimeOut: setTimeout(() => {
                this.checkCompanyName(name);
            }, 1000)
        })
    }

    render() {
        const { isLoading, editedProfile } = this.props;
        const { loading, imageUrl } = this.state;
        const stateOptions = STATE_OPTIONS.map((x, idx) => (
            <Option key={`${x.shortName}${idx}`} value={x.shortName}>{x.shortName} ({x.name})</Option>
        ));

        const validateMessages = {
            required: "Please input your ${name}!",
            types: {
                email: '${name} is not validate email!',
            },
            pattern: {
                mismatch: "${name} does not match pattern",
            }
        };

        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        return (
            <LoadingOverlay active={isLoading} spinner>
                <div className="container">
                    <Form name="editClient"
                        validateTrigger="onBlur"
                        onFinish={this.onFinish}
                        layout={"vertical"}
                        className='d-flex flex-column flex-wrap align-items-center'
                        validateMessages={validateMessages}
                        initialValues={this.props.editedProfile}
                        ref={this.formRef}>
                        <h2>Edit Profile</h2>
                        <div className="d-flex w-100 justify-content-between double-inputs">
                            <Item name={'firstName'} rules={[{ required: true }]} label="First Name">
                                <Input />
                            </Item>
                            <Item name={'lastName'} rules={[{ required: true }]} label="Last Name">
                                <Input />
                            </Item>
                        </div>
                        <Item label="Profile Image">
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action="/api/profile/set-image"
                                headers={{ 'Authorization': `Bearer ${AuthStore.getToken()}` }}
                                beforeUpload={beforeUpload}
                                onChange={this.handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Item>
                        <Item name={'title'} label="Title">
                            <Input />
                        </Item>
                        <Item name={'email'} label="Email address" rules={[{ type: 'email', required: true }]}>
                            <Input disabled />
                        </Item>
                        <Item name={'phone'} label="Cell Phone" rules={[{ required: true, pattern: phoneRegexp }]}>
                            <MaskedInput mask='111-111-1111' />
                        </Item>
                        <Item name={'company'} {...!this.state.isCompanyUnique && { help: "Current name is exist!", validateStatus: "error" }}
                            label="Company" rules={[{ required: true }]}>
                            <Input disabled={!editedProfile.isCompanyOwner} onChange={this.changeCompanyName} />
                        </Item>
                        <Item name={'address1'} label="Address 1" rules={[{ required: true }]}>
                            <Input />
                        </Item>
                        <Item name={'address2'} label="Address 2">
                            <Input />
                        </Item>
                        <Item name={'city'} label="City" rules={[{ required: true }]}>
                            <Input />
                        </Item>
                        <div className="d-flex w-100 justify-content-between double-inputs">
                            <Item name={'state'} label="State" rules={[{ required: true }]}>
                                <Select >
                                    {stateOptions}
                                </Select>
                            </Item>
                            <Item name={'zip'} label="ZIP" rules={[{ required: true }]}>
                                <MaskedInput mask="11111" placeholder="" />
                            </Item>
                        </div>
                        <Divider />
                        <Item label="Current password" name={'currentPassword'}>
                            <Input.Password autoComplete="new-password" />
                        </Item>
                        <Item label="New password" name={'newPassword'}
                            rules={[
                                {
                                    pattern: passwordRegexp,
                                    message: passwordRegexpMessage
                                }
                            ]}>
                            <Input.Password />
                        </Item>
                        <Item name={'newPasswordConfirm'}
                            label="Confirm new password"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_rule, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),]}>
                            <Input.Password />
                        </Item>
                        <Divider />
                        <Item>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Link className="btn btn-outline-primary btn-lg" to={PAGE_LINK.LIST_OF_CLIENTS}>Cancel</Link>
                                </div>
                                <div>
                                    <Button htmlType="submit" disabled={this.state.blockSave} loading={isLoading} className="btn btn-primary">Save</Button>
                                </div>
                            </div>
                        </Item>
                    </Form>
                </div>
            </LoadingOverlay>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.editProfile,
    EditProfileStore.editProfileActions
)(withUserLayout(EditProfile as any, PAGE_LINK.EDIT_PROFILE));