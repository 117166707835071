import { STRING_EMPTY } from "../../../assets/constants/general";
import { IPagination } from "../../../assets/global/types";

export const OptOutActionTypes = {
    setUserOptOuts: 'SET_USER_OPT_OUTS',
    setUserOptOutsIsLoading: 'SET_USER_OPT_OUTS_IS_LOADING',
    setUserOptOutsPagination: 'SET_USER_OPT_OUTS_PAGINATION',
    setUserOptOutsType: 'SET_USER_OPT_OUTS_TYPE'
}

export const DELETED_TYPE = "Deleted";
export const OPTED_OUT_TYPE = "Opted Out";
export const BOTH_TYPE = "Both";
export const CSV_HEADERS = "FIRSTNAME,LASTNAME,EMAIL,ADDRESS,CITY,ZIP,STATE";

export const SUPPRESSION_TYPES = {
    OPTED_OUT: 0,
    DELETED: 1,
    BOTH: 2
};

export const SUPPRESSION_TITLES = {
    [SUPPRESSION_TYPES.OPTED_OUT]: OPTED_OUT_TYPE,
    [SUPPRESSION_TYPES.DELETED]: DELETED_TYPE,
    [SUPPRESSION_TYPES.BOTH]: BOTH_TYPE
};

export const defaultPagination: IPagination = {
    pageNumber: 1,
    pageSize: 10,
    searchCriteria: STRING_EMPTY,
    sortCriteria: 'date',
    isAscend: false,
    total: 0
};