import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { ErrorPage } from "./error-page";
import Register from "./auth/register";
import SignIn from "./auth/signin";
import EditProfile from "./edit-profile";
import AddClient from "./admin/add-client";
import ListClients from "./admin/list-clients";
import EditClient from "./admin/edit-client"
import CCPAText from "./ccpa-text"
import { LandingPage } from './landing';
import { TermsOfUsePage } from './terms-of-use';
import AlertsContactsPage from './alerts/alerts-contacts';
import AlertsCenterPage from './alerts/alerts-center';
import { FAQPage } from './faq';
import { PrivacyPolicyPage } from './privacy-policy';
import { ListOwnersAndMailersPage } from './list-owners-and-mailers';
import { SiteMapPage } from './site-map';
import { NewsPage } from './news';
import AdminNewsPage from "../pages/news/admin-news";
import AddArticlePage from "../pages/news/add-article";
import ArticlePage from "../pages/news/article";
import EditArticlePage from "../pages/news/edit-article";
import { SearchResultPage } from './search/search-result';
import { SearchResultsByAddressPage } from './search/search-results-by-address';
import { SearchResultsByEmailPage } from './search/search-results-by-email';
import { SearchDeleteConfirmationPage } from './search/search-delete-confirmation';
import { ThankYouPage } from './auth/thank-you';
import { ResendPasswordPage } from './auth/resend-password';
import ResetPasswordPage from './auth/reset-password';
import { PAGE_LINK } from '../assets/constants/page-links';
import SomethingWentWrongPage from '../pages/smth-went-wrong';
import { AccountConfirmedPage } from '../pages/auth/account-confirmed';
import UploadPage from "../pages/admin/upload";
import UploadHistoryPage from '../pages/admin/upload/upload-history';
import pdfDocument from '../assets/docs/ccpa.pdf';
import OptOutsPage from '../pages/admin/opt-outs';
import SetPassword from "../pages/set-password";
import AddAgreement from "../pages/admin/add-agreement";
import MappingPage from "../pages/admin/mapping";
import ConfigService from "./configs/config-service";
import PrivateRoute from "../common/routes/private-route";
import ContactUs from "../pages/contact-us";
import { PUBLIC_ROUTE_ROLES, ADMIN_ROUTE_ROLES, EDITOR_ROUTE_ROLES } from "../common/routes/filtered-roles-constants";

const isPrerender = navigator.userAgent === "ReactSnap";

export default class Routes extends React.Component<any, any> {

    componentDidMount() {
        !isPrerender && ConfigService.getConfigs();
    }

    render() {
        return (
            <Switch>
                <Route exact path={PAGE_LINK.LANDING} render={() => <LandingPage />} />
                <Route path={PAGE_LINK.LOGIN} render={() => <SignIn />} />
                <Route path={PAGE_LINK.TERMS_OF_USE} render={() => <TermsOfUsePage />} />
                <Route path={PAGE_LINK.FAQ} render={() => <FAQPage />} />
                <Route path={PAGE_LINK.PRIVACY_POLICY} render={() => <PrivacyPolicyPage />} />
                <Route path={PAGE_LINK.LIST_OWNERS_AND_MAILERS} render={() => <ListOwnersAndMailersPage />} />
                <Route path={PAGE_LINK.SITE_MAP} render={() => <SiteMapPage />} />
                <Route path={PAGE_LINK.CCPA} component={CCPAText} />
                <Route path={PAGE_LINK.CONTACT_US} component={ContactUs} />
                <Route path={PAGE_LINK.THANK_YOU} render={() => <ThankYouPage />} />
                <Route path={PAGE_LINK.RESEND_PASSWORD} render={() => <ResendPasswordPage />} />
                <Route path={PAGE_LINK.RESET_PASSWORD} render={() => <ResetPasswordPage />} />
                <Route path={PAGE_LINK.REGISTER} component={Register} />
                <Route path={PAGE_LINK.SEARCH_RESULT} render={() => <SearchResultPage />} />
                <Route path={PAGE_LINK.SEARCH_RESULTS} render={() => <SearchResultsByEmailPage />} />
                <Route path={PAGE_LINK.SEARCH_RESULTS_BY_ADDRESS_ROUTE} render={() => <SearchResultsByAddressPage />} />
                <Route path={PAGE_LINK.SEARCH_DELETE_CONFIRMATION} render={() => <SearchDeleteConfirmationPage />} />
                <Route path={PAGE_LINK.CONFIRM_USER} render={() => <AccountConfirmedPage />} />
                <Route path="/error/:code?" component={ErrorPage} />
                <Route path={PAGE_LINK.DOWNLOAD_GUIDE} component={pdfDocument} />
                <Route path={PAGE_LINK.SET_PASSWORD} component={SetPassword} />
                <Route path={PAGE_LINK.ALERTS_CENTER} component={AlertsCenterPage} />

                <PrivateRoute listRoles={PUBLIC_ROUTE_ROLES} path={PAGE_LINK.UPLOAD_FILE} component={UploadPage} />
                <PrivateRoute listRoles={PUBLIC_ROUTE_ROLES} path={PAGE_LINK.UPLOAD_HISTORY} component={UploadHistoryPage} />
                <PrivateRoute listRoles={PUBLIC_ROUTE_ROLES} path={PAGE_LINK.OPT_OUTS} component={OptOutsPage} />
                <PrivateRoute listRoles={ADMIN_ROUTE_ROLES} path={PAGE_LINK.LIST_OF_CLIENTS} component={ListClients} />
                <PrivateRoute listRoles={ADMIN_ROUTE_ROLES} path={PAGE_LINK.CREATE_USER} component={AddClient} />
                <PrivateRoute listRoles={ADMIN_ROUTE_ROLES} path={PAGE_LINK.MANAGE_MAPPINGS} component={MappingPage} />
                <PrivateRoute listRoles={ADMIN_ROUTE_ROLES} path={PAGE_LINK.EDIT_USER} component={EditClient} />
                <PrivateRoute listRoles={PUBLIC_ROUTE_ROLES} path={PAGE_LINK.EDIT_PROFILE} component={EditProfile} />
                <PrivateRoute listRoles={ADMIN_ROUTE_ROLES} path={PAGE_LINK.ADD_AGREEMENT} component={AddAgreement} />
                <PrivateRoute listRoles={ADMIN_ROUTE_ROLES} path={PAGE_LINK.ALERTS_CONTACTS} component={AlertsContactsPage} />
                <Route path={PAGE_LINK.NEWS} component={NewsPage} exact />
                <Route path={PAGE_LINK.ARTICLE} component={ArticlePage} />
                <PrivateRoute listRoles={EDITOR_ROUTE_ROLES} allowForMainCompanyAdmin={true} path={PAGE_LINK.ADMIN_NEWS} component={AdminNewsPage} />
                <PrivateRoute listRoles={EDITOR_ROUTE_ROLES} allowForMainCompanyAdmin={true} path={PAGE_LINK.ADD_ARTICLE} component={AddArticlePage} />
                <PrivateRoute listRoles={EDITOR_ROUTE_ROLES} allowForMainCompanyAdmin={true} path={PAGE_LINK.EDIT_ARTICLE} component={EditArticlePage} />
                <Route component={SomethingWentWrongPage} />
            </Switch>
        );
    }
}