import * as React from "react";
import { connect } from "react-redux";
import * as NewsStore from "./news-reducer";
import LoadingOverlay from "react-loading-overlay";
import { withUserLayout } from "../../common/user-layout";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { INewsState, ISlug } from "./news-types";
import { ApplicationState } from "../../store";
import { RouteComponentProps } from "react-router";
import { IArticleViewModel } from "./news-types";
import { Button, Divider, Form, Input, notification, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FormInstance } from "antd/lib/form";
import {
    editorModules,
    editorFormats,
    ValidationMessages,
    NewsNotifications,
    MAX_SHORT_CONTENT_LENGTH,
    EDITOR_CONTENT_TYPES,
} from "./news-constants";
import { DEFAULT_ZERO_VALUE, MAX_IMAGE_FILE_SIZE, STRING_EMPTY } from "../../assets/constants/general";
import { Editor } from "@tinymce/tinymce-react";

const { Item } = Form;

type NewsProps = RouteComponentProps<ISlug> &
    INewsState &
    typeof NewsStore.actions;

export class EditArticlePage extends React.PureComponent<NewsProps> {
    state = {
        image: {} as File,
        tempImageLink: STRING_EMPTY,
        shortContentSymbolsLeft: MAX_SHORT_CONTENT_LENGTH,
        isImageChanged: false,
        isHtmlChanged: false,
    };

    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        const { getArticle, loadCategories } = this.props;
        const { slug } = this.props.match.params;

        loadCategories();
        getArticle(slug);
    }

    onFinish = (values: IArticleViewModel) => {
        if (
            this.state.isHtmlChanged ||
            this.state.isImageChanged ||
            this.checkIfFormWasChanged(values)
        ) {
            this.completeEdit(values);
        } else {
            notification.error({
                message: NewsNotifications.nothingWasChanged,
            });
        }
    };

    completeEdit(values: IArticleViewModel) {
        values.image = this.state.isImageChanged ? this.state.image : null;
        values.id = this.props.article.id;
        values.title = values.title.trim();
        values.htmlContent = this.getHtmlContent(values.editor);
        this.props.editArticle(values);
    }

    getHtmlContent(editor: any) {
        const type = this.state.isHtmlChanged ? editor.level.type : null;
        switch (type) {
            case EDITOR_CONTENT_TYPES.COMPLETE: {
                return editor.level.content;
            }
            case EDITOR_CONTENT_TYPES.FRAGMENTED: {
                return editor.level.fragments.join(STRING_EMPTY);
            }
        }
    }

    onTitleChange = (e: any) => {
        this.formRef.current.setFieldsValue({
            slug: this.createSlug(e.target.value),
        });
    };

    onImageChange = (e: any) => {
        const file = e.target.files[DEFAULT_ZERO_VALUE];
        
        if (file.size > MAX_IMAGE_FILE_SIZE) {
            this.formRef.current.setFieldsValue({
                image: undefined
            });
            return notification.error({message: NewsNotifications.imageFileSizeTooLarge})
        }
        
        this.setState({
            image: file,
            isImageChanged: true,
            tempImageLink: window.URL.createObjectURL(file),
        });
    };

    onShortContentChange = (e: any) => {
        const value = e.target.value;

        this.setState({
            shortContentSymbolsLeft: MAX_SHORT_CONTENT_LENGTH - value.length,
        });
        if (value.length === MAX_SHORT_CONTENT_LENGTH) {
            notification.error({
                message: ValidationMessages.maxSymbolsReached,
            });
        }
    };

    formChanged = (e: any) => {
        this.setState({
            lastUpdatedElement: e.target,
        });
    };

    checkIfFormWasChanged(values: IArticleViewModel) {
        return (
            values.title !== this.props.article.title ||
            values.shortContent !== this.props.article.shortContent ||
            values.categoryId !== this.props.article.categoryId
        );
    }

    createSlug = (title: string = STRING_EMPTY) => {
        return title
            .trim()
            .toLowerCase()
            .replace(/[^\w ]+/g, STRING_EMPTY)
            .replace(/ +/g, "-");
    };

    handleEditorChange = (content: string, editor: any) => {
        if (!this.state.isHtmlChanged) {
            this.setState({
                isHtmlChanged: true,
            });
        }
    };

    public render() {
        const { isLoading, article, history, categories } = this.props;

        const initialValues = {
            title: article.title,
            slug: article.slug,
            shortContent: article.shortContent,
        };

        return (
            <LoadingOverlay
                className="article-page d-flex flex-column justify-content-start w-100"
                active={isLoading}
                spinner
            >
                {article.id > DEFAULT_ZERO_VALUE ? (
                    <div className="container">
                        <Form
                            name="add_article"
                            onFinish={this.onFinish}
                            ref={this.formRef}
                            layout={"vertical"}
                            className="d-flex flex-column flex-wrap align-items-center"
                            onChange={this.formChanged}
                            initialValues={initialValues}
                        >
                            <div className="d-flex w-100 justify-content-between double-inputs">
                                <Item
                                    name={"title"}
                                    rules={[
                                        {
                                            required: true,
                                            message: ValidationMessages.title,
                                        },
                                    ]}
                                    label="Title"
                                >
                                    <Input
                                        placeholder="Article's title"
                                        onChange={this.onTitleChange}
                                    />
                                </Item>
                                <Item name={"slug"} label="Slug">
                                    <Input readOnly />
                                </Item>
                            </div>
                            <Item
                                label={`Short content (${
                                    this.state.shortContentSymbolsLeft ===
                                        MAX_SHORT_CONTENT_LENGTH &&
                                    article.shortContent.length > DEFAULT_ZERO_VALUE
                                        ? MAX_SHORT_CONTENT_LENGTH -
                                          article.shortContent.length
                                        : this.state.shortContentSymbolsLeft
                                } characters available)`}
                                name={"shortContent"}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            ValidationMessages.shortContent,
                                    },
                                ]}
                            >
                                <TextArea
                                    placeholder="Briefly describe the article's content"
                                    style={{ height: "150px" }}
                                    maxLength={MAX_SHORT_CONTENT_LENGTH}
                                    onChange={this.onShortContentChange}
                                />
                            </Item>
                            <Item
                                label="Category"
                                name="categoryId"
                                initialValue={article.categoryId}
                                rules={[
                                    {
                                        required: true,
                                        message: ValidationMessages.category,
                                    },
                                ]}
                            >
                                <Select>
                                    {categories.map((elem, i) => {
                                        return (
                                            <Select.Option
                                                key={`category_item_${i}`}
                                                value={elem.id as string}
                                            >
                                                {elem.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Item>
                            <Item label="Content" name={"editor"}>
                                <Editor
                                    initialValue={article.htmlContent}
                                    init={{
                                        height: 400,
                                        menubar: true,
                                        plugins: editorModules,
                                        toolbar: editorFormats,
                                    }}
                                    onEditorChange={this.handleEditorChange}
                                />
                            </Item>
                            <Item label="Image" name={"image"}>
                                <div>
                                    <img
                                        className="img-fluid"
                                        src={
                                            this.state.isImageChanged
                                                ? this.state.tempImageLink
                                                : article.linkToImage
                                        }
                                    />
                                    <div className="d-flex ">
                                        <Button
                                            htmlType="button"
                                            className="btn btn-outline-primary btn-lg w-auto"
                                        >
                                            Change image
                                            <Input
                                                onChange={this.onImageChange}
                                                accept="image/*"
                                                type="file"
                                                className="upload-input"
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </Item>
                            <Divider />
                            <Item>
                                <div className="d-flex justify-content-between w-100">
                                    <div className="align-right">
                                        <Button
                                            htmlType="submit"
                                            loading={isLoading}
                                            className="btn btn-primary w-auto"
                                        >
                                            SAVE
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            htmlType="button"
                                            className="btn btn-outline-primary w-auto"
                                            onClick={() => {
                                                history.push(
                                                    PAGE_LINK.ADMIN_NEWS
                                                );
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </Item>
                        </Form>
                    </div>
                ) : (
                    <div></div>
                )}
            </LoadingOverlay>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.news,
    NewsStore.actions
)(withUserLayout(EditArticlePage as any, PAGE_LINK.EDIT_ARTICLE));