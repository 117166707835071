import * as React from "react";
import * as NewsStore from "./news-reducer";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { INewsState, ISubNewsComponentProps } from "./news-types";
import { FIRST_PAGE_NUMBER } from "../../assets/constants/general";
import { DEFAULT_SUB_NEWS_COUNT, SUB_NEWS_TYPES } from "./news-constants";
import Icon, { FireOutlined, ProfileOutlined } from "@ant-design/icons";
import FireIcon from "../../assets/images/fire.svg"
import CCPALabel from "../../assets/images/ccpa-label.svg"
import { Link } from "react-router-dom";

type SubNewsProps = INewsState &
    typeof NewsStore.actions &
    ISubNewsComponentProps;

export class SubNews extends React.PureComponent<SubNewsProps> {
    componentDidMount() {
        const { loadSubNews, pagination, subNewsCategoryId, subNewsType } = this.props;
        pagination.pageNumber = FIRST_PAGE_NUMBER;
        pagination.pageSize = DEFAULT_SUB_NEWS_COUNT;

        loadSubNews(subNewsType, subNewsCategoryId, pagination);
    }

    getSubNewsTitle() {
        const { subNewsType, subNewsTitle } = this.props;

        switch (subNewsType) {
            case SUB_NEWS_TYPES.RECENT_NEWS: {
                return (
                    <>
                        <img src={FireIcon} height="25px" className="mr-2" />
                        {subNewsTitle}
                    </>
                );
            }
            case SUB_NEWS_TYPES.CATEGORY_NEWS: {
                return (
                    <>
                        <img src={CCPALabel} height="25px" className="mr-2" />
                        {subNewsTitle}
                    </>
                )
            }
        }
    }

    public render() {
        const { subNews, subNewsType } = this.props;
        return (
            <div className="recent-news-block">
                <div className="label">{this.getSubNewsTitle()}</div>
                <div className="recent-news-list">
                    {subNews[subNewsType].map((article, i) => {
                        return (
                            <div
                                key={`recent-article-${i}`}
                                className="recent-article-item"
                            >
                                <Link
                                    to={PAGE_LINK.ARTICLE.replace(
                                        ":slug",
                                        article.slug
                                    )}
                                >
                                    <div className="title">{article.title}</div>
                                    <div className="category-name">
                                        {article.category.name}
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default SubNews;