import * as React from "react";
import { isIOS } from 'react-device-detect';
import phone from '../images/phone.svg';

type PhoneDialProps = { phoneNumber: string, withIcon?: boolean, className?: string };

export const PhoneDial = ({ phoneNumber, withIcon = false, className }: PhoneDialProps) => {
    const phoneLink = isIOS ? `telprompt:${phoneNumber}` : `tel:${phoneNumber}`;

    return (
        <div className="phone">
            <a className={className} href={phoneLink}>{withIcon && <img src={phone} alt="Phone" />} {phoneNumber}</a>
        </div>
    )
}