import * as React from "react";
import { connect } from "react-redux";
import { withLayout } from "../../common/layout";
import { RootReducer } from "../../store/configure-store";
import { CONTACT_INFO } from "../../assets/constants/contact_info";

import './terms-of-use.scss';
import { Helmet } from "react-helmet";

class TermsOfUsePage extends React.Component<{}, {}> {
    public render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Terms of Use</title>
                    <meta name="description"
                        content="MyDataPrivacy, LLC’s Software as a Service solution was developed by The Alesco Group, LLC, dba “Alesco Data”, a leading supplier of marketing data to Resellers, Brokers, Agencies, and SMBs." />
                </Helmet>

                <div className="terms-of-use-header py-4">
                    <div className="container">
                        <h1>Terms of Use</h1>
                        <h2>MyDataPrivacy, LLC’s Software as a Service solution was developed by The Alesco Group, LLC, dba “Alesco Data”, a leading supplier of marketing data to Resellers, Brokers, Agencies, and SMBs.</h2>
                    </div>
                </div>

                <div className="container">
                    <div className="mt-4 terms-of-use-page">
                        <p>The company provides a cost effective, easy-to-implement solution for businesses subject to the California Consumer Privacy Act (CCPA). A Licensee’s data from their CRM or private database, including prospect lists acquired from time to time, are uploaded to MyDataPrivacy.com. The data is sorted in silos prescribed by CCPA, with Personally Identifiable Information (defined as information unique to one individual) masked. From a link on the footer of the businesses’ website, or from its privacy policy, a consumer can search for his or her own data in MyDataPrivacy.com, by name and address or email address. Consumers are able to opt-out or delete themselves from the businesses’ database through the MyDataPrivacy.com website, subject to verification and specific restrictions required under the law.</p>
                        <p>MyDataPrivacy.com captures date, time, and IP address of consumer opt-outs and deletes, and makes same available to businesses for download and suppression.</p>
                        <p>Unless otherwise noted, all information, user account information, articles, data, images, passwords, screens, text, user names, Web pages, or other materials (collectively "Content") appearing on the this site (hereafter "Site") are the exclusive property of this website (hereinafter "Company"):</p>
                        <p>All information, products, services and software contained on or used in the Site ("Company") is Copyright 2019 by Company. All rights reserved. Please assume that everything you see or read on the Site is copyrighted, and may not be copied or reproduced unless otherwise noted.</p>
                        <p>The trademarks, logos, service marks, and trade dress (collectively the "Trademarks") displayed on the Site are registered and unregistered Trademarks of Company or others.</p>
                        <p>Images of people, objects, or places displayed on the Site are either the property of Company, or used with permission by Company.</p>
                        <p>Company owns or uses by permission all software contained on the Site, including without limitation all HTML code and Active X controls. Copyright and other laws and international treaty provisions protect this software. The law expressly prohibits any modification, redistribution, or reproduction of the software, and such actions could result in severe civil and criminal penalties. Company will seek and support prosecuting violators to the maximum extent possible.</p>
                        <p>You may not copy, display, distribute, download, license, modify, publish, re-post, reproduce, reuse, sell, transmit, use to create a derivative work, or otherwise use the content of the Site for public or commercial purposes. Nothing on the Site shall be construed to confer any grant or license of any intellectual property rights, whether by estoppel, by implication, or otherwise.</p>
                        <p className="text-center">For more information on our terms of use, contact us at <a className='email-link' href={`mailto:${CONTACT_INFO.EMAIL}`}>info@mydataprivacy.com</a></p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: RootReducer) => { }

const connectedTermsOfUsePage = connect(mapStateToProps, {})(withLayout(TermsOfUsePage));
export { connectedTermsOfUsePage as TermsOfUsePage };