import AuthStore, { IAuthUser } from "../../local-store/auth";
import { ACCESS_STATUS } from "./filtered-roles-constants";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from "../../assets/constants/roles";
import ConfigManager from "../../pages/configs/config-manager";
const jwt = require('jsonwebtoken');

export const accessFilter = (listRoles: string[], allowForMainCompanyAdmin: boolean): ACCESS_STATUS => {
    const token = AuthStore.getToken();
    const mainCompany = ConfigManager.mainCompany();

    if (!token) {
        return ACCESS_STATUS.NON_AUTHORIZED;
    } else {
        const decodedToken = jwt.decode(token);
        const currentUserRole = decodedToken.role;
        const currentUserCompany = decodedToken.company;
        if (listRoles.includes(currentUserRole) || (currentUserRole == SUPER_ADMIN_ROLE)) {
            return ACCESS_STATUS.ACCESSIBLE;
        } if (allowForMainCompanyAdmin && currentUserRole == ADMIN_ROLE && currentUserCompany == mainCompany) {
            return ACCESS_STATUS.ACCESSIBLE;
        }
        else {
            return ACCESS_STATUS.FORBIDDEN;
        }
    }
}