import { Button } from 'antd';
import MappedTable from "./mapped-table";
import * as React from 'react';

interface IAddToDatabaseProps {
    onFinish: () => void,
    // selectedHeaderValues: {}; TODO: for finish table
    records: object[];
    onBack: Function;
}

const AddToDatabase = ({ onFinish, records, onBack }: IAddToDatabaseProps) => {
    return (
        <div className="d-flex flex-column align-content-center">
            <Button className="m-3" size="large" type="primary" onClick={onFinish}>ADD TO DATABASE</Button>
            <Button className="back_btn m-3" onClick={() => onBack()} shape="round" type="text">Back</Button>
            {/* TODO: Add finish table
             <MappedTable tableData={records} selectedHeaderValues={selectedHeaderValues} /> */}
        </div>
    );
}

export default AddToDatabase;