import { Reducer } from "redux";
import { AppThunkAction } from '../../../store';
import UploadService, { IMappingProfile, IUploadFile, IUserMapping } from "./upload-service";
import { typedAction } from "../../../helpers/action-helper";
import { columnNotificationDuration,
         headerField,
         notificationDuration,
         numberNotMappedFieldsToShowColumnError,
         stepSize,
         suportedField,
         UploadActionTypes,
         zeroNumber } from "./upload-constats";
import { IDataForUpload, IFileData, IMapModel, IOrderFieldsModel, IOrderModel, ISelectValue, IUploadActions, IUploadState, IUserUpload, OptionalFields } from "./upload-types";
import { showErrorNotification } from "../../../helpers/notification-helper";
import { IPagination } from "../../../assets/global/types";
import _ from "lodash";
import { notification } from "antd";

const uploadService = new UploadService();

const start = () => typedAction(UploadActionTypes.started);
const success = () => typedAction(UploadActionTypes.success);
const fail = (error: { [key: string]: string }) => typedAction(UploadActionTypes.failed, error);
const setMapper = (mapModel: any) => typedAction(UploadActionTypes.setMapper, mapModel);
const setOrder = (orderModel: IOrderModel) => typedAction(UploadActionTypes.setOrder, orderModel);
const setDataForUpload = (dataForUpload: IDataForUpload) => typedAction(UploadActionTypes.setDataForUpload, dataForUpload);
const setFileData = (data: IFileData) => typedAction(UploadActionTypes.setFileData, data);
const setCurrentStep = (step: number) => typedAction(UploadActionTypes.setCurrentStep, step);
const setShowModalFalse = () => typedAction(UploadActionTypes.setShowModalFalse);
const saveMapperStart = () => typedAction(UploadActionTypes.saveMapperStart);
const saveMapperSuccess = () => typedAction(UploadActionTypes.saveMapperSuccess);
const saveMapperFailed = (error: { [key: string]: string }) => typedAction(UploadActionTypes.saveMapperFailed, error);
const setUserMappings = (userMappings: IUserMapping[]) => typedAction(UploadActionTypes.setUserMappings, userMappings);
const setUserUploads = (uploads: any[]) => typedAction(UploadActionTypes.setUserUploads, uploads);
const setUserUploadsIsLoading = (isLoading: boolean) => typedAction(UploadActionTypes.setUserUploadsIsLoading, isLoading);
const setUserUploadsPagination = (pagination: IPagination) => typedAction(UploadActionTypes.setUserUploadsPagination, pagination);
const addFileToOrder = (orderFile: IUploadFile) => typedAction(UploadActionTypes.addFileToOrder, orderFile);
const removeFile = (fileId: string) => typedAction(UploadActionTypes.removeFile, fileId);
const addNewMappProfile = (mappModel: IMapModel) => typedAction(UploadActionTypes.addNewMappProfile, mappModel);
const setMapperLoading = (isLoading: boolean, index: number) => typedAction(UploadActionTypes.setMapperLoading, { isLoading, index });
const setMapperSaved = (name: string, isSaved: boolean, index: number) => typedAction(UploadActionTypes.setMapperSaved, { name, isSaved, index });
const selectMapping = (mappingName: string, isAllFiles: boolean, fileIndex: number) => typedAction(UploadActionTypes.selectMapping, { mappingName, isAllFiles, fileIndex});
const setFileIndex = (index: number) => typedAction(UploadActionTypes.setFileIndex, { index });

const getDataForUpload = (): AppThunkAction<UploadAction> => (dispatch) => {
    uploadService.getDataForUpload()
        .then((response) => {
            if (!response.is_error) {
                dispatch(setDataForUpload(response.content));
            } else {
                showErrorNotification(response);
            }
        })
}

const getUploads = (): AppThunkAction<UploadAction> => (dispatch, getState) => {
    dispatch(setUserUploadsIsLoading(true));
    const { userUploadsPagination } = getState().upload;

    uploadService.getUploads(userUploadsPagination)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setUserUploads(response.content?.uploads))
                dispatch(setUserUploadsPagination(response.content?.pagination))
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setUserUploadsIsLoading(false)));
}

const getUserMappings = (): AppThunkAction<UploadAction> => (dispatch) => {
    uploadService.getMappingProfiles()
        .then((response) => {
            if (!response.is_error) {
                dispatch(setUserMappings(response.content));
            } else {
                showErrorNotification(response);
            }
        })
}

const saveMapper = (index: number, model: IMappingProfile): AppThunkAction<UploadAction> => async (dispatch) => {
    dispatch(setMapperLoading(true, index));
    await uploadService.saveMappingProfile(model)
        .then((response) => {
            if (!response.is_error) {
                notification.success({ message: `Mapping '${model.name}' was saved successfully!` });
                dispatch(setMapperSaved(model.name, true, index));
            }
            else {
                dispatch(saveMapperFailed(response.error_content.errors));
                showErrorNotification(response);
                setMapperSaved(null, false, index)
            }
        })
        .catch((response) => {
            dispatch(saveMapperFailed(response.error_content.errors));
            showErrorNotification(response);
        })
        .finally(() => dispatch(setMapperLoading(false, index)))
}

const finishUpload = (): AppThunkAction<UploadAction> => (dispatch, getState) => {
    dispatch(start());
    const { orderModel } = getState().upload;
    uploadService.finishUpload(orderModel)
        .then((response) => {
            if (!response.is_error) {
                dispatch(success());
            }
            else {
                dispatch(fail(response.error_content.errors));
                showErrorNotification(response);
            }
        })
        .catch((response) => {
            dispatch(fail(response.error_content.errors));
            showErrorNotification(response);
        })
}

type UploadAction = ReturnType<
    typeof start |
    typeof success |
    typeof fail |
    typeof setMapper |
    typeof setOrder |
    typeof setFileData |
    typeof setDataForUpload |
    typeof setShowModalFalse |
    typeof saveMapperStart |
    typeof saveMapperSuccess |
    typeof saveMapperFailed |
    typeof removeFile>

export const uploadActions: IUploadActions = {
    start,
    success,
    fail,
    setFileData,
    setMapper,
    setOrder,
    setCurrentStep,
    finishUpload,
    setDataForUpload,
    getDataForUpload,
    setShowModalFalse,
    saveMapperFailed,
    saveMapperStart,
    saveMapperSuccess,
    saveMapper,
    getUserMappings,
    setUserMappings,
    getUploads,
    setUserUploadsPagination,
    addFileToOrder,
    removeFile,
    addNewMappProfile,
    setMapperLoading,
    setMapperSaved,
    selectMapping,
    setFileIndex
}

const unloadedState: IUploadState = {
    saveMappingStatus: {
        isLoading: false,
        isFailed: false,
        isSuccess: false,
        errors: {},
    },
    uploadStatus: {
        isFailed: false,
        isLoading: false,
        isSuccess: false,
        showModal: false,
        errors: {},
    },
    userMappings: [],
    uploadModel: {},
    orderModel: {
        businessPurpose: null,
        thirdPartyCategory: null,
        referenceNumber: null,
        collectionSources: [] as number[],
        uploadFiles: []
    },
    mapModels: [],
    fieldsValues: [],
    filesData: [],
    current: 0,
    currentFileIndex: 0,
    dataForUpload: {
        thirdPartyCategories: [],
        collectionSources: [],
        businessPurposes: [],
        optionalFields: [],
        supportedFields: []
    },
    userUploads: [],
    userUploadsIsLoading: false,
    userUploadsPagination: {
        pageNumber: 1,
        pageSize: 10,
        searchCriteria: null,
        sortCriteria: 'UploadedAt',
        isAscend: false,
        total: 0
    }
};

export const reducer: Reducer<IUploadState, UploadAction> = (state: IUploadState = unloadedState, action: UploadAction): IUploadState => {
    switch (action.type) {
        case UploadActionTypes.setFileData: {

            const { dataForUpload: { supportedFields } } = state;
            const fileData = action.payload as IFileData;

            let selectedHeaderValues: { [key: string]: ISelectValue } = {};

            _.uniqBy(fileData.headers, h => h.outputColumn)
                .forEach(h => {
                    const type = supportedFields.find(x => _.toLower(x) === _.toLower(h.type)) ? suportedField : headerField;
                    const fileColumn = fileData.fileColumns.find(fColumn => _.toLower(fColumn) === _.toLower(h.outputColumn));

                    if (fileColumn) {
                        selectedHeaderValues = {
                            ...selectedHeaderValues,
                            [fileColumn]: {
                                name: type === suportedField ? h.type : h.outputColumn,
                                friendlyName: type === headerField && h.friendlyOutputColumn,
                                type
                            } as ISelectValue
                        };
                    }
                });

            supportedFields.forEach(sField => {
                const fileColumn = fileData.fileColumns.find(fColumn => _.toLower(fColumn) === _.toLower(sField));
                if (fileColumn) {
                    selectedHeaderValues = {
                        ...selectedHeaderValues,
                        [fileColumn]: {
                            name: sField,
                            type: suportedField
                        } as ISelectValue
                    }
                }
            });

            return {
                ...state,
                filesData: [...state.filesData, fileData],
                mapModels: [...state.mapModels, {
                    canSave: fileData.fileColumns.length == _.keys(selectedHeaderValues).length,
                    fileId: fileData.fileId,
                    fileName: fileData.fileName,
                    isNewMapping: true,
                    name: null,
                    selectedHeaderValues,
                    isLoading: false

                } as IMapModel]
            };
        }
        case UploadActionTypes.setFileIndex: {
            return {
                ...state,
                currentFileIndex: action.payload.index
            }
        }
        case UploadActionTypes.removeFile: {
            const { filesData = [], orderModel: { uploadFiles = [] }, mapModels = [] } = state;

            const filesDataIndex = filesData.findIndex(fileData => fileData.fileId == action.payload);
            const uploadFilesIndex = uploadFiles.findIndex(uploadFile => uploadFile.fileId == action.payload);
            const mapModelsIndex = mapModels.findIndex(mapModel => mapModel.fileId == action.payload);

            let newFilesData = filesData.slice();
            let newUploadFiles = uploadFiles.slice();
            let newMapModels = mapModels.slice();

            newFilesData.splice(filesDataIndex, 1);
            newUploadFiles.splice(uploadFilesIndex, 1);
            newMapModels.splice(mapModelsIndex, 1);

            return {
                ...state,
                filesData: newFilesData,
                orderModel: {
                    ...state.orderModel,
                    uploadFiles: newUploadFiles
                },
                mapModels: newMapModels
            }
        }
        case UploadActionTypes.setOrder:
            return {
                ...state,
                orderModel: action.payload as IOrderModel
            };
        case UploadActionTypes.setCurrentStep:
            return {
                ...state,
                current: action.payload
            }
        case UploadActionTypes.setDataForUpload:
            return {
                ...state,
                dataForUpload: action.payload
            }
        case UploadActionTypes.saveMapperStart:
            return {
                ...state,
                saveMappingStatus: {
                    isFailed: false,
                    isLoading: true,
                    isSuccess: false,
                    errors: {}
                }
            }
        case UploadActionTypes.saveMapperSuccess:
            return {
                ...state,
                current: state.current + stepSize,
                saveMappingStatus: {
                    isFailed: false,
                    isLoading: false,
                    isSuccess: true,
                    errors: {}
                }
            }
        case UploadActionTypes.saveMapperFailed:
            return {
                ...state,
                saveMappingStatus: {
                    isFailed: true,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.payload
                }
            }
        case UploadActionTypes.started:
            return {
                ...state,
                uploadStatus: {
                    showModal: false,
                    isFailed: false,
                    isLoading: true,
                    isSuccess: false,
                    errors: {}
                }
            }
        case UploadActionTypes.success:
            return {
                ...state,
                uploadStatus: {
                    isFailed: false,
                    isLoading: false,
                    isSuccess: true,
                    showModal: true,
                    errors: {}
                }
            }
        case UploadActionTypes.failed:
            return {
                ...state,
                uploadStatus: {
                    showModal: false,
                    isFailed: true,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.payload
                }
            }
        case UploadActionTypes.setShowModalFalse:
            return {
                ...unloadedState,
                dataForUpload: {
                    ...state.dataForUpload,
                },
                uploadStatus: {
                    showModal: false,
                    isFailed: false,
                    isLoading: false,
                    isSuccess: false,
                    errors: {}
                }
            }
        case UploadActionTypes.addNewMappProfile: {
            const { mapModels, filesData } = state;

            let newMapModels = [...mapModels];

            let index = newMapModels.findIndex((mapModel => mapModel.fileId == action.payload.fileId));

            if (index != (-1)) {
                newMapModels[index] = action.payload;
            } else {
                newMapModels.push(action.payload);
            }

            newMapModels[index].canSave = filesData[index].fileColumns.length == _.keys(newMapModels[index].selectedHeaderValues).length;

            return {
                ...state,
                mapModels: newMapModels
            }
        }
        case UploadActionTypes.selectMapping: {
            const { userMappings, filesData, mapModels, orderModel: {uploadFiles = []} } = state;

            let newMapModels = [...mapModels];
            let mappedFiles = [...uploadFiles];

            let fieldsValues = new Array<Object>(mapModels.length);

            let startIndex = action.payload.fileIndex;
            let lastIndex = startIndex + 1;

            if (action.payload.isAllFiles) {
                startIndex = zeroNumber;
                lastIndex = filesData.length;
            }

            if (action.payload.mappingName) {
                userMappings.filter(x => x.name === action.payload.mappingName).forEach(usermapp => {
                    for (let index = startIndex; index < lastIndex; index++) {
                        let selectedHeaderValues = new Object() as {
                            [key: string]: ISelectValue;
                        };

                        let fieldsValue = new Object() as { [key: string]: string };
                        let notMappedFields: string[] = [];

                        usermapp.mappingColumns.forEach(mappcolumn => {
                            const fileColumnName = filesData[index].fileColumns.find(x => x.toLowerCase() === mappcolumn.fileColumnName.toLowerCase());

                            if (fileColumnName) {
                                fieldsValue[fileColumnName] = mappcolumn.type === suportedField
                                    ? mappcolumn.columnName
                                    : mappcolumn.type === headerField
                                        ? (mappcolumn.friendlyName || mappcolumn.columnName)
                                        : mappcolumn.type;

                                selectedHeaderValues[fileColumnName] = {
                                    name: mappcolumn.columnName,
                                    type: mappcolumn.type,
                                    friendlyName: mappcolumn.friendlyName
                                } as ISelectValue;
                            }
                        });

                        filesData[index].fileColumns.forEach(colName => {
                            if (!usermapp.mappingColumns.find(x => x.fileColumnName.toLowerCase() === colName.toLowerCase())) {
                                notMappedFields.push(colName);
                                fieldsValue[colName] = null;
                            }
                        })

                        if (notMappedFields.length > zeroNumber && notMappedFields.length <= numberNotMappedFieldsToShowColumnError) {
                            notMappedFields.forEach((colName) => {
                                notification.warning({
                                    message: filesData[index].fileName,
                                    description: `${colName} was not mapped`,
                                    duration: columnNotificationDuration,
                                });
                            });
                        } else if (notMappedFields.length > numberNotMappedFieldsToShowColumnError) {
                            notification.warning({
                                message: filesData[index].fileName,
                                description: `Some fields were not mapped`,
                                duration: notificationDuration
                            });
                        }

                        const canSave = filesData[index].fileColumns.length === _.keys(selectedHeaderValues).length;

                        newMapModels[index] = {
                            ...mapModels[index],
                            isNewMapping: false,
                            selectedHeaderValues: selectedHeaderValues,
                            name: action.payload.mappingName,
                            isAllFiles: true,
                            canSave: canSave
                        }

                        if (canSave) {
                            let mapping: { [key: string]: ISelectValue } = {};

                            _.keys(selectedHeaderValues)
                                .filter((key) => !!selectedHeaderValues[key].name)
                                .forEach(key => {
                                    mapping[selectedHeaderValues[key]?.name] = { name: key, type: selectedHeaderValues[key]?.type }
                                });

                            if (mappedFiles[index]) {
                                mappedFiles[index].mapping = mapping;
                            } else {
                                mappedFiles.push({
                                    fileId: filesData[index].fileId,
                                    fileName: filesData[index].fileName,
                                    recordsCount: filesData[index].recordsCount,
                                    mapping: mapping
                                });
                            }
                        }

                        fieldsValues[index] = fieldsValues;
                    }
                })
            }

            return {
                ...state,
                fieldsValues,
                orderModel: {
                    ...state.orderModel,
                    uploadFiles: mappedFiles
                },
                mapModels: newMapModels
            }
        }
        case UploadActionTypes.setMapperLoading: {
            const { isLoading, index } = action.payload;
            const mapModelsCopy = [...state.mapModels];
            mapModelsCopy.filter(model => model.canSave)
                .forEach((mapModel, idx) => {
                    if (index === idx) {
                        mapModel.isLoading = isLoading;
                    }
                })

            return {
                ...state,
                mapModels: mapModelsCopy
            }
        }
        case UploadActionTypes.setMapperSaved: {
            const { isSaved, index, name } = action.payload;
            const mapModelsCopy = [...state.mapModels];
            mapModelsCopy.filter(model => model.canSave)
                .forEach((mapModel, idx) => {
                    if (index === idx) {
                        mapModel.isSaved = isSaved;
                        mapModel.name = name;
                    }
                })

            return {
                ...state,
                mapModels: mapModelsCopy
            }
        }
        case UploadActionTypes.addFileToOrder: {
            const { orderModel: { uploadFiles = [] } } = state;

            let newUploadFiles = [...uploadFiles];

            let index = uploadFiles.findIndex((uploadFile => uploadFile.fileId == action.payload.fileId));

            if (index != (-1)) {
                newUploadFiles[index].mapping = action.payload.mapping
            } else {
                newUploadFiles.push(action.payload)
            }

            return {
                ...state,
                orderModel: {
                    ...state.orderModel,
                    uploadFiles: newUploadFiles
                }
            }
        }
        case UploadActionTypes.setUserMappings:
            return {
                ...state,
                userMappings: action.payload
            }
        case UploadActionTypes.setUserUploads:
            return {
                ...state,
                userUploads: action.payload as IUserUpload[]
            }
        case UploadActionTypes.setUserUploadsIsLoading:
            return {
                ...state,
                userUploadsIsLoading: action.payload as boolean
            }
        case UploadActionTypes.setUserUploadsPagination:
            return {
                ...state,
                userUploadsPagination: action.payload as IPagination
            }
        default: return state;
    }
}