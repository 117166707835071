import { Button, Col, Collapse, Form, Input, Row } from 'antd';
import * as React from 'react';
import { withLayout } from "../../common/layout";
import { isEmail, isPhone, isRequired, PhoneInput, withoutDigits } from '../../helpers/validate-helper';
import "./contact-us.scss";
import "../../assets/styles/app.scss";
import schedule from '../../assets/images/schedule.svg';
import { FAQQuestions } from "../landing/landing-helper";
import { MinusOutlined, PlusOutlined, PauseCircleTwoTone, PlayCircleTwoTone } from '@ant-design/icons';
import { CollapseProps } from 'antd/lib/collapse';
import { IFAQQuestion, IPotentialClient, ILandingProps, ILandingActions } from '../landing/landing-types';
import { Link } from 'react-router-dom';
import { PAGE_LINK } from '../../assets/constants/page-links';
import { FormInstance } from 'antd/lib/form';
import { FORM_NAMES } from '../landing/landing-constants';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { landingActions } from "../landing/landing-reducer";
import { Helmet } from 'react-helmet';

const { Panel } = Collapse;

type FormProps = ILandingProps & ILandingActions;

class ContactUs extends React.Component<FormProps> {
    formRef = React.createRef<FormInstance>();

    public onFinish = (values: IPotentialClient, formName: string) => {
        values.formName = formName;
        this.props.sendForm(values);
    }

    render() {

        const collapseProps: CollapseProps = {
            bordered: false,
            className: 'm-2',
            expandIconPosition: "right",
            expandIcon: ({ isActive }) => isActive
                ? <MinusOutlined style={{ color: "#2966F4" }} />
                : <PlusOutlined style={{ color: "#2966F4" }} />
        };

        const validateMessages = {
            types: {
                email: '${label} is not validate email!',
            }
        };

        return (
            <React.Fragment>
                <Helmet>
                    <title>Conatact Us</title>
                    <meta name="description"
                        content="Contact with My Data Privacy" />

                </Helmet>
                <div className="contact-us-page">
                    <div className="d-flex justify-content-center p-4 mb-5 align-Form.Items-center top-line">
                        <h1 className="m-0">Let's get started.</h1>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 contact-info-box p-0">
                                <div className="info-text">
                                    <p>My Data Privacy provides turnkey CCPA compliance for </p>
                                    <p>small to medium-sized businesses.. </p>
                                    <p>Our solution is simple, </p>
                                    <p>cost-effective and 100% outsourced.  </p>
                                </div>
                                <ul className="mt-4">
                                    <li>Cost-Effective</li>
                                    <li>Easy Data Mapping</li>
                                    <li>Built-in Risk Management</li>
                                    <li>Automated Compliance</li>
                                    <li>Drag and Drop File Uploads</li>
                                </ul>
                                <div className="d-flex align-items-start setup mt-4 justify-content-center flex-row">
                                    <img className='img-fluid mr-4' src={schedule} alt={"schedule"} />
                                    <div className="d-flex flex-column  pt-1">
                                        <p >Setup Takes Just</p>
                                        <strong >24 Hours!</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                                <Form layout={"vertical"}
                                    className='contact-form-contaiter px-md-5'
                                    scrollToFirstError={true}
                                    ref={this.formRef} name="nest-messages"
                                    onFinish={(values: IPotentialClient) => this.onFinish(values, FORM_NAMES.contactUs)}
                                    validateMessages={validateMessages}
                                >
                                    <h3 className="mb-4">Contact Us Today</h3>
                                    <Form.Item name={'firstName'} rules={[isRequired('First Name'), withoutDigits('First Name')]}>
                                        <Input placeholder='Name *' />
                                    </Form.Item>
                                    <Form.Item name={'email'} rules={[isRequired('Email Address'), isEmail('Email Address')]}>
                                        <Input placeholder='Email *' />
                                    </Form.Item>
                                    <Form.Item name={'company'}>
                                        <Input placeholder='Company Name' />
                                    </Form.Item>
                                    <Form.Item name={'phone'} rules={[isPhone('Phone Number')]}>
                                        <PhoneInput label='Phone Number' />
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-center mt-3 ">
                                            <Button
                                                loading={this.props.formLoading}
                                                type="primary"
                                                htmlType="submit">
                                                submit
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="ccpa-faq">
                        <div className="mt-5 d-flex  align-items-center flex-column info-block bordered p-4 pb-5">
                            <div className="container align-items-center ">
                                <div className="area-title text-center">CCPA</div>
                                <div className="main-title text-center mb-5" style={{ fontWeight: 400 }}>Frequently Asked Questions</div>
                                <Row className='d-flex w-100 questions-collapse'>
                                    <Col xs={24} md={24} lg={12}>
                                        <Collapse {...collapseProps}>
                                            {
                                                FAQQuestions.slice(0, 5).map((faq: IFAQQuestion, index) => (
                                                    <Panel header={faq.question} key={`faq_${index}`}>{faq.answer}</Panel>
                                                ))
                                            }
                                        </Collapse>
                                    </Col>
                                    <Col xs={24} md={24} lg={12}>
                                        <Collapse {...collapseProps}>
                                            {
                                                FAQQuestions.slice(5).map((faq: IFAQQuestion, index) => (
                                                    <Panel header={faq.question} key={`faq_${index}`}>{faq.answer}</Panel>
                                                ))
                                            }
                                        </Collapse>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="container d-flex flex-column align-items-center justify-content-center">
                        <div style={{ maxWidth: "700px" }} className="what-is-ccpa p-5">
                            <h3 className="text-center" >What is the CCPA?</h3>
                            <p className="text-center">
                                The CCPA (California Consumer Privacy Act), is a
                                bill meant to enhance privacy rights and
                                consumer protection for residents of California.
                            </p>
                            <Link to={PAGE_LINK.CCPA} className="btn btn-lg btn-primary faq-link">
                                Learn More
                            </Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state: ApplicationState) => state.landing

export default connect(mapStateToProps, landingActions)(withLayout(ContactUs));