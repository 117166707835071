import { IPagination } from '../../assets/global/types';
import RestUtilities from '../../helpers/rest-utilities';
import { IArticleViewModel, INewsModel, IArticle, ICategory } from './news-types';
import AuthStore from "../../local-store/auth";

class NewsService {
    deleteArticle(article: IArticle) {
        return RestUtilities.post<boolean>(`/api/news/delete-article`, article)
    }

    loadNews = (categoryId: number, pagination: IPagination) => {
        return RestUtilities.post<INewsModel>(`/api/news/get-news`, { categoryId: categoryId, pagination: pagination });
    }

    loadCategories = () => {
        return RestUtilities.get<ICategory[]>(`/api/news/get-categories`);
    }

    getCCPANews = () => {
        return RestUtilities.get<IArticle[]>('/api/news/get-ccpa-news');
    }

    createAticle = (article: IArticleViewModel) => {
        return this.processArticleRequest("create-article", article);
    }

    editArticle = (article: IArticleViewModel) => {
        return this.processArticleRequest("edit-article", article);
    }

    loadHtmlContent = (link: string): Promise<string | Document | boolean> => {
        return fetch(link)
            .then(response => {
                if (response.ok) {
                    return response.text()
                } else {
                    throw new Error("Could not load the article's content!")
                }
            })
            .then(html => {
                var parser = new DOMParser();
                var doc = parser.parseFromString(html, "text/html");
                return doc.querySelector('body').innerHTML;
            })
            .catch(() => {
                return false;
            });
    }

    processArticleRequest = (link: string, data: IArticleViewModel) => {
        const formData = new FormData();
        Object.entries(data).forEach((elem: any) => {
            formData.append(elem[0], elem[1]);
        });

        return fetch(`/api/news/${link}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${AuthStore.getToken()}`
            },
            body: formData
        });
    }

    getArticle = (slug: string) => {
        return RestUtilities.get<IArticle>(`/api/news/get-article?slug=${slug}`)
    }

    subscribe = (subscribeModel: any) => {
        return RestUtilities.post('/api/news/subscribe', subscribeModel);
    }
}

export default NewsService;