import * as React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as AddClientStore from "./reducer";
import { ApplicationState } from '../../../store';
import { IAddClientState, ICreatedUser } from "./add-client-types";
import { withUserLayout } from "../../../common/user-layout";
import { PAGE_LINK } from "../../../assets/constants/page-links";
import { Button, Form, Input, Select } from "antd";
import { STATE_OPTIONS } from "../../../assets/constants/states";
import * as _ from 'lodash';
import { ADMIN_ROLE, EDITOR_ROLE, LIST_OWNER_ROLE, SUPER_ADMIN_ROLE } from "../../../assets/constants/roles";
import AuthStore, { IAuthUser } from "../../../local-store/auth"
import { isPhone, isRequired, PhoneInput } from "../../../helpers/validate-helper";
import { MaskedInput } from 'antd-mask-input';
import ConfigManager from "../../configs/config-manager";
const jwt = require('jsonwebtoken');

const { Item } = Form;
const { Option } = Select;

type AddClientProps = IAddClientState
    & typeof AddClientStore.addClientActions

export class AddClient extends React.PureComponent<AddClientProps>{

    componentDidMount() {
        const authUser: IAuthUser = JSON.parse(AuthStore.getUser());

        if (authUser.role === SUPER_ADMIN_ROLE) {
            this.props.getListCompanies();
        }
    }

    onFinish = (values: any) => {
        this.props.addClient(values as ICreatedUser);
    }

    render() {
        const { errors = {}, isFailed, listCompanies, isLoading } = this.props;
        const authUser: IAuthUser = JSON.parse(AuthStore.getUser());
        const decodedToken = jwt.decode(AuthStore.getToken());

        const mainCompany = ConfigManager.mainCompany();
        const stateOptions = STATE_OPTIONS.map((x, idx) => (
            <Option key={`${x.shortName}${idx}`} value={x.shortName}>{x.shortName} ({x.name})</Option>
        ));

        const companyOptions = listCompanies.map(company => (
            <Option key={company.id} value={company.name}>{company.name}</Option>
        ))

        const validateMessages = {
            required: "Please input your ${name}!",
            types: {
                email: '${name} is not validate email!',
            },
            pattern: {
                mismatch: "${name} does not match pattern",
            }
        };

        return (
            <div className="container">
                <Form name="register"
                    onFinish={this.onFinish}
                    layout={"vertical"}
                    className='d-flex flex-column flex-wrap align-items-center'
                    validateMessages={validateMessages}
                    validateTrigger="onBlur">
                    <h2>Add List Owner</h2>
                    <div className="d-flex w-100 justify-content-between double-inputs">
                        <Item label="First Name" name={'firstName'} rules={[{ required: true }]}>
                            <Input />
                        </Item>
                        <Item label="Last Name" name={'lastName'} rules={[{ required: true }]}>
                            <Input />
                        </Item>
                    </div>
                    <Item name={'title'} label="Title">
                        <Input />
                    </Item>
                    <Item name={'email'} label="Email address" rules={[{ type: 'email', required: true }]}>
                        <Input />
                    </Item>
                    <Item label="Cell Phone" name={'phone'} rules={[isPhone('Cell Phone'), isRequired('Cell Phone')]}>
                        <PhoneInput label='' />
                    </Item>
                    {
                        decodedToken.role == SUPER_ADMIN_ROLE ?
                            (
                                <Item label="Company" name={'company'} rules={[{ required: true }]}>
                                    <Select>
                                        {companyOptions}
                                    </Select>
                                </Item>
                            ) : (
                                < Item name={'company'} label="Company" initialValue={authUser.company} rules={[{ required: true }]}>
                                    <Input value={authUser.company} disabled />
                                </ Item>
                            )
                    }
                    <Item name={'address1'} label="Address 1" rules={[{ required: true }]}>
                        <Input />
                    </Item>
                    <Item name={'address2'} label="Address 2">
                        <Input />
                    </Item>
                    <Item label="City" name={'city'} rules={[{ required: true }]}>
                        <Input />
                    </Item>
                    <div className="d-flex w-100 justify-content-between double-inputs">
                        <Item label="State" name={'state'} rules={[{ required: true }]}>
                            <Select>
                                {stateOptions}
                            </Select>
                        </Item>
                        <Item label="ZIP" name={'zip'} rules={[{ required: true }]}>
                            <MaskedInput mask="11111" placeholder="" />
                        </Item>
                    </div>
                    <div className="row border-bottom border-top pt-4 p-0">
                        <div className="col m-0">
                            <h5>Role</h5>
                        </div>
                        <div className="col m-0">
                            <Item initialValue={LIST_OWNER_ROLE} name={'role'} rules={[{ required: true }]}>
                                <Select>
                                    <Option value={LIST_OWNER_ROLE}>User</Option>
                                    <Option value={ADMIN_ROLE}>Admin</Option>
                                    {mainCompany == decodedToken.company && <Option value={EDITOR_ROLE}>Editor</Option>}
                                </Select>
                            </Item>
                        </div>
                    </div>
                    <Item>
                        <div className="d-flex justify-content-between">
                            <div>
                                <Link className="btn btn-outline-primary btn-lg" to={PAGE_LINK.LIST_OF_CLIENTS}>Cancel</Link>
                            </div>
                            <div>
                                <Button htmlType="submit" loading={isLoading} className="btn btn-primary">Save</Button>
                            </div>
                        </div>
                    </Item>
                </Form>
            </div >
        );
    }
}

export default connect(
    (state: ApplicationState) => state.addClient,
    AddClientStore.addClientActions)(withUserLayout(AddClient as any, PAGE_LINK.CREATE_USER));