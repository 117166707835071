import { IMappingActionsTypes } from "./mapping-types";

export const MappingConstants: IMappingActionsTypes = {
    success: 'REMOVE_MAPPING_SUCCESS',
    failed: 'REMOVE_MAPPING_FAILED',
    setMappings: 'SET_LIST_MAPPINGS',
    startLoading: 'START_MAPPING_REQUEST',
    uploadExcelFile: 'UPLOAD_EXCEL_FILE',
    showMessages: "SET_MAPPING_MESSAGES",
    hideModal: "MAPPING_HIDE_MODAL",
    modalClose: "MAPPING_ERROR_MODAL_CLOSE",
    showNotification: "MAPPING_SHOW_FILE_ERROR"
}

export const columns = [
    {
        title: 'File header',
        dataIndex: 'fileColumnName',
        key: 'fileColumnName',
    },
    {
        title: 'Colomn header',
        dataIndex: 'columnName',
        key: 'columnName',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
];