import * as ListClients from "../pages/admin/list-clients/reducer";
import { IListClientsState } from "../pages/admin/list-clients/list-clients-types"
import * as AddClient from "../pages/admin/add-client/reducer";
import { IAddClientState } from "../pages/admin/add-client/add-client-types"
import * as EditProfile from "../pages/edit-profile/reducer";
import { IEditProfileState } from "../pages/edit-profile/edit-profile-types";
import * as Auth from "../pages/auth/reducer";
import { IAuthState } from "../pages/auth/auth-types";
import { landingReducer } from '../pages/landing/landing-reducer';
import { searchReducer } from '../pages/search/search-reducer';
import { ILandingState } from "../pages/landing/landing-types";
import * as Upload from "../pages/admin/upload/reducer";
import { IUploadState } from "../pages/admin/upload/upload-types";
import { ISearchState } from "../pages/search/search-types";
import { IOptOutState } from "../pages/admin/opt-outs/opt-out-types";
import * as OptOut from "../pages/admin/opt-outs/opt-out-reducer";
import * as EditClient from "../pages/admin/edit-client/reducer";
import { IEditClientState } from "../pages/admin/edit-client/edit-client-types";
import { IMappingState } from "../pages/admin/mapping/mapping-types";
import * as Mapping from "../pages/admin/mapping/reducer";
import * as SetPassword from "../pages/set-password/reducer";
import { ISetPasswordState } from "../pages/set-password/set-password-types";
import * as AddAgreement from "../pages/admin/add-agreement/reducer";
import { IAddAgreementState } from "../pages/admin/add-agreement/add-agreement-types";
import { IAlertsState } from "../pages/alerts/alerts-types";
import * as Alerts from "../pages/alerts/alerts-reducer";
import { INewsState } from "../pages/news/news-types";
import * as News from "../pages/news/news-reducer";

export interface ApplicationState {
    listClients: IListClientsState;
    addClient: IAddClientState;
    editProfile: IEditProfileState;
    auth: IAuthState;
    landing: ILandingState;
    upload: IUploadState;
    search: ISearchState;
    editClient: IEditClientState;
    optOut: IOptOutState;
    setPassword: ISetPasswordState;
    addAgreement: IAddAgreementState;
    alert: IAlertsState;
    news: INewsState;
    mapping: IMappingState;
}

export const reducers = {
    listClients: ListClients.reducer,
    addClient: AddClient.reducer,
    editProfile: EditProfile.reducer,
    auth: Auth.reducer,
    landing: landingReducer,
    upload: Upload.reducer,
    search: searchReducer,
    optOut: OptOut.reducer,
    editClient: EditClient.reducer,
    setPassword: SetPassword.reducer,
    addAgreement: AddAgreement.reducer,
    alert: Alerts.reducer,
    news: News.reducer,
    mapping: Mapping.reducer
};

export interface AppThunkAction<TAction> {
    (
        dispatch: (action: TAction) => void,
        getState: () => ApplicationState
    ): void;
}