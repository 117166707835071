import * as React from "react";
import { connect } from "react-redux";
import { INewsState } from "./news-types";
import * as NewsStore from "./news-reducer";
import { ApplicationState } from "../../store";
import { TablePaginationConfig } from "antd/lib/table";
import moment from "moment";
import { DEFAULT_ZERO_VALUE, PAGE_SIZE_OPTIONS } from "../../assets/constants/general";
import LoadingOverlay from "react-loading-overlay";
import { Table } from "antd";
import { withUserLayout } from "../../common/user-layout";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { Link } from "react-router-dom";

type NewsProps = INewsState & typeof NewsStore.actions;

export class AdminNewsPage extends React.PureComponent<NewsProps> {
    componentDidMount() {
        const { loadNews } = this.props;

        loadNews(DEFAULT_ZERO_VALUE);
    }

    onChange = (updatedPagination: TablePaginationConfig) => {
        const { loadNews, setPagination, pagination } = this.props;

        setPagination({
            ...pagination,
            pageNumber: updatedPagination.current,
            pageSize: updatedPagination.pageSize,
        });
        loadNews(DEFAULT_ZERO_VALUE);
    };

    deleteArticle = (slug: string) => {
        const { deleteArticle } = this.props;
        deleteArticle(slug);
    }

    onShowSizeChange = (current: number, pageSize: number) => {
        const { loadNews, setPagination, pagination } = this.props;

        setPagination({ ...pagination, pageNumber: current, pageSize });
        loadNews(DEFAULT_ZERO_VALUE);
    };

    public render() {
        const { isLoading, news, pagination } = this.props;
        const columns = [
            {
                title: "Date",
                dataIndex: "createdAt",
                key: "createdAt",
                sorter: false,
                width: 150,
                render: (text: string) =>
                    `${moment(text).format("MMM Do YYYY")}`,
            },
            {
                title: "Title",
                dataIndex: "title",
                key: "title",
                sorter: false,
            },
            {
                title: "Preview",
                dataIndex: "slug",
                key: "slug",
                sorter: false,
                render: (text: string) => (
                    <Link to={PAGE_LINK.ARTICLE.replace(":slug", text)}>
                        Preview
                    </Link>
                ),
            },
            {
                title: "Short Content",
                dataIndex: "shortContent",
                key: "shortContent",
                width: 500,
                sorter: false,
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                sorter: false,
            },
            {
                title: "Actions",
                dataIndex: "slug",
                key: "actions",
                sorter: false,
                render: (slug: string) => (
                    <div>
                        <Link to={PAGE_LINK.EDIT_ARTICLE.replace(":slug", slug)}>
                            Edit
                        </Link>{" "}
                        | <a onClick={() => this.deleteArticle(slug)} style={{ color: "#e08719" }}>Delete</a>
                    </div>
                ),
            },
        ];

        return (
            <div>
                <LoadingOverlay
                    className="admin-news-grid d-flex flex-column justify-content-start align-items-center w-100 p-4"
                    active={isLoading}
                    spinner
                >
                    <div className="d-flex justify-content-between w-100 mb-3">
                        <Link
                            className="btn btn-outline-primary btn-lg my-2 align-right download-csv"
                            to={PAGE_LINK.ADD_ARTICLE}
                        >
                            ADD ARTICLE
                        </Link>
                    </div>
                    <Table
                        dataSource={news.map((x, idx) => ({
                            ...x,
                            key: `news_item_${idx}`,
                        }))}
                        columns={columns}
                        pagination={{
                            current: pagination.pageNumber,
                            pageSize: pagination.pageSize,
                            total: pagination.total,
                            onShowSizeChange: this.onShowSizeChange,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: PAGE_SIZE_OPTIONS,
                        }}
                        bordered={true}
                        onChange={this.onChange}
                        className="w-100"
                    />
                </LoadingOverlay>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.news,
    NewsStore.actions
)(withUserLayout(AdminNewsPage as any, PAGE_LINK.ADMIN_NEWS));