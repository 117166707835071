import { AnyAction, applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { history } from "../helpers/history-helper";
import { reducers } from "./";
import { createLogger } from 'redux-logger';
import { SigninActionTypes } from "../pages/auth/auth-constants";

const middleware = [thunk, routerMiddleware(history)];

// For console logs
// if (process.env.NODE_ENV === `development`) {
//     const loggerMiddleware = createLogger({
//         collapsed: (_getState, _action, logEntry) => !logEntry.error
//     });

//     middleware.push(loggerMiddleware);
// }

const appReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
});

const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === SigninActionTypes.setUserLoggedOut) {
        const { landing, search, auth, setPassword } = state;
        state = { landing, search, auth, setPassword };
    }

    return appReducer(state, action);
}

const enhancers = [] as any[];

// Use for redux devtools
// const windowIfDefined =
//     typeof window === "undefined" ? null : (window as any);
// if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
//     enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
// }

export type RootReducer = ReturnType<typeof rootReducer>

export const store = createStore(
    rootReducer,
    compose(applyMiddleware(...middleware), ...enhancers)
);