import * as React from 'react';
import { Table, Modal, Tabs, Input, Button, Tooltip } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as MappingStore from "./reducer";
import { ApplicationState } from '../../../store';
import { withUserLayout } from '../../../common/user-layout';
import { PAGE_LINK } from '../../../assets/constants/page-links';
import LoadingOverlay from 'react-loading-overlay';
import { IMapping, IMappingState } from './mapping-types';
import './mapping.scss';
import { columns } from './mapping-constants';
import { FileTextOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

type MappingProps = IMappingState
    & typeof MappingStore.mappingActions

export interface MappingState {
    File: File,
    UniqFileInputKey: string
}

class MappingPage extends React.PureComponent<MappingProps> {

    state: MappingState = {
        File: null,
        UniqFileInputKey: Math.floor(Math.random() * 100000).toString()
    }
    
    componentDidMount() {
        const { getMappings } = this.props;

        getMappings();
    }

    componentDidUpdate() {
        const {isModalOpen, msgs, isNotificationOpen, modalClose} = this.props

        const title = isModalOpen? 'Some mapping profiles are already exists!' :  
                                   `Headers that listed below are not recognized. Please add / modify headings and try again.`;

        if ((isModalOpen || isNotificationOpen) && msgs.length > 0) {
            modalClose();
            Modal.error({
                className: 'mapping-wrapper',
                title: title,
                content: (
                  <div className = "my-4 msg-container">
                      {msgs.map((msg, index) => {
                          return <p key={`mapping_error_${msg + index}`} className = "msg">{index + 1}. {msg}</p>
                      })}
                  </div>
                ),
                okButtonProps: {
                    className: 'btn-modal'
                },
            });
        }
    }

    onDelete = (mappingId: number) => {
        const { deleteMapping } = this.props;

        deleteMapping(mappingId);
    }

    onConfirm = (mapping: IMapping) => {
        Modal.confirm({
            title: 'Deleting',
            content: `Do you want to delete ${mapping.name}?`,
            okText: 'Delete',
            className: 'mapping-wrapper',
            cancelText: 'Cancel',
            okType: 'danger',
            onOk: () => {
                this.onDelete(mapping.id);
            },
            okButtonProps: {
               className: 'btn-modal'
            },
            cancelButtonProps: {
                className: 'btn-modal'
            }
          });    
    }
    
    onFilePick = (event: any) => {
        this.setState({
            File: event.target.files[0]
        });
    }

    uploadFile = () => {
        const { uploadExcelFile } = this.props;

        let data = new FormData();
        data.append('file', this.state.File);
    
        uploadExcelFile(data);

        this.setState({
            UniqFileInputKey: Math.floor(Math.random() * 100000).toString()
        })
    }
    
    render() {
        const { mappingsList, isLoading } = this.props;
        
        return (
            <LoadingOverlay active={isLoading} spinner>
                <div className = {"mapping-wrapper justify-content-start d-flex"}>
                    <div className="upload-form  col-12 col-sm-10 col-md-8 col-lg-6">
                        <h3>Upload mapping profile from excel file.</h3>
                        <div className = {"d-flex flex-row"}>
                            <Input  type="file"
                                    onChange = {this.onFilePick}
                                    className="upload-input px-3"
                                    accept=".xls, .xlsx"
                                    title = "Choose .xls or .xlsx file"
                                    key = {this.state.UniqFileInputKey}>
                            </Input>

                            <Button
                                    className="btn btn-primary btn-upload btn-sm my-0"
                                    disabled = {!this.state.File}
                                    onClick = {this.uploadFile}
                            >
                                     UPLOAD
                            </Button>

                            <Tooltip title="Download example">
                                <a
                                    href="/templates/empty-mapping-template.xlsx"
                                    download
                                    target = "_blank"
                                    className="w-auto"
                                >
                                    <FileTextOutlined className = {"download-template"} />
                                </a>
                            </Tooltip>
                        </div>
                        <p className="download-info my-2">*Accepted files are .xls and .xlsx</p>
                    </div> 
                </div>
                {mappingsList.length === 0  && !isLoading? <p>You don't have mapping profiles yet!</p> : 
                    <Tabs tabPosition='left'>
                        {
                            mappingsList.map(mapping => (
                                <TabPane key={`map_${mapping.name}`} tab={mapping.name}>
                                    <div className="container mapping-wrapper">
                                        <div className="d-flex justify-content-between mb-3">
                                            <button
                                                className="btn btn-outline-danger btn-delete btn-md my-2"
                                                onClick={() => { this.onConfirm(mapping) }}
                                            >
                                                DELETE
                                            </button>
                                        </div>
                                        <Table rowKey={`fileColumnName`} columns={columns} dataSource={mapping.mappingColumns} pagination={false} scroll={{ x: true }} />
                                    </div>
                                </TabPane>
                            ))
                        }
                    </Tabs>
                }
            </LoadingOverlay>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.mapping, MappingStore.mappingActions)
    (withUserLayout(MappingPage as any, PAGE_LINK.MANAGE_MAPPINGS));