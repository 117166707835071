import AuthStore, { IToken } from '../local-store/auth';
import { showErrorNotification } from './notification-helper';
import { history } from "./history-helper";
import { PAGE_LINK } from '../assets/constants/page-links';

export const refresh = () => {
    const user = JSON.parse(AuthStore.getUser())
    const refreshToken = AuthStore.getRefreshToken();
    return fetch("/api/auth/refresh", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user && user.email, refreshToken })
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                AuthStore.clear();
                history.push(PAGE_LINK.LOGIN);
            }
        })
        .then((response: IToken) => {
            AuthStore.setToken(response);
        })
        .catch((error) => {
            showErrorNotification({ is_error: true, error_content: error });
            AuthStore.clear();
        })
}