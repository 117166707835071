import { typedAction } from "../../helpers/action-helper";
import { FORM_NAMES, LandingActionTypes } from "./landing-constants";
import { ILandingState, ILandingActions, IPotentialClient } from "./landing-types";
import { Reducer } from "redux";
import { AppThunkAction } from "../../store";
import { showErrorNotification } from "../../helpers/notification-helper";
import LandingService from './landing-service';
import { notification } from "antd";

let landingService = new LandingService();

const initialState: ILandingState = {
    isLoaded: false,
    isPlaying: false,
    formLoading: false
};

type LandingAction = ReturnType<typeof typedAction>

export const load = (isLoaded: boolean) => typedAction(LandingActionTypes.load, isLoaded);
export const setFirstName = (value: string) => typedAction(LandingActionTypes.setFirstName, value);
export const setLastName = (value: string) => typedAction(LandingActionTypes.setLastName, value);
export const setEmail = (value: string) => typedAction(LandingActionTypes.setEmail, value);
export const setCompany = (value: string) => typedAction(LandingActionTypes.setCompany, value);
export const setPhone = (value: string) => typedAction(LandingActionTypes.setPhone, value);
export const setVideoPlaying = (value: boolean) => typedAction(LandingActionTypes.setVideoPlaying, value);
export const setFormLoading = (isLoading: boolean) => typedAction(LandingActionTypes.setFormLoading, isLoading);

export const downloadGuide = (): AppThunkAction<LandingAction> => (dispatch, getState) => {
    dispatch(load(false));
    const { firstName, lastName, email, phone, company } = getState().landing;

    landingService.downloadGuide({ firstName, lastName, email, phone, company, formName: FORM_NAMES.downlodGuide } as IPotentialClient)
        .then((response) => {
            if (response.is_error) {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(load(true)));
}

export const sendForm = (potentialClient: IPotentialClient): AppThunkAction<LandingAction> => (dispatch, getState) => {
    dispatch(setFormLoading(true));
    landingService.downloadGuide(potentialClient)
        .then(response => {
            if (response.is_error) {
                showErrorNotification(response);
            } else {
                notification.success({ message: "Form submitted successfully" });
            }
        })
        .finally(() => dispatch(setFormLoading(false)))
}

export const landingActions: ILandingActions = {
    load,
    setFirstName,
    setLastName,
    setEmail,
    setCompany,
    setPhone,
    setVideoPlaying,
    downloadGuide,
    setFormLoading,
    sendForm
}

export const landingReducer: Reducer<ILandingState, LandingAction> = (state = initialState, action: LandingAction): ILandingState => {
    switch (action.type) {
        case LandingActionTypes.setFormLoading:
            return {
                ...state,
                formLoading: action.payload as boolean
            };
        case LandingActionTypes.load:
            return {
                ...state,
                isLoaded: action.payload as boolean
            };
        case LandingActionTypes.setFirstName: {
            return {
                ...state,
                firstName: action.payload as string
            }
        }
        case LandingActionTypes.setLastName: {
            return {
                ...state,
                lastName: action.payload as string
            }
        }
        case LandingActionTypes.setEmail: {
            return {
                ...state,
                email: action.payload as string
            }
        }
        case LandingActionTypes.setCompany: {
            return {
                ...state,
                company: action.payload as string
            }
        }
        case LandingActionTypes.setPhone: {
            return {
                ...state,
                phone: action.payload as string
            }
        }
        case LandingActionTypes.setVideoPlaying: {
            return {
                ...state,
                isPlaying: action.payload as boolean
            }
        }
        default:
            return state;
    }
}