import * as React from "react";
import { connect } from "react-redux";
import { withLayout } from "../../common/layout";
import { ApplicationState } from "../../store";
import { searchActions } from "./search-reducer";
import { ISearchProps } from "./search-types";
import { withRouter } from "react-router";
import { deleteConfirmationRules } from "./search-helper";
import { Button } from "antd";

import './search.scss';

class SearchDeleteConfirmationPage extends React.Component<ISearchProps, {}> {
    public render() {
        const { match: { params: { token } }, confirmDelete, isLoading } = this.props;

        return (
            <div className="d-flex flex-column m-4">
                {deleteConfirmationRules()}
                <p className="font-weight-bold">Persuant to Civil Code section 1798.105 in the California Consumer Privacy Act (CCPA), you may only delete your own record, or the record of someone who has requested you do same. The date, time and your IP address is being captured and stored in our system, for the sole purpose of memorializing this transaction.</p> 
                <Button loading={isLoading} onClick={() => confirmDelete(token)} type="primary" className="text-uppercase confirm-delete">Confirm Delete</Button>
            </div>
        )
    }
}

const connectedSearchPage = connect((state: ApplicationState) => state.search, searchActions)(withLayout(withRouter(SearchDeleteConfirmationPage)));
export { connectedSearchPage as SearchDeleteConfirmationPage };