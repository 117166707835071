export const DEFAULT_ZERO_VALUE = 0;
export const FIRST_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_TRUE_VALUE = true;
export const STRING_EMPTY = '';
export const NONE = 'None';
export const PAGE_SIZE_OPTIONS = ['10', '20', '50', '100'];
export const FIVE_MINS = 300000;

// MAX IMAGE FILE SIZE IS 5 MB
export const ONE_KB_LENGTH = 1024;
export const MAX_IMAGE_FILE_SIZE = 5 * ONE_KB_LENGTH * ONE_KB_LENGTH;