import { typedAction } from "../../helpers/action-helper";
import { Reducer } from "redux";
import { AppThunkAction } from "../../store";
import { showErrorNotification } from "../../helpers/notification-helper";
import NewsService from "./news-service";
import { NewsActionTypes, NewsNotifications } from "./news-constants";
import { IArticleViewModel, IArticle, INewsActions, INewsState, ICategory, INewsLink } from "./news-types";
import { DEFAULT_ZERO_VALUE, FIRST_PAGE_NUMBER, STRING_EMPTY } from "../../assets/constants/general";
import { IPagination } from "../../assets/global/types";
import { notification } from "antd";
import { history } from "../../helpers/history-helper";
import { PAGE_LINK } from "../../assets/constants/page-links"
import ConfigManager from "../configs/config-manager"
import moment from "moment";
import { IAuthUser } from "../../local-store/auth";

let newsService = new NewsService();

const initialState: INewsState = {
    isLoading: false,
    news: [],
    ccpaNews: [],
    subNews: {
        recentNews: [],
        categoryNews: []
    },
    article: {
        id: DEFAULT_ZERO_VALUE,
        categoryId: DEFAULT_ZERO_VALUE,
        category: {} as ICategory,
        createdBy: {} as IAuthUser,
        slug: STRING_EMPTY,
        title: STRING_EMPTY,
        htmlContent: STRING_EMPTY,
        createdAt: moment.min(),
        linkToHtmlContent: STRING_EMPTY,
        linkToImage: STRING_EMPTY,
        shortContent: STRING_EMPTY,
        status: false
    },
    categories: [],
    pagination: {
        isAscend: false,
        pageNumber: FIRST_PAGE_NUMBER,
        pageSize: 7,
        searchCriteria: STRING_EMPTY,
        sortCriteria: "createdAt",
        total: DEFAULT_ZERO_VALUE
    }
};

type NewsAction = ReturnType<typeof typedAction>;

export const loadNews = (categoryId: number): AppThunkAction<NewsAction> => (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const { pagination } = getState().news;

    newsService.loadNews(categoryId, pagination)
        .then((response) => {
            if (response.is_error) {
                showErrorNotification(response);
            } else {
                dispatch(setNews(response.content?.data))
                dispatch(setPagination(response.content?.pagination))
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

export const getCCPANews = (): AppThunkAction<NewsAction> => (dispatch, getState) => {
    newsService.getCCPANews()
        .then(response => {
            if (!response.is_error) {
                dispatch(setCCPANews(response.content))
            }
        })
}

export const loadSubNews = (subNewsType: string, categoryId: number, pagination: IPagination): AppThunkAction<NewsAction> => (dispatch, getState) => {
    dispatch(setIsLoading(true));

    newsService.loadNews(categoryId, pagination)
        .then((response) => {
            if (response.is_error) {
                showErrorNotification(response);
            } else {
                dispatch(setSubNews(subNewsType, response.content.data));
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

export const loadCategories = (): AppThunkAction<NewsAction> => (dispatch) => {
    dispatch(setIsLoading(true));

    newsService.loadCategories()
        .then((response) => {
            if (response.is_error) {
                showErrorNotification(response);
            } else {
                dispatch(setCategories(response.content));
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

export const getArticle = (slug: string): AppThunkAction<NewsAction> => (dispatch) => {
    dispatch(setIsLoading(true));

    newsService.getArticle(slug)
        .then(response => {
            if (response.is_error) {
                history.push(PAGE_LINK.ERROR_404);

                showErrorNotification(response);
            } else {
                response.content.linkToImage =
                    `${ConfigManager.azureStorage()}/${ConfigManager.newsContainer()}/${ConfigManager.articlesDirectory()}/${ConfigManager.articleImagesDirectory()}/${response.content.linkToImage}`;
                if (response.content.linkToHtmlContent) {
                    newsService.loadHtmlContent(`${ConfigManager.azureStorage()}/${ConfigManager.newsContainer()}/${ConfigManager.articlesDirectory()}/${response.content.linkToHtmlContent}`)
                        .then(html => {
                            if (html) {
                                response.content.htmlContent = html as string;
                            } else {
                                notification.error({ message: NewsNotifications.htmlLoadError })
                            }

                            dispatch(setArticle(response.content));
                        })
                }
                else {
                    dispatch(setArticle(response.content))
                }
            }

        })
        .finally(() => dispatch(setIsLoading(false)));
}

export const subscribe = (subscribeModel: any): AppThunkAction<NewsAction> => (dispatch) => {
    dispatch(setIsLoading(true));

    newsService.subscribe(subscribeModel)
        .then((response) => {
            if (!response.is_error) {
                notification.success({ message: "You have successfully subscribed" });
            } else {
                notification.error({ message: "Subscrition failed" });
            }
        })
        .catch(() => {
            notification.error({ message: "Subscrition failed" });
        })
        .finally(() => {
            dispatch(setIsLoading(false));
        })
}

export const deleteArticle = (slug: string): AppThunkAction<NewsAction> => (dispatch, getState) => {
    dispatch(setIsLoading(true));

    const { news, pagination } = getState().news;
    const article = news.find((elem) => { return elem.slug === slug });

    newsService.deleteArticle(article)
        .then((response) => {
            if (response.content) {
                dispatch(setNews(news.filter((elem) => elem.id !== article.id)));

                pagination.total--;
                dispatch(setPagination(pagination));

                notification.success({ message: NewsNotifications.articleDeleted });
            }
        })
        .finally(() => { dispatch(setIsLoading(false)) });
}

export const createArticle = (article: IArticleViewModel): AppThunkAction<NewsAction> => (dispatch) => {
    dispatch(setIsLoading(true));
    newsService.createAticle(article)
        .then((response) => {
            if (response.ok) {
                notification.success({ message: NewsNotifications.articleCreated });
                history.push(PAGE_LINK.ADMIN_NEWS);
            } else {
                response.json()?.then(error => {
                    showErrorNotification({ is_error: true, error_content: { errors: {}, message: error.message } });
                })
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

export const editArticle = (article: IArticleViewModel): AppThunkAction<NewsAction> => (dispatch) => {
    dispatch(setIsLoading(true));
    newsService.editArticle(article)
        .then((response) => {
            if (response.ok) {
                notification.success({ message: NewsNotifications.articleEdited });
                history.push(PAGE_LINK.ADMIN_NEWS);
            } else {
                response.json()?.then(error => {
                    showErrorNotification({ is_error: true, error_content: { errors: {}, message: error.message } });
                })
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

export const setIsLoading = (isLoading: boolean) =>
    typedAction(NewsActionTypes.setIsLoading, isLoading);
export const setPagination = (pagination: IPagination) =>
    typedAction(NewsActionTypes.setPagination, pagination);
export const setNews = (news: IArticle[]) =>
    typedAction(NewsActionTypes.setNews, news);
export const setCCPANews = (news: INewsLink[]) =>
    typedAction(NewsActionTypes.setCCPANews, news)
export const setSubNews = (subNewsType: string, subNews: IArticle[]) =>
    typedAction(NewsActionTypes.setSubNews, { subNewsType, subNews });
export const setArticle = (article: IArticle) =>
    typedAction(NewsActionTypes.setArticle, article);
export const setCategories = (categories: ICategory[]) =>
    typedAction(NewsActionTypes.setCategories, categories);

export const actions: INewsActions = {
    setIsLoading,
    setPagination,
    loadSubNews,
    setSubNews,
    loadNews,
    getCCPANews,
    setNews,
    getArticle,
    setArticle,
    deleteArticle,
    createArticle,
    editArticle,
    loadCategories,
    setCategories,
    setCCPANews,
    subscribe
};

export const reducer: Reducer<INewsState, NewsAction> = (
    state = initialState,
    action: NewsAction
): INewsState => {
    switch (action.type) {
        case NewsActionTypes.setIsLoading:
            return {
                ...state,
                isLoading: action.payload as boolean,
            };
        case NewsActionTypes.setNews:
            return {
                ...state,
                news: action.payload as IArticle[],
            };
        case NewsActionTypes.setCCPANews:
            return {
                ...state,
                ccpaNews: action.payload as INewsLink[]
            }
        case NewsActionTypes.setSubNews:
            {
                const { subNewsType, subNews } = action.payload as any;
                const newSubNews = { ...state.subNews, [subNewsType]: subNews };

                return {
                    ...state,
                    subNews: newSubNews
                };
            }
        case NewsActionTypes.setArticle:
            return {
                ...state,
                article: action.payload as IArticle,
            };
        case NewsActionTypes.setPagination:
            return {
                ...state,
                pagination: action.payload as IPagination,
            };
        case NewsActionTypes.setCategories:
            return {
                ...state,
                categories: action.payload as ICategory[],
            };
        default:
            return state;
    }
};