import { MAX_IMAGE_FILE_SIZE, ONE_KB_LENGTH } from "../../assets/constants/general";
import { INewsActionTypes } from "./news-types";

export const NewsActionTypes: INewsActionTypes = {
    setIsLoading: "SET_IS_LOADING",
    setNews: "SET_NEWS",
    setSubNews: "SET_RECENT_NEWS",
    setArticle: "SET_ARTICLE",
    setPagination: "SET_PAGINATION",
    setCategories: "SET_CATEGORIES",
    setCCPANews: "SET_CCPA_NEWS",
    subscribe: "SUBSCRIBE"
};

export const SUB_NEWS_TYPES = {
    RECENT_NEWS: 'recentNews',
    CATEGORY_NEWS: 'categoryNews'
}

export const SUB_NEWS_TITLES = {
    RECENT_NEWS: "Recent news"
}

export const MAX_SHORT_CONTENT_LENGTH = 500;
export const DEFAULT_SUB_NEWS_COUNT = 5;

export const ValidationMessages = {
    title: "Title is required",
    shortContent: "Short content is required",
    maxSymbolsReached: "Max amount of symbols used!",
    image: "Image is required",
    category: "Category is required"
}

export const EDITOR_CONTENT_TYPES = {
    COMPLETE: "complete",
    FRAGMENTED: "fragmented"
}

export const NewsNotifications = {
    articleCreated: "Article has been successfully created!",
    articleEdited: "Article has been successfully updated!",
    articleDeleted: "Article has been successfully deleted!",
    htmlLoadError: "Could not load the article's content!",
    nothingWasChanged: "There is nothing to update!",
    imageFileSizeTooLarge: `Max image file size is ${MAX_IMAGE_FILE_SIZE / (ONE_KB_LENGTH * ONE_KB_LENGTH)} MB!`
}

export const editorModules = [
    "advlist lists link image charmap print preview anchor",
    "searchreplace code fullscreen",
    "media table paste code help",
];

export const editorFormats = "undo redo | formatselect | bold italic underline link | alignleft aligncenter alignright | bullist numlist outdent indent | removeformat";