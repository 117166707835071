import { typedAction } from "../../helpers/action-helper";
import { Reducer } from "redux";
import { AppThunkAction } from "../../store";
import { showErrorNotification } from "../../helpers/notification-helper";
import AlertsService from './alerts-service';
import { AlertContact, AlertContactModel, IAlertContact, IAlertItem, IAlertsActions, IAlertsState } from "./alerts-types";
import { AlertsActionTypes, NOTIFY_TYPE } from "./alerts-constants";
import { notification } from "antd";
import { DEFAULT_PAGE_SIZE, DEFAULT_ZERO_VALUE, FIRST_PAGE_NUMBER, FIVE_MINS, STRING_EMPTY } from "../../assets/constants/general";
import { IPagination } from "../../assets/global/types";

let alertsService = new AlertsService();

const initialState: IAlertsState = {
    isLoading: false,
    alertContacts: [],
    isCodeSent: false,
    sentAlertContactId: DEFAULT_ZERO_VALUE,
    verificationCode: STRING_EMPTY,
    alerts: [],
    alertsPagination: {
        pageNumber: FIRST_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE,
        searchCriteria: null,
        sortCriteria: "UploadDate",
        isAscend: false,
        total: DEFAULT_ZERO_VALUE
    },
    alertsIsLoading: false,
    targetTime: DEFAULT_ZERO_VALUE,
    currentNotifyType: NOTIFY_TYPE.EMAIL,
    currentValue: STRING_EMPTY
};

type AlertsAction = ReturnType<typeof typedAction>

export const setIsLoading = (isLoading: boolean) => typedAction(AlertsActionTypes.setIsLoading, isLoading);
export const setAlertContacts = (alertContacts: IAlertContact[]) => typedAction(AlertsActionTypes.setAlertContacts, alertContacts);
export const setIsCodeSent = (isCodeSent: boolean) => typedAction(AlertsActionTypes.setIsCodeSent, isCodeSent);
export const setSentAlertContact = (sentAlertContact: AlertContact) => typedAction(AlertsActionTypes.setSentAlertContact, sentAlertContact);
export const setSentAlertContactId = (sentAlertContactId: number) => typedAction(AlertsActionTypes.setSentAlertContactId, sentAlertContactId);
export const setVerificationCode = (code: string) => typedAction(AlertsActionTypes.setVerificationCode, code);
export const setAlerts = (alerts: IAlertItem[]) => typedAction(AlertsActionTypes.setAlerts, alerts);
export const setAlertsPagination = (pagination: IPagination) => typedAction(AlertsActionTypes.setAlertsPagination, pagination);
export const setAlertsIsLoading = (isLoading: boolean) => typedAction(AlertsActionTypes.setAlertsIsLoading, isLoading);
export const setTargetTime = (targetTime: number) => typedAction(AlertsActionTypes.setTargetTime, targetTime);
export const setCurrentNotifyType = (type: NOTIFY_TYPE) => typedAction(AlertsActionTypes.setCurrentNotifyType, type);
export const setCurrentValue = (value: string) => typedAction(AlertsActionTypes.setCurrentValue, value);

export const getAlertContacts = (): AppThunkAction<AlertsAction> => (dispatch) => {
    dispatch(setIsLoading(true));

    alertsService.getAlertContacts()
        .then((response) => {
            if (!response.is_error) {
                const data = (response.content || []).map(x => new AlertContact(x));
                dispatch(setAlertContacts(data));
            }
            else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

export const getAlerts = (): AppThunkAction<AlertsAction> => (dispatch, getState) => {
    dispatch(setAlertsIsLoading(true));
    const { alertsPagination } = getState().alert;

    alertsService.getAlerts(alertsPagination)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setAlerts(response.content?.alerts));
                dispatch(setAlertsPagination(response.content?.pagination));
            }
            else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setAlertsIsLoading(false)));
}

export const addAlertContact = (model: AlertContactModel): AppThunkAction<AlertsAction> => async (dispatch) => {
    dispatch(setIsLoading(true));

    await alertsService.addAlertContact(model)
        .then((response) => {
            if (!response.is_error) {
                const targetTime = new Date().getTime() + FIVE_MINS;

                dispatch(setSentAlertContactId(response.content || DEFAULT_ZERO_VALUE));
                dispatch(setIsCodeSent(true));
                dispatch(setTargetTime(targetTime));
            }
            else {
                notification.error({ message: response.error_content });
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

export const confirmAlertContact = (alertContactId: number): AppThunkAction<AlertsAction> => async (dispatch) => {
    dispatch(setIsLoading(true));

    await alertsService.confirmAlertContact(alertContactId)
        .then((response) => {
            if (!response.is_error) {
                const targetTime = new Date().getTime() + FIVE_MINS;
                const alertContact = new AlertContact(response.content);

                dispatch(setSentAlertContactId(alertContact.id));
                dispatch(setSentAlertContact(alertContact));
                dispatch(setIsCodeSent(true));
                dispatch(setTargetTime(targetTime));
                dispatch(setCurrentValue(alertContact.value));
                dispatch(setCurrentNotifyType(alertContact.type as NOTIFY_TYPE));
            }
            else {
                notification.error({ message: response.error_content });
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

export const deleteAlertContact = (alertContactId: number): AppThunkAction<AlertsAction> => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const { sentAlertContactId } = getState().alert;

    if (sentAlertContactId === alertContactId) {
        dispatch(setSentAlertContactId(DEFAULT_ZERO_VALUE));
        dispatch(setSentAlertContact(null));
        dispatch(setIsCodeSent(false));
        dispatch(setTargetTime(DEFAULT_ZERO_VALUE));
        dispatch(setCurrentValue(STRING_EMPTY));
        dispatch(setCurrentNotifyType(NOTIFY_TYPE.EMAIL));
    }

    await alertsService.deleteAlertContact(alertContactId)
        .then((response) => {
            if (!response.is_error) {
                notification.success({ message: response.content });
            }
            else {
                notification.error({ message: response.error_content });
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

export const sendVerificationCode = (): AppThunkAction<AlertsAction> => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const { sentAlertContactId, verificationCode } = getState().alert;

    await alertsService.sendVerificationCode(sentAlertContactId, verificationCode)
        .then((response) => {
            if (!response.is_error) {
                notification.success({ message: response.content });
                dispatch(setSentAlertContactId(DEFAULT_ZERO_VALUE));
                dispatch(setSentAlertContact(null));
                dispatch(setIsCodeSent(false));
                dispatch(setCurrentValue(STRING_EMPTY));
                dispatch(setCurrentNotifyType(NOTIFY_TYPE.EMAIL));
            }
            else {
                notification.error({ message: response.error_content });
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

export const actions: IAlertsActions = {
    setIsLoading,
    setAlertContacts,
    getAlertContacts,
    addAlertContact,
    confirmAlertContact,
    deleteAlertContact,
    setIsCodeSent,
    sendVerificationCode,
    setSentAlertContactId,
    setSentAlertContact,
    setVerificationCode,
    setAlerts,
    getAlerts,
    setAlertsPagination,
    setAlertsIsLoading,
    setTargetTime,
    setCurrentNotifyType,
    setCurrentValue
}

export const reducer: Reducer<IAlertsState, AlertsAction> = (state = initialState, action: AlertsAction): IAlertsState => {
    switch (action.type) {
        case AlertsActionTypes.setIsLoading:
            return {
                ...state,
                isLoading: action.payload as boolean
            };
        case AlertsActionTypes.setAlertsIsLoading:
            return {
                ...state,
                alertsIsLoading: action.payload as boolean
            };
        case AlertsActionTypes.setAlertContacts: {
            return {
                ...state,
                alertContacts: action.payload as IAlertContact[]
            }
        }
        case AlertsActionTypes.setIsCodeSent: {
            return {
                ...state,
                isCodeSent: action.payload as boolean
            }
        }
        case AlertsActionTypes.setSentAlertContactId: {
            return {
                ...state,
                sentAlertContactId: action.payload as number
            }
        }
        case AlertsActionTypes.setVerificationCode: {
            return {
                ...state,
                verificationCode: action.payload as string
            }
        }
        case AlertsActionTypes.setSentAlertContact: {
            return {
                ...state,
                sentAlertContact: action.payload as AlertContact
            }
        }
        case AlertsActionTypes.setAlerts: {
            return {
                ...state,
                alerts: action.payload as IAlertItem[]
            }
        }
        case AlertsActionTypes.setAlertsPagination: {
            return {
                ...state,
                alertsPagination: action.payload as IPagination
            }
        }
        case AlertsActionTypes.setTargetTime: {
            return {
                ...state,
                targetTime: action.payload as number
            }
        }
        case AlertsActionTypes.setCurrentNotifyType:
            return {
                ...state,
                currentNotifyType: action.payload as NOTIFY_TYPE
            };
        case AlertsActionTypes.setCurrentValue:
            return {
                ...state,
                currentValue: action.payload as string
            };
        default:
            return state;
    }
}