import { IPagination } from '../../assets/global/types';
import RestUtilities from '../../helpers/rest-utilities';
import { AlertContactModel, IAlertContact, IAlertModel } from './alerts-types';

class AlertsService {
    getAlerts = (pagination: IPagination) => {
        return RestUtilities.post<IAlertModel>(`/api/alert/get-alerts`, pagination);
    }

    getAlertContacts = () => {
        return RestUtilities.get<IAlertContact[]>(`/api/alert/get-alert-contacts`);
    }

    addAlertContact = (model: AlertContactModel) => {
        return RestUtilities.post<number>('/api/alert/add-alert-contact', model);
    }

    confirmAlertContact = (alertContactId: number) => {
        return RestUtilities.get<IAlertContact>(`/api/alert/confirm-alert-contact/${alertContactId}`);
    }

    deleteAlertContact = (alertContactId: number) => {
        return RestUtilities.get<string>(`/api/alert/delete-alert-contact/${alertContactId}`);
    }

    sendVerificationCode = (alertContactId: number, code: string) => {
        return RestUtilities.get(`/api/alert/check-verification/${alertContactId}/${code}`);
    }
}

export default AlertsService;