import * as React from 'react';
import { connect } from 'react-redux';
import { PAGE_LINK } from '../../../assets/constants/page-links';
import { withUserLayout } from '../../../common/user-layout';
import { ApplicationState } from '../../../store';
import { IEditClientState, IEditedUser } from './edit-client-types';
import * as EditClientStore from "./reducer";
import { RouteComponentProps } from 'react-router';
import { Button, Checkbox, Form, Input, Radio, Select } from "antd";
import { STATE_OPTIONS } from '../../../assets/constants/states';
import { ADMIN_ROLE, EDITOR_ROLE, LIST_OWNER_ROLE, SUPER_ADMIN_ROLE } from '../../../assets/constants/roles';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FormInstance } from 'antd/lib/form';
import { phoneRegexp } from "../../../helpers/regexp";
import { MaskedInput } from 'antd-mask-input';
import LoadingOverlay from 'react-loading-overlay';
import AuthStore, { IAuthUser } from '../../../local-store/auth';
import * as EditProfileStore from "../../edit-profile/reducer"
import ConfigManager from "../../configs/config-manager";
import "../add-agreement/add-agreement.scss"
const jwt = require('jsonwebtoken');

const { Item } = Form;
const { Option } = Select;



type EditClientProps = RouteComponentProps<any>
    & IEditClientState
    & typeof EditClientStore.editClientActions
    & typeof EditProfileStore.editProfileActions

class EditClient extends React.PureComponent<EditClientProps> {
    state = {
        selectedRole: null as string
    }

    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        this.props.getClient(this.props.match.params.userId);
    }

    componentDidUpdate(prevProps: EditClientProps) {
        if (this.props.editedUser !== prevProps.editedUser) {
            this.formRef.current.setFieldsValue(this.props.editedUser);
            this.setState({ selectedRole: this.props.editedUser.role });

            const user = jwt.decode(AuthStore.getToken());
            if (this.props.editedUser.id == user.nameid) {
                this.props.getProfile();
            }
        }
    }

    onFinish = (values: IEditedUser) => {
        let editedUser = values;
        editedUser.id = this.props.match.params.userId;
        if (!editedUser.role) {
            editedUser.role = this.props.editedUser.role;
        }
        this.props.editClient(editedUser);
    }
    render() {
        const { errors, isFailed, isLoading, editedUser } = this.props;
        const mainCompany = ConfigManager.mainCompany();
        const currentUserCompany = ((JSON.parse(AuthStore.getUser())) as IAuthUser).company;
        const token = AuthStore.getToken();
        const user = jwt.decode(token);

        const stateOptions = STATE_OPTIONS.map((x, idx) => (
            <Option key={`${x.shortName}${idx}`} value={x.shortName}>{x.shortName} ({x.name})</Option>
        ));
        const validateMessages = {
            required: "Please input your ${name}!",
            types: {
                email: '${name} is not validate email!',
            },
            pattern: {
                mismatch: "${name} does not match pattern",
            }
        };

        return (
            <LoadingOverlay active={isLoading} spinner>
                <div className="container">
                    <Form name="editClient"
                        onFinish={this.onFinish}
                        layout={"vertical"}
                        className='d-flex flex-column flex-wrap align-items-center'
                        validateMessages={validateMessages}
                        ref={this.formRef}>
                        <h2>Edit List Owner</h2>
                        <div className="d-flex w-100 justify-content-between double-inputs">
                            <Item name={'firstName'} label="First Name" rules={[{ required: true }]}>
                                <Input />
                            </Item>
                            <Item name={'lastName'} label="Last Name" rules={[{ required: true }]}>
                                <Input />
                            </Item>
                        </div>
                        <Item name={'title'} label="Title">
                            <Input />
                        </Item>
                        <Item name={'email'} label="Email address" rules={[{ type: 'email', required: true }]}>
                            <Input disabled />
                        </Item>
                        <Item name={'phone'} label="Cell Phone (for user authentication). Format (555-555-5555)" rules={[{ required: true, pattern: phoneRegexp }]}>
                            <MaskedInput mask='111-111-1111' />
                        </Item>
                        < Item name={'company'} label="Company" rules={[{ required: true }]}>
                            <Input disabled />
                        </ Item>
                        <Item name={'address1'} label="Address 1" rules={[{ required: true }]}>
                            <Input />
                        </Item>
                        <Item name={'address2'} label="Address 2">
                            <Input />
                        </Item>
                        <Item name={'city'} label="City" rules={[{ required: true }]}>
                            <Input />
                        </Item>
                        <div className="d-flex w-100 justify-content-between double-inputs">
                            <Item label="State" name={'state'} rules={[{ required: true }]}>
                                <Select >
                                    {stateOptions}
                                </Select>
                            </Item>
                            <Item name={'zip'} label="ZIP" rules={[{ required: true }]}>
                                <MaskedInput mask="11111" placeholder="" />
                            </Item>
                        </div>
                        {
                            editedUser.id && (user.nameid != editedUser.id) && (
                                <div className="row border-bottom border-top pt-4 p-0">
                                    <div className="col-12 m-0 pl-0">
                                        <h5>Role</h5>
                                    </div>
                                    <Item className="col-6 pl-0" name={'role'} rules={[{ required: true }]}>
                                        <Select disabled={editedUser.isCompanyOwner} onChange={(value) => { this.setState({ selectedRole: value }) }}>
                                            <Option value={LIST_OWNER_ROLE}>User</Option>
                                            <Option value={ADMIN_ROLE}>Admin</Option>
                                            {mainCompany == user.company && <Option value={EDITOR_ROLE}>Editor</Option>}
                                        </Select>
                                    </Item>
                                    {
                                        user && user.role == SUPER_ADMIN_ROLE && this.state.selectedRole == ADMIN_ROLE && (
                                            <Item className="col-6 pt-2" valuePropName="checked" name="isCompanyOwner">
                                                <Checkbox disabled={editedUser.isCompanyOwner}>Company Owner</Checkbox>
                                            </Item>
                                        )
                                    }
                                </div>)
                        }

                        <Item>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Link className="btn btn-outline-primary btn-lg button-round" to={PAGE_LINK.LIST_OF_CLIENTS}>Cancel</Link>
                                </div>
                                <div>
                                    <Button htmlType="submit" loading={isLoading} className="btn btn-primary button-round">Save</Button>
                                </div>
                            </div>
                        </Item>
                    </Form>
                </div >
            </LoadingOverlay>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.editClient,
    { ...EditClientStore.editClientActions, ...EditProfileStore.editProfileActions }
)(withUserLayout(EditClient as any, PAGE_LINK.EDIT_USER));