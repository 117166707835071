import RestUtilities, { IAuthResponse, IRegisterResponse, IVerifyResponse } from './rest-utilities';
import AuthStore, { IAuthUser, IToken } from '../local-store/auth';
import { IRegisterUser, IVerifyModel, IResetPasswordModel, IForgotPasswordModel, IChangePasswordModel } from '../pages/auth/auth-types';

class Auth {
    static isSignedIn(): boolean {
        return !!AuthStore.getToken();
    }

    signIn(email: string, password: string) {
        return RestUtilities.post<IAuthResponse>(`/api/auth/login`, { email, password })
            .then((response) => {
                if (!response.is_error) {
                    AuthStore.setToken(response && response.content && response.content.token || {} as IToken);
                    AuthStore.setUser(response && response.content && response.content.user || {} as IAuthUser)
                }
                return response;
            });
    }

    register(userProps: IRegisterUser) {
        return RestUtilities.post<IRegisterResponse>(`/api/auth/register`, userProps);
    }

    confirm(token: string): Promise<boolean> {
        return RestUtilities.post('/api/auth/confirm', { token: token })
            .then((response) => {
                return true;
            }).catch((err) => {
                return false;
            });
    }

    resendEmail(registerEmail: string) {
        return RestUtilities.post<IRegisterResponse>(`/api/auth/resend-email`, { registerEmail });
    }

    sendVerifyCode(verifyModel: IVerifyModel) {
        return RestUtilities.post<IVerifyResponse>(`/api/verify`, verifyModel);
    }

    resetPassword = (value: IResetPasswordModel) => {
        return RestUtilities.post(`/api/auth/reset-password`, value);
    }

    forgotPassword = (value: IForgotPasswordModel) => {
        return RestUtilities.post(`/api/auth/forgot-password`, value);
    }

    changePassword = (value: IChangePasswordModel) => {
        return RestUtilities.post(`/api/auth/change-password`, value);
    }

    confirmAccount = (userId: string, token: string) => {
        return RestUtilities.post(`api/auth/confirm-user`, { userId, token });
    }

    signOut(): void {
        AuthStore.clear();
    }
}

export default Auth;