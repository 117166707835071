export interface IConfig {
    blobUrl: string;
    articlesDirectory: string;
    articleImagesDirectory: string;
    newsContainer: string;
    mainCompany: string;
    avatarsDirectory: string;
    profilesContainer: string;
}

// String values of the {IConfig} properties names
export const CONFIG_PROPERTIES = {
    BLOB_URL: "blobUrl",
    ARTICLES_DIRECTORY: "articlesDirectory",
    ARTICLE_IMAGES_DIRECTORY: "articleImagesDirectory",
    NEWS_CONTAINER: "newsContainer",
    MAIN_COMPANY: "mainCompany",
    PROFILES_CONTAINER: "profilesContainer",
    AVATARS_DIRECTORY: "avatarsDirectory",
    // default property name used to verify configs settlement
    CONGIGS_SET: "configs_set"
}