import RestUtilities from "../../../helpers/rest-utilities";
import { IEditedUser } from "./edit-client-types";

export default class EditClientService {
    editClient(editedClient: IEditedUser) {
        return RestUtilities.post<IEditedUser>(`/api/admin/edit-user`, editedClient);
    }

    getClient(clientId: number) {
        return RestUtilities.get<IEditedUser>(`/api/admin/get-user-info/${clientId}`);
    }
}