import * as React from "react";
import { Layout, Button, Row, Col, Divider } from 'antd';
import newLogo from '../assets/images/new-logo.svg';
import bigLogo from '../assets/images/big-logo.svg';
import { PhoneDial } from "../assets/global/phone-dial";
import { PAGE_LINK } from "../assets/constants/page-links";
import { CONTACT_INFO } from "../assets/constants/contact_info";
import { Link } from "react-router-dom";
import linkedin from "../assets/images/linkedin.jpg";
import CookieConsent from "react-cookie-consent";
import AuthStore, { IAuthUser } from '../local-store/auth';
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { setUserLoggedOut } from "../pages/auth/reducer";
import { EDITOR_ROLE } from "../assets/constants/roles";

const { Header, Footer, Content } = Layout;

type IBaseLayoutProps = {
    setUserLoggedOutAction?: () => void;
}

const isPrerender = navigator.userAgent === "ReactSnap";

export const withLayout = <P extends object>(Component: React.ComponentType<P>) => {
    class BaseLayout extends React.Component<P & IBaseLayoutProps, {}> {
        constructor(props: P & IBaseLayoutProps) {
            super(props);
        }

        render() {
            const user = AuthStore.getUser();
            return (
                <Layout>
                    {
                        isPrerender ? null :
                            !user && (
                                <CookieConsent location="bottom"
                                    buttonText="ACCEPT"
                                    cookieName="MDPCookie"
                                    hideOnAccept={true}
                                    style={{ background: "#f7f8f9", color: "#2F2F2F", fontSize: "15px", fontWeight: 'bold', minHeight: '70px', alignItems: 'center', borderTop: 'solid 3px #2966f4' }}
                                    buttonStyle={{ background: '#2966f4', color: "#fff", fontSize: "14px", fontWeight: 'bold', width: '130px', height: '38px', borderRadius: '50px' }}>
                                    <div style={{ margin: '0 50px' }}>We use cookies on this site to enhance your user experience.</div>
                                </CookieConsent>
                            )
                    }
                    <Header className='d-flex main-header'>
                        <Link to={PAGE_LINK.LANDING}>
                            <Button type='link' className='link-bottom-button d-flex align-items-center h-100'>
                                <img src={newLogo} alt="Logo" className='logo' />
                                <span className="text-uppercase ml-2 logo-title">My Data Privacy</span>
                            </Button>
                        </Link>
                        <div className="d-flex align-items-center justify-content-between">
                            <PhoneDial className="header-number" phoneNumber={CONTACT_INFO.PHONE} withIcon={true} />
                            {
                                user ? (
                                    <Link to={(JSON.parse(user).role === EDITOR_ROLE) ? PAGE_LINK.ADMIN_NEWS : PAGE_LINK.UPLOAD_FILE}><Button className='login-button rounded-button ml-3'>Dashboard</Button></Link>
                                ) : (
                                    <Link to={PAGE_LINK.LOGIN}><Button className='login-button rounded-button ml-3'>Login</Button></Link>
                                )
                            }
                        </div>
                    </Header>
                    <Content>
                        <Component {...this.props as P} />
                        <Footer className='d-flex main-footer justify-content-center align-items-center'>
                            <div className="d-flex page-links w-100">
                                <div className="container mt-5">
                                    <Row justify="center" className='w-100'>
                                        <Col xs={24} sm={24} md={24} lg={7} className="d-flex justify-content-center align-items-center">
                                            <Link to='/' className='link-bottom-button d-flex flex-column align-items-center h-100'>
                                                <img src={bigLogo} alt="Logo" />
                                                <span className="text-uppercase mt-2 logo-title mb-0">My Data Privacy</span>
                                            </Link>
                                        </Col>
                                        <Col xs={24} sm={10} md={10} lg={8} className="mb-3">
                                            <span className='link-bottom-button mb-1'>{CONTACT_INFO.ADDRESS}</span>
                                            <span className='link-bottom-button mb-1'>{CONTACT_INFO.SUITE}</span>
                                            <span className='link-bottom-button mb-1'>{CONTACT_INFO.CITY}</span>
                                            <a className='link-bottom-button mb-1' href={`mailto:${CONTACT_INFO.EMAIL}`}>{CONTACT_INFO.EMAIL}</a>
                                            <a className='link-bottom-button' href={CONTACT_INFO.LINKEDIN}><img src={linkedin} alt="linkedin" /></a>
                                        </Col>
                                        <Col xs={24} sm={8} md={9} lg={6} className="mb-3">
                                            <Link to={PAGE_LINK.LOGIN} className='link-bottom-button mb-1'>Login</Link>
                                            <Link to={PAGE_LINK.LIST_OWNERS_AND_MAILERS} className='link-bottom-button mb-1'>List Owners & Mailers</Link>
                                            <Link to={PAGE_LINK.NEWS} className='link-bottom-button mb-1' >Latest News</Link>
                                            <Link to={PAGE_LINK.FAQ} className='link-bottom-button mb-1' >FAQ</Link>
                                        </Col>
                                        <Col xs={24} sm={6} md={5} lg={3} className="mb-3">
                                            <Link to={PAGE_LINK.TERMS_OF_USE} className='link-bottom-button mb-1'>Terms of Use</Link>
                                            <Link to={PAGE_LINK.PRIVACY_POLICY} className='link-bottom-button mb-1'>Privacy Policy</Link>
                                            <Link to={PAGE_LINK.SITE_MAP} className='link-bottom-button mb-1'>Site Map</Link>
                                            <Link to={PAGE_LINK.CONTACT_US} className='link-bottom-button mb-1'>Contact Us</Link>
                                        </Col>
                                        <Col xs={24} className="mt-md-5 pt-3" >
                                            <p className="copyright">
                                                © {new Date().getFullYear()} My Data Privacy. All Rights Reserved.
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Footer>
                    </Content>
                </Layout >
            );
        }
    };

    const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
        setUserLoggedOutAction: () => dispatch(setUserLoggedOut())
    });

    return connect(null, mapDispatchToProps)(BaseLayout as any);
}