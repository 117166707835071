import RestUtilities from '../../helpers/rest-utilities';
import { IAddress, IDeletedUser, IOptOutInfo, ISearchHeadersResponse, ISearchUser } from './search-types';

class SearchService {
    searchByEmail = (email: string) => {
        return RestUtilities.post<number>(`/api/search/get-results-count-by-email`, { email });
    }

    searchByAddress = (info: ISearchUser) => {
        return RestUtilities.post<string>(`/api/search/search-by-address`, info);
    }

    optOutEmail = (searchEmail: string) => {
        return RestUtilities.post<boolean>(`/api/search/send-opt-out-email`, { email: searchEmail });
    }

    sendDeleteEmail = (optOutInfo: IOptOutInfo, deleteEmail: string) => {
        return RestUtilities.post<boolean>(`/api/search/send-delete-email`, { optOutInfo, email: deleteEmail });
    }

    loadSearchResults = (guid: string) => { 
        return RestUtilities.post<ISearchHeadersResponse>(`/api/search/load-search-results`, { guid })
    }

    loadSearchHistory = (guid: string) => { 
        return RestUtilities.post<ISearchUser>(`/api/search/load-search-history`, { guid })
    }

    loadAddressResults = (searchText: string) => {
        return RestUtilities.get<IAddress[]>(`/api/search/get-address-details?searchText=${searchText}`);
    }

    optOutInfo = (info: IOptOutInfo) => {
        return RestUtilities.post<boolean>(`/api/search/opt-out-info`, info);
    }

    confirmDelete = (guid: string) => {
        return RestUtilities.post<IDeletedUser>(`/api/search/confirm-delete`, { guid })
    }

    getCaptchaKey = () => {
        return RestUtilities.get<string>(`/api/config/get-captcha-key`);
    }
}

export default SearchService;