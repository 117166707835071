import { Reducer } from "redux";
import { AppThunkAction } from '../../../store';
import AddClientService from "./add-client-service"
import { typedAction } from "../../../helpers/action-helper";
import { AddClientActionTypes } from "./add-client-constants";
import { IAddClientActions, IAddClientState, ICompany } from "./add-client-types";
import { showErrorNotificationCallback } from "../../../helpers/notification-helper";
import { IRegisterUser } from "../../auth/auth-types";
import { notification } from "antd";
import { history } from "../../../helpers/history-helper";
import { PAGE_LINK } from "../../../assets/constants/page-links";
let addClientService = new AddClientService();

const start = () => typedAction(AddClientActionTypes.started);
const success = () => typedAction(AddClientActionTypes.success);
const fail = (errors: { [key: string]: string }) => typedAction(AddClientActionTypes.failed, errors);
const setListCompanies = (companies: ICompany[]) => typedAction(AddClientActionTypes.setListCompanies, companies);

const addClient = (client: IRegisterUser): AppThunkAction<AddClientAction> => (dispatch) => {
    dispatch(start());

    addClientService.add(client)
        .then((response) => {
            if (!response.is_error) {
                dispatch(success());
                notification.success({
                    message: "The user was added successfully!"
                })
                history.push(PAGE_LINK.LIST_OF_CLIENTS);
            } else {
                showErrorNotificationCallback(response, 'Smth went wrong when trying to add a new client', (errors: { [key: string]: string }) => dispatch(fail(errors)), true);
            }
        })
}

const getListCompanies = (): AppThunkAction<AddClientAction> => (dispatch) => {
    addClientService.getListCompanies()
        .then((response) => {
            if (!response.is_error) {
                dispatch(setListCompanies(response.content))
            } else {
                showErrorNotificationCallback(response, 'Smth went wrong when trying to add a new client', (errors: { [key: string]: string }) => dispatch(fail(errors)), true);
            }
        })
}

type AddClientAction = ReturnType<typeof start | typeof success | typeof fail | typeof setListCompanies>

export const addClientActions: IAddClientActions = {
    start,
    success,
    fail,
    addClient,
    getListCompanies,
    setListCompanies
}

const unloadedState: IAddClientState = { errors: {}, isFailed: false, isLoading: false, isSuccess: false, listCompanies: [] };

export const reducer: Reducer<IAddClientState, AddClientAction> = (state: IAddClientState = unloadedState, action: AddClientAction): IAddClientState => {
    switch (action.type) {
        case AddClientActionTypes.started:
            return {
                ...state,
                isLoading: true,
                isFailed: false,
                isSuccess: false,
                errors: {}
            };
        case AddClientActionTypes.success:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };
        case AddClientActionTypes.failed:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
                errors: action.payload as { [key: string]: string }
            };
        case AddClientActionTypes.setListCompanies:
            return {
                ...state,
                listCompanies: action.payload as ICompany[]
            }
        default: return state;
    }
}