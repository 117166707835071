import RestUtilities from "../../../helpers/rest-utilities";
import { IDataForUpload, IOrderFieldsModel, IOrderModel, ISelectValue, IUploadHistoryModel } from "./upload-types";
import AuthStore from "../../../local-store/auth";
import { IPagination } from "../../../assets/global/types";

export interface IUploadFile {
    fileId: string;
    fileName: string;
    recordsCount: number;
    mapping: { [key: string]: ISelectValue };
}

export interface IMappingProfile {
    name: string;
    mapping: { [key: string]: ISelectValue };
}

export interface IMappingColumn {
    fileColumnName: string;
    columnName: string;
    type: string;
    friendlyName?: string;
}

export interface IUserMapping {
    id: number;
    name: string;
    mappingColumns: IMappingColumn[]
}

export default class UploadService {

    getMappingProfiles() {
        return RestUtilities.get<IUserMapping[]>("/api/upload/get-mapping-profiles");
    }

    finishUpload(model: IOrderModel) {
        return RestUtilities.post("/api/upload/add-to-database", model);
    }

    getDataForUpload() {
        return RestUtilities.get<IDataForUpload>("/api/upload/get-upload-data");
    }

    getUploads(pagination: IPagination) {
        return RestUtilities.post<IUploadHistoryModel>(`/api/upload/get-uploads`, pagination);
    }

    saveMappingProfile(model: IMappingProfile) {
        return RestUtilities.post("/api/upload/save-mapping-profile", model);
    }

    deleteFile(fileId: string) {
        return RestUtilities.delete(`/api/upload/delete-file/${fileId}`);
    }
}