import * as React from "react";
import { Link, RouteComponentProps } from 'react-router-dom';
import * as Auth from "./reducer";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { withFormLayout } from "../../common/form-layout";
import { Form, Input, Button, Radio, Row, Col, Select, Statistic } from "antd";
import { IAuthState, IRegisterUser } from "./auth-types";
import { RadioVerificationTypes } from "./auth-constants";
import { STATE_OPTIONS } from '../../assets/constants/states';
import { Store } from "antd/lib/form/interface";
import { RadioChangeEvent } from "antd/lib/radio";
import { STRING_EMPTY } from "../../assets/constants/general";
import { LikeOutlined } from '@ant-design/icons';
import { PAGE_LINK } from "../../assets/constants/page-links";
import { passwordRegexp, passwordRegexpMessage } from "../../helpers/regexp";
import { MaskedInput } from 'antd-mask-input';
import * as _ from 'lodash';
import { isPhone, isRequired, PhoneInput } from "../../helpers/validate-helper";
import "./auth.scss"
import { Helmet } from "react-helmet";

const { Item } = Form;
const { Option } = Select;
const { Countdown } = Statistic;

let authStyle = require('../../assets/styles/auth.styl');

type AuthProps = IAuthState
    & typeof Auth.registerActions
    & RouteComponentProps<any>

const Register = (props: AuthProps) => {
    const [form] = Form.useForm();

    const onFinish = (values: Store) => {
        const userProps = values as IRegisterUser;
        props.register(userProps);
    };

    const { isLoading, setVerificationType, targetTime, isCodeSent, verificationType, sendVerifyCode, setEmail, setPhoneNumber, verified, phoneStatus } = props;
    const stateOptions = STATE_OPTIONS.map((x, idx) => (
        <Option key={`${x.shortName}${idx}`} value={x.shortName}>{x.shortName} ({x.name})</Option>
    ));
    const validateMessages = {
        required: "Please input your ${name}!",
        types: {
            email: '${name} is not validate email!',
        },
        pattern: {
            mismatch: "${name} does not match pattern",
        }
    };


    const onFinishCountDown = () => {
        setVerificationType(STRING_EMPTY);
    }

    const subbmitCode = () => {
        form.validateFields()
            .then(() => {
                sendVerifyCode();
            })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Register</title>
                <meta name="description"
                    content="Register your account on MyDataPrivacy.com" />

            </Helmet>
            <Form name="register"
                form={form}
                onFinish={onFinish}
                layout={"vertical"}
                validateTrigger="onBlur"
                className='d-flex flex-column flex-wrap align-items-center auth-page w-100'
                scrollToFirstError
                validateMessages={validateMessages}>
                <h2 className={authStyle.formAuthHeading}>Register</h2>
                <h5 className="mb-4">Already have an account? <Link to={PAGE_LINK.LOGIN}>Back to Login</Link></h5>
                <div className="d-flex w-100 justify-content-between double-inputs">
                    <Item name={'firstName'} rules={[{ required: true }]}>
                        <Input autoComplete="chrome-off" placeholder="First Name *" />
                    </Item>
                    <Item name={'lastName'} rules={[{ required: true }]}>
                        <Input autoComplete="chrome-off" placeholder="Last Name *" />
                    </Item>
                </div>
                <Item name={'title'}>
                    <Input autoComplete="chrome-off" placeholder="Title" />
                </Item>
                <Item name={"email"} rules={[{ type: 'email', required: true }]}>
                    <Input autoComplete="chrome-off" placeholder="Email address *" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)} />
                </Item>
                <Item style={{ marginBottom: "30px" }} validateStatus={phoneStatus} name={'phone'} rules={[isPhone('Cell Phone'), isRequired('Cell Phone')]}>
                    <PhoneInput label='Cell Phone *' onChange={setPhoneNumber} />
                </Item>
                <Item name={'company'} rules={[{ required: true }]}>
                    <Input autoComplete="chrome-off" placeholder="Company *" />
                </Item>
                <Item name={'address1'} rules={[{ required: true }]}>
                    <Input autoComplete="chrome-off" placeholder="Address 1 *" />
                </Item>
                <Item name={'address2'}>
                    <Input autoComplete="chrome-off" placeholder="Address 2" />
                </Item>
                <Item name={'city'} rules={[{ required: true }]}>
                    <Input autoComplete="chrome-off" placeholder="City *" />
                </Item>
                <div className="d-flex w-100 justify-content-between double-inputs">
                    <Item name={'state'} rules={[{ required: true }]}>
                        <Select placeholder="State *">
                            {stateOptions}
                        </Select>
                    </Item>
                    <Item name={'zip'} rules={[{ required: true }]}>
                        <MaskedInput autoComplete="chrome-off" mask="11111" placeholder="ZIP *" />
                    </Item>
                </div>
                <div className="d-flex w-100 justify-content-between double-inputs">
                    <Item name={'password'}
                        rules={[
                            {
                                pattern: passwordRegexp,
                                message: passwordRegexpMessage
                            },
                            {
                                required: true,
                                message: 'Please input your password!'
                            }
                        ]}>
                        <Input type="password"
                            autoComplete="new-password"
                            placeholder="Password *" />
                    </Item>
                    <Item name={'passwordConfirm'}
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!'
                            },
                            ({ getFieldValue }) => ({
                                validator(_rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            })]}>
                        <Input type="password"
                            autoComplete="chrome-off"
                            placeholder="Confirm Password *" />
                    </Item>
                </div>
                <Item name={'verification'} label="" className='radio-block' initialValue={RadioVerificationTypes.SMS}>
                    <Radio.Group value={verificationType} onChange={(event: RadioChangeEvent) => setVerificationType(event.target.value)} className="d-flex w-100">
                        <Row className='d-flex w-100 justify-content-between'>
                            <Col span={12}><Radio disabled={isCodeSent} style={{ color: "white", fontWeight: "bold" }} value={RadioVerificationTypes.SMS}>SMS</Radio></Col>
                            <Col span={12}><Radio disabled={isCodeSent} style={{ color: "white", fontWeight: "bold" }} value={RadioVerificationTypes.VOICE}>Voice</Radio></Col>
                        </Row>
                    </Radio.Group>
                </Item>
                {
                    isCodeSent && (
                        <div className="d-flex flex-column justify-content-center">
                            {
                                (verificationType == RadioVerificationTypes.SMS) ? (
                                    <React.Fragment>
                                        <h5 className='text-center'>Enter a Code from an SMS Message</h5>
                                        <p className='text-center'>A Verification Code was Sent to Your Cell Phone. For enhanced security please enter the verification code below</p>
                                    </React.Fragment>) :
                                    (verificationType == RadioVerificationTypes.VOICE) ? (
                                        <React.Fragment>
                                            <p className='text-center'>We’re Calling You With a Verification Code. For enhanced security, please enter the code when prompted.</p>
                                        </React.Fragment>
                                    ) : null
                            }
                            <div className='text-center'><strong>Code is valid for: <Countdown value={targetTime} style={{ color: 'white' }} onFinish={onFinishCountDown} /></strong></div>
                            <Item name={'code'} rules={[{ min: 6, max: 6 }]}>
                                <Input placeholder="Code *" autoComplete="chrome-off" />
                            </Item>
                            <Form.Item>
                                <Button disabled={!verified} loading={isLoading} type="primary" size="large" block htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    )
                }
                {
                    !isCodeSent && (
                        <Form.Item>
                            <Button disabled={!props.phoneNumber && !isCodeSent} onClick={() => subbmitCode()} loading={isLoading} type="primary" size="large" block>
                                Next
                            </Button>
                        </Form.Item>
                    )
                }
            </Form>
        </React.Fragment>
    );
}

export default connect(
    (state: ApplicationState) => state.auth,
    Auth.registerActions)(withFormLayout(Register));