import { Reducer } from "redux";
import { AppThunkAction } from '../../../store';
import { typedAction } from "../../../helpers/action-helper";
import { showErrorNotificationCallback } from "../../../helpers/notification-helper";
import MappingService from "./mapping-service";
import { MappingConstants } from "./mapping-constants";
import { IMapping, IMappingActions, IMappingState } from "./mapping-types";
import { notification } from "antd";

const mappingService = new MappingService();

const fail = (errors: { [key: string]: string }) => typedAction(MappingConstants.failed, errors);
const setListMappings = (mappings: IMapping[]) => typedAction(MappingConstants.setMappings, mappings);
const success = () => typedAction(MappingConstants.success);
const startLoading = () => typedAction(MappingConstants.startLoading)
const showMessages = (msgs: string[]) => typedAction(MappingConstants.showMessages, msgs);
const showNotification = (msgs: string[]) => typedAction(MappingConstants.showNotification, msgs);
const hideModal = () => typedAction(MappingConstants.hideModal);

const getMappings = (): AppThunkAction<MappingActions> => (dispatch) => {
    dispatch(startLoading());
    mappingService.getMappingProfiles()
        .then((response) => {
            if (!response.is_error) {
                dispatch(setListMappings(response.content))
            } else {
                showErrorNotificationCallback(response, 'Smth went wrong when trying to get mappings', (errors: { [key: string]: string }) => dispatch(fail(errors)), true);
            }
        });
}

const modalClose = (): AppThunkAction<MappingActions> => (dispatch) => {
    dispatch(hideModal());
}

const uploadExcelFile = (file: FormData): AppThunkAction<MappingActions> => (dispatch) => {
    dispatch(startLoading());
    mappingService.uploadExcelFile(file)
        .then((response) => {
            if (!response.is_error) {
                if (response.content.isSuccess) {
                    dispatch(showMessages(response.content.errorMsg))
                } else {
                    dispatch(showNotification(response.content.errorMsg));
                }
               
                dispatch(setListMappings(response.content.data));
            } else {
                showErrorNotificationCallback(response, 'Upload error. Please try again!', (errors: { [key: string]: string }) => dispatch(fail(errors)), true);
            }
        });
}

const deleteMapping = (mappingId: number): AppThunkAction<MappingActions> => (dispatch) => {
    dispatch(startLoading());
    mappingService.deleteMapping(mappingId)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setListMappings(response.content))
                notification.success({ message: "The mapping profile has been deleted" })
            } else {
                showErrorNotificationCallback(response, 'Smth went wrong when trying to delete mapping', (errors: { [key: string]: string }) => dispatch(fail(errors)), true);
            }
        });
}

type MappingActions = ReturnType<typeof setListMappings | 
                                 typeof fail | 
                                 typeof success | 
                                 typeof startLoading | 
                                 typeof showMessages | 
                                 typeof hideModal |
                                 typeof showNotification>

export const mappingActions: IMappingActions = {
    fail,
    getMappings,
    setMappings: setListMappings,
    success: success,
    deleteMapping,
    startLoading,
    uploadExcelFile,
    showMessages,
    hideModal,
    modalClose,
    showNotification,
}

const unloadedState: IMappingState = { 
                                       errors: {}, 
                                       isFailed: false, 
                                       isLoading: false, 
                                       isSuccess: false, 
                                       mappingsList: [], 
                                       isModalOpen: false, 
                                       msgs: [],
                                       isNotificationOpen: false,
                                    };

export const reducer: Reducer<IMappingState, MappingActions> = (state: IMappingState = unloadedState, action: MappingActions): IMappingState => {
    switch (action.type) {
        case MappingConstants.startLoading:
            return {
                ...state,
                isLoading: true,
                isFailed: false,
                isSuccess: false,
                errors: {}
            };
        case MappingConstants.setMappings:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                mappingsList: action.payload as IMapping[]
            };
        case MappingConstants.success:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };
        case MappingConstants.showMessages:
            return {
                ...state,
                isModalOpen: true,
                msgs: action.payload as string[]
            };
        case MappingConstants.showNotification:
            return {
                ...state,
                isNotificationOpen: true,
                msgs: action.payload as string[]
            };
        case MappingConstants.hideModal:
            return {
                ...state,
                isModalOpen: false,
                isNotificationOpen: false,
                msgs: []
            };
        case MappingConstants.failed:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
                errors: action.payload as { [key: string]: string }
            };
        default: return state;
    }
}