import { typedAction } from "../../helpers/action-helper";
import { SearchActionTypes } from "./search-constants";
import { ISearchState, ISearchActions, ISearchUser, ISearchResponse, ISelectedGroup, IDeletedUser, IExpandedResult, IOptOutInfo } from "./search-types";
import { Reducer } from "redux";
import { AppThunkAction } from '../../store';
import { history } from "../../helpers/history-helper";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { DEFAULT_ZERO_VALUE, STRING_EMPTY } from "../../assets/constants/general";
import { groupByPropertyName, groupByPropertyNameAndFunc, groupByPropertyNameToPair } from '../../helpers/array-helper';
import SearchService from './search-service';
import * as _ from 'lodash';
import { showErrorNotification } from '../../helpers/notification-helper';
import { Dictionary } from "lodash";

import publicIp from 'public-ip';
import { notification } from "antd";
import { push } from "connected-react-router";

let searchService = new SearchService();

const initialState: ISearchState = {
    isLoading: false,
    searchEmail: STRING_EMPTY,
    resultsCount: null,
    isOptOutEmailSent: false,
    searchResults: {},
    selectedGroups: [],
    showDeleteModal: false,
    showThankYouModal: false,
    expandedResults: [],
    showOptOutModal: false,
    optouts: {},
    deleteEmail: STRING_EMPTY,
    captchaKey: STRING_EMPTY,
    searchHeaders: {},
    searchHeadersMasked: {},
    searchHeadersPII: {}
};

type SearchAction = ReturnType<typeof typedAction>

export const setLoading = (isLoaded: boolean) => typedAction(SearchActionTypes.setLoading, isLoaded);
export const setSearchEmail = (searchEmail: string) => typedAction(SearchActionTypes.setSearchEmail, searchEmail);
export const setSearchUser = (searchUser: ISearchUser) => typedAction(SearchActionTypes.setSearchUser, searchUser);
export const setResultsCount = (resultsCount: number) => typedAction(SearchActionTypes.setResultsCount, resultsCount);
export const setOptOutEmailSent = (isOptOutEmailSent: boolean) => typedAction(SearchActionTypes.setOptOutEmailSent, isOptOutEmailSent);
export const setSearchResults = (searchResults: { [x: string]: ISearchResponse[] }) => typedAction(SearchActionTypes.setSearchResults, searchResults);
export const setSelectedGroup = (group: string, value: boolean) => typedAction(SearchActionTypes.setSelectedGroup, { group, value });
export const setSelectedGroups = (groups: string[]) => typedAction(SearchActionTypes.setSelectedGroups, groups);
export const setShowDeletedModal = (uploadedBy?: string) => typedAction(SearchActionTypes.setShowDeletedModal, uploadedBy);
export const setShowThankYouModal = () => typedAction(SearchActionTypes.setShowThankYouModal);
export const setDeletedUser = (user: IDeletedUser) => typedAction(SearchActionTypes.setDeletedUser, user);
export const setExpandedResults = (results: IExpandedResult[]) => typedAction(SearchActionTypes.setExpandedResults, results);
export const updateExpanded = (group: string, index: number) => typedAction(SearchActionTypes.updateExpanded, { group, index });
export const setShowOptOutModal = (uploadedBy?: string) => typedAction(SearchActionTypes.setShowOptOutModal, uploadedBy);
export const setOptOuts = (optouts: { [x: string]: IOptOutInfo }) => typedAction(SearchActionTypes.setOptOuts, optouts);
export const setDeleteEmail = (email: string) => typedAction(SearchActionTypes.setDeleteEmail, email);
export const setCaptchaKey = (key: string) => typedAction(SearchActionTypes.setCaptchaKey, key);
export const setSearchHeaders = (headers: Dictionary<string>) => typedAction(SearchActionTypes.setSearchHeaders, headers);
export const setSearchHeadersMasked = (headers: Dictionary<boolean>) => typedAction(SearchActionTypes.setSearchHeadersMasked, headers);
export const setSearchHeadersPII = (headers: Dictionary<boolean>) => typedAction(SearchActionTypes.setSearchHeadersPII, headers);

export const searchByEmail = (email: string): AppThunkAction<SearchAction> => (dispatch) => {
    dispatch(setLoading(true));

    dispatch(setSearchEmail(email));

    searchService.searchByEmail(email)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setResultsCount(response.content && response.content || DEFAULT_ZERO_VALUE));
                if (response.content && response.content > 0) {
                    history.push(PAGE_LINK.SEARCH_RESULT);
                }
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setLoading(false)));
}

export const getCaptchaKey = (): AppThunkAction<SearchAction> => (dispatch) => {
    dispatch(setLoading(true));

    searchService.getCaptchaKey()
        .then((response) => {
            if (!response.is_error) {
                dispatch(setCaptchaKey(response.content || STRING_EMPTY));
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setLoading(false)));
}

export const searchByUserInfo = (userInfo: ISearchUser): AppThunkAction<SearchAction> => (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setSearchUser(userInfo));

    searchService.searchByAddress(userInfo)
        .then((response) => {
            if (!response.is_error) {
                // todo: there will be one additional step here to verify that the consumer performing the search is the person that should receive the data
                history.push(`${PAGE_LINK.SEARCH_RESULTS_BY_ADDRESS}/${response.content}`);
            } else {
                showErrorNotification(response);
                () => dispatch(setLoading(false))
            }
        })
}

export const optOutEmail = (): AppThunkAction<SearchAction> => (dispatch, getState) => {
    const { searchEmail } = getState().search;
    dispatch(setLoading(true));

    searchService.optOutEmail(searchEmail)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setOptOutEmailSent(response.content));
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setLoading(false)));
}

export const sendDeleteEmail = (): AppThunkAction<SearchAction> => async (dispatch, getState) => {
    const { deleteEmail, uploadedByToDelete } = getState().search;
    dispatch(setLoading(true));

    if (uploadedByToDelete) {
        const ip = await publicIp.v4();
        const info: IOptOutInfo = {
            userGuid: uploadedByToDelete.ownerId,
            company: uploadedByToDelete.ownerCompany,
            ipAddress: ip,
            email: uploadedByToDelete.email,
            documentId: uploadedByToDelete.documentId,
            fileId: uploadedByToDelete.fileId,
            firstName: uploadedByToDelete.firstName,
            lastName: uploadedByToDelete.lastName,
            address: uploadedByToDelete.address,
            city: uploadedByToDelete.city,
            zip: uploadedByToDelete.zip,
            optOutGuid: uploadedByToDelete?.optout?.optOutGuid,
            state: uploadedByToDelete.state
        };

        searchService.sendDeleteEmail(info, deleteEmail)
            .then((response) => {
                if (!response.is_error && response.content) {
                    dispatch(setShowThankYouModal());
                } else {
                    showErrorNotification(response);
                }
            })
            .finally(() => dispatch(setLoading(false)));
    }
}

export const loadSearchResults = (guid: string): AppThunkAction<SearchAction> => async (dispatch) => {
    dispatch(setLoading(true));

    await searchService.loadSearchResults(guid)
        .then((response) => {
            if (!response.is_error) {
                const { searchResponses, headers } = response.content || {};
                const data = searchResponses.map(x => x.uploadedBy === null ? { ...x, uploadedBy: 'default' } : x);
                const groupedData = response.content && groupByPropertyName(data, 'ownerCompany');
                const optouts = groupByPropertyNameAndFunc(data, 'ownerCompany', x => x[0]?.optout);

                dispatch(setOptOuts(optouts));
                dispatch(setSearchResults(groupedData));
                dispatch(setSelectedGroups(_.keys(groupedData)));
                dispatch(setSearchHeaders(groupByPropertyNameToPair(headers, 'outputColumn', 'friendlyOutputColumn')));
                dispatch(setSearchHeadersMasked(groupByPropertyNameToPair(headers, 'outputColumn', 'isPartialMasked')));
                dispatch(setSearchHeadersPII(groupByPropertyNameToPair(headers, 'outputColumn', 'pii')));

                const expandedResults: IExpandedResult[] = [];

                _.keys(groupedData)
                    .forEach(group => groupedData[group].forEach((_x, idx) => expandedResults.push({ group, index: idx, expanded: false })));

                dispatch(setExpandedResults(expandedResults));
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setLoading(false)));
}

export const loadSearchHistory = (guid: string): AppThunkAction<SearchAction> => async (dispatch) => {
    dispatch(setLoading(true));

    await searchService.loadSearchHistory(guid)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setSearchUser(response.content));
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setLoading(false)));
}

export const optOutInfo = (guid: string): AppThunkAction<SearchAction> => async (dispatch, getState) => {
    const { uploadedByToOptOut } = getState().search;
    dispatch(setLoading(true));

    if (uploadedByToOptOut) {
        const ip = await publicIp.v4();
        const info: IOptOutInfo = {
            userGuid: uploadedByToOptOut.ownerId,
            company: uploadedByToOptOut.ownerCompany,
            ipAddress: ip,
            email: uploadedByToOptOut.email,
            documentId: uploadedByToOptOut.documentId,
            fileId: uploadedByToOptOut.fileId,
            firstName: uploadedByToOptOut.firstName,
            lastName: uploadedByToOptOut.lastName,
            address: uploadedByToOptOut.address,
            city: uploadedByToOptOut.city,
            zip: uploadedByToOptOut.zip,
            state: uploadedByToOptOut.state
        };

        await searchService.optOutInfo(info)
            .then((response) => {
                if (!response.is_error && response.content) {
                    dispatch(setShowOptOutModal());
                    loadSearchResults(guid)(dispatch, getState);
                } else {
                    showErrorNotification(response);
                }
            })
            .finally(() => dispatch(setLoading(false)));
    }
}

export const confirmDelete = (token: string): AppThunkAction<SearchAction> => (dispatch) => {
    dispatch(setLoading(true));

    searchService.confirmDelete(token)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setDeletedUser(response.content));
                notification.success({message: "Data successfully deleted"});
                dispatch(push(PAGE_LINK.LANDING));
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setLoading(false)));
}

export const searchActions: ISearchActions = {
    setLoading,
    setSearchEmail,
    searchByEmail,
    searchByUserInfo,
    setOptOutEmailSent,
    optOutEmail,
    setSearchResults,
    loadSearchResults,
    setSelectedGroup,
    setSelectedGroups,
    setSearchUser,
    setShowDeletedModal,
    setShowThankYouModal,
    confirmDelete,
    setDeletedUser,
    updateExpanded,
    setShowOptOutModal,
    optOutInfo,
    setOptOuts,
    sendDeleteEmail,
    setDeleteEmail,
    loadSearchHistory,
    getCaptchaKey,
    setCaptchaKey,
    setSearchHeaders,
    setSearchHeadersMasked,
    setSearchHeadersPII
}

export const searchReducer: Reducer<ISearchState, SearchAction> = (state = initialState, action: SearchAction): ISearchState => {
    switch (action.type) {
        case SearchActionTypes.setLoading:
            return {
                ...state,
                isLoading: action.payload as boolean
            };
        case SearchActionTypes.setSearchEmail:
            return {
                ...state,
                searchEmail: action.payload as string
            };
        case SearchActionTypes.setCaptchaKey:
            return {
                ...state,
                captchaKey: action.payload as string
            };
        case SearchActionTypes.setResultsCount:
            return {
                ...state,
                resultsCount: action.payload as number
            };
        case SearchActionTypes.setOptOutEmailSent:
            return {
                ...state,
                isOptOutEmailSent: action.payload as boolean
            };
        case SearchActionTypes.setSearchResults:
            return {
                ...state,
                searchResults: action.payload as { [x: string]: ISearchResponse[] }
            }
        case SearchActionTypes.setSelectedGroups:
            return {
                ...state,
                selectedGroups: (action.payload as string[]).map(group => ({ group, value: true }))
            }
        case SearchActionTypes.setSelectedGroup:
            const updatedGroup = action.payload as ISelectedGroup;
            let selectedGroupsCopy = [...state.selectedGroups].map(g => g.group === updatedGroup.group ? updatedGroup : g);

            return {
                ...state,
                selectedGroups: selectedGroupsCopy
            }
        case SearchActionTypes.setSearchUser:
            return {
                ...state,
                searchUser: action.payload as ISearchUser
            }
        case SearchActionTypes.setShowDeletedModal: {
            let key = STRING_EMPTY;
            let result = { ...state.uploadedByToDelete };

            if (typeof action.payload === 'string') {
                key = action.payload;
                const results = state.searchResults[key];
                result = results && results[0];
            }

            return {
                ...state,
                showDeleteModal: !state.showDeleteModal,
                deleteEmail: result.email,
                uploadedByToDelete: result
            }
        }
        case SearchActionTypes.setShowThankYouModal:
            return {
                ...state,
                showThankYouModal: !state.showThankYouModal
            }
        case SearchActionTypes.setDeletedUser:
            return {
                ...state,
                deletedUser: action.payload as IDeletedUser
            }
        case SearchActionTypes.setExpandedResults:
            return {
                ...state,
                expandedResults: action.payload as IExpandedResult[]
            }
        case SearchActionTypes.updateExpanded: {
            let expandedResultsCopy = [...state.expandedResults];
            const { group, index } = action.payload as IExpandedResult;
            expandedResultsCopy.forEach(x => (x.group === group && x.index === index) ? x.expanded = !x.expanded : x);

            return {
                ...state,
                expandedResults: expandedResultsCopy
            }
        }
        case SearchActionTypes.setShowOptOutModal: {
            let key = STRING_EMPTY;
            let result = { ...state.uploadedByToOptOut };

            if (typeof action.payload === 'string') {
                key = action.payload;
                const results = state.searchResults[key];
                result = results && results[0];
            }

            return {
                ...state,
                showOptOutModal: !state.showOptOutModal,
                uploadedByToOptOut: result
            }
        }
        case SearchActionTypes.setOptOuts:
            return {
                ...state,
                optouts: action.payload as { [x: string]: IOptOutInfo }
            }
        case SearchActionTypes.setDeleteEmail:
            return {
                ...state,
                deleteEmail: action.payload as string
            }
        case SearchActionTypes.setSearchHeaders:
            return {
                ...state,
                searchHeaders: action.payload as Dictionary<string>
            }
        case SearchActionTypes.setSearchHeadersMasked:
            return {
                ...state,
                searchHeadersMasked: action.payload as Dictionary<boolean>
            }
        case SearchActionTypes.setSearchHeadersPII:
            return {
                ...state,
                searchHeadersPII: action.payload as Dictionary<boolean>
            }
        default:
            return state;
    }
}