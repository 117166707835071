import { IRegisterActionTypes, ISigninActionTypes, IRadioVerificationTypes } from "./auth-types";

export const RegisterActionTypes: IRegisterActionTypes = {
    started: "REGISTER_STARTED",
    success: "REGISTER_SUCCESS",
    failed: "REGISTER_FAILED",
    registerEmail: "SET_REGISTER_EMAIL",
    setLoading: 'SET_LOADING',
    setVerificationType: "SET_VERIFICATION_TYPE",
    setVerification: "SET_VERIFY",
    setEmail: "SET_EMAIL",
    setPhoneNumber: "SET_PHONE_NUMBER",
    setErrors: "SET_ERRORS",
    setPhoneStatus: 'SET_PHONE_STATUS',
    setIsCodeSent: 'SET_IS_CODE_SENT',
    setTargetTime: 'SET_TARGET_TIME'
}

export const SigninActionTypes: ISigninActionTypes = {
    started: "SIGNIN_STARTED",
    success: "SIGNIN_SUCCESS",
    failed: "SIGNIN_FAILED",
    setSignInEmail: "SET_SIGNIN_EMAIL",
    setSignInPassword: "SET_SIGNIN_PASSWORD",
    setSignInRemember: "SET_SIGNIN_REMEMBER",
    setUserLoggedOut: 'USER_LOGGED_OUT'
}

export const RadioVerificationTypes: IRadioVerificationTypes = {
    // according to https://www.twilio.com/docs/verify/api/verification
    SMS: "sms",
    VOICE: "call"
}

export const ERROR_MESSAGES = {
    PHONE_INVALID: 'Phone number is not valid. Please provide another one!'
}
