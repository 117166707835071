import * as React from 'react';
import "./upload.scss";
import * as UploadStore from "./reducer"
import { IUploadState, IUserUpload } from './upload-types';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { withUserLayout } from '../../../common/user-layout';
import { PAGE_LINK } from '../../../assets/constants/page-links';
import LoadingOverlay from 'react-loading-overlay';
import { Input, Progress, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { PAGE_SIZE_OPTIONS } from '../../../assets/constants/general';
import { createAtToLocal } from '../../../helpers/date-time-helper';
import { CSVLink } from 'react-csv';
import { csvHeadersForExport } from './upload-constats';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';

type UploadProps = IUploadState
    & typeof UploadStore.uploadActions

export class UploadHistoryPage extends React.PureComponent<UploadProps>{
    componentDidMount() {
        const { getUploads } = this.props;

        getUploads();
    }

    onChange = (pagination: TablePaginationConfig, _filters: any, sorter: SorterResult<IUserUpload>) => {
        const { getUploads, setUserUploadsPagination, userUploadsPagination } = this.props;

        setUserUploadsPagination({
            ...userUploadsPagination,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sortCriteria: sorter.columnKey?.toString(),
            isAscend: sorter.order === 'ascend'
        });
        getUploads();
    }

    onShowSizeChange = (current: number, pageSize: number) => {
        const { getUploads, setUserUploadsPagination, userUploadsPagination } = this.props;

        setUserUploadsPagination({ ...userUploadsPagination, pageNumber: current, pageSize });
        getUploads();
    }

    onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { setUserUploadsPagination, userUploadsPagination } = this.props;

        setUserUploadsPagination({ ...userUploadsPagination, searchCriteria: event.target.value, pageNumber: 1 });
    }

    render() {
        const { userUploads, userUploadsPagination, userUploadsIsLoading, getUploads } = this.props;
        const columns = [{
            title: 'Uploaded',
            dataIndex: 'uploadedAt',
            key: 'uploadedAt',
            sorter: true,
            render: (text: string) => createAtToLocal(text)
        }, {
            title: 'Reference (Order) Number',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
            sorter: true
        }, {
            title: 'File Name',
            dataIndex: 'fileName',
            key: 'fileName',
            sorter: true
        }, {
            title: 'List Owner',
            dataIndex: 'listOwner',
            key: 'listOwner',
            sorter: true
        }, {
            title: 'Uploaded by',
            dataIndex: 'uploadedBy',
            key: 'uploaduploadedByedAt',
            sorter: true
        }, {
            title: 'Quantity',
            dataIndex: 'recordsCount',
            key: 'recordsCount',
            sorter: true
        }, {
            title: 'Opt-Outs',
            dataIndex: 'optOutsCount',
            key: 'optOutsCount',
            sorter: true
        }, {
            title: 'Deletes',
            dataIndex: 'deletesCount',
            key: 'deletesCount',
            sorter: true
        }, {
            title: 'Upload',
            dataIndex: 'completed',
            key: 'completed',
            sorter: false,
            render: (text: boolean) => <Progress type="circle" percent={text ? 100 : 0} status={text ? 'success' : 'exception'} width={24} />
        }];

        return (
            <LoadingOverlay className="user-uploads-page d-flex flex-column justify-content-start align-items-center w-100 p-4" active={userUploadsIsLoading} spinner>
                <Input.Search onChange={this.onSearch} value={userUploadsPagination.searchCriteria} onSearch={getUploads} className='mb-2' />
                <Table dataSource={userUploads.map((x, idx) => ({ ...x, key: `uploaded_item_${idx}` }))}
                    columns={columns}
                    pagination={{
                        current: userUploadsPagination.pageNumber,
                        pageSize: userUploadsPagination.pageSize,
                        total: userUploadsPagination.total,
                        onShowSizeChange: this.onShowSizeChange,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: PAGE_SIZE_OPTIONS
                    }}
                    onChange={this.onChange}
                    className='w-100' />
                <div style={{ minHeight: "auto" }} className="w-100 d-flex flex-row-reverse">
                    {/*ToDo: normal file name */}
                    <CSVLink className="btn btn-info btn-lg download-csv"
                        headers={csvHeadersForExport}
                        data={userUploads}
                        enclosingCharacter={``}
                        filename={`Upload_History_(${moment().format('L')}).csv`}>
                        Download CSV <DownloadOutlined />
                    </CSVLink>
                </div>
            </LoadingOverlay>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.upload,
    UploadStore.uploadActions)(withUserLayout(UploadHistoryPage as any, PAGE_LINK.UPLOAD_HISTORY));