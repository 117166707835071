import * as React from "react";
import * as OptOutStore from "./opt-out-reducer";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { withUserLayout } from "../../../common/user-layout";
import { PAGE_LINK } from "../../../assets/constants/page-links";
import LoadingOverlay from "react-loading-overlay";
import { Button, Col, Form, Input, Radio, Row, Select, Table, Tooltip } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import {
    FIRST_PAGE_NUMBER,
    PAGE_SIZE_OPTIONS
} from "../../../assets/constants/general";
import { IOptOutItem, IOptOutState, IOptOutType } from "./opt-out-types";
import { RadioChangeEvent } from "antd/lib/radio";
import { DownloadOutlined, UploadOutlined, FileTextOutlined } from "@ant-design/icons";
import { CSV_HEADERS, SUPPRESSION_TITLES, SUPPRESSION_TYPES } from "./opt-out-constants";
import { CSVLink } from "react-csv";
import { createAtToLocal } from "../../../helpers/date-time-helper";

const { Group } = Radio;
const { Search } = Input;

type OptOutProps = IOptOutState & typeof OptOutStore.optOutActions;

export class OptOutsPage extends React.PureComponent<OptOutProps> {
    state = {
        selectedImportType: null as number,
        fileInputKey: Math.random().toString(36)
    };

    componentDidMount() {
        const { getOptOuts } = this.props;

        getOptOuts();
    }

    onChange = (
        pagination: TablePaginationConfig,
        _filters: any,
        sorter: SorterResult<IOptOutItem>
    ) => {
        const {
            getOptOuts,
            setUserOptOutsPagination,
            userOptOutsPagination,
        } = this.props;
        const sortCriteria = sorter.columnKey?.toString();

        setUserOptOutsPagination({
            ...userOptOutsPagination,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sortCriteria: sortCriteria === "type" ? "isDeleted" : sortCriteria,
            isAscend: sorter.order === "ascend",
        });
        getOptOuts();
    };

    onShowSizeChange = (current: number, pageSize: number) => {
        const {
            getOptOuts,
            setUserOptOutsPagination,
            userOptOutsPagination,
        } = this.props;

        setUserOptOutsPagination({
            ...userOptOutsPagination,
            pageNumber: current,
            pageSize,
        });
        getOptOuts();
    };

    onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { setUserOptOutsPagination, userOptOutsPagination } = this.props;

        setUserOptOutsPagination({
            ...userOptOutsPagination,
            searchCriteria: event.target.value,
            pageNumber: FIRST_PAGE_NUMBER,
        });
    };

    onImportTypeSelect = (selectedImportType: number) => {
        this.setState({ selectedImportType });
    }

    onFinish = async (event: any) => {
        const { importDocument, getOptOuts } = this.props;
        const file = event.target.files[0] as File;

        await importDocument(this.state.selectedImportType.toString(), file);

        getOptOuts();
        // THIS RESETS INPUT FIELD
        this.setState({
            fileInputKey: Math.random().toString(36)
        })
    }

    onTypeChange = (event: RadioChangeEvent) => {
        const {
            setUserOptOutsType,
            getOptOuts,
            setUserOptOutsPagination,
            userOptOutsPagination,
        } = this.props;

        setUserOptOutsPagination({
            ...userOptOutsPagination,
            pageNumber: FIRST_PAGE_NUMBER,
        });
        setUserOptOutsType(event.target.value);
        getOptOuts();
    };

    render() {
        const {
            userOptOutsType,
            userOptOuts,
            userOptOutsPagination,
            userOptOutsIsLoading,
            getOptOuts,
            downloadOuts
        } = this.props;
        const columns = [
            {
                title: "First Name",
                dataIndex: "firstName",
                key: "firstName",
                sorter: true,
                render: (text: string) => text ? text : "N/A"
            },
            {
                title: "Last Name",
                dataIndex: "lastName",
                key: "lastName",
                sorter: true,
                render: (text: string) => text ? text : "N/A"
            },
            {
                title: "Postal Address",
                dataIndex: "address",
                key: "address",
                sorter: true,
                render: (text: string) => text ? text : "N/A"
            },
            {
                title: "City",
                dataIndex: "city",
                key: "city",
                sorter: true,
                render: (text: string) => text ? text : "N/A"
            },
            {
                title: "State",
                dataIndex: "state",
                key: "state",
                sorter: true,
                render: (text: string) => text ? text : "N/A"
            },
            {
                title: "Zip",
                dataIndex: "zip",
                key: "zip",
                sorter: true,
                render: (text: string) => text ? text : "N/A"
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email",
                sorter: true,
                render: (text: string) => text ? text : "N/A"
            },
            {
                title: "Date",
                dataIndex: "date",
                key: "date",
                sorter: true,
                render: (text: string) => createAtToLocal(text)
            },
            {
                title: "IP",
                dataIndex: "ipAddress",
                key: "ipAddress",
                sorter: true,
                render: (text: string) => text ? text : "N/A"
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                sorter: true,
                render: (text: string) => text ? text : "N/A"
            },
            {
                title: "Reference (Order) Number",
                dataIndex: "orderNumber",
                key: "orderNumber",
                sorter: true,
                render: (text: string) => text ? text : "N/A"
            },
            {
                title: "Date of Uploading Data File",
                dataIndex: "fileUploadingDate",
                key: "fileUploadingDate",
                sorter: true,
                render: (text: string) => createAtToLocal(text)
            },
        ];

        return (
            <LoadingOverlay
                className="user-opt-outs-page d-flex flex-column justify-content-start align-items-center w-100 p-4"
                active={userOptOutsIsLoading}
                spinner
            >
                <Row className="w-100 align-items-center mb-3">
                    <Col xs={24} md={12} lg={12}>
                        <Group
                            onChange={this.onTypeChange}
                            value={userOptOutsType}
                        >
                            <Radio value={IOptOutType.optOut}>
                                <strong>Opt Out</strong>
                            </Radio>
                            <Radio value={IOptOutType.delete}>
                                <strong>Delete</strong>
                            </Radio>
                            <Radio value={IOptOutType.both}>
                                <strong>Both</strong>
                            </Radio>
                        </Group>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                        <Search
                            onChange={this.onSearch}
                            value={userOptOutsPagination.searchCriteria}
                            onSearch={getOptOuts}
                            className="mb-2"
                        />
                    </Col>
                </Row>
                <Table
                    dataSource={userOptOuts.map((x, idx) => ({
                        ...x,
                        key: `opt_out_item_${idx}`,
                    }))}
                    columns={columns}
                    bordered
                    pagination={{
                        current: userOptOutsPagination.pageNumber,
                        pageSize: userOptOutsPagination.pageSize,
                        total: userOptOutsPagination.total,
                        onShowSizeChange: this.onShowSizeChange,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: PAGE_SIZE_OPTIONS,
                    }}
                    onChange={this.onChange}
                    className="w-100"
                />

                <div className="d-flex w-100 justify-content-end mt-3">
                    <Form className="d-flex flex-row w-100 justify-content-start mt-3">
                        <Form.Item rules={[{ required: true, message: "Please select type" }]} className="w-auto">
                            <Select onChange={this.onImportTypeSelect} placeholder="Select import type" style={{ width: "230px" }} allowClear>
                                <Select.Option value={SUPPRESSION_TYPES.OPTED_OUT}>Opt-Outs</Select.Option>
                                <Select.Option value={SUPPRESSION_TYPES.DELETED}>Deletes</Select.Option>
                                <Select.Option value={SUPPRESSION_TYPES.BOTH}>Both</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ width: "auto" }}>
                            <Button
                                className="ant-btn ant-btn-primary upload-csv ant-btn-round w-auto mx-2"
                                loading={userOptOutsIsLoading}
                                disabled={this.state.selectedImportType == null}
                            >
                                Import Csv <UploadOutlined />
                                <Input
                                    type="file"
                                    key={this.state.fileInputKey}
                                    onChange={(event) => this.onFinish(event)}
                                    className="upload-input px-3"
                                />
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Tooltip title="Download example">
                                <CSVLink
                                    data={CSV_HEADERS}
                                    filename={"Example_opt-out.csv"}
                                    className="w-auto"
                                >
                                    <FileTextOutlined style={{ fontSize: "48px", color: "#0e9bff" }} />
                                </CSVLink>
                            </Tooltip>
                        </Form.Item>
                    </Form>
                    <Button className="download-csv mt-3" shape="round" onClick={downloadOuts}>
                        Download CSV <DownloadOutlined />
                    </Button>
                </div >
            </LoadingOverlay >
        );
    }
}

export default connect(
    (state: ApplicationState) => state.optOut,
    OptOutStore.optOutActions
)(withUserLayout(OptOutsPage as any, PAGE_LINK.OPT_OUTS));