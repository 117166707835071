import React, { Component } from 'react';
import { ILandingActions, ILandingProps, IPotentialClient } from "./landing-types"
import { Button, Checkbox, Form, Input, Modal } from "antd";
import '../list-owners-and-mailers/list-owners-and-mailers.scss';
import './landing.scss';
import "../../assets/styles/app.scss";
import { landingActions } from "./landing-reducer";
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { isEmail, isPhone, isRequired, PhoneInput, withoutDigits } from '../../helpers/validate-helper';
import { FormInstance } from 'antd/lib/form';
const { Item } = Form;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const validateMessages = {
    types: {
        email: '${label} is not validate email!',
    }
};

export interface ModalFormOwnProps {
    formName: string;
    isVisible: boolean;
    onClose: () => void;
}
type ModalFormProps = ModalFormOwnProps & ILandingProps & ILandingActions;

class ModalForm extends React.Component<ModalFormProps> {
    formRef = React.createRef<FormInstance>();

    onFinish = (values: IPotentialClient) => {
        const { formName, sendForm, onClose } = this.props;
        values.formName = formName;
        sendForm(values);
        onClose();
        this.formRef.current.resetFields();
    }

    render() {
        const formContent = (
            <div id="app" className="d-flex flex-column main-form">
                <h3>{this.props.formName}</h3>
                <div className="d-flex">
                    <Form {...layout} ref={this.formRef} name="nest-messages" className='d-flex flex-column w-100' onFinish={this.onFinish} validateMessages={validateMessages}>
                        <div className="d-flex w-100 justify-content-between double-inputs">
                            <Item name={'firstName'}
                                rules={[isRequired('First Name'), withoutDigits('First Name')]}
                                label="First Name">
                                <Input />
                            </Item>
                            <Item name={'lastName'}
                                rules={[isRequired('Last Name'), withoutDigits('Last Name')]}
                                label="Last Name">
                                <Input />
                            </Item>
                        </div>
                        <Item name={'company'} label="Company Name">
                            <Input />
                        </Item>
                        <Item name={'email'}
                            label="Email Address"
                            rules={[isRequired('Email Address'), isEmail('Email Address')]}>
                            <Input />
                        </Item>
                        <Item name={'phone'} label="Phone Number" rules={[isPhone('Phone Number')]}>
                            <PhoneInput label='' />
                        </Item>
                        <Item name="permission">
                            <Checkbox checked defaultChecked disabled>mydataprivacy.com has permission to contact me with information related to this service.</Checkbox>
                        </Item>
                        <Item>
                            <Button loading={this.props.formLoading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Item>
                    </Form>
                </div>
            </div>
        )
        return (
            <Modal footer={null} visible={this.props.isVisible} onCancel={this.props.onClose}>
                {formContent}
            </Modal>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.landing,
    landingActions
)(ModalForm);