import { Button, Input, Modal, Form } from "antd";
import _ from "lodash";
import { Dictionary } from "lodash";
import React from "react";
import { DEFAULT_ZERO_VALUE, NONE } from "../../assets/constants/general";
import message from '../../assets/images/message.svg';
import { ISearchResponse } from "./search-types";

import './search.scss';

export const searchResultsTableColumns = [{
    title: 'Data was Collected From',
    dataIndex: 'collectedFrom',
    key: 'collectedFrom',
    width: '20%',
    render: (value: string[], row: any, index: number) => {
        return {
            children: value && value.length > DEFAULT_ZERO_VALUE ? value.toString() : NONE,
            props: {
                rowSpan: index === 0 ? row.count : 0
            }
        }
    },
}, {
    title: 'Business Purpose for Collecting/Selling Data',
    dataIndex: 'businessPurpose',
    key: 'businessPurpose',
    width: '20%',
    render: (value: string, row: any, index: number) => {
        return {
            children: value,
            props: {
                rowSpan: index === 0 ? row.count : 0
            }
        }
    },
}, {
    title: 'Data Shared with these 3rd Party Categories',
    dataIndex: 'sharedWith3rdPartyCategories',
    key: 'sharedWith3rdPartyCategories',
    width: '20%',
    render: (value: string[], row: any, index: number) => {
        return {
            children: value && value.length > DEFAULT_ZERO_VALUE ? value.toString() : NONE,
            props: {
                rowSpan: index === 0 ? row.count : 0
            }
        }
    },
}, {
    title: 'Data Categories Shared',
    dataIndex: 'dataCategoriesShared',
    key: 'dataCategoriesShared',
    width: '20%'
}, {
    title: 'Data Elements Shared',
    dataIndex: 'dataElementsShared',
    key: 'dataElementsShared',
    width: '20%'
}];

export const formatDataSource = (props: ISearchResponse & { key: string, searchHeaders: Dictionary<string> }): any[] => {
    const { key, collectedFrom, businessPurpose, sharedWith3rdPartyCategories, searchHeaders, masked, custom, headerElements } = props;

    const dataShared: { [key: string]: string } = { ...JSON.parse(headerElements), ...JSON.parse(custom), ...JSON.parse(masked) };

    const dataSource = [];
    if (_.keys(dataShared).filter(x => !_.isEmpty(x)).length === DEFAULT_ZERO_VALUE) {
        dataSource.push({
            key: `${key}_data`,
            count: 1,
            collectedFrom,
            businessPurpose,
            sharedWith3rdPartyCategories,
        })
    } else {
        _.keys(dataShared).forEach((category: string, index: number) => {
            if (!_.isEmpty(dataShared[category])) {
                dataSource.push({
                    key: `${key}_${index}`,
                    count: _.values(dataShared).length,
                    collectedFrom,
                    businessPurpose,
                    sharedWith3rdPartyCategories,
                    dataCategoriesShared: searchHeaders[category] || category,
                    dataElementsShared: dataShared[category]
                })
            }
        });
    }

    return dataSource;
}

export const countEmailLetters = (word: string) => word && word.match(/([^@]+)/)[0]?.length;

export const hideEmail = (email: string) => email && email.replace(/([^@]+)/, '*'.repeat(countEmailLetters(email)));

export const hideStringValue = (value: string) => value && `${value[0]}${'*'.repeat(value.length - 1)}`;

export const searchDescription = "The following report details which categories of Personal Information have been collected, how your data was collected, the bussiness purpose for which that data was used, the type of bussiness with which it was shared and the data categories and/or specific pieces of information contained in your profile";

export const deleteModal = (show: boolean, value: string, setShowDeletedModal: () => void, onInputChange: (email: string) => void, onOk: () => void) => (
    <Modal visible={show}
        className='w-75'
        centered={true}
        onCancel={() => setShowDeletedModal()}
        footer={
            <div className='d-flex justify-content-between'>
                <span className='font-weight-bold'>Please send a "delete confirmation link" to</span>
                <div className="d-flex justify-content-end">
                    <Form.Item rules={[{ type: 'email' }]}>
                        <Input defaultValue={value} onChange={(event) => onInputChange(event.target.value)} placeholder="name@email.com" />
                    </Form.Item>
                    <Button onClick={onOk} type='primary' className='text-uppercase'>Okay</Button>
                </div>
            </div>
        }>
        {deleteConfirmationRules()}
        <p className="text-muted mb-0">You can only permanent delete your record, subject to the conditions above, by selecting a link sent to you by email.</p>
    </Modal>
)

export const optOutModal = (show: boolean, setShowOptOutModal: () => void, onOk: () => void, isLoading: boolean) => (
    <Modal visible={show}
        className='w-75 opt-out-modal'
        centered={true}
        onCancel={() => setShowOptOutModal()}
        footer={
            <div className='d-flex justify-content-center'>
                <Button loading={isLoading} onClick={onOk} type='primary' className='text-uppercase'>Okay</Button>
            </div>
        }>
        <p className="text-muted text-center mb-1 p-2">Please remember, the system only recognizes exact matches on your email address or name and postal address together. Please enter other email addresses you have used, active or inactive, to see wthat's in the database. As well. if you have moved, search on previous addresses too. And finally, if you've changed your name, try entering your former name with your current and former addresses as well.</p>
    </Modal>
)

export const thankYouModal = (show: boolean, setShowThankYouModal: () => void) => (
    <Modal visible={show}
        className='w-25 m-3'
        centered={true}
        closable={false}
        footer={
            <div className='d-flex w-100'>
                <Button type='primary' className='w-100 text-uppercase' onClick={setShowThankYouModal}>Thank You</Button>
            </div>
        }>
        <div className="d-flex flex-column align-items-center justify-content-center">
            <h3>Thank You</h3>
            <img src={message} alt='Message' className='m-3' />
            <h4 className="text-center">Please check your email.</h4>
        </div>
    </Modal>
);

export const deleteConfirmationRules = () => (
    <div className="d-flex flex-column m-3 mb-0">
        <p>Please know that a business or service provider shall not be required to comply with a consumer's request to delete the consumer's personal information if it is necessary for the business or service provider to maintain the consumer's personal information in order to:</p>
        <ol>
            <li>Complete the transaction for which the personal information was collected, provide a good or service requested by the consumer, or reasonably anticipated within the context of a business's ongoing business relationship with the consumer, or otherwise perform a contact between the business and the consumer.</li>
            <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity.</li>
            <li>Debug to identify and repair errors that imair existing indended functionality.</li>
            <li>Exercise free speech, ensure the right of another consumer to excercise his or her right of free speach, or excercise another right provided for by law.</li>
            <li>Comply with the California Electronic Commuications Privacy Act pursuant to Chapter 3.6 (commencing with Section 1546) of Title 12 of Part 2 of the Penal Code.</li>
            <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the businesses' deletion of the information is likely to render impossible or seriously impair the achievement of such research, if the consumer has provided informed consent.</li>
            <li>To enable solely internal uses that are reasonably aligned with the expectations of the consumer based on the consumer's relationship with the business.</li>
            <li>Comply with a legal obligation.</li>
            <li>Otherwise use the consumer's personal information, internally, in a lawful manner that is compatible with the context in which the consumer provided the information.</li>
        </ol>
    </div>
);