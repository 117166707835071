const devLinks = {
    LANDING: '/',
    LOGIN: '/login',
    SEARCH: '/search',
    TERMS_OF_USE: '/terms-of-use',
    CCPA: '/ccpa-text',
    FAQ: '/faq',
    PRIVACY_POLICY: '/privacy-policy',
    LIST_OWNERS_AND_MAILERS: '/list-owners-and-mailers',
    SITE_MAP: '/site-map',
    MANAGE_MAPPINGS: '/manage-mappings',
    NEWS: '/news',
    ARTICLE: '/news/read/:slug',
    ADMIN_NEWS: '/news/manage',
    ADD_ARTICLE: '/news/add',
    EDIT_ARTICLE: '/news/edit/:slug',
    REGISTER: "/register",
    EDIT_PROFILE: "/profile/edit",
    CREATE_USER: "/create-user",
    CONTACT_US: "/contact-us",
    LIST_OF_CLIENTS: "/list-clients",
    THANK_YOU: "/thank-you",
    RESEND_PASSWORD: '/resend-password',
    RESET_PASSWORD: '/reset-password',
    SEARCH_RESULT: '/search-result',
    UPLOAD_FILE: '/upload-csv',
    SEARCH_RESULTS: '/search-results/:guid',
    SEARCH_RESULTS_BY_ADDRESS_ROUTE: '/address-search-results/:guid',
    SEARCH_RESULTS_BY_ADDRESS: '/address-search-results',
    SEARCH_DELETE_CONFIRMATION: '/delete-opt-out/:token',
    SEARCH_MORE: '/search-more',
    CONFIRM_USER: '/confirm-user',
    UPLOAD_HISTORY: '/upload-history',
    DOWNLOAD_GUIDE: '/ccpa.pdf',
    EDIT_USER: '/edit-user/:userId',
    OPT_OUTS: '/opt-outs',
    SET_PASSWORD: '/set-password',
    ADD_AGREEMENT: '/add-agreement/:userId',
    ALERTS_CONTACTS: '/notification-contacts',
    ALERTS_CENTER: '/notification-center',
    ERROR_404: "/404",
    // ERROR PAGE
    ERROR_PAGE: "/error/:code"
}

const prodLinks = {
    LANDING: '/',
    LOGIN: '/login',
    SEARCH: '/search',
    TERMS_OF_USE: '/terms-of-use/index.html',
    CCPA: '/ccpa-text/index.html',
    FAQ: '/faq/index.html',
    PRIVACY_POLICY: '/privacy-policy/index.html',
    LIST_OWNERS_AND_MAILERS: '/list-owners-and-mailers/index.html',
    SITE_MAP: '/site-map/index.html',
    MANAGE_MAPPINGS: '/manage-mappings',
    NEWS: '/news',
    ARTICLE: '/news/read/:slug',
    ADMIN_NEWS: '/news/manage',
    ADD_ARTICLE: '/news/add',
    EDIT_ARTICLE: '/news/edit/:slug',
    REGISTER: "/register",
    EDIT_PROFILE: "/profile/edit",
    CREATE_USER: "/create-user",
    CONTACT_US: "/contact-us/index.html",
    LIST_OF_CLIENTS: "/list-clients",
    THANK_YOU: "/thank-you",
    RESEND_PASSWORD: '/resend-password',
    RESET_PASSWORD: '/reset-password',
    SEARCH_RESULT: '/search-result',
    UPLOAD_FILE: '/upload-csv',
    SEARCH_RESULTS: '/search-results/:guid',
    SEARCH_RESULTS_BY_ADDRESS_ROUTE: '/address-search-results/:guid',
    SEARCH_RESULTS_BY_ADDRESS: '/address-search-results',
    SEARCH_DELETE_CONFIRMATION: '/delete-opt-out/:token',
    SEARCH_MORE: '/search-more',
    CONFIRM_USER: '/confirm-user',
    UPLOAD_HISTORY: '/upload-history',
    DOWNLOAD_GUIDE: '/ccpa.pdf',
    EDIT_USER: '/edit-user/:userId',
    OPT_OUTS: '/opt-outs',
    SET_PASSWORD: '/set-password',
    ADD_AGREEMENT: '/add-agreement/:userId',
    ALERTS_CONTACTS: '/notification-contacts',
    ALERTS_CENTER: '/notification-center',
    ERROR_404: "/404.html",
    // ERROR PAGE
    ERROR_PAGE: "/error/:code"
}

// List of router links
export const PAGE_LINK = prodLinks;