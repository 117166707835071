import { notification } from "antd";
import { Reducer } from "redux";
import { typedAction } from "../../helpers/action-helper";
import { showErrorNotification } from "../../helpers/notification-helper";
import { AppThunkAction } from '../../store';
import { EditProfileActionTypes } from "./edit-profile-constants";
import EditProfileService from "./edit-profile-service";
import { IEditedProfile, IEditProfileActions, IEditProfileState } from "./edit-profile-types";
import AuthStore, { IAuthUser } from '../../local-store/auth';

let editProfileService = new EditProfileService();

const setIsLoading = (isLoading: boolean) => typedAction(EditProfileActionTypes.setIsLoading, isLoading);
const setIsSuccess = (isSuccess: boolean) => typedAction(EditProfileActionTypes.setIsSuccess, isSuccess);
const setEditedProfile = (profile: IEditedProfile) => typedAction(EditProfileActionTypes.setEditedProfile, profile);

const getProfile = (): AppThunkAction<EditProfileAction> => (dispatch, getState) => {
    dispatch(setIsLoading(true));
    editProfileService.getProfile()
        .then(response => {
            if (!response.is_error) {
                dispatch(setEditedProfile(response.content));

                let currentUser = JSON.parse(AuthStore.getUser()) as IAuthUser;
                currentUser.company = response.content.company;
                currentUser.fullName = `${response.content.firstName} ${response.content.lastName}`;
                currentUser.avatar = response.content.avatar;
                AuthStore.setUser(currentUser);
            } else {
                showErrorNotification(response);
            }
        }).finally(() => dispatch(setIsLoading(false)));
}

const editProfile = (profile: IEditedProfile): AppThunkAction<EditProfileAction> => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    await editProfileService.editProfile(profile)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setIsSuccess(true));
                notification.success({ message: "Profile edited" })
            } else {
                dispatch(setIsSuccess(false));
                showErrorNotification(response);
            }
        }).finally(() => dispatch(setIsLoading(false)));
}

export const editProfileActions: IEditProfileActions = {
    editProfile,
    getProfile,
    setEditedProfile,
    setIsLoading,
    setIsSuccess
}

type EditProfileAction = ReturnType<typeof typedAction>

const unloadedState: IEditProfileState = {
    isLoading: false,
    isSuccess: false,
    editedProfile: {} as IEditedProfile
}

export const reducer: Reducer<IEditProfileState, EditProfileAction> = (state: IEditProfileState = unloadedState, action: EditProfileAction): IEditProfileState => {
    switch (action.type) {
        case EditProfileActionTypes.setIsLoading:
            return {
                ...state,
                isLoading: action.payload as boolean
            }
        case EditProfileActionTypes.setIsSuccess:
            return {
                ...state,
                isSuccess: action.payload as boolean
            }
        case EditProfileActionTypes.setEditedProfile:
            return {
                ...state,
                editedProfile: action.payload as IEditedProfile
            }
        default: return state;
    }
}
