import { Alert, Button, Col, Form, Input, Row, Select, Tabs, Tooltip } from "antd";
import { Store } from "antd/lib/form/interface";
import * as React from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import { QuestionCircleOutlined } from '@ant-design/icons';
import ReCAPTCHA from "react-google-recaptcha";
import { ApplicationState } from "../../store";
import { searchActions } from "./search-reducer";
import { IAddressValue, IMore, ISearchProps, ISearchUser } from "./search-types";
import { withRouter } from "react-router";
import moment from 'moment';
import { DEFAULT_ZERO_VALUE, STRING_EMPTY } from "../../assets/constants/general";
import { AutoCompleteAddress } from './search-address-input';
import _ from "lodash";

import './search.scss';
import { Helmet } from "react-helmet";

const { Item } = Form;
const { TabPane } = Tabs;
const { Option } = Select;

const isPrerender = navigator.userAgent === "ReactSnap";

type ISearchState = {
    captchaValue: string;
    addressValue: string;
    cityValue: string;
    stateValue: string;
    zipValue: string;
    subBuildings?: string[];
    addressSelected: boolean;
}

class SearchPage extends React.Component<ISearchProps & IMore, ISearchState> {
    constructor(props: ISearchProps) {
        super(props);

        this.state = {
            captchaValue: STRING_EMPTY,
            addressValue: STRING_EMPTY,
            cityValue: STRING_EMPTY,
            stateValue: STRING_EMPTY,
            zipValue: STRING_EMPTY,
            subBuildings: [],
            addressSelected: null
        }
    }

    componentDidMount() {
        const { getCaptchaKey } = this.props;
        !isPrerender && getCaptchaKey();
    }

    public onFinishByEmail = (values: Store) => {
        const { email } = values;
        this.props.searchByEmail(_.trim(email));
    };

    public onFinishByUserInfo = (values: Store) => {
        const { addressValue, zipValue, stateValue, cityValue, addressSelected } = this.state;

        const userInfo: ISearchUser = {
            firstName: _.trim(values.firstName),
            lastName: _.trim(values.lastName),
            address: _.trim(addressValue),
            zipCode: _.trim(zipValue),
            suite: _.trim(values.suite),
            city: _.trim(cityValue),
            state: _.trim(stateValue)
        };
        if (addressSelected != null && addressSelected) {
            this.props.searchByUserInfo(userInfo);
        } else if (addressSelected == null) {
            this.setState({ addressSelected: false })
        }
    };

    public onChange = (value: string | null) => {
        this.setState({ captchaValue: value })
    };

    public render() {
        const { isLoading, deletedUser, searchEmail, setSearchEmail, captchaKey, resultsCount } = this.props;
        const { captchaValue, subBuildings, addressSelected } = this.state;
        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            types: {
                email: '${label} is not validate email!',
            }
        };

        const name = deletedUser && (deletedUser.firstName && deletedUser.lastName ? `${deletedUser.firstName} ${deletedUser.lastName}` : deletedUser.email);

        return (
            <div>
                <Helmet>
                    <meta name="description"
                        content="myDataPrivacy.com makes it safe to know what participating List Owners know about you. If your name is found in the database, you will have the option to Opt-Out of receiving future marketing messages and/or Deleting your name altogether from List Owners, Data Brokers or Mailers who participate with this service. First step is to search if you're in the database." />
                </Helmet>
                <div className="d-flex flex-column container-fluid align-items-center">
                    <h1 className="mt-5 mb-3"><strong>Are You in Our Database?</strong></h1>
                    {deletedUser && <h4 className='m-3 deleted-user'>{name}, your record has been deleted on {moment(deletedUser.updatedAt).format('LL')}</h4>}
                    <div className='blue-line'></div>
                    <div className="d-flex search-container">
                        <Row>
                            <Col xs={24} md={24} lg={8}>
                                <div className="d-flex flex-column justify-content-between pl-5 py-5 h-75">
                                    <div className="search-title">What do list owners know about me?</div>
                                    <div className="search-title">For what business purpose was my personal information sold?</div>
                                    <div className="search-title">Can I opt-out or have my information deleted?</div>
                                </div>
                            </Col>
                            <Col xs={24} md={24} lg={6}>
                                <div className="d-flex flex-column p-5">
                                    <Fragment>
                                        <p><strong>myDataPrivacy.com</strong> makes it safe to know what participating list owners know about you. Search our database by entering your email address or name and postal address. If your name is found in the database, you will have the option to opt-out of receiving future marketing messages and/or deleting your name from national databases.</p>
                                    </Fragment>
                                </div>
                            </Col>
                            <Col xs={24} md={24} lg={10}>
                                <Tabs style={{ width: "100%" }} onChange={() => this.setState({ captchaValue: STRING_EMPTY })} className="d-flex p-5" defaultActiveKey="1">
                                    <TabPane tab="Email Address" key="1">
                                        <Form {...layout} name="nest-messages" className='d-flex flex-column w-100' onFinish={this.onFinishByEmail} validateMessages={validateMessages}>
                                            <Item name='email' label="Email Address" rules={[{ type: 'email' }]}>
                                                <Input value={searchEmail} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchEmail(event.target.value)} />
                                            </Item>
                                            {!_.isEmpty(captchaKey) && <ReCAPTCHA onExpired={() => this.setState({ captchaValue: STRING_EMPTY })} sitekey={captchaKey} onChange={this.onChange} />}
                                            <p>The information entered above will not be retained, rented or sold. It is used only to locate you in our database.</p>
                                            {
                                                resultsCount === DEFAULT_ZERO_VALUE && <Alert className="mb-3" message={<>The email address you entered was <strong>Not Found. </strong>
                                                    If there is another email address associated with your personal information, please re-enter and try searching again.
                                                </>} type="warning" />
                                            }
                                            <Item>
                                                <Button disabled={captchaValue === STRING_EMPTY} loading={isLoading} type="primary" htmlType="submit">
                                                    Search
                                                </Button>
                                            </Item>
                                        </Form>
                                    </TabPane>
                                    <TabPane tab="Name & Postal Address" key="2">
                                        <Form {...layout} name="nest-messages" className='d-flex flex-column w-100' onFinish={this.onFinishByUserInfo} validateMessages={validateMessages}>
                                            <div className="d-flex w-100 justify-content-between double-inputs">
                                                <Item name='firstName' label="First Name">
                                                    <Input />
                                                </Item>
                                                <Item name='lastName' label="Last Name">
                                                    <Input />
                                                </Item>
                                            </div>
                                            <Item name='address'
                                                validateStatus={
                                                    (addressSelected == null || addressSelected) ? "" : "error"
                                                }
                                                help={
                                                    (addressSelected == null || addressSelected) ? "" : "Please select the address from the provided list"
                                                }
                                                label={
                                                    <Fragment>
                                                        <span>Postal Address</span>
                                                        <Tooltip title="If your address displays with a 5-digit zip code, and a 9-digit zip code format, please choose the 5 digit zip code option.">
                                                            <QuestionCircleOutlined className='m-2' />
                                                        </Tooltip>
                                                    </Fragment>}>
                                                <AutoCompleteAddress onValueSelect={(value: IAddressValue) => this.setState({ addressValue: value.address, zipValue: value.postalCode, subBuildings: value.subBuildings, cityValue: value.city, stateValue: value.state, addressSelected: true })} />
                                            </Item>
                                            {
                                                subBuildings && subBuildings.length > DEFAULT_ZERO_VALUE && (
                                                    <Item name='suite' label="Unit">
                                                        <Select>
                                                            {
                                                                subBuildings.map((sub, index) => (
                                                                    <Option key={`${sub}_${index}`} value={sub}>{sub}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Item>
                                                )
                                            }
                                            {!_.isEmpty(captchaKey) && <ReCAPTCHA onExpired={() => this.setState({ captchaValue: STRING_EMPTY })} sitekey={captchaKey} onChange={this.onChange} />}
                                            <p>The information entered above will not be retained, rented or sold. It is used only to locate you in our database.</p>

                                            <Item>
                                                <Button disabled={captchaValue === STRING_EMPTY} loading={isLoading} type="primary" htmlType="submit">
                                                    Search
                                                </Button>
                                            </Item>
                                        </Form>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

const connectedSearchPage = connect((state: ApplicationState, ownProps: IMore) => ({ ...state.search, ...ownProps }), searchActions)(withRouter(SearchPage));
export default connectedSearchPage;