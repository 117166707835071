import * as React from "react";
import { connect } from "react-redux";
import { IArticleViewModel, INewsState } from "./news-types";
import * as NewsStore from "./news-reducer";
import { ApplicationState } from "../../store";
import { withUserLayout } from "../../common/user-layout";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { Button, Divider, Form, Input, notification, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FormInstance } from "antd/lib/form";
import { Editor } from "@tinymce/tinymce-react";

import { DEFAULT_ZERO_VALUE, MAX_IMAGE_FILE_SIZE, STRING_EMPTY } from "../../assets/constants/general";
import { editorFormats, editorModules, EDITOR_CONTENT_TYPES, MAX_SHORT_CONTENT_LENGTH, NewsNotifications, ValidationMessages } from "./news-constants";
import { RouteComponentProps } from "react-router";

const { Item } = Form;

type AddArticleProps = RouteComponentProps &
    INewsState &
    typeof NewsStore.actions;

export class AddArticlePage extends React.Component<AddArticleProps> {
    state = {
        image: {} as File,
        tempImageLink: STRING_EMPTY,
        shortContentSymbolsLeft: MAX_SHORT_CONTENT_LENGTH
    };

    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        const { loadCategories } = this.props;

        loadCategories();
    }

    onFinish = (values: IArticleViewModel) => {
        values.image = this.state.image;
        values.title = values.title.trim();
        values.htmlContent = this.getHtmlContent(values.editor);
        this.props.createArticle(values);
    }

    getHtmlContent(editor: any) {
        if (editor) {
            switch (editor.level.type) {
                case EDITOR_CONTENT_TYPES.COMPLETE: {
                    return editor.level.content;
                }
                case EDITOR_CONTENT_TYPES.FRAGMENTED: {
                    return editor.level.fragments.join(STRING_EMPTY);
                }
                default: {
                    return STRING_EMPTY;
                }
            }
        }
        return STRING_EMPTY;
    }

    onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.formRef.current.setFieldsValue({
            slug: this.createSlug(e.target.value),
        });
    };

    onShortContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;

        this.setState({
            shortContentSymbolsLeft: MAX_SHORT_CONTENT_LENGTH - value.length
        })
        if (value.length === MAX_SHORT_CONTENT_LENGTH) {
            notification.error({ message: ValidationMessages.maxSymbolsReached })
        }

    }

    onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[DEFAULT_ZERO_VALUE] as File;

        if (file.size > MAX_IMAGE_FILE_SIZE) {
            this.formRef.current.setFieldsValue({
                image: undefined
            });
            return notification.error({ message: NewsNotifications.imageFileSizeTooLarge })
        }

        this.setState({
            image: file ? file : null,
            tempImageLink: file ? window.URL.createObjectURL(file) : STRING_EMPTY,
        });
    };

    createSlug = (title: string) => {
        return title
            .trim()
            .toLowerCase()
            .replace(/[^\w ]+/g, STRING_EMPTY)
            .replace(/ +/g, "-");
    };

    render() {
        const { isLoading, history, categories } = this.props;

        return (
            <div className="container">
                <Form
                    name="add_article"
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    layout={"vertical"}
                    className="d-flex flex-column flex-wrap align-items-center add-article"
                >
                    <div className="d-flex w-100 justify-content-between double-inputs">
                        <Item
                            name={"title"}
                            rules={[
                                {
                                    required: true,
                                    message: ValidationMessages.title,
                                },
                            ]}
                            label="Title"
                        >
                            <Input
                                placeholder="Article's title"
                                onChange={this.onTitleChange}
                            />
                        </Item>
                        <Item name={"slug"} label="Slug">
                            <Input readOnly />
                        </Item>
                    </div>
                    <Item
                        label={`Short content (${this.state.shortContentSymbolsLeft})`}
                        name={"shortContent"}
                        rules={[
                            {
                                required: true,
                                message: ValidationMessages.shortContent
                            }
                        ]}
                    >
                        <TextArea
                            placeholder="Briefly describe the article's content"
                            maxLength={MAX_SHORT_CONTENT_LENGTH}
                            onChange={this.onShortContentChange}
                            style={{ height: "150px" }}
                        />
                    </Item>
                    <Item
                        label="Category"
                        name="categoryId"
                        rules={[
                            {
                                required: true,
                                message: ValidationMessages.category,
                            },
                        ]}
                    >
                        <Select>
                            {categories.map((elem, i) => {
                                return (
                                    <Select.Option
                                        key={`category_item_${i}`}
                                        value={elem.id as string}
                                    >
                                        {elem.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Item>
                    <Item label="Content" name={"editor"}>
                        <Editor
                            initialValue={STRING_EMPTY}
                            init={{
                                height: 400,
                                menubar: true,
                                plugins: editorModules,
                                toolbar: editorFormats,
                            }}
                        />
                    </Item>
                    <Item
                        label="Image"
                        name={"image"}
                        rules={[
                            {
                                required: true,
                                message: ValidationMessages.image,
                            },
                        ]}
                    >
                        <div>
                            <img
                                className="img-fluid"
                                style={{
                                    display:
                                        this.state.tempImageLink.length > DEFAULT_ZERO_VALUE
                                            ? "initial"
                                            : "none",
                                }}
                                src={this.state.tempImageLink}
                            />
                            <div className="d-flex ">
                                <Button
                                    htmlType="button"
                                    shape="round"
                                    className="btn btn-outline-primary btn-lg w-auto "
                                >
                                    Add image
                                    <Input
                                        onChange={this.onImageChange}
                                        accept="image/*"
                                        type="file"
                                        name="image"
                                        className="upload-input"
                                    />
                                </Button>
                            </div>
                        </div>
                    </Item>
                    <Divider />
                    <Item>
                        <div className="d-flex justify-content-between w-100">
                            <div className="align-right">
                                <Button
                                    htmlType="submit"
                                    loading={isLoading}
                                    shape="round"

                                    className="btn btn-primary w-auto "
                                >
                                    Create
                                </Button>
                            </div>
                            <div>
                                <Button
                                    htmlType="button"
                                    shape="round"

                                    className="btn btn-outline-primary w-auto"
                                    onClick={() => {
                                        history.push(PAGE_LINK.ADMIN_NEWS);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Item>
                </Form>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.news,
    NewsStore.actions
)(withUserLayout(AddArticlePage as any, PAGE_LINK.ADD_ARTICLE));