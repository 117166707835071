import * as React from 'react';
import { Form, Input, Button, Select, Row, Col, Alert } from 'antd';
import { IOrderField, IOrderFieldsModel, IOrderModel } from './upload-types';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as UploadStore from "./reducer"
import { OrderDefautlValues, stepSize } from './upload-constats';
import { FormInstance } from 'antd/lib/form';

const { Option } = Select;

type ICreateOrderProps = {
    businessPurposes: IOrderField[];
    thirdPartyCategories: IOrderField[];
    collectionSources: IOrderField[];
    selectedValues: any;
    current: number;
} & typeof UploadStore.uploadActions;

class CreateOrder extends React.Component<ICreateOrderProps>{

    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        this.props.getDataForUpload();
    }

    componentDidUpdate() {
        const { thirdPartyCategories, businessPurposes, collectionSources, selectedValues } = this.props;

        let initialValues = {
            thirdPartyCategory: null as any,
            businessPurpose: null as any,
            collectionSources: [] as Array<any>
        }

        if (thirdPartyCategories && businessPurposes && collectionSources) {
            if (!selectedValues.referenceNumber) {
                thirdPartyCategories.forEach(item => {
                    if (item.name == OrderDefautlValues.thirdPartyCategory) {
                        initialValues.thirdPartyCategory = item.id;
                    }
                });

                businessPurposes.forEach(item => {
                    if (item.name == OrderDefautlValues.businessPurpose) {
                        initialValues.businessPurpose = item.id;
                    }
                });

                collectionSources.forEach(item => {
                    if (OrderDefautlValues.collectionSources.includes(item.name)) {
                        initialValues.collectionSources.push(item.id);
                    }
                });
                this.formRef.current.setFieldsValue(initialValues);
            }
        }
    }

    createOrder = (values: any) => {
        const { setOrder, current, setCurrentStep } = this.props;

        const model = {
            referenceNumber: values.referenceNumber,
            thirdPartyCategory: values.thirdPartyCategory,
            businessPurpose: values.businessPurpose,
            collectionSources: values.collectionSources,

        } as IOrderModel;

        setOrder(model);
        setCurrentStep(current + stepSize);
    }

    render() {
        const { thirdPartyCategories, businessPurposes, collectionSources, selectedValues } = this.props;

        const thirdPartyCategorySelect = thirdPartyCategories.map((item) => {
            return <Option value={item.id} key={item.id}>{item.name}</Option>
        });

        const businessPurposeSelect = businessPurposes.map((item) => {
            return <Option value={item.id} key={item.id}>{item.name}</Option>
        });

        const collectionSourcesSelect = collectionSources.map((item) => {
            return <Option value={item.id} key={item.id}>{item.name}</Option>
        });

        return (
            <React.Fragment>
                <Row className="mb-5">
                    <Col xs={24} md={12}>
                        <div className="container" >
                            <Form
                                className="mb-0"
                                name="createOrderForm"
                                onFinish={this.createOrder}
                                ref={this.formRef}
                                layout="vertical"
                                initialValues={selectedValues && selectedValues}
                            >
                                <Form.Item
                                    name="referenceNumber"
                                    rules={[{ required: true, message: 'Please input your Reference (Order) Number!' }]}
                                >
                                    <Input placeholder="Reference (Order) Number" />
                                </Form.Item>
                                <Form.Item
                                    name="thirdPartyCategory"
                                    rules={[{ required: true, message: 'Please select Data Shared with 3rd Party Categories!' }]}
                                >
                                    <Select placeholder="Data Shared with 3rd Party Categories" allowClear>
                                        {thirdPartyCategorySelect}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="businessPurpose"
                                    rules={[{ required: true, message: 'Please select Business Purpose for Collecting/Selling Data!' }]}
                                >
                                    <Select placeholder="Business Purpose for Collecting/Selling Data" allowClear>
                                        {businessPurposeSelect}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0 }}
                                    name="collectionSources"
                                    rules={[{ required: true, message: 'Please select Data was Collected From!' }]}
                                >
                                    <Select maxTagCount="responsive" maxTagPlaceholder={(omittedValues) => <>and {omittedValues.length} more selected</>}
                                        placeholder="Data was Collected From" mode="multiple" showArrow={true} showSearch={false} >
                                        {collectionSourcesSelect}
                                    </Select>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="container" style={{ height: "100%" }}>
                            <div className="info-box" style={{ textAlign: "left" }}>
                                <ul>
                                    <li>All files must be in .csv, .txt, .xls, xlsx, or .dbf format and must contain header rows.</li>
                                    <li>All files must be standardized .csv, .txt, .xls, xlsx, or .dbf format without custom delimiters and wrappers</li>
                                    <li>All files must have first name and last name in separate fields.</li>
                                    <li>PO Box addresses are not searchable by consumers.</li>
                                    <li>This tool does not support Internet Explorer.</li>
                                    <li>For files larger than 2.0GB or with column headers longer than 64 characters, please contact us to set up secure FTP for file upload.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="d-flex justify-content-end"><Button onClick={() => this.formRef.current.submit()} className="next_btn" shape="round" type="primary">Next</Button> </div>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            businessPurposes: state.upload.dataForUpload.businessPurposes,
            collectionSources: state.upload.dataForUpload.collectionSources,
            thirdPartyCategories: state.upload.dataForUpload.thirdPartyCategories,
            selectedValues: {
                referenceNumber: state.upload.orderModel.referenceNumber,
                collectionSources: state.upload.orderModel.collectionSources,
                businessPurpose: state.upload.orderModel.businessPurpose,
                thirdPartyCategory: state.upload.orderModel.thirdPartyCategory,
            },
            current: state.upload.current
        };
    },
    UploadStore.uploadActions
)(CreateOrder);