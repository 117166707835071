import * as React from "react";
import { STRING_EMPTY } from "../constants/general";

type InfoCardProps = { icon: string, alt: string, description: string, className?: string };

export const InfoCard = ({ icon, alt, description, className = STRING_EMPTY }: InfoCardProps) => {
    return (
        <div className={`d-flex flex-column info-card ${className}`}>
            <div className="item">
                <img src={icon} alt={alt} />
            </div>
            <div className="title">{description}</div>
        </div>
    )
}