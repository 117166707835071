import { IAlertsActionTypes } from "./alerts-types";

export enum NOTIFY_TYPE {
    EMAIL = 'Email',
    PHONE = 'Phone'
}

export const EMAIL = "Email";
export const PHONE = "Phone";
export const ALERT_TYPE = EMAIL || PHONE;

export const AlertsActionTypes: IAlertsActionTypes = {
    setIsLoading: 'SET_ALERTS_IS_LOADING',
    setAlertContacts: 'SET_ALERTS_CONTACTS',
    setIsCodeSent: 'SET_IS_CODE_SENT',
    setSentAlertContactId: 'SET_SENT_ALERT_CONTACT_ID',
    setSentAlertContact: 'SET_SENT_ALERT_CONTACT',
    setVerificationCode: 'SET_VERIFICATION_CODE',
    setAlerts: 'SET_ALERTS',
    setAlertsPagination: 'SET_ALERTS_PAGINATION',
    setAlertsIsLoading: 'SET_ALERTS_IS_LOADING',
    setTargetTime: 'SET_TARGET_TIME',
    setCurrentNotifyType: 'SET_CURRENT_NOTIFY_TYPE',
    setCurrentValue: 'SET_CURRENT_VALUE'
}