import * as React from "react";
import "./news.scss";
import { IArticle } from "./news-types";
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
} from "react-share";

import { ShareAltOutlined } from "@ant-design/icons";

class ShareArticleBlock extends React.PureComponent<IArticle> {

    public render() {
        const { slug, shortContent, title } = this.props;
        const pageLink = window.location.href;

        return (
            <div className="share-article-block">
                <div className="label">
                    <ShareAltOutlined />
                    Share article
                </div>
                <FacebookShareButton url={pageLink}>
                    <FacebookIcon />
                </FacebookShareButton>
                <LinkedinShareButton
                    title={title}
                    summary={shortContent}
                    url={pageLink}
                >
                    <LinkedinIcon />
                </LinkedinShareButton>
                <TwitterShareButton title={title} url={pageLink}>
                    <TwitterIcon />
                </TwitterShareButton>
            </div>
        );
    }
}

export default ShareArticleBlock;