import * as React from 'react';
import { Modal, Button } from 'antd';
import "../../../assets/styles/colors.scss"

export interface Props {
    show: boolean;
    onClose: () => void;
}

const FinishModal: React.FunctionComponent<Props> = ({ show = false, onClose }) => (
    <Modal
        className="upload-modal"
        centered
        visible={show}
        footer={null}
        closable={false}
    >
        <h2>File Upload Successful!</h2>
        <p>
            Your file has been uploaded to our database.
            All personally Identifiable Information (PII) contained within your dataset has been intentionally masked and the original PII data has been deleted from our system.
            Your file is now in queue to be CASS formatted for address standardization. This process may take minutes or hours.
            See “Upload History” for uploading status.
        </p>
        <Button type="primary" block size="large" onClick={onClose}>
            <strong>OKAY</strong>
        </Button>
    </Modal>
)

export default FinishModal;