import * as React from "react";
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import * as AuthStore from "./reducer";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { Form, Input, Button, Checkbox } from "antd";
import { IAuthState } from "./auth-types";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { withFormLayout } from "../../common/form-layout";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import LocalAuthStore from "../../local-store/auth"
import * as _ from 'lodash';
import "./auth.scss";
import { Helmet } from "react-helmet";

const { Item } = Form;

let authStyle = require('../../assets/styles/auth.styl');

type AuthProps = IAuthState
    & typeof AuthStore.signInActions
    & RouteComponentProps<any>

const SignIn = (props: AuthProps) => {
    const [form] = Form.useForm();

    const { signIn, forgotPassword, isLoading, setSignInEmail, signInEmail, signInPassword, signInRemember, setSignInPassword, setSignInRemember } = props;
    const validateMessages = {
        required: "Please input your ${name}!",
        types: {
            email: '${name} is not validate email!',
        }
    };
    const loginIsAvailable = !_.isEmpty(signInEmail) && !_.isEmpty(signInPassword);

    const onForgotPassword = () => {
        form.validateFields([
            'email'
        ])
        forgotPassword();
    }

    return (
        LocalAuthStore.getToken() ? <Redirect to="/" /> :
            <React.Fragment>
                <Helmet>
                    <title>Log in to Your Account</title>
                    <meta name="description"
                        content="Login to your My Data Privacy account" />

                </Helmet>
                <Form form={form}
                    name="signin"
                    layout={"vertical"}
                    validateTrigger="onBlur"
                    className='d-flex flex-column flex-wrap align-items-center auth-page mt-5'
                    validateMessages={validateMessages}>
                    <h1 className="">Log in to Your Account</h1>
                    <h5 className="mb-5">Don't have an account? <Link to={PAGE_LINK.REGISTER}>Sign Up!</Link></h5>
                    <div className="px-5">
                        <Item name="email" rules={[{ type: 'email', required: true }]}>
                            <Input placeholder="Email address" value={signInEmail} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSignInEmail(event.target.value)} />
                        </Item>
                        <Item rules={[{ required: true, message: 'Please input your password!' }, { min: 8 }]}>
                            <Input type="password" placeholder="Password" value={signInPassword} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSignInPassword(event.target.value)} />
                        </Item>
                        <Item>
                            <Button shape="round" disabled={!loginIsAvailable} onClick={signIn} loading={isLoading} type="primary" size="large" htmlType="submit">
                                Login
                            </Button>
                        </Item>
                        <div className="d-flex w-100 justify-content-center" style={{ height: "25px" }}>
                            <a className='email-link' onClick={onForgotPassword}>Forgot password</a>
                        </div>
                    </div>
                </Form >
            </React.Fragment>
    );
}

export default connect(
    (state: ApplicationState) => state.auth,
    AuthStore.signInActions)(withFormLayout(SignIn));