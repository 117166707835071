import { Moment } from "moment";
import { IPagination } from "../../../assets/global/types";
import { DELETED_TYPE, OPTED_OUT_TYPE } from "./opt-out-constants";

export enum IOptOutType {
    optOut = 'optOut',
    delete = 'delete',
    both = 'both'
}

export interface IOptOutModel {
    optOuts: IOptOutItem[];
    pagination: IPagination;
}

export interface IOptOutItem {
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    email: string;
    date: Moment;
    ipAddress: string;
    isDeleted?: boolean;
    type?: string;
    orderNumber?: string;
    fileUploadingDate?: Moment;
}

export class OptOutItem implements IOptOutItem {
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    email: string;
    date: Moment;
    ipAddress: string;
    type?: string;
    orderNumber?: string;
    fileUploadingDate?: Moment;

    constructor(item: IOptOutItem) {
        this.firstName = item.firstName;
        this.lastName = item.lastName;
        this.address = item.address;
        this.city = item.city;
        this.state = item.state;
        this.zip = item.zip;
        this.email = item.email;
        this.date = item.date;
        this.ipAddress = item.ipAddress;
        this.type = item.isDeleted ? DELETED_TYPE : OPTED_OUT_TYPE;
        this.orderNumber = item?.orderNumber;
        this.fileUploadingDate = item?.fileUploadingDate;
    }
}

export interface IOptOutState {
    userOptOutsType: IOptOutType;
    userOptOuts: IOptOutItem[];
    userOptOutsPagination: IPagination;
    userOptOutsIsLoading: boolean;
}

export interface IOptOutActions {
    setUserOptOuts: (uploads: any[]) => void;
    setUserOptOutsIsLoading: (isLoading: boolean) => void;
    setUserOptOutsPagination: (pagination: IPagination) => void;
    setUserOptOutsType: (type: IOptOutType) => void;

    getOptOuts: () => void;
    downloadOuts: () => void;
    importDocument: (type: string, file: File) => void;
}