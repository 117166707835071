import { IConfig, CONFIG_PROPERTIES } from "./config-types";

class ConfigManager {

    // getters
    static azureStorage = (): string => {
        return localStorage.getItem(CONFIG_PROPERTIES.BLOB_URL);
    }
    static articlesDirectory = (): string => {
        return localStorage.getItem(CONFIG_PROPERTIES.ARTICLES_DIRECTORY);
    }
    static articleImagesDirectory = (): string => {
        return localStorage.getItem(CONFIG_PROPERTIES.ARTICLE_IMAGES_DIRECTORY);
    }
    static newsContainer = (): string => {
        return localStorage.getItem(CONFIG_PROPERTIES.NEWS_CONTAINER);
    }
    static profilesContainer = (): string => {
        return localStorage.getItem(CONFIG_PROPERTIES.PROFILES_CONTAINER);
    }
    static avatarsDirectory = (): string => {
        return localStorage.getItem(CONFIG_PROPERTIES.AVATARS_DIRECTORY);
    }
    static mainCompany = (): string => {
        return localStorage.getItem(CONFIG_PROPERTIES.MAIN_COMPANY);
    }
    // setter
    setConfigs = (data: IConfig) => {
        localStorage.setItem(CONFIG_PROPERTIES.BLOB_URL, data.blobUrl);
        localStorage.setItem(CONFIG_PROPERTIES.ARTICLES_DIRECTORY, data.articlesDirectory);
        localStorage.setItem(CONFIG_PROPERTIES.ARTICLE_IMAGES_DIRECTORY, data.articleImagesDirectory);
        localStorage.setItem(CONFIG_PROPERTIES.NEWS_CONTAINER, data.newsContainer);
        localStorage.setItem(CONFIG_PROPERTIES.MAIN_COMPANY, data.mainCompany);
        localStorage.setItem(CONFIG_PROPERTIES.PROFILES_CONTAINER, data.profilesContainer);
        localStorage.setItem(CONFIG_PROPERTIES.AVATARS_DIRECTORY, data.avatarsDirectory);
        localStorage.setItem(CONFIG_PROPERTIES.CONGIGS_SET, "1");
    }
}

export default ConfigManager;