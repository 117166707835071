import { Button, Card, Form, Input } from 'antd';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as SetPasswordStore from "./reducer";
import { IPasswordModel, ISetPasswordState } from './set-password-types';
import { withFormLayout } from '../../common/form-layout';
import { Link } from 'react-router-dom';
import { PAGE_LINK } from '../../assets/constants/page-links';
import { passwordRegexp, passwordRegexpMessage } from "../../helpers/regexp";
import "../auth/auth.scss";

const { Item } = Form;

type SetPasswordProps = RouteComponentProps<any>
    & ISetPasswordState
    & typeof SetPasswordStore.setPasswordActions

class SetPassword extends React.Component<SetPasswordProps> {

    onSetPassword = (values: IPasswordModel) => {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let email = url.searchParams.get("email");
        let confirmToken = url.searchParams.get("confirmToken");

        this.props.setPassword({ ...values, email, confirmToken } as IPasswordModel)
    }

    render() {
        const { isLoading } = this.props;

        return (
            <Form onFinish={this.onSetPassword}
                validateTrigger="onBlur"
                layout={"vertical"}
                name="set-password"
                className='d-flex flex-column align-items-center auth-page w-100'>
                <h2 className="mb-4">Please set your password</h2>
                <h5 className="mb-4">Back to <Link to={PAGE_LINK.LOGIN}>Login</Link></h5>
                <div className="px-5">
                    <Item name={'password'}
                        rules={[
                            {
                                pattern: passwordRegexp,
                                message: passwordRegexpMessage
                            },
                            {
                                required: true,
                                message: 'Please input your password!'
                            }]}>
                        <Input type="password" placeholder="Password" />
                    </Item>
                    <Item name={'confirmPassword'}
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!'
                            },
                            ({ getFieldValue }) => ({
                                validator(_rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            })]}>
                        <Input type="password" placeholder="Confirm Password" />
                    </Item>
                    <Item>
                        <Button htmlType="submit" size="large" type="primary" loading={isLoading}>Save</Button>
                    </Item>
                </div>

            </Form>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.setPassword,
    SetPasswordStore.setPasswordActions
)(withFormLayout(SetPassword));