import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withLayout } from "../../common/layout";
import { ApplicationState } from "../../store";
import { searchActions } from "./search-reducer";
import { ISearchProps, ISearchResponseInfo, SearchResponseInfo } from "./search-types";
import LoadingOverlay from 'react-loading-overlay';
import moment from "moment";
import { Button, Checkbox, Col, Row, Table, Tooltip } from "antd";
import { DEFAULT_ZERO_VALUE } from "../../assets/constants/general";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { deleteModal, formatDataSource, hideEmail, optOutModal, searchDescription, searchResultsTableColumns, thankYouModal } from "./search-helper";
import * as _ from 'lodash';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { PAGE_LINK } from "../../assets/constants/page-links";
import { CustomType, HeaderType, SearchResponseInfoTitles } from "./search-constants";

import './search.scss';

class SearchResultsByAddressPage extends React.Component<ISearchProps, {}> {
    public async componentDidMount() {
        const { match: { params: { guid } }, loadSearchResults, loadSearchHistory } = this.props;

        await loadSearchResults(guid);
        await loadSearchHistory(guid);
    }

    public render() {
        const {
            match: { params: { guid } }, searchHeaders,
            searchResults, isLoading, showDeleteModal,
            optOutInfo, setShowDeletedModal, showOptOutModal, searchHeadersPII,
            setShowOptOutModal, showThankYouModal, setShowThankYouModal,
            optouts, sendDeleteEmail, setDeleteEmail, expandedResults,
            updateExpanded, setSelectedGroup, selectedGroups, searchUser, deleteEmail,

        } = this.props;
        const searchResultsForShow = _.keys(searchResults).filter(x => selectedGroups.find(g => g.group === x)?.value);
        const resultsCount = searchResultsForShow.map(a => searchResults[a].length).reduce((a, b) => a + b, DEFAULT_ZERO_VALUE);
        const searchString = searchUser && `${searchUser.firstName} ${searchUser.lastName}, ${searchUser.city}, ${searchUser.state}, ${searchUser.address}, ${searchUser.zipCode}`;
        const info = (
            <Tooltip title={searchDescription}>
                <QuestionCircleOutlined className='m-2' />
            </Tooltip>
        );
        const showInfo = (searchResultKey: string, index: number) => expandedResults.find(x => x.group === searchResultKey && x.index === index)?.expanded;

        return (
            <div className="d-flex">
                <LoadingOverlay className="search-page d-flex flex-column justify-content-start align-items-center w-100 p-4" active={isLoading} spinner>
                    {deleteModal(showDeleteModal, deleteEmail, setShowDeletedModal, setDeleteEmail, () => { setShowDeletedModal(); sendDeleteEmail(); })}
                    {optOutModal(showOptOutModal, setShowOptOutModal, () => optOutInfo(guid), isLoading)}
                    {thankYouModal(showThankYouModal, setShowThankYouModal)}
                    <Row>
                        {
                            _.keys(searchResults).length > DEFAULT_ZERO_VALUE && (
                                <Col xs={24} md={6} lg={6}>
                                    <div className="search-menu d-flex flex-column m-3">
                                        {
                                            _.keys(searchResults).map(group => (
                                                <Checkbox key={`group_checkbox_${group}`}
                                                    checked={selectedGroups.find(x => x.group === group)?.value}
                                                    onChange={(e: CheckboxChangeEvent) => setSelectedGroup(group, e.target.checked)}>
                                                    <div className="d-flex w-100 justify-content-between">
                                                        <span>{group}</span>
                                                        <span className='ml-3'>{searchResults[group]?.length}</span>
                                                    </div>
                                                </Checkbox>
                                            ))
                                        }
                                    </div>
                                </Col>
                            )
                        }
                        <Col xs={24} md={18} lg={18}>
                            <div className="d-flex flex-column m-3">
                                {!isLoading && searchString && <h2> Search by "{searchString}" {resultsCount > DEFAULT_ZERO_VALUE ? <>produced <strong>{resultsCount} results</strong></> : <strong>Not Found</strong>} {resultsCount > DEFAULT_ZERO_VALUE && `with the following information` && info}</h2>}
                                {
                                    !isLoading && resultsCount === DEFAULT_ZERO_VALUE && (
                                        <div className='d-flex flex-column align-items-center'>
                                            <Button className='search-button' href={PAGE_LINK.LANDING}>Go To Main page</Button>
                                        </div>
                                    )
                                }
                                {
                                    searchResultsForShow
                                        .map((searchResultKey, idx) => (
                                            <div key={`search_result_${searchResultKey}_${idx}`} className='d-flex flex-column'>
                                                {
                                                    searchResults[searchResultKey]
                                                        .map((search, index) => {
                                                            const { uploadedAt, firstName, lastName, zip, address, email } = search;
                                                            const userInfo: ISearchResponseInfo = new SearchResponseInfo(search as ISearchResponseInfo);
                                                            const dataSource = formatDataSource({ key: `_${searchResultKey}_${idx}_${index}`, ...search, searchHeaders });
                                                            const headersPII: { [key: string]: string } = JSON.parse(search.headerElementsPII);

                                                            return (
                                                                <div key={`search_result_${searchResultKey}_${idx}_${index}`}
                                                                    className="d-flex flex-column justify-content-start align-items-start w-100 m-4 card-shadow p-3">
                                                                    <div className="d-flex flex-column mb-4">
                                                                        <h3>{searchResultKey}</h3>
                                                                        <small className='text-lowercase text-muted'>{moment(uploadedAt).format('LLLL')}</small>
                                                                    </div>
                                                                    <span><strong>{firstName} {lastName}</strong>, {zip || '-'}, {address || '-'}, <span className='email-link'>{hideEmail(email)}</span></span>
                                                                    {
                                                                        _.keys(headersPII).length > DEFAULT_ZERO_VALUE && (
                                                                            <span>Personally Identifiable Information
                                                                                {
                                                                                    showInfo(searchResultKey, index) ? (
                                                                                        <div className="d-flex flex-column text-muted">
                                                                                            {
                                                                                                _.keys(headersPII).map(key => {
                                                                                                    return (<div key={key}>{searchHeaders[key] || key}: {headersPII[key]}</div>)
                                                                                                })
                                                                                            }
                                                                                            <span onClick={() => updateExpanded(searchResultKey, index)} className='email-link text-uppercase'>Less</span>
                                                                                        </div>
                                                                                    ) : (
                                                                                            _.keys(headersPII).length > DEFAULT_ZERO_VALUE && <span onClick={() => updateExpanded(searchResultKey, index)} className='email-link text-uppercase ml-1'>More</span>
                                                                                        )
                                                                                }
                                                                            </span>
                                                                        )
                                                                    }

                                                                    <Table dataSource={dataSource}
                                                                        columns={searchResultsTableColumns}
                                                                        pagination={false} />
                                                                </div>
                                                            )
                                                        })
                                                }
                                                {
                                                    optouts[searchResultKey] && !optouts[searchResultKey].requestedToDelete ? (
                                                        <div className="d-flex flex-column ml-4 mb-4 question">
                                                            <span><strong>Opt-Out Status:</strong></span>
                                                            <span>Opted-Out requested {moment(optouts[searchResultKey].createdAt).format('LL')} from IP Address {optouts[searchResultKey].requestedByIp} at {moment(optouts[searchResultKey].createdAt).format('LT')}</span>
                                                        </div>
                                                    ) : (
                                                            <div className='ml-4 mt-4 question'>Do you wish to Opt-Out from future marketing by this List Owner/Mailer? <span onClick={() => setShowOptOutModal(searchResultKey)} className='email-link'>YES</span></div>
                                                        )
                                                }
                                                <div className='ml-4 question'>Do you wish to have your record Deleted by this List Owner/Mailer? <span onClick={() => setShowDeletedModal(searchResultKey)} className='email-link'>YES</span></div>
                                            </div>
                                        )
                                        )
                                }
                            </div>
                        </Col>
                    </Row>
                </LoadingOverlay>
            </div >
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({ ...state.search });

const connectedSearchPage = connect(mapStateToProps, searchActions)(withLayout(withRouter(SearchResultsByAddressPage)));
export { connectedSearchPage as SearchResultsByAddressPage };