import { Reducer } from "redux";
import AddAgreementService from "./add-agreement-service";
import { typedAction } from "../../../helpers/action-helper";
import { AddAgreementActionTypes } from "./add-agreement-constants";
import { IAddAgreementActions, IAddAgreementState, IAgreement, IAgreementModel } from "./add-agreement-types";
import { AppThunkAction } from '../../../store';
import { notification } from "antd";
import { showErrorNotification } from "../../../helpers/notification-helper";
import { history } from "../../../helpers/history-helper"
import { PAGE_LINK } from "../../../assets/constants/page-links";

let addAgreementService = new AddAgreementService();

const setIsLoading = (isLoading: boolean) => typedAction(AddAgreementActionTypes.setIsLoading, isLoading);
const setIsSuccess = (isSuccess: boolean) => typedAction(AddAgreementActionTypes.setIsSuccess, isSuccess);
const setAgreement = (agreement: IAgreement) => typedAction(AddAgreementActionTypes.setAgreement, agreement);

const addOrEditAgreement = (model: IAgreementModel): AppThunkAction<AddAgreementAction> => (dispatch) => {
    dispatch(setIsLoading(true));
    addAgreementService.addOrEdit(model)
        .then((response) => {
            if (response.ok) {
                dispatch(setIsSuccess(true));
                notification.success({ message: "Agreement added!" });
                history.push(PAGE_LINK.LIST_OF_CLIENTS);
            } else {
                response.json().then(error => {
                    dispatch(setIsSuccess(false));
                    notification.error({ message: error.message })
                })
            }
        })
        .finally(() => dispatch(setIsLoading(false)));
}

const getAgreement = (userId: number): AppThunkAction<AddAgreementAction> => (dispatch) => {
    dispatch(setIsLoading(true));

    addAgreementService.get(userId)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setAgreement(response.content));
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
}

const deleteAgreement = (userId: number): AppThunkAction<AddAgreementAction> => (dispatch) => {
    dispatch(setIsLoading(true));

    addAgreementService.delete(userId)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setIsSuccess(true));
                notification.success({ message: "Agreement deleted!" });
                history.push(PAGE_LINK.LIST_OF_CLIENTS);
            } else {
                dispatch(setIsSuccess(false));
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setIsLoading(false)))
}

type AddAgreementAction = ReturnType<typeof typedAction>

const unloadedState: IAddAgreementState = {
    isLoading: false,
    isSuccess: false,
    editedAgreement: {
        agreementDate: null,
        type: null,
        userId: 0,
        fileName: null,
        id: null
    }
}

export const addAgreementActions: IAddAgreementActions = {
    setIsLoading,
    setIsSuccess,
    addOrEditAgreement,
    getAgreement,
    setAgreement,
    deleteAgreement
}

export const reducer: Reducer<IAddAgreementState, AddAgreementAction> = (state: IAddAgreementState = unloadedState, action: AddAgreementAction): IAddAgreementState => {
    switch (action.type) {
        case AddAgreementActionTypes.setIsLoading:
            return {
                ...state,
                isLoading: action.payload as boolean
            }
        case AddAgreementActionTypes.setIsSuccess:
            return {
                ...state,
                isSuccess: action.payload as boolean
            }
        case AddAgreementActionTypes.setAgreement:
            return {
                ...state,
                editedAgreement: action.payload as IAgreement
            }
        default: return state;
    }
}