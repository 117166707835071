import * as _ from 'lodash';

export function groupByPropertyName<P extends object>(array: P[], property: string) {
    return _.mapValues(_.groupBy(array, property), list => list);
}

export function groupByPropertyNameAndFunc<P extends object>(array: P[], property: string, func: (p: P[]) => any) {
    return _.mapValues(_.groupBy(array, property), func);
}

export function groupByPropertyNameToPair<P extends object>(array: P[], property: string, valueProperty: string) {
    return _.mapValues(_.keyBy(array, property), valueProperty);
}