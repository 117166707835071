import * as React from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { ApplicationState } from "../../store";
import * as AlertsStore from './alerts-reducer';
import { IAlertItem, IAlertsState } from './alerts-types';
import { PAGE_SIZE_OPTIONS } from '../../assets/constants/general';
import { PAGE_LINK } from '../../assets/constants/page-links';
import { withUserLayout } from '../../common/user-layout';
import ip from '../../assets/images/ip.svg';
import _ from 'lodash';

type AlertsProps = IAlertsState
    & typeof AlertsStore.actions;

export class AlertsCenterPage extends React.PureComponent<AlertsProps>{
    componentDidMount() {
        const { getAlerts } = this.props;

        getAlerts();
    }

    onChange = (pagination: TablePaginationConfig, _filters: any, sorter: SorterResult<IAlertItem>) => {
        const { getAlerts, setAlertsPagination, alertsPagination } = this.props;

        setAlertsPagination({
            ...alertsPagination,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sortCriteria: sorter.columnKey?.toString(),
            isAscend: sorter.order === 'ascend'
        });
        getAlerts();
    }

    onShowSizeChange = (current: number, pageSize: number) => {
        const { getAlerts, setAlertsPagination, alertsPagination } = this.props;

        setAlertsPagination({ ...alertsPagination, pageNumber: current, pageSize });
        getAlerts();
    }

    render() {
        const { alerts, alertsPagination, alertsIsLoading } = this.props;
        const columns = [{
            title: 'Contact Details',
            dataIndex: 'contactDetails',
            key: 'contactDetails',
            width: '15%'
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '15%'
        }, {
            title: 'Upload Data',
            dataIndex: 'uploadName',
            key: 'uploadName',
            render: (_text: string, { uploadName, uploadDate }: IAlertItem) => (
                <div className="d-flex flex-column">
                    <span>{uploadName}</span>
                    <span>{moment(uploadDate).format('MMM Do YYYY')} at {moment(uploadDate).format('LT')}</span>
                </div>
            ),
            width: '20%'
        }, {
            title: 'Opt-Out / Delete Info',
            dataIndex: 'optOutDate',
            key: 'optOutDate',
            render: (_text: string, { isDelete, optOutDate, optOutIPAddress }: IAlertItem) => (
                <div className="d-flex w-100 justify-content-between">
                    <span>{ isDelete ? 'Delete' : 'Opt-Out'}</span>
                    <div>
                        {moment(optOutDate).format('MMM Do YYYY')} at {moment(optOutDate).format('LT')}
                        { !_.isEmpty(optOutIPAddress) && <Tooltip title={optOutIPAddress}><img style={{ width: '24px' }} src={ip} alt='IP Address' /></Tooltip> }
                    </div>
                </div>
            ),
            width: '35%'
        }, {
            title: 'Alerts',
            dataIndex: 'alertsDescription',
            key: 'alertsDescription',
            render: (_text: string, { isDelete }: IAlertItem) => (
                <div className="d-flex w-100 justify-content-between">
                    <span>The contact previously { isDelete ? 'deleted' : 'opted out'}.</span>
                </div>
            ),
            width: '15%'
        }];

        return (
            <LoadingOverlay className="alerts-center-page d-flex flex-column justify-content-start align-items-center w-100 p-4" active={alertsIsLoading} spinner>
                <Table dataSource={alerts.map((x, idx) => ({ ...x, key: `alerts_center_item_${idx}` }))}
                    columns={columns}
                    pagination={{
                        current: alertsPagination.pageNumber,
                        pageSize: alertsPagination.pageSize,
                        total: alertsPagination.total,
                        onShowSizeChange: this.onShowSizeChange,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: PAGE_SIZE_OPTIONS
                    }}
                    onChange={this.onChange}
                    className='w-100' />
            </LoadingOverlay>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.alert,
    AlertsStore.actions)(withUserLayout(AlertsCenterPage as any, PAGE_LINK.ALERTS_CENTER));