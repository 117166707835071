import { ISearchActionTypes } from './search-types';

export const SearchActionTypes: ISearchActionTypes = {
    setLoading: 'SET_LOADED_SEARCH_PAGE',
    setSearchEmail: 'SET_SEARCH_EMAIL',
    setResultsCount: 'SET_SEARCH_RESULTS_COUNT',
    setOptOutEmailSent: 'SET_OPT_OUT_EMAIL_SENT',
    setSearchResults: 'SET_SEARCH_RESULTS',
    setSelectedGroup: 'SET_SELECTED_GROUP',
    setSelectedGroups: 'SET_SELECTED_GROUPS',
    setSearchUser: 'SET_SEARCH_USER',
    setShowDeletedModal: 'SET_SHOW_DELETED_MODAL',
    setShowThankYouModal: 'SET_SHOW_THANK_YOU_MODAL',
    setDeletedUser: 'SET_DELETED_USER',
    setExpandedResults: 'SET_EXPANDED_RESULTS',
    updateExpanded: 'UPDATE_EXPANDED',
    setShowOptOutModal: 'SET_SHOW_OPT_OUT_MODAL',
    setOptOuts: 'SET_OPT_OUTS',
    setDeleteEmail: 'SET_DELETE_EMAIL',
    setCaptchaKey: 'SET_CAPTCHA_KEY',
    setSearchHeaders: 'SET_SEARCH_HEADERS',
    setSearchHeadersMasked: 'SET_SEARCH_HEADERS_MASKED',
    setSearchHeadersPII: 'SET_SEARCH_HEADERS_PII',
}

export const SearchResponseInfoTitles: any = {
    address1: 'Address 1',
    address2: 'Address 2',
    phone2: 'Phone Number 2',
    phone3: 'Phone Number 3',
    socialSecurityNumber: 'Social Security Number',
    driversLicenseNumber: 'Drivers License Number',
    bankAccountNumber: 'Bank Account Number',
    ipAddress: 'IP Address',
    stateIDNumber: 'State ID Number',
    passportNumber: 'Passport Number',
    insurancePolicyNumber: 'Insurance Policy Number',
    creditDebitCardNumber: 'Credit Debit Card Number',
    creditRating: 'Credit Rating',
    healthInsuranceNumber: 'Health Insurance Number',
    biometricInformation: 'Biometric Information',
    browsingHistory: 'Browsing History',
    searchHistory: 'Search History',
    geolocation: 'Geolocation',
    educationInformation: 'Education Information',
    vin: 'Vin',
    custom: 'Custom'
}

export const CustomType = "custom";
export const HeaderType = "headerElements";