import RestUtilities from '../../helpers/rest-utilities';
import ConfigManager from './config-manager';
import { IConfig } from './config-types';

class ConfigService {
    static getConfigs = () => {
        if (!localStorage.getItem("configs_set")) {
            RestUtilities.get<IConfig>(`/api/config/get-all`).then((response) => {
                if (!response.is_error) {
                    const configManager = new ConfigManager();
                    configManager.setConfigs(response.content);
                }
            })
        }
    }
}

export default ConfigService;