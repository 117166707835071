import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withLayout } from "../../common/layout";
import "./site-map.scss";
import { PAGE_LINK } from '../../assets/constants/page-links';
import { ApplicationState } from "../../store";
import * as NewsStore from "../news/news-reducer";
import { INewsLink } from "../news/news-types";
import { Helmet } from "react-helmet";

interface SiteMapProps {
    ccpaNews: INewsLink[]
}

const isPrerender = navigator.userAgent === "ReactSnap";

type NewsProps = SiteMapProps & typeof NewsStore.actions;

class SiteMapPage extends React.Component<NewsProps> {

    componentDidMount() {
        !isPrerender && this.props.getCCPANews();
    }

    public render() {
        const { ccpaNews } = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Site Map</title>
                    <meta name="description"
                        content="List of pages on My Data Privacy" />
                </Helmet>
                <div className="site-map-header">
                    <h1>SITE MAP</h1>
                </div>
                <div className="container" style={{ minHeight: "auto" }}>
                    <div className="row">
                        <div className="col-12 row offset-md-1">
                            <div className="col-12 col-md-6 site-map-menu">
                                <ul>
                                    <li><Link to="/">Home Page</Link></li>

                                    <li><Link to={PAGE_LINK.TERMS_OF_USE}>Terms of Use</Link></li>

                                    <li><Link to={PAGE_LINK.CCPA}>CCPA Full Text</Link></li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6 site-map-menu">
                                <ul>
                                    <li><Link to={PAGE_LINK.FAQ}>FAQ’s on CCPA</Link></li>

                                    <li><Link to={PAGE_LINK.PRIVACY_POLICY}>Privacy Policy</Link></li>

                                    <li><Link to={PAGE_LINK.LIST_OWNERS_AND_MAILERS}>List Owners & Mailers</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 site-map-menu d-flex justify-content-md-around" >
                            <ul>
                                <li><Link to={PAGE_LINK.LOGIN}>Login to Your Account</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="solid mt-4 mb-0" />
                <hr className="solid mt-1" />
                <div className="container">
                    <div className="row">
                        <div className="col-12 row offset-md-1">
                            <div className="col-12 ccpa-news">
                                <ul>
                                    <li><Link to={PAGE_LINK.NEWS}>CCPA News</Link></li>
                                    <ul className="news-menu">
                                        {
                                            ccpaNews.map((item: INewsLink, index: number) => <li key={item.slug + index}>
                                                <Link to={PAGE_LINK.ARTICLE.replace(":slug", item.slug)}>
                                                    {item.title}
                                                </Link>
                                            </li>)
                                        }
                                    </ul>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    ccpaNews: state.news.ccpaNews
});

const connectedSiteMapPage = connect(mapStateToProps, NewsStore.actions)(withLayout(SiteMapPage));
export { connectedSiteMapPage as SiteMapPage };