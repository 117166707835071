import { Button, Col, Form, Row, Tabs } from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { withLayout } from "../../common/layout";
import { ApplicationState } from "../../store";
import { searchActions } from "./search-reducer";
import { ISearchProps } from "./search-types";
import download from '../../assets/images/download.svg';
import { withRouter } from "react-router";
import { DEFAULT_ZERO_VALUE } from "../../assets/constants/general";

import './search.scss';

class SearchResultPage extends React.Component<ISearchProps, {}> {
    public render() {
        const { resultsCount, searchEmail, isOptOutEmailSent, isLoading, optOutEmail } = this.props;

        return (
            <div className="d-flex flex-column align-items-center card-max-container justify-content-center mb-5">
                <h1 className="mt-5 mb-3">Your Information was found in the Database <strong>{resultsCount} results</strong></h1>
                <div className="d-flex card-shadow w-100 m-4 p-4">
                    <Row>
                        <Col xs={24} md={24} lg={12}>
                            <p className='m-4'><strong>We found the email address you entered.</strong> Our system can email <span className='email-link'>{searchEmail}</span> the name, and postal address associated with this record, the categories of personal information contained in the record, and the list of Mailers who purchased it. There will be a link in the email we send, to Opt-Out of future marketing campaigns by List Owners who subscribe to this service.</p>
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <div className="d-flex flex-column m-4">
                                <p>It is not possible for mydataprivacy.com to delete, or even correct erroneous information in the database. That can only be done by the List Owner or Mailer.</p>
                                <Button onClick={optOutEmail} loading={isLoading} disabled={isOptOutEmailSent || resultsCount === DEFAULT_ZERO_VALUE} type="primary">{isOptOutEmailSent ? 'Email Sent' : 'Yes, Email me'}</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="d-flex card-shadow w-100 m-4 p-4">
                    <Row>
                        <Col xs={24} md={24} lg={12}>
                            <div className="d-flex align-items-center h-100">
                                <img src={download} alt='Download' />
                                <h3 className='m-4'>Would you like to Opt-Out?</h3>
                            </div>
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <div className="d-flex flex-column m-4">
                                <p>If you Opt-Out, List Owners who subscribe to this service, will no longer rent, sell or share your name. To Opt-Out, select the Opt-Out link in the email you receive from mydataprivacy.com.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="d-flex card-shadow w-100 m-4 p-4">
                    <Row>
                        <Col xs={24} md={24} lg={12}>
                            <div className="d-flex align-items-center h-100">
                                <img src={download} alt='Download' />
                                <h3 className='m-4'>Would you like to Delete your record?</h3>
                            </div>
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <div className="d-flex flex-column m-4">
                                <p>If you Delete, List Owners who subscribe to this service, will permanently Delete your name. To Delete, select the Delete link in the email you receive from mydataprivacy.com.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const connectedSearchPage = connect((state: ApplicationState) => state.search, searchActions)(withLayout(withRouter(SearchResultPage)));
export { connectedSearchPage as SearchResultPage };