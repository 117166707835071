import RestUtilities from "../../../helpers/rest-utilities";
import { IAgreement, IAgreementModel } from "./add-agreement-types";
import AuthStore from "../../../local-store/auth";
import { refresh } from "../../../helpers/refresh-token";

export default class AddAgreementService {
    addOrEdit(model: IAgreementModel) {

        let formData = new FormData();
        formData.append('userId', model.userId.toString());
        formData.append('type', model.type);
        formData.append('date', model.agreementDate);
        formData.append('agreement', model.agreement);

        refresh();
        return fetch("/api/admin/add-or-edit-agreement", {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${AuthStore.getToken()}`
            },
            body: formData
        })
    }

    get(userId: number) {
        return RestUtilities.get<IAgreement>(`/api/admin/get-agreement/${userId}`);
    }

    delete(userId: number) {
        return RestUtilities.get(`/api/admin/delete-agreement/${userId}`);
    }
}