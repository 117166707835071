import * as React from "react";
import { connect } from "react-redux";
import { withLayout } from "../../common/layout";
import { RootReducer } from "../../store/configure-store";
import { Row, Col, Form, Input, Button, Checkbox } from "antd";
import { Store } from "antd/lib/form/interface";
import weddingPlanning from '../../assets/images/wedding-planning.svg';
import find from '../../assets/images/find.svg';
import logo from '../../assets/images/logo.svg';
import warning from '../../assets/images/warning.svg';
import message from '../../assets/images/message.svg';
import ccpaDoc from '../../assets/docs/ccpa.pdf';
import { PAGE_LINK } from "../../assets/constants/page-links";
import { isEmail, isPhone, isRequired, PhoneInput, withoutDigits } from '../../helpers/validate-helper';
import { landingActions } from "../landing/landing-reducer";
import { ApplicationState } from '../../store';
import { ILandingActions, ILandingProps, IPotentialClient } from "../landing/landing-types";
import './list-owners-and-mailers.scss';
import { FORM_NAMES } from "../landing/landing-constants";
import { FormInstance } from "antd/lib/form";
import { Helmet } from "react-helmet";

const { Item } = Form;

type FormProps = ILandingProps & ILandingActions;

class ListOwnersAndMailersPage extends React.Component<FormProps> {
    formRef1 = React.createRef<FormInstance>();
    formRef2 = React.createRef<FormInstance>();

    public onFinish = (values: IPotentialClient, formName: string) => {
        values.formName = formName;
        this.props.sendForm(values);
        this.formRef1.current.resetFields();
        this.formRef2.current.resetFields();
    };
    public render() {
        const layout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const validateMessages = {
            types: {
                email: '${label} is not validate email!',
            }
        };

        return (
            <React.Fragment>
                <Helmet>
                    <title>List Owners & Mailers</title>
                    <meta name="description"
                        content="What is CCPA? The California Consumer Privacy Act (CCPA) is a bill meant to enhance privacy rights and consumer protection for residents of California, USA. The bill was passed by the California State Legislature and signed into law on June 28, 2018, to amend Part 4 of Division 3 of the California Civil Code. The CCPA becomes effective on January 1, 2020." />
                </Helmet>
                <div className="d-flex flex-column list-owners-and-mailers">
                    <div className="slogan">
                        <div className="d-flex">
                            <Row>
                                <Col xs={24} md={8} lg={12}>
                                    <div className="d-flex h-100 align-items-center justify-content-center main-text w-100">
                                        <div> Turnkey Solution for Compilers, List Owners and Mailers</div>
                                    </div>
                                </Col>
                                <Col xs={24} md={16} lg={12}>
                                    <div className="d-flex flex-column main-form">
                                        <span className="title">Turnkey CCPA Compliance</span>
                                        <div className="d-flex">
                                            <Form {...layout} ref={this.formRef1} name="nest-messages" className='d-flex flex-column w-100' onFinish={(values: IPotentialClient) => this.onFinish(values, FORM_NAMES.turnkeyCCPA)} validateMessages={validateMessages}>
                                                <div className="d-flex w-100 justify-content-between double-inputs">
                                                    <Item name={'firstName'}
                                                        rules={[isRequired('First Name'), withoutDigits('First Name')]}
                                                        label="First Name">
                                                        <Input />
                                                    </Item>
                                                    <Item name={'lastName'}
                                                        rules={[isRequired('Last Name'), withoutDigits('Last Name')]}
                                                        label="Last Name">
                                                        <Input />
                                                    </Item>
                                                </div>
                                                <Item name={'company'} label="Company Name">
                                                    <Input />
                                                </Item>
                                                <Item name={'email'}
                                                    label="Email Address"
                                                    rules={[isRequired('Email Address'), isEmail('Email Address')]}>
                                                    <Input />
                                                </Item>
                                                <Item name={'phone'} label="Phone Number" rules={[isPhone('Phone Number')]}>
                                                    <PhoneInput label='' />
                                                </Item>
                                                <Item name="permission">
                                                    <Checkbox checked defaultChecked disabled>mydataprivacy.com has permission to contact me with information related to this service.</Checkbox>
                                                </Item>
                                                <Item>
                                                    <Button loading={this.props.formLoading} type="primary" htmlType="submit">
                                                        Learn More
                                                    </Button>
                                                </Item>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="d-flex flex-column white-block pb-0">
                        <div className="d-flex card-shadow mb-2">
                            <Row>
                                <Col xs={24} md={24} lg={12}>
                                    <div className="d-flex flex-column p-5">
                                        <div className="d-flex title-bottom flex-column">
                                            <div>What is <strong>CCPA</strong>?</div>
                                            <div className='blue-line'></div>
                                        </div>
                                        <div className="d-flex text">
                                            The California Consumer Privacy Act (CCPA) is a bill meant to enhance privacy rights and consumer protection for residents of California, USA. The bill was passed by the California State Legislature and signed into law on June 28, 2018, to amend Part 4 of Division 3 of the California Civil Code. The CCPA becomes effective on January 1, 2020.
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={24} lg={12}><div className="card-image"></div></Col>
                            </Row>
                        </div>
                        <Row>
                            <Col xs={24} md={24} lg={12} className="py-2">
                                <div className="d-flex flex-column align-items-start card-shadow p-5 mr-lg-2 ">
                                    <img src={find} alt='Find' />
                                    <p>
                                        In order to provide California consumers with access to, and control over, their Personal Information, the
                                        CCPA requires that businesses:
                                    </p>
                                    <ul className="style-list">
                                        <li>
                                            Provide two or more designated methods to submit requests for information, including a toll-free number and a web page
                                        </li>
                                        <li>
                                            Provide consumers with a link to a web page titled “Do Not Sell My Personal Information” that allows consumers to
                                            Opt Out of the sale of their personal data
                                        </li>
                                        <li>
                                            Provide consumers an option to have their personal information deleted entirely and to direct any service providers
                                            to delete the consumer’s personal information from their records
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={24} md={24} lg={12} className="py-2">
                                <div className="d-flex flex-column align-items-start card-shadow p-5 ml-lg-2">
                                    <img src={weddingPlanning} alt='Wedding Planning' />
                                    <p>
                                        Under the CCPA, when a California resident makes a verified request to a business, that business must disclose the following, delivered by mail or electronically:
                                    </p>
                                    <ul className="style-list">
                                        <li>
                                            The categories of personal information it collects about the consumer
                                        </li>
                                        <li>
                                            The specific pieces of personal information it collects about the consumer
                                        </li>
                                        <li>
                                            The categories of sources from which that information is collected
                                        </li>
                                        <li>
                                            The business purposes for collecting or selling the information
                                        </li>
                                        <li>
                                            The categories of 3rd parties with which the information is shared or sold
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <div className="d-flex mb-4 mt-2">
                            <Row>
                                <Col xs={24} md={24} lg={12}><div className="card-image-2"></div></Col>
                                <Col xs={24} md={24} lg={12}>
                                    <div className="d-flex flex-column p-5">
                                        <div className="d-flex title-bottom flex-column">
                                            <div>Is My Company <strong>Required to Comply</strong>?</div>
                                            <div className='blue-line'></div>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <p>
                                                The CCPA applies to any for-profit business that collects consumers' personal data, that does business in California, and satisfies at least one of the following thresholds:
                                            </p>
                                            <ul className="style-list">
                                                <li>
                                                    Has annual gross revenues in excess of $25 million
                                                </li>
                                                <li>
                                                    Do you annually buy, receive for commercial purposes, sell or share for commercial purposes personal information of 50,000 or more California consumers, households or devices?
                                                </li>
                                                <li className="or">
                                                    <strong>OR</strong>
                                                </li>
                                                <li>
                                                    Earns more than half of its annual revenue from selling consumers' personal information
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="d-flex flex-column text-center align-items-center justify-content-center p-5">
                            <p>For the basics regarding CCPA <a target="_blank" rel="noopener noreferrer" className='email-link ml-1' href={ccpaDoc}>click here</a></p>
                            <p>For the full CCPA text <a className='email-link ml-1' href={PAGE_LINK.CCPA}>click here</a></p>
                        </div>
                    </div>
                    <div className="d-flex w-100 flex-column align-items-center justify-content-center blue-block">
                        <div className="title">Consequences of Non-Compliance</div>
                        <div className="text">Businesses found to be in violation of any of their obligations imposed by the CCPA are subject to a civil penalty of up to <strong>$2,500</strong> per violation and up to <strong>$7,500</strong> per willful violation.</div>
                    </div>
                    <div className="d-flex flex-column white-block">
                        <div className="d-flex mb-4">
                            <Row>
                                <Col xs={24} md={24} lg={11}>
                                    <div className="d-flex flex-column pt-5 pb-5">
                                        <div className="d-flex title-bottom flex-column m-3">
                                            <div><strong>myDataPrivacy.com</strong> – How It Works</div>
                                            <div className='blue-line'></div>
                                        </div>
                                        <div className="d-flex flex-column p-4">
                                            <p>
                                                myDataPrivacy.com provides a cost effective, easy-to-implement solution for List Owners and Mailers subject to the CCPA, providing comprehensive data access and disclosure compliance. The site offers central repository for List Owners and Mailers to maintain CCPA-related data points and transactions, relieving them of the burden of managing compliance in-house.
                                            </p>
                                            <p>
                                                Each list used or sold is uploaded to your account within myDataPrivacy.com. Easy click navigation and drop down menus guide you through the order creation and data upload process. Compliance details such as categories of personal information, specific pieces of information, data sources and categories are captured. File layouts are easily mapped within the system and drag & drop file uploads allow for quick and easy workflows. Suppression and Deletion files are continuously updated and available for download at any time.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={24} lg={12}>
                                    <div className="card-image-3">
                                        <div className="logo-box">
                                            <img src={logo} alt="Logo" className='logo' />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="d-flex flex-column mt-5 pt-5 pb-5">
                            <div className="d-flex title-bottom align-items-center flex-column mb-2">
                                <div style={{ maxWidth: '800px' }} className='text-center'>How Does myDataPrivacy.com Keep My Business in Compliance With <strong>CCPA</strong>?</div>
                                <div className='blue-line'></div>
                            </div>
                            <Row>
                                <Col xs={24} md={24} lg={11}>
                                    <div className="d-flex flex-column align-items-start">
                                        <img className='m-3' src={warning} alt='Warning' />
                                        <p className='m-3'>
                                            myDataPrivacy.com covers each of these compliance items and then some. Notification, access, disclosure, reporting, opt-out and deletion requests are all handled within the platform. It provides consumers with an easy-access, verified request portal to access their personal information. It offers online reporting to satisfy disclosure requirements along with opt-out and deletion request options.
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={24} md={24} lg={12}>
                                    <div className="d-flex flex-column m-3">
                                        <p>
                                            Broadly speaking, under the CCPA List Owners & Mailers are required to:
                                        </p>
                                        <ul className="style-list">
                                            <li>
                                                Inform California consumers of their rights under CCPA
                                            </li>
                                            <li>
                                                Disclose which categories and specific pieces of information are being collected
                                            </li>
                                            <li>
                                                Detail how that information is being used, including to whom it is shared or sold
                                            </li>
                                            <li>
                                                Provide access to personal information via a verified request mechanism
                                            </li>
                                            <li>
                                                Honor opt-out and deletion requests
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="d-flex blue-block flex-column align-items-center justify-content-center">
                        <div className="d-flex title-bottom flex-column">
                            <img src={message} alt='Message' />
                            <div><strong>Write</strong> to Us</div>
                            <div className='blue-line'></div>
                        </div>
                        <div className="d-flex">
                            <Form {...layout} ref={this.formRef2} name="nest-messages" className='d-flex flex-column w-100' onFinish={(values: IPotentialClient) => this.onFinish(values, FORM_NAMES.writeToUs)} validateMessages={validateMessages}>
                                <div className="d-flex w-100 justify-content-between double-inputs">
                                    <Item name={'firstName'} label="First Name" rules={[isRequired('First Name'), withoutDigits('First Name')]}>
                                        <Input />
                                    </Item>
                                    <Item name={'lastName'} label="Last Name" rules={[isRequired('Last Name'), withoutDigits('Last Name')]}>
                                        <Input />
                                    </Item>
                                </div>
                                <Item name={'company'} label="Company Name">
                                    <Input />
                                </Item>
                                <div className="d-flex w-100 justify-content-between double-inputs">
                                    <Item name={'email'} label="Email Address" rules={[isRequired('Email Address'), isEmail('Email Address')]}>
                                        <Input />
                                    </Item>
                                    <Item name={'phone'} label="Phone Number" rules={[isPhone('Phone Number')]}>
                                        <PhoneInput label='' />
                                    </Item>
                                </div>
                                <Item>
                                    <Button type="primary" htmlType="submit" loading={this.props.formLoading}>
                                        Send
                                    </Button>
                                </Item>
                                <Item name="permission">
                                    <Checkbox checked defaultChecked disabled>mydataprivacy.com has permission to contact me with information related to this service.</Checkbox>
                                </Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state: ApplicationState) => state.landing

const connectedListOwnersAndMailersPage = connect(mapStateToProps, landingActions)(withLayout(ListOwnersAndMailersPage));
export { connectedListOwnersAndMailersPage as ListOwnersAndMailersPage };