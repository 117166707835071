import { IListClientsActionTypes } from "./list-clients-types";

export const ListClientsActionTypes: IListClientsActionTypes = {
    setListClients: "SET_LIST_CLIENTS",
    setListClientsIsLoading: "SET_LIST_CLIENTS_IS_LOADING",
    setListClientsPagination: "SET_LIST_CLIENTS_PAGINATION",
}

export const patnToEdit = '/edit-user/';
export const pathToAgreement = "/add-agreement/";

export const STATUSES = {
    NEW: "New",
    ACTIVE: "Active",
    INACTIVE: "Inactive"
}

export const csvHeaders = [
    { label: 'Status', key: 'status' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Company', key: 'company' },
    { label: 'Email', key: 'email' },
    { label: 'Title', key: 'title' },
    { label: 'Phone', key: 'phone' },
    { label: 'Address 1', key: 'address1' },
    { label: 'Address 2', key: 'address2' },
    { label: 'City', key: 'city' },
    { label: 'State', key: 'state' },
    { label: 'Zip', key: 'zip' },
    { label: 'Type', key: 'type' },
    { label: 'Agreement Date', key: 'agreementDate' }
]