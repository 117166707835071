import React from 'react'
import MaskedInput from 'antd-mask-input'
import { Rule } from "antd/lib/form";

export const isRequired = (label: string): Rule => ({
    required: true,
    transform: (value: string) => value.trim(),
    message: `${label} is required!`
});

export const withoutDigits = (label: string): Rule => ({
    pattern: /^[a-zA-Z]+$/,
    message: `${label} shouldn't include digits!`
});

export const isEmail = (label: string): Rule => ({
    type: 'email',
    message: `${label} is not valid email!`
});

export const isPhone = (label: string): Rule => ({
    min: 12,
    max: 12,
    pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
    message: `${label} is not valid!`
});

export const PhoneInput = (props: { label: string, value?: string, disabled?: boolean, onChange?: (value: string) => void }) => {
    const { label, value, disabled = false, onChange = () => ({}) } = props;

    return (
        <MaskedInput mask='111-111-1111'
            disabled={disabled}
            value={value}
            placeholder={label}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)} />
    )
};

export const CodeInput = (props: { label: string, value?: string, disabled?: boolean, onChange?: (value: string) => void, onPressEnter?: () => void }) => {
    const { label, value, disabled = false, onChange = () => ({}), onPressEnter = () => ({}) } = props;

    return (
        <MaskedInput mask='111111'
            disabled={disabled}
            value={value}
            placeholder={label}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
            onPressEnter={onPressEnter} />
    )
};