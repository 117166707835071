export interface IAuthState {
    isLoading: boolean;
    isFailed: boolean;
    isSuccess: boolean;
    registerSuccess: boolean;
    registerFailed: boolean;
    errors: { [key: string]: string };
    registerEmail: string;
    verificationType: string;
    verified: boolean;
    email: string;
    phoneNumber: string;
    signInEmail: string;
    signInPassword: string;
    signInRemember: boolean;
    resetPassword: string;
    confirmResetPassword: string;
    phoneStatus: PhoneStatus;
    targetTime: number;
    isCodeSent: boolean;
}

export enum PhoneStatus {
    error = "error",
    success = "success"
}

export interface IRegisterUser {
    address1: string;
    address2?: string;
    city: string;
    company: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirm: string;
    phone: string;
    state: string;
    title?: string;
    code?: string;
    isVerify?: boolean;
    verification?: string;
    zip: string;
    role?: string;
}

export interface ISignInUser {
    email: string;
    password: string;
    remember: boolean;
}

export interface IRegisterActionTypes {
    started: string;
    success: string;
    failed: string;
    registerEmail: string;
    setLoading: string;
    setVerificationType: string;
    setVerification: string;
    setEmail: string;
    setPhoneNumber: string;
    setErrors: string;
    setPhoneStatus: string;
    setTargetTime: string;
    setIsCodeSent: string;
}

export interface IRadioVerificationTypes {
    SMS: string;
    VOICE: string;
}

export interface ISigninActionTypes {
    started: string;
    success: string;
    failed: string;
    setSignInEmail: string;
    setSignInPassword: string;
    setSignInRemember: string;
    setUserLoggedOut: string;
}

export interface IVerifyModel {
    email: string;
    channel: string;
    phoneNumber: string;
}

export interface IResetPasswordModel {
    userId: string;
    password: string;
    confirmPassword: string;
    code: string;
}

export interface IForgotPasswordModel {
    email: string;
}

export interface IChangePasswordModel {
    email: string;
    oldPassword: string;
    newPassword: string;
    repeatNewPassword: string;
}

export interface IRegisterActions {
    start: () => { type: string; };
    success: () => { type: string; };
    fail: (errors: { [key: string]: string }) => { type: string; payload: { [key: string]: string }; };
    register: (userProps: IRegisterUser) => void;
    setRegisterEmail: (email: string) => void;
    resendEmail: () => void;
    setVerificationType: (type: string) => void;
    setVerification: (verified: boolean) => void;
    sendVerifyCode: () => void;
    setEmail: (value: string) => void;
    setPhoneNumber: (value: string) => void;
    confirmUser: (userId: string, code: string) => void;
    setPhoneStatus: (status: PhoneStatus) => void;
    setTargetTime: (targetTime: number) => void;
    setIsCodeSent: (isCodeSent: boolean) => void;
}

export interface ISigninActions {
    start: () => { type: string; };
    success: () => { type: string; };
    fail: (errors: { [key: string]: string }) => { type: string; payload: { [key: string]: string }; };
    signIn: () => void;
    forgotPassword: () => void;
    resetPassword: (model: IResetPasswordModel) => void;
    setSignInEmail: (value: string) => void;
    setSignInPassword: (value: string) => void;
    setSignInRemember: (value: boolean) => void;
}