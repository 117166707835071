import * as React from "react";
import { connect } from "react-redux";
import { withLayout } from "../../common/layout";
import { ApplicationState } from "../../store";
import * as NewsStore from "./news-reducer";
import { IArticle, INewsState, ISubNewsComponentProps } from "./news-types";
import LoadingOverlay from "react-loading-overlay";
import "./news.scss";
import { Pagination, Tabs } from "antd";
import moment from "moment";
import {
    DEFAULT_ZERO_VALUE,
    FIRST_PAGE_NUMBER,
} from "../../assets/constants/general";
import { PAGE_LINK } from "../../assets/constants/page-links";
import ConfigManager from "../configs/config-manager";
import SubscriptionBlock from "./subscription-block";
import { SubNews } from "./sub-news-block";
import { SUB_NEWS_TITLES, SUB_NEWS_TYPES } from "./news-constants";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const { TabPane } = Tabs;

const isPrerender = navigator.userAgent === "ReactSnap";

type NewsProps = INewsState & typeof NewsStore.actions;
class NewsPage extends React.PureComponent<NewsProps> {
    currentTab: number;

    constructor(props: NewsProps) {
        super(props);
        this.currentTab = 1;
    }

    componentDidMount() {
        const { loadNews, loadCategories } = this.props;

        !isPrerender && loadCategories();
        !isPrerender && loadNews(FIRST_PAGE_NUMBER);
    }

    onPaginationChange = (pageNumber: number, pageSize?: number) => {
        const { loadNews, setPagination, pagination } = this.props;

        setPagination({
            ...pagination,
            pageNumber: pageNumber,
        });

        loadNews(this.currentTab);
    };

    onTabChange = (current: string) => {
        const { pagination, setPagination, loadNews } = this.props;

        setPagination({
            ...pagination,
            pageNumber: FIRST_PAGE_NUMBER,
        });

        this.currentTab = +current;
        loadNews(+current);
    };

    onFinish = () => {
        console.log("finished");
    };

    getArticleItemHtml(article: IArticle, isBig: boolean) {
        const bannerSize = isBig ? "big" : "small";
        return (
            <div className="article-banner">
                <div className="article-banner-item">
                    <div
                        className={`${bannerSize} article-cover`}
                        style={{
                            backgroundImage: `url(${ConfigManager.azureStorage()}/${ConfigManager.newsContainer()}/${ConfigManager.articlesDirectory()}/${ConfigManager.articleImagesDirectory()}/${article.linkToImage})`,
                        }}
                    ></div>
                </div>
                <div className="category-name article-banner-item">
                    {article.category.name}
                </div>
                <div className={`${bannerSize} title article-banner-item`}>
                    <Link to={PAGE_LINK.ARTICLE.replace(":slug", article.slug)}>
                        {article.title}
                    </Link>
                </div>
                <div className="article-banner-item article-release-info">
                    by{" "}
                    <span className="author">{article.createdBy.fullName}</span>{" "}
                    <span className="author-date-divider">&bull;</span>{" "}
                    <span className="date">
                        {moment(article.createdAt).format("MMM Do YYYY")}
                    </span>
                </div>
            </div>
        );
    }

    public render() {
        const { isLoading, news, pagination, categories } = this.props;

        const subNewsData: ISubNewsComponentProps = {
            subNewsCategoryId: DEFAULT_ZERO_VALUE,
            subNewsTitle: SUB_NEWS_TITLES.RECENT_NEWS,
            subNewsType: SUB_NEWS_TYPES.RECENT_NEWS,
        };

        return (
            <React.Fragment>
                <Helmet>
                    <title>Latest News & Articles</title>
                    <meta name="description"
                        content="Latest News and Articles about CCPA and data privacy" />
                </Helmet>
                <LoadingOverlay className="news-page" active={isLoading} spinner>
                    <SubscriptionBlock />
                    <Tabs defaultActiveKey="1" onChange={this.onTabChange}>
                        {categories.map((elem) => (
                            <TabPane tab={elem.name} key={elem.id} className=" d-flex justify-content-center">
                                <div className="row news-content-block  container p-0">
                                    <div className="col-sm-8 col-lg-9">
                                        {news.length > DEFAULT_ZERO_VALUE ? (
                                            <div className="left-news-block">
                                                <div className="main-article">
                                                    {this.getArticleItemHtml(
                                                        news[DEFAULT_ZERO_VALUE],
                                                        true
                                                    )}
                                                </div>
                                                <div className="row">
                                                    {news
                                                        .slice(1, news.length)
                                                        .map((article, i) => {
                                                            return (
                                                                <div
                                                                    key={`article_${i}`}
                                                                    className="col-sm-6"
                                                                >
                                                                    {this.getArticleItemHtml(
                                                                        article,
                                                                        false
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                                <Pagination
                                                    current={pagination.pageNumber}
                                                    pageSize={pagination.pageSize}
                                                    total={pagination.total}
                                                    defaultCurrent={
                                                        FIRST_PAGE_NUMBER
                                                    }
                                                    size="small"
                                                    onChange={
                                                        this.onPaginationChange
                                                    }
                                                    responsive={true}
                                                />
                                            </div>
                                        ) : (
                                            <h2>No news in category</h2>
                                        )}
                                    </div>
                                    <div className="col-sm-4 col-lg-3">
                                        <div className="right-news-block">
                                            <SubNews
                                                {...this.props}
                                                {...subNewsData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        ))}
                    </Tabs>
                </LoadingOverlay>
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state: ApplicationState) => state.news;

const connectedNewsPage = connect(
    mapStateToProps,
    NewsStore.actions
)(withLayout(NewsPage));
export { connectedNewsPage as NewsPage };