import { Button, Input, Radio, Form } from "antd";
import { DeleteOutlined, CheckOutlined, WarningOutlined, FileProtectOutlined } from '@ant-design/icons';
import * as React from "react";
import { connect } from "react-redux";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { withUserLayout } from "../../common/user-layout";
import { ApplicationState } from "../../store";
import * as AlertsStore from './alerts-reducer';
import { AlertContactModel, IAlertsContactsPage, IAlertsState } from './alerts-types';
import { NOTIFY_TYPE } from './alerts-constants';
import { RadioChangeEvent } from "antd/lib/radio";
import { PhoneInput, CodeInput, isPhone, isEmail } from "../../helpers/validate-helper";
import warning from '../../assets/images/warning-note.svg';
import LoadingOverlay from 'react-loading-overlay';
import { DEFAULT_ZERO_VALUE, STRING_EMPTY } from "../../assets/constants/general";
import validator from 'validator';
import Countdown from "antd/lib/statistic/Countdown";

import './alerts.scss';

const { Group } = Radio;
const { Item } = Form;

type AlertsProps = IAlertsState
    & typeof AlertsStore.actions;

class AlertsContactsPage extends React.Component<AlertsProps, IAlertsContactsPage> {
    constructor(props: AlertsProps) {
        super(props);
        
        this.state = {
            isDisabled: true,
            verifyIsDisabled: true
        }
    }

    componentDidMount() {
        const { getAlertContacts } = this.props;
        getAlertContacts();
    }

    setValue = (value: string) => {
        const { currentNotifyType, setCurrentValue } = this.props;

        this.setState({
            isDisabled: this.checkIsDisabled(currentNotifyType, value)
        }, () => setCurrentValue(value));
    }

    checkIsDisabled = (notityType: NOTIFY_TYPE, value: string) => {
        const isNotEmail = !validator.isEmail(value);
        const isNotPhone = !validator.matches(value, /[+]{1}[0-9 ]+[^_]$/);

        return notityType === NOTIFY_TYPE.EMAIL ? isNotEmail : isNotPhone;
    }

    setVerifyValue = (value: string) => {
        const { setVerificationCode } = this.props;

        this.setState({
            verifyIsDisabled: this.checkVerifyIsDisabled(value)
        }, () => setVerificationCode(value));
    }

    checkVerifyIsDisabled = (value: string) => {
        const intValue = parseInt(value);
        return !validator.isLength(`${intValue}`, { min: 6, max: 6 });
    }

    addAlertContact = async () => {
        const { addAlertContact, getAlertContacts, currentNotifyType, currentValue } = this.props;

        await addAlertContact(new AlertContactModel(currentValue, currentNotifyType));
        getAlertContacts();
    }

    confirm = async (id: number) => {
        const { confirmAlertContact, getAlertContacts } = this.props;

        await confirmAlertContact(id);
        getAlertContacts();
    }

    delete = async (id: number) => {
        const { deleteAlertContact, getAlertContacts } = this.props;

        await deleteAlertContact(id);
        getAlertContacts();
    }

    sendVerificationCode = async () => {
        const { sendVerificationCode, getAlertContacts } = this.props;

        await sendVerificationCode();
        getAlertContacts();
    }

    onFinishCountDown = () => {
        const { setIsCodeSent, getAlertContacts, setTargetTime, setCurrentValue } = this.props;

        setCurrentValue(STRING_EMPTY);
        setIsCodeSent(false);
        setTargetTime(DEFAULT_ZERO_VALUE);
        getAlertContacts();
    }

    public render() {
        const { isDisabled, verifyIsDisabled } = this.state;
        const { 
            isLoading, alertContacts, isCodeSent, targetTime, currentNotifyType, currentValue,
            setCurrentValue, setCurrentNotifyType
        } = this.props;
        const isValueInvalid = isDisabled && currentValue !== STRING_EMPTY;

        return (
            <LoadingOverlay className="alerts-contacts-page d-flex flex-column" active={isLoading} spinner>
                <div className="d-flex w-100 description">
                    <p>
                        Continuing to contact consumers who have opted-out or requested their record to be deleted from your database may be a violation of the
                        California Consumer Privacy Act and/or the California Privacy Rights Act and is contrary to best practices. My Data Privacy’s “Alerts” system
                        notifies you when a consumer record you upload matches the record of a consumer who has previously opted-out or requested their
                        information to be deleted, alerting you to the potential for future violations if that record is not handled appropriately according to
                        CCPA/CPRA guidelines.
                    </p>
                    <div className="divider"></div>
                    <p>
                        Please enter the name, email address and/or mobile phone number (to receive texts) of up to 3 people at your company you wish to be notified.
                    </p>
                </div>
                {
                    alertContacts && alertContacts.map((contact, index) => (
                        <div key={`alert_contact_${index}`} className="d-flex justify-content-between align-items-center notify-form mb-2 pl-4 p-2">
                            <span><strong>{contact.value}</strong></span>
                            <div className="d-flex align-items-center">
                                {
                                    contact.isConfirmed ?
                                        <div className='confirmed'><CheckOutlined /><small>confirmed</small></div> :
                                        <div className='unconfirmed'><WarningOutlined /><small>unconfirmed</small></div>
                                }
                                {
                                    !contact.isConfirmed &&
                                    <Button type="dashed" className='d-flex align-items-center justify-content-center ml-3 to-confirm' onClick={() => this.confirm(contact.id)}><FileProtectOutlined /><small>confirm</small></Button>
                                }
                                <Button type="text" shape="circle" className='d-flex align-items-center justify-content-center ml-3' onClick={() => this.delete(contact.id)} icon={<DeleteOutlined />} />
                            </div>

                        </div>
                    ))
                }
                <div className="d-flex flex-column notify-form">
                    <h3><strong>How should Alerts notify you</strong></h3>
                    <Group disabled={isCodeSent} name="radiogroup" value={currentNotifyType} onChange={(event: RadioChangeEvent) => { setCurrentNotifyType(event.target.value); setCurrentValue(STRING_EMPTY) }}>
                        <Radio value={NOTIFY_TYPE.EMAIL}>{NOTIFY_TYPE.EMAIL}</Radio>
                        <Radio value={NOTIFY_TYPE.PHONE}>{NOTIFY_TYPE.PHONE}</Radio>
                    </Group>
                    {
                        currentNotifyType === NOTIFY_TYPE.EMAIL && (
                            <div className="d-flex">
                                <Item validateStatus={isValueInvalid ? 'error' : 'success'} help={ isValueInvalid && 'Please input correct email!'}>
                                    <Input disabled={isCodeSent} value={currentValue} placeholder='Email Address' onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setValue(event.target.value)} />
                                </Item>
                            </div>
                        )
                    }
                    {
                        currentNotifyType === NOTIFY_TYPE.PHONE && (
                            <div className="d-flex">
                                <Item validateStatus={isValueInvalid ? 'error' : 'success'} help={ isValueInvalid && 'Please input correct phone number!'}>
                                    <PhoneInput disabled={isCodeSent} value={currentValue} label='Phone Number' onChange={this.setValue} />
                                </Item>
                            </div>
                        )
                    }
                    {
                        isCodeSent && (
                            <div className="d-flex w-100 mt-3 justify-content-between">
                                <div className="d-flex flex-column verify-block align-items-end">
                                    <CodeInput label='Verification code' onChange={this.setVerifyValue} onPressEnter={this.sendVerificationCode} />
                                    <Button disabled={verifyIsDisabled} className='text-uppercase send-button mt-3' onClick={this.sendVerificationCode}>Verify</Button>
                                </div>
                                <Countdown value={targetTime} onFinish={this.onFinishCountDown} />
                            </div>
                        )
                    }
                    <div className="d-flex flex-column mt-5 warning">
                        <div className="d-flex mb-3">
                            <img src={warning} alt="Note!" />
                            <p><strong>Note!</strong></p>
                        </div>
                        <p><strong>Variations in spelling, spacing, extra characters, etc. impact the effectiveness of this service.</strong></p>
                        <p><strong>Our Alerts system should not be your sole methodology for detecting or resolving potential violations.</strong></p>
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-end mt-4">
                    <Button disabled={isDisabled || isCodeSent} className='text-uppercase send-button' onClick={this.addAlertContact}>Send</Button>
                </div>
            </LoadingOverlay>
        )
    }
}

export default connect((state: ApplicationState) => state.alert, AlertsStore.actions)(withUserLayout(AlertsContactsPage as any, PAGE_LINK.ALERTS_CONTACTS));