import * as React from "react";
import { connect } from 'react-redux';
import * as ListClientsStore from "./reducer";
import { ApplicationState } from '../../../store';
import { IListClientsState, IUser } from "./list-clients-types";
import { PAGE_LINK } from "../../../assets/constants/page-links";
import { withUserLayout } from "../../../common/user-layout";
import LoadingOverlay from 'react-loading-overlay';
import { Input, message, Modal, Table, Tag } from "antd";
import { ColumnsType, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { Link } from "react-router-dom";
import { DEFAULT_ZERO_VALUE, PAGE_SIZE_OPTIONS } from "../../../assets/constants/general";
import { CSVLink } from "react-csv";
import { csvHeaders, pathToAgreement, patnToEdit, STATUSES } from "./list-clients-constants";
import { FileAddOutlined, DownloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import AuthStore, { IAuthUser } from "../../../local-store/auth";
import {SUPER_ADMIN_ROLE} from "../../../assets/constants/roles";
import moment from "moment";

type ListClientsProps = IListClientsState
    & typeof ListClientsStore.listClientsActions

class ListClients extends React.PureComponent<ListClientsProps>{
    state = {
        modalVisible: false,
        selctedUserId: DEFAULT_ZERO_VALUE,
        activate: false,
        deleteModalVisible: false,
        deletedUserId: DEFAULT_ZERO_VALUE
    }

    componentDidMount() {
        this.props.getListClients();
    }

    onChange = (pagination: TablePaginationConfig, _filters: any, sorter: SorterResult<IUser>) => {
        const { getListClients, setListClientsPagination, listClientsPagination } = this.props;

        setListClientsPagination({
            ...listClientsPagination,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sortCriteria: sorter.columnKey?.toString(),
            isAscend: sorter.order === 'ascend'
        })
        getListClients();
    }

    onShowSizeChange = (current: number, pageSize: number) => {
        const { getListClients, setListClientsPagination, listClientsPagination } = this.props;

        setListClientsPagination({ ...listClientsPagination, pageNumber: current, pageSize });
        getListClients();
    }

    onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { setListClientsPagination, listClientsPagination } = this.props;

        setListClientsPagination({ ...listClientsPagination, searchCriteria: event.target.value, pageNumber: 1 });
    }

    showModal = (userId: number, activate: boolean) => {
        this.setState({ selctedUserId: userId, activate, modalVisible: true });
    }

    showDeleteModal = (deletedUserId: number) => {
        this.setState({ deletedUserId, deleteModalVisible: true });
    }

    closeModal = () => {
        this.setState({
            modalVisible: false,
            selctedUserId: DEFAULT_ZERO_VALUE,
            activate: false,
            deleteModalVisible: false,
            deletedUserId: DEFAULT_ZERO_VALUE
        });
    }

    deleteUser = () => {
        const { deletedUserId } = this.state;
        this.props.deleteUser(deletedUserId);
        this.closeModal();
    }

    activateDeactivate = () => {
        const { activate, selctedUserId } = this.state;
        this.props.activateOrDeactivateUser(selctedUserId, activate);
        this.closeModal();
    }

    render() {
        const authUser: IAuthUser = JSON.parse(AuthStore.getUser());
        const { listClients, listClientsPagination, listClientsIsLoading, getListClients } = this.props;
        const { activate, modalVisible, deleteModalVisible } = this.state;
        const columns = [{
            title: 'Status',
            dataIndex: ['status', 'id'],
            key: 'status',
            sorter: true,
            render: (value: any, user: IUser, index: number) => {
                if (user.email == authUser.email || (user.isCompanyOwner && authUser.role != SUPER_ADMIN_ROLE)) {
                    return null;
                }
                switch (user.status) {
                    case STATUSES.NEW:
                        return <Tag color="geekblue">{STATUSES.NEW}</Tag>
                    case STATUSES.ACTIVE:
                        return <Tag color="green" onClick={() => this.showModal(user.id, false)}>{STATUSES.ACTIVE}</Tag>
                    case STATUSES.INACTIVE:
                        return <Tag color="volcano" onClick={() => this.showModal(user.id, true)}>{STATUSES.INACTIVE}</Tag>
                    default:
                        break;
                }
            }
        }, {
            title: 'First Name ',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: true,
        }, {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: true,
        }, {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            sorter: true,
            render: (value: any, user: IUser, index: number) => {
                return (
                    <div className="d-flex flex-column">
                        {user.company}
                        {user.isCompanyOwner && <span style={{ fontSize: "12px", color: "#0e9bff" }}>(Owner)</span>}
                    </div>
                )
            }

        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        }, {
            title: 'Download',
            dataIndex: 'download',
            key: 'download',
            align: "center",
            render: (value: any, user: IUser, index: number) => {
                return user.download && <a href={`/api/admin/download-agreement/${user.id}`}><DownloadOutlined style={{ fontSize: "25px" }} /></a>
            }
        }, {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            align: "center",
            render: (value: any, user: IUser, index: number) => {
                return user.email != authUser.email && !user.isCompanyOwner && <DeleteOutlined title="Delete" style={{ fontSize: "25px", color: "red" }} onClick={() => this.showDeleteModal(user.id)} />
            }
        }, {
            title: 'More',
            dataIndex: 'id',
            key: 'more',
            align: "left",
            render: (userId: any, user: IUser, index: number) => (
                <div className="row">
                    <Link title="Add agreement" to={pathToAgreement + userId} className="mx-2" style={{ fontSize: "25px" }}><FileAddOutlined /></Link>
                    <Link title="Edit profile" to={patnToEdit + userId} className="mx-2" style={{ fontSize: "25px" }}><EditOutlined /></Link>
                </div>
            )
        }] as ColumnsType<IUser>;

        return (
            <React.Fragment>
                <LoadingOverlay className="user-uploads-page d-flex flex-column justify-content-start align-items-center w-100 p-4" active={listClientsIsLoading} spinner>
                    <div className="d-flex justify-content-between w-100 mb-5">
                        <div>
                            <Input.Search className="my-2" size={"large"} onChange={this.onSearch} value={listClientsPagination.searchCriteria} onSearch={getListClients} />
                        </div>
                        <div>
                            <Link style={{ paddingTop: "1px" }} className="ant-btn ant-btn-primary ant-btn-round ant-btn-lg" to={PAGE_LINK.CREATE_USER} >CREATE USER</Link>
                        </div>
                    </div>
                    <Table scroll={{ x: true }}
                        dataSource={listClients.map((x, idx) => ({ ...x, key: `uploaded_item_${idx}` }))}
                        columns={columns}
                        pagination={{
                            current: listClientsPagination.pageNumber,
                            pageSize: listClientsPagination.pageSize,
                            total: listClientsPagination.total,
                            onShowSizeChange: this.onShowSizeChange,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: PAGE_SIZE_OPTIONS
                        }}
                        onChange={this.onChange}
                        className='w-100' />
                </LoadingOverlay>
                <div style={{ minHeight: "auto" }} className="d-flex flex-row-reverse">
                    <CSVLink className="btn btn-info btn-lg download-csv"
                        headers={csvHeaders}
                        data={listClients}
                        enclosingCharacter={``}
                        filename={`List_Clients_(${moment().format('L')}).csv`}>
                        Download CSV <DownloadOutlined />
                    </CSVLink>
                </div>

                <Modal
                    confirmLoading={listClientsIsLoading}
                    title={activate ? "Activation of User" : "Deactivation of User"}
                    visible={modalVisible}
                    onOk={this.activateDeactivate}
                    onCancel={this.closeModal}
                    okType={activate ? "primary" : "danger"}
                    okText={activate ? "Activate" : "Deactivate"}
                >
                    Do you want to {activate ? "activate" : "deactivate"} the current user?
                </Modal>
                <Modal
                    confirmLoading={listClientsIsLoading}
                    title="Deleting of user"
                    visible={deleteModalVisible}
                    onOk={this.deleteUser}
                    onCancel={this.closeModal}
                    okType={"danger"}
                    okText={"Delete"}
                >
                    Do you want to delete the current user?
                </Modal>
            </React.Fragment>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.listClients,
    ListClientsStore.listClientsActions
)(withUserLayout(ListClients as any, PAGE_LINK.LIST_OF_CLIENTS));