import { IPagination } from "../../../assets/global/types";
import RestUtilities from "../../../helpers/rest-utilities";
import { IOptOutModel } from "./opt-out-types";
import AuthStore from "../../../local-store/auth";

export default class OptOutService {
    getOptOuts(pagination: IPagination, isDeleted: boolean | null) {
        return RestUtilities.post<IOptOutModel>(`/api/optout/get-all`, { pagination, isDeleted });
    }

    importDocument(type: string, file: File) {
        const formData = new FormData();
        formData.append("type", type);
        formData.append("file", file);

        return fetch(`/api/optout/import`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${AuthStore.getToken()}`
            },
            body: formData
        });
    }
}