import * as React from 'react';
import { message, Steps, notification, Tabs } from 'antd';
import CreateOrder from "./create-order";
import MatchHeaders from "./match-headers";
import UploadFile from "./upload-file"
import AddToDatabase from "./add-to-database";
import "./upload.scss";
import * as UploadStore from "./reducer"
import { IFileData } from './upload-types';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import UploadService from "./upload-service";
import { mapUndefIndex, stepSize, stepsNumbers } from './upload-constats';
import { withUserLayout } from '../../../common/user-layout';
import { PAGE_LINK } from '../../../assets/constants/page-links';
import FinishModal from "./finish-modal";
import AuthStore from "../../../local-store/auth"
import SaveMappingProfile from "./save-mapping-profile";
import TabTitle from "./tab-title"
import { Prompt } from 'react-router';

const { Step } = Steps;
const uploadService = new UploadService;

type UploadProps = {
    current: number,
    filesData: IFileData[],
    newMapModelsCount: number,
    uploadStatus: {
        isLoading: boolean;
        isFailed: boolean;
        isSuccess: boolean;
        showModal: boolean;
        errors: { [key: string]: string };
    }
}
    & typeof UploadStore.uploadActions

interface UploadState {
    fileList: any[];
    finishModalOpen: boolean,
    showStepSaveMapping: boolean,
    activeMappingTab: number,
    notSaveContent: boolean
}

export class UploadPage extends React.PureComponent<UploadProps, UploadState>{
    state: Readonly<UploadState> = {
        fileList: [],
        finishModalOpen: false,
        showStepSaveMapping: true,
        activeMappingTab: 0,
        notSaveContent: false
    }

    componentWillUnmount() {
        this.props.setShowModalFalse();
    }

    onFinish = () => {
        this.props.finishUpload();
        this.setState({
            finishModalOpen: true,
            fileList: [],
            showStepSaveMapping: true,
            activeMappingTab: 0,
            notSaveContent: false
        });
    };

    showSavingMapping = (show: boolean) => {
        this.setState({ showStepSaveMapping: show });
    }

    next = () => {
        const current = this.props.current + stepSize;
        const { filesData = [] } = this.props;

        if (this.props.current == stepsNumbers.uploadFile && filesData.length == 0) {
            notification.error({ message: "Please upload files" });
            return;
        }

        this.props.setCurrentStep(current);
    };

    prev = () => {
        const current = this.props.current - stepSize;
        this.props.setCurrentStep(current);
    };

    setTabWithError = (fileIndex: number,) => {
        const { filesData = [], current } = this.props;
        if (current > stepsNumbers.matchHeaders && fileIndex != mapUndefIndex) {
            notification.error({ message: `${filesData[fileIndex].fileName} is not mapped` });
            this.props.setCurrentStep(stepsNumbers.matchHeaders);
            this.setState({ activeMappingTab: fileIndex });
        } else if (fileIndex == mapUndefIndex) {
            notification.error({ message: `Please, Map All Files` });
            this.props.setCurrentStep(stepsNumbers.matchHeaders);
        }
    }

    getFileExtension = (filename: string) => {
        const extension = filename.split('.').pop();
        return extension;
    }

    setActiveFileIndex = (key: string) => {
        this.setState({ activeMappingTab: parseInt(key) });
        this.props.setFileIndex(parseInt(key));
    }

    render() {
        const { filesData = [], newMapModelsCount, current, uploadStatus } = this.props;
        const { activeMappingTab } = this.state;

        const uploadProps = {
            multiple: true,
            accept: ".csv,.xls,.xlsx,.dbf,.txt",
            action: 'api/upload/upload-file',
            defaultFileList: this.state.fileList,
            headers: {
                authorization: `Bearer ${AuthStore.getToken()}`,
            },
            onRemove: async (file: any) => {
                if (file.status === 'error') {
                    return true;
                }
                const { fileId = "", fileName }: IFileData = file.response
                var operationResult = false;
                await uploadService.deleteFile(`${fileId}.${this.getFileExtension(fileName)}`).then((response) => {
                    if (!response.is_error) {
                        this.setState(state => {
                            const index = state.fileList.indexOf(file);
                            const newFileList = state.fileList.slice();
                            newFileList.splice(index, 1);
                            return {
                                fileList: newFileList,
                            };
                        });
                        this.props.removeFile(fileId);
                        operationResult = true;
                    } else {
                        notification.error({ message: `Can't delete "${fileName}"` });
                    }
                })
                return operationResult;
            },
            onChange: (info: any) => {
                if (info.file.status === 'done') {
                    this.props.setFileData(info.file.response);
                    this.setState({ fileList: info.fileList.concat(), notSaveContent: true })
                    notification.success({ message: `${info.file.name} file uploaded successfully` });
                } else if (info.file.status === 'error') {
                    notification.error({ message: info.file.response?.message });
                }
            }
        };

        const matchHeadersTabs = filesData.map((fileData, index) => {
            return (
                <Tabs.TabPane tab={<TabTitle fileName={fileData.fileName} fileIndex={index} />} key={index}>
                    <MatchHeaders fileIndex={index} setTabWithError={this.setTabWithError} />
                </Tabs.TabPane>
            )
        })

        const steps = [
            {
                index: stepsNumbers.createOrder,
                title: "Сreate Your Order",
                content: <CreateOrder />
            },
            {
                index: stepsNumbers.uploadFile,
                title: "Upload Your File",
                content: (<div>
                    <UploadFile getMappings={() => this.props.getUserMappings()} uploadProps={uploadProps} onNext={this.next} onBack={this.prev} />
                </div>)
            },
            {
                index: stepsNumbers.matchHeaders,
                title: "Match File Headers",
                content: <Tabs
                            defaultActiveKey="0"
                            activeKey={(activeMappingTab + 1) <= filesData.length ? activeMappingTab.toString() : '0'}
                            onTabClick={this.setActiveFileIndex} >
                            {matchHeadersTabs}
                        </Tabs>
            },
            {
                index: stepsNumbers.addToDatabase,
                title: "Add to Database",
                content: <AddToDatabase onFinish={this.onFinish} records={filesData[0]?.fileRecords} onBack={this.prev} />
            },
        ];

        if (newMapModelsCount > 0) {
            steps.push({
                index: stepsNumbers.saveMapping,
                title: "Save Mapping Profile",
                content: <SaveMappingProfile />
            });
            steps.sort((a, b) => a.index - b.index);
        }

        const loader = (
            <div style={{ alignItems: "center" }}>Loading...</div>
        );

        return (
            <div>
                <Prompt when={this.state.notSaveContent} message='You have unsaved changes, are you sure you want to leave?' />
                <Steps current={current}>
                    {
                        steps.map(item => (
                            <Step className={item.index == stepsNumbers.createOrder ? "first-step" : item.index == stepsNumbers.addToDatabase ? "last-step" : null} key={item.title} title={item.title} />
                        ))
                    }
                </Steps>
                {
                    (uploadStatus.isLoading) ? loader : <div className="steps-content">{steps[current].content}</div>
                }
                <FinishModal show={uploadStatus.isSuccess && uploadStatus.showModal} onClose={() => this.props.setShowModalFalse()} />
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            current: state.upload.current,
            filesData: state.upload.filesData,
            newMapModelsCount: state.upload.mapModels.filter(model => model.isNewMapping).length,
            uploadStatus: state.upload.uploadStatus
        }
    },
    UploadStore.uploadActions)(withUserLayout(UploadPage as any, PAGE_LINK.UPLOAD_FILE));