import { Moment } from "moment";
import { IPagination } from "../../assets/global/types";
import { NOTIFY_TYPE } from "./alerts-constants";

export interface IAlertsActionTypes {
    setIsLoading: string;
    setAlertContacts: string;
    setIsCodeSent: string;
    setSentAlertContactId: string;
    setSentAlertContact: string;
    setVerificationCode: string;
    setAlerts: string;
    setAlertsPagination: string;
    setAlertsIsLoading: string;
    setTargetTime: string;
    setCurrentNotifyType: string;
    setCurrentValue: string;
}

export interface IAlertsContactsPage {
    isDisabled: boolean;
    verifyIsDisabled: boolean;
}

export interface IAlertContact {
    id: number;
    type: string;
    value: string;
    isConfirmed: boolean;
}

export interface IAlertItem {
    contactDetails: string;
    email: string;
    uploadName: string;
    uploadDate: Moment;
    isDelete: boolean;
    optOutDate: Moment;
    optOutIPAddress: string;
}

export class AlertContactModel {
    public type: string;
    public value: string;

    constructor(value: string, type: string) {
        this.type = type;
        this.value = value;
    }
}

export class AlertContact implements IAlertContact {
    public id: number;
    public type: string;
    public value: string;
    public isConfirmed: boolean;

    constructor(contact: IAlertContact) {
        this.id = contact.id;
        this.type = contact.type;
        this.value = contact.value;
        this.isConfirmed = contact.isConfirmed;
    }
}

export interface IAlertsState {
    isLoading: boolean;
    alertContacts: IAlertContact[];
    isCodeSent: boolean;
    sentAlertContact?: AlertContact;
    sentAlertContactId: number;
    verificationCode: string;
    alerts: IAlertItem[];
    alertsPagination: IPagination;
    alertsIsLoading: boolean;
    targetTime: number;
    currentNotifyType: NOTIFY_TYPE;
    currentValue: string;
}

export interface IAlertModel {
    alerts: IAlertItem[];
    pagination: IPagination;
}

export interface IAlertsActions {
    setIsLoading: (isLoading: boolean) => void;
    setAlertContacts: (alertContacts: IAlertContact[]) => void;
    setIsCodeSent: (isCodeSent: boolean) => void;
    setSentAlertContactId: (sentAlertContactId: number) => void;
    setSentAlertContact: (sentAlertContact: AlertContact) => void;
    setVerificationCode: (code: string) => void;
    setAlerts: (alerts: IAlertItem[]) => void;
    setAlertsPagination: (alertsPagination: IPagination) => void;
    setAlertsIsLoading: (isLoading: boolean) => void;
    setTargetTime: (targetTime: number) => void;
    setCurrentNotifyType: (type: NOTIFY_TYPE) => void;
    setCurrentValue: (value: string) => void;

    getAlerts: () => void;
    getAlertContacts: () => void;
    addAlertContact: (model: AlertContactModel) => void;
    confirmAlertContact: (alertContactId: number) => void;
    deleteAlertContact: (alertContactId: number) => void;
    sendVerificationCode: () => void;
}