import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { withFormLayout } from "../../common/form-layout";
import message from '../../assets/images/message.svg';
import * as AuthStore from "./reducer";
import { IAuthState } from "./auth-types";
import { RouteComponentProps, Link } from "react-router-dom";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { Spin } from 'antd';
import { DEFAULT_ZERO_VALUE } from "../../assets/constants/general";
import * as _ from 'lodash';

type AuthProps = IAuthState
    & typeof AuthStore.registerActions
    & RouteComponentProps<any>;

const ThankYouPage = (props: AuthProps) => {
    const { registerEmail, resendEmail, isLoading, errors } = props;
    const errorsList = errors && _.values(errors);

    return (
        <div className="d-flex flex-column align-items-center main-block">
            <h2>Thank You</h2>
            <img src={message} alt='Message' />
            <Spin className="mt-3" spinning={isLoading} />
            {
                errors && errorsList.length > DEFAULT_ZERO_VALUE && (
                    <div className="alert alert-danger d-flex flex-column m-3" role="alert">
                        {errorsList.map((error, i) => <div key={`registration_error_${i}`}>{error}</div>)}
                    </div>
                )
            }
            <p className="m-3 text-center">Please check your {registerEmail} email for the <strong>registration confirmation</strong>. After you confirm your registration, there will be a short delay until your account is live.</p>
            <div className="d-flex align-items-center">
                <a className='email-link' onClick={resendEmail}>Send an Email Again</a>
                <span className='m-1'>,</span>
                <Link className='email-link' to={PAGE_LINK.REGISTER}>Re-Register</Link>
                <span className='m-1'>or</span>
                <Link className='email-link' to={PAGE_LINK.LOGIN}>Login</Link>
            </div>
        </div>
    );
}

const connectedThankYouPage = connect((state: ApplicationState) => state.auth, AuthStore.registerActions)(withFormLayout(ThankYouPage));
export { connectedThankYouPage as ThankYouPage };