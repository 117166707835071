import { IUploadActionTypes } from "./upload-types";

export const UploadActionTypes: IUploadActionTypes = {
    started: "UPLOAD_STARTED",
    success: "UPLOAD_SUCCESS",
    failed: "UPLOAD_FAILED",
    setFileData: "SET_FILE_DATA",
    setMapper: "SET_MAPPER",
    setOrder: "SET_ORDER",
    setCurrentStep: "SET_CURRENT_STEP",
    setDataForUpload: "SET_DATA_FOR_UPLOAD",
    setSelectHeaderValue: "SET_SELECT_HEADER_VALUE",
    setShowModalFalse: "CLOSE_MODAL",
    saveMapperStart: "SAVE_MAPPER_START",
    saveMapperSuccess: "SAVE_MAPPER_SUCCESS",
    saveMapperFailed: "SAVE_MAPPER_FAILED",
    setUserMappings: "SET_USER_MAPPINGS",
    setUserUploads: 'SET_USER_UPLOADS',
    setUserUploadsIsLoading: 'SET_USER_UPLOADS_IS_LOADING',
    setUserUploadsPagination: 'SET_USER_UPLOADS_PAGINATION',
    setSelectHeaderValuesEmpty: "SET_SELECT_HEADER_VALUES_EMPTY",
    addFileToOrder: "ADD_FILE_TO_ORDER",
    removeFile: "REMOVE_FILE",
    addNewMappProfile: "ADD_NEW_MAPP_PROFILE",
    setMapperLoading: 'SET_MAPPER_LOADING',
    setMapperSaved: 'SET_MAPPER_SAVED',
    selectMapping: 'SELECT_MAPPING_PROFILE',
    setFileIndex: 'SELECT_FILE_INDEX'
}

export const OrderDefautlValues = {
    thirdPartyCategory: "Retail",
    businessPurpose: "Marketing",
    collectionSources: ["Catalogs", "Public Records", "Website Registrations"]
}

export const mapUndefIndex = -1;
export const stepSize = 1;
export const zeroNumber = 0;
export const numberNotMappedFieldsToShowColumnError = 3;
export const columnNotificationDuration = 5;
export const notificationDuration = 7;
export const suportedField = "Supported";
export const ignoredField = "Ignore";
export const headerField = "Header";
export const stepsNumbers = {
    createOrder: 0,
    uploadFile: 1,
    matchHeaders: 2,
    saveMapping: 3,
    addToDatabase: 4
}

export const csvHeadersForExport = [
    { label: 'Uploaded', key: 'uploadedAt' },
    { label: 'Reference (Order) Number', key: 'orderNumber' },
    { label: 'File Name', key: 'fileName' },
    { label: 'List Owner', key: 'listOwner' },
    { label: 'Uploaded by', key: 'uploadedBy' },
    { label: 'Quantity', key: 'recordsCount' },
    { label: 'Opt-Outs', key: 'optOutsCount' },
    { label: 'Deletes', key: 'deletesCount' },
    { label: 'Upload', key: 'completed' }
]