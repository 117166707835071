import * as React from "react";
import { connect } from "react-redux";
import { withLayout } from "../../common/layout";
import { RootReducer } from "../../store/configure-store";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { CONTACT_INFO } from "../../assets/constants/contact_info";

import './privacy-policy.scss';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class PrivacyPolicyPage extends React.Component<{}, {}> {
    public render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Privacy Policy</title>
                    <meta name="description"
                        content="Our privacy policy details how we collect, use and share your personal information" />

                </Helmet>

                <div className="privacy-policy-header mb-3 mb-md-5 py-3">
                    <div className="container">
                        <h1>Privacy Policy</h1>
                        <h2>Our privacy policy details how we collect, use and share your personal information</h2>
                    </div>
                </div>

                <div className="container privacy-policy-content">
                    <div className="d-flex flex-column">
                        <h2>What Does This Website Do With Your Personal Information</h2>
                        <p>Our privacy policy details how we collect, use, and share your personal information. Protecting the privacy and security of Consumers' personal information is very important to us. Please read this notice carefully to understand what we do with the personal information we collect both online and offline.</p>
                        <p>When you contact us, online or offline, we may collect personal information about you. We use only the information necessary to respond to your concerns. We also may collect information about your visit to our websites for security and internal operations purposes.</p>
                        <p>This website reports use of your data by List Owners who subscribe to this service. It never sells or releases information you give to us, for purposes other than managing your privacy.</p>
                        <h2>Questions About This Privacy Policy</h2>
                        <h2 className="mb-0">Contact us at:</h2>
                        <div className="d-flex flex-column blue-block mt-4 mb-4 p-5">
                            <div>
                                <p className='mb-0' style={{ fontWeight: "bold" }}>My Data Privacy, LLC</p>
                                <p className='mb-0'>5276 Summerlin Commons Way</p>
                                <p className='mb-0'>Suite 703</p>
                                <p className='mb-0'>Ft. Myers, FL 33907</p>
                                <a className='email-link' href={`mailto:${CONTACT_INFO.EMAIL}`}>info@mydataprivacy.com</a>
                            </div>
                        </div>
                        <p className="mb-0 px-md-5">
                            We encourage citizens of the State of California to be familiar with their rights under
                            <Link className='ml-1' to={PAGE_LINK.CCPA}><strong>California Consumer Privacy Act</strong> (CCPA)</Link>
                        </p>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state: RootReducer) => { }

const connectedPrivacyPolicyPage = connect(mapStateToProps, {})(withLayout(PrivacyPolicyPage));
export { connectedPrivacyPolicyPage as PrivacyPolicyPage };