import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";

export interface TabTitleStoreProps {
    mapped: boolean;
}

export interface TabTitleOwnProps {
    fileIndex: number;
    fileName: string;
}

type TabTitleProps = TabTitleOwnProps & TabTitleStoreProps;

class TabTitle extends React.Component<TabTitleProps> {
    render() {
        const { mapped, fileName } = this.props;
        return (
            <React.Fragment>
                {mapped ? <CheckCircleOutlined style={{ color: "#52c41a" }} /> : <WarningOutlined style={{ color: "#faad14" }} />}
                {fileName}
            </React.Fragment>
        );
    }
}

export default connect<TabTitleStoreProps, {}, TabTitleOwnProps>(
    (state: ApplicationState, ownProps: TabTitleOwnProps) => {
        return {
            mapped: state.upload.mapModels[ownProps.fileIndex].canSave
        }
    }
)(TabTitle);