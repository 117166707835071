import { ILandingActionTypes } from './landing-types';

export const LandingActionTypes: ILandingActionTypes = {
    load: 'LOAD_LANDING_PAGE',
    setFirstName: 'SET_LANDING_FIRSTNAME',
    setLastName: 'SET_LANDING_LASTNAME',
    setEmail: 'SET_LANDING_EMAIL',
    setCompany: 'SET_LANDING_COMPANY',
    setPhone: 'SET_LANDING_PHONE',
    setVideoPlaying: 'SET_VIDEO_PLAYING',
    setFormLoading: 'SET_FORM_LOADING'
}

export const FORM_NAMES = {
    downlodGuide: "Download Guide",
    tryItFree: "Try It Free For 30 Days",
    scheduleDemo: "Schedule A Demo",
    turnkeyCCPA: "Turnkey CCPA Compliance",
    writeToUs: "Write to Us",
    ccpaText: "CCPA text",
    contactUs: "Contact Us"
}