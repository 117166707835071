import * as Cookies from 'js-cookie'

export interface IToken {
    accessToken: string,
    refreshToken: string,
}

export interface IAuthUser {
    email: string;
    fullName: string;
    role: string;
    company: string;
    isCompanyOwner: boolean;
    avatar: string;
}

export default class Auth {
    static STORAGE_KEY: string = "accessToken";
    static STORAGE_REFRESH: string = "refreshToken";
    static STORAGE_USER: string = "user";
    static LOGIN_EMAIL: string = "loginEmail";
    static LOGIN_PASSWORD: string = "loginPassword";

    static getToken() {
        return Cookies.get(Auth.STORAGE_KEY);
    }

    static removeToken(): void {
        Cookies.remove(Auth.STORAGE_KEY);
    }

    static getRefreshToken() {
        return Cookies.get(Auth.STORAGE_REFRESH);
    }

    static setToken(token: IToken) {
        Cookies.set(Auth.STORAGE_KEY, token.accessToken);
        Cookies.set(Auth.STORAGE_REFRESH, token.refreshToken);
    }

    static setUser(user: IAuthUser) {
        Cookies.set(Auth.STORAGE_USER, JSON.stringify(user));
    }

    static getUser(): string {
        return Cookies.get(Auth.STORAGE_USER);
    }

    static setLoginEmail(email: string) {
        Cookies.set(Auth.LOGIN_EMAIL, email);
    }

    static getLoginEmail(): string {
        return Cookies.get(Auth.LOGIN_EMAIL);
    }

    static setLoginPassword(password: string) {
        Cookies.set(Auth.LOGIN_PASSWORD, password);
    }

    static getLoginPassword(): string {
        return Cookies.get(Auth.LOGIN_PASSWORD);
    }

    static clearToken() {
        Cookies.remove(Auth.STORAGE_REFRESH);
        Cookies.remove(Auth.STORAGE_KEY);
    }

    static clear() {
        Cookies.remove(Auth.STORAGE_USER);
        Cookies.remove(Auth.STORAGE_REFRESH);
        Cookies.remove(Auth.STORAGE_KEY);
    }
}