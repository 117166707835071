import * as React from "react";
import { Link, RouteComponentProps } from 'react-router-dom';
import * as AuthStore from "./reducer";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { Form, Input, Button } from "antd";
import { IAuthState } from "./auth-types";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { withFormLayout } from "../../common/form-layout";
import { Store } from "antd/lib/form/interface";
import * as _ from 'lodash';
import { passwordRegexp, passwordRegexpMessage } from "../../helpers/regexp";
import "./auth.scss"

const { Item } = Form;

let authStyle = require('../../assets/styles/auth.styl');

type AuthProps = IAuthState
    & typeof AuthStore.signInActions
    & RouteComponentProps<any>

const ResetPasswordPage = (props: AuthProps) => {
    const { errors, isLoading, registerFailed, location, resetPassword } = props;
    const validateMessages = {
        required: "'${label}' is required"
    };
    const onFinish = (values: Store) => {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const userId = url.searchParams.get("userId");
        const code = url.searchParams.get('codeId');

        resetPassword({ userId, code, password: values.password, confirmPassword: values.confirmPassword })
    }

    return (
        <Form name="signin"
            layout={"vertical"}
            onFinish={onFinish}
            validateTrigger="onBlur"
            className='d-flex flex-column flex-wrap align-items-center auth-page'
            validateMessages={validateMessages}>
            <h2 className={authStyle.formAuthHeading}>Reset Password</h2>
            <h5 className="mb-4">Already have an account? <Link to={PAGE_LINK.LOGIN}>Back to Login</Link></h5>
            {errors && registerFailed &&
                <div className="alert alert-danger d-flex flex-column" role="alert">
                    {_.values(errors).map((error, i) => <div key={`registration_error_${i}`}>{error}</div>)}
                </div>
            }
            <Item name="password" rules={[{
                pattern: passwordRegexp,
                message: passwordRegexpMessage
            },
            { required: true, message: 'Please input your password!' },]}>
                <Input type="password" placeholder="Password" />
            </Item>
            <Item name="confirmPassword" rules={[
                {
                    required: true,
                    message: 'Please confirm your password!'
                },
                ({ getFieldValue }) => ({
                    validator(_rule, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject('The two passwords that you entered do not match!');
                    },
                })]}>
                <Input type="password" placeholder="Confirm Password" />
            </Item>
            <Item>
                <Button loading={isLoading} type="primary" size="large" block htmlType="submit">
                    Save
                </Button>
            </Item>
        </Form>
    );
}

export default connect(
    (state: ApplicationState) => state.auth,
    AuthStore.signInActions)(withFormLayout(ResetPasswordPage));