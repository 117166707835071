import { IMappingProfile, IUploadFile, IUserMapping } from "./upload-service";
import { IPagination } from '../../../assets/global/types';
import { Moment } from "moment";

export interface IFileData {
    fileId: string;
    fileName: string;
    fileColumns: string[];
    fileRecords: [];
    recordsCount: number;
    fileRecordsJson: string;
    headers: IHeader[];
}

export interface IHeader {
    id: number;
    outputColumn: string;
    friendlyOutputColumn: string;
    type: string;
}

export class FileData implements IFileData {
    public fileName: string;
    public fileColumns: string[];
    public fileRecords: [];
    public fileRecordsJson: string;
    public headers: IHeader[];
    public fileId: string;
    public recordsCount: number;

    constructor(data: IFileData) {
        this.fileName = data.fileName;
        this.fileColumns = data.fileColumns;
        this.fileRecords = JSON.parse(data.fileRecordsJson);
        this.fileRecordsJson = data.fileRecordsJson;
        this.headers = data.headers;
        this.fileId = data.fileId;
        this.recordsCount = data.recordsCount;
    }
}

export interface IOrderModel {
    referenceNumber: string;
    thirdPartyCategory: number;
    businessPurpose: number;
    collectionSources: number[];
    uploadFiles: IUploadFile[]
}

export enum OptionalFields {
    Category = "Category",
    CategoryMasked = "CategoryMasked",
    CustomElements = "CustomElements",
    Ignore = "Ignore"
}

export interface ISelectValue {
    name: string;
    type: string;
    friendlyName?: string;
}

export interface IOrderField {
    id: string;
    name: string;
}

export interface IOrderFieldsModel {
    businessPurposes: IOrderField[];
    thirdPartyCategories: IOrderField[];
    collectionSources: IOrderField[];
    values: any;
}

export interface IDataForUpload {
    businessPurposes: IOrderField[];
    thirdPartyCategories: IOrderField[];
    collectionSources: IOrderField[];
    optionalFields: string[];
    supportedFields: [];
}

export interface IUploadHistoryModel {
    uploads: IUserUpload[];
    pagination: IPagination;
}

export interface IUserUpload {
    uploadedAt: Moment;
    orderNumber: string;
    fileName: string;
    listOwner: string;
    uploadedBy: string;
    recordsCount: number;
    optOutsCount: number;
    deletesCount: number;
    completed: boolean;
}

export interface IMapModel {
    fileId: string;
    fileName: string;
    name: string;
    isNewMapping: boolean;
    canSave: boolean;
    selectedHeaderValues: { [key: string]: ISelectValue };
    isLoading: boolean;
    isSaved: boolean;
    isAllFiles: boolean;
}

export interface IUploadState {
    uploadStatus: {
        isLoading: boolean;
        isFailed: boolean;
        isSuccess: boolean;
        showModal: boolean;
        errors: { [key: string]: string };
    };
    saveMappingStatus: {
        isLoading: boolean;
        isFailed: boolean;
        isSuccess: boolean;
        errors: { [key: string]: string };
    };
    userMappings: IUserMapping[],
    uploadModel: any;
    orderModel: IOrderModel;
    fieldsValues:  Object[],
    filesData: IFileData[];
    currentFileIndex: number;
    current: number;
    dataForUpload: IDataForUpload;
    mapModels: IMapModel[];
    userUploads: IUserUpload[];
    userUploadsPagination: IPagination;
    userUploadsIsLoading: boolean;
}

export interface IUploadActionTypes {
    removeFile: string;
    started: string;
    success: string;
    failed: string;
    setFileData: string;
    setOrder: string;
    addFileToOrder: string;
    setMapper: string;
    setCurrentStep: string;
    setDataForUpload: string;
    setSelectHeaderValue: string;
    setSelectHeaderValuesEmpty: string;
    setShowModalFalse: string;
    saveMapperStart: string;
    saveMapperSuccess: string;
    saveMapperFailed: string;
    setUserMappings: string;
    setUserUploads: string;
    setUserUploadsIsLoading: string;
    setUserUploadsPagination: string;
    addNewMappProfile: string;
    setMapperLoading: string;
    setMapperSaved: string;
    selectMapping: string;
    setFileIndex: string;
}

export interface IUploadActions {
    start: () => { type: string; };
    success: () => { type: string; };
    fail: (error: { [key: string]: string }) => { type: string; payload: { [key: string]: string }; };
    removeFile: (fileId: string) => { type: string; payload: string };
    setFileData: (data: IFileData) => { type: string; payload: IFileData };
    setOrder: (orderModel: IOrderModel) => { type: string; payload: IOrderModel; };
    setMapper: (mapModel: {}) => { type: string; payload: {}; };
    setCurrentStep: (step: number) => { type: string; payload: number; };
    finishUpload: () => void;
    setDataForUpload: (dataForUpload: IDataForUpload) => { type: string; payload: IDataForUpload; };
    getDataForUpload: () => void;
    setShowModalFalse: () => { type: string; };
    saveMapperStart: () => { type: string; };
    saveMapperSuccess: () => { type: string; };
    saveMapperFailed: (error: { [key: string]: string }) => { type: string; payload: { [key: string]: string }; };
    saveMapper: (index: number, model: IMappingProfile) => void;
    getUserMappings: () => void;
    setUserMappings: (userMappings: IUserMapping[]) => { type: string; payload: IUserMapping[] };
    getUploads: () => void;
    setUserUploadsPagination: (pagination: IPagination) => void;
    addFileToOrder: (uoloadedFile: IUploadFile) => { type: string; payload: IUploadFile };
    addNewMappProfile: (mapModel: IMapModel) => { type: string; payload: IMapModel };
    setMapperLoading: (isLoading: boolean, index: number) => void;
    setMapperSaved: (name: string, isSaved: boolean, index: number) => void;
    selectMapping: (mappingName: string, isAllFiles: boolean, fileIndex: number) => {type: string, payload: {mappingName: string, isAllFiles: boolean, fileIndex: number}}
    setFileIndex: (index: number) => {type: string, payload: {index: number}};
}