import RestUtilities from "../../../helpers/rest-utilities";
import { ICompany, ICreatedUser } from "./add-client-types";

export default class AddClientService {
    add(addClient: ICreatedUser) {
        return RestUtilities.post("/api/admin/register-user", addClient);
    }

    getListCompanies() {
        return RestUtilities.get<ICompany[]>("/api/admin/get-companies");
    }
}