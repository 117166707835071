import * as React from "react";
import { connect } from "react-redux";
import * as NewsStore from "./news-reducer";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { withUserLayout } from "../../common/user-layout";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { INewsState, ISlug, ISubNewsComponentProps } from "./news-types";
import { ApplicationState } from "../../store";
import ShareArticleBlock from "./share-article";
import { SubNews } from "./sub-news-block";
import { RouteComponentProps } from "react-router";
import { SUB_NEWS_TITLES, SUB_NEWS_TYPES } from "./news-constants";
import {
    DEFAULT_ZERO_VALUE
} from "../../assets/constants/general";
import { withLayout } from "../../common/layout";
import { Helmet } from "react-helmet";

type NewsProps = RouteComponentProps<ISlug> &
    INewsState &
    typeof NewsStore.actions;

export class ArticlePage extends React.PureComponent<NewsProps> {
    componentDidMount() {
        const { getArticle } = this.props;
        const { slug } = this.props.match.params;

        getArticle(slug);
    }

    public render() {
        const { isLoading, article } = this.props;
        const createdAt = moment(article.createdAt).format("MMM Do YYYY")
        return (
            <LoadingOverlay
                className="article-page container-fluid d-flex flex-column justify-content-start w-100"
                active={isLoading}
                spinner
            >
                <Helmet>
                    <title>{article.title}</title>
                    <meta name="description"
                        content={`${article.title} ${article.shortContent}`} />
                </Helmet>

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <div className="big title">{article.title}</div>
                            <div className="article-release-info">
                                <span className="category-name">
                                    {article.category.name}
                                </span>
                                <span className="author">
                                    by {article.createdBy.fullName}
                                </span>
                                <span className="author-date-divider">&bull;</span>
                                <span className="date">
                                    {moment(article.createdAt).format(
                                        "MMM Do YYYY"
                                    )}
                                </span>
                            </div>
                            <div
                                className={`big article-cover`}
                                style={{
                                    backgroundImage: `url(${article.linkToImage})`,
                                }}
                            ></div>
                            <p className="short-content">{article.shortContent}</p>
                            <div dangerouslySetInnerHTML={{ __html: article.htmlContent }} className="article-contents">
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <ShareArticleBlock {...this.props.article} />
                            <SubNews
                                {...this.props}
                                {...({
                                    subNewsCategoryId: DEFAULT_ZERO_VALUE,
                                    subNewsType: SUB_NEWS_TYPES.RECENT_NEWS,
                                    subNewsTitle: SUB_NEWS_TITLES.RECENT_NEWS,
                                } as ISubNewsComponentProps)}
                            />
                            {article.categoryId > DEFAULT_ZERO_VALUE && (
                                <SubNews
                                    {...this.props}
                                    {...({
                                        subNewsCategoryId: article.categoryId,
                                        subNewsType: SUB_NEWS_TYPES.CATEGORY_NEWS,
                                        subNewsTitle: article.category.name,
                                    } as ISubNewsComponentProps)}
                                />
                            )}
                        </div>
                    </div>
                </div>

            </LoadingOverlay>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.news,
    NewsStore.actions
)(withLayout(ArticlePage));