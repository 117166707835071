import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ACCESS_STATUS } from "./filtered-roles-constants"
import { accessFilter } from "./access-filter"
import { PAGE_LINK } from "../../assets/constants/page-links";

const PrivateRoute = ({ component, listRoles = [], allowForMainCompanyAdmin = false, ...rest }: any) => {

    const access = accessFilter(listRoles, allowForMainCompanyAdmin);
    return (
        <Route {...rest} render={props =>
        (access === ACCESS_STATUS.NON_AUTHORIZED ?
            <Redirect to={PAGE_LINK.LOGIN} />
            : access === ACCESS_STATUS.FORBIDDEN ?
                <Redirect to={PAGE_LINK.ERROR_404} /> :
                access === ACCESS_STATUS.ACCESSIBLE ?
                    React.createElement(component, props) :
                    <Redirect to={PAGE_LINK.LANDING} />)
        } />
    );
};

export default PrivateRoute;