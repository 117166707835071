import * as React from 'react';
import { Table } from 'antd';
import { ISelectValue } from './upload-types';
import { ColumnsType } from 'antd/lib/table/interface';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { ignoredField } from './upload-constats';

export interface IUploadTableStoreProps {
    fileColumns: string[];
    tableData: object[];
    selectedHeaderValues: { [key: string]: ISelectValue };
}

export interface IUploadTableOwnProps {
    fileIndex: number;
}

type IUploadTableProps = IUploadTableStoreProps & IUploadTableOwnProps;

class UploadTable extends React.Component<IUploadTableProps>{
    render() {
        const { fileColumns = [], tableData = [], selectedHeaderValues } = this.props;
        const columns: ColumnsType<object> = [];
        fileColumns.forEach((item) => {
            const header = selectedHeaderValues[item];
            if (header && header.type != ignoredField) {
                columns.push({
                    title: header.friendlyName || header.name,
                    dataIndex: item,
                    key: item,
                })
            }
        })
        const dataSource = tableData.map((item, index) => ({
            ...item,
            key: `upload_item_${index}`
        }))
        return (
            <Table scroll={{ x: true }}
                pagination={false}
                columns={columns}
                dataSource={dataSource} />
        );
    }
}

export default connect<IUploadTableStoreProps, {}, IUploadTableOwnProps>(
    (state: ApplicationState, ownProps: IUploadTableOwnProps) => {
        return {
            fileColumns: state.upload.filesData[ownProps.fileIndex].fileColumns,
            selectedHeaderValues: state.upload.mapModels[ownProps.fileIndex].selectedHeaderValues,
            tableData: JSON.parse(state.upload.filesData[ownProps.fileIndex].fileRecordsJson),
        }
    }
)(UploadTable);