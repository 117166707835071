import { Dictionary } from "lodash";
import { Moment } from "moment";
import { RouteComponentProps } from "react-router";

export type ISearchProps = RouteComponentProps<IGuid & IToken> &
    ISearchState & ISearchActions;

export interface ISearchActionTypes {
    setLoading: string;
    setResultsCount: string;
    setSearchEmail: string;
    setOptOutEmailSent: string;
    setSearchResults: string;
    setSelectedGroup: string;
    setSelectedGroups: string;
    setSearchUser: string;
    setShowDeletedModal: string;
    setShowThankYouModal: string;
    setDeletedUser: string;
    setExpandedResults: string;
    updateExpanded: string;
    setShowOptOutModal: string;
    setOptOuts: string;
    setDeleteEmail: string;
    setCaptchaKey: string;
    setSearchHeaders: string;
    setSearchHeadersMasked: string;
    setSearchHeadersPII: string;
}

type IGuid = {
    guid?: string;
}

type IToken = {
    token?: string;
}

export type IAddressResponse = {
    resultCode: string;
    results: IAddress[];
}

export type IAddress = {
    address: IAddressValue;
}

export type IAddressValue = {
    address1?: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    subBuildings: string[];
}

export type IAutoCompleteAddress = {
    onValueSelect: (value: IAddressValue) => void;
}

export type IMore = {
    more?: boolean;
}

export interface ISelectedGroup {
    group: string;
    value: boolean;
}

export interface IExpandedResult {
    group: string;
    index: number;
    expanded?: boolean;
}

export interface IOptOutInfo {
    userGuid: string;
    ipAddress: string;
    email: string;
    documentId: string;
    fileId: string;
    firstName: string;
    lastName: string;
    address: string;
    state: string;
    city: string;
    zip: string;
    company: string;
    createdAt?: Moment;
    isDeleted?: boolean;
    ownerId?: number;
    requestedByEmail?: string
    requestedByIp?: string;
    requestedToDelete?: boolean;
    optOutGuid?: string;
}

export interface ISearchState {
    isLoading: boolean;
    searchEmail: string;
    resultsCount: number;
    isOptOutEmailSent: boolean;
    searchResults: { [x: string]: ISearchResponse[] };
    selectedGroups: ISelectedGroup[];
    searchUser?: ISearchUser;
    uploadedByToDelete?: ISearchResponse;
    showDeleteModal: boolean;
    showThankYouModal: boolean;
    deletedUser?: IDeletedUser;
    expandedResults: IExpandedResult[];
    showOptOutModal: boolean;
    uploadedByToOptOut?: ISearchResponse;
    optouts: { [x: string]: IOptOutInfo };
    deleteEmail: string;
    captchaKey: string;
    searchHeaders: Dictionary<string>;
    searchHeadersMasked: Dictionary<boolean>;
    searchHeadersPII: Dictionary<boolean>;
};

export interface ISearchUser {
    firstName: string;
    lastName: string;
    address?: string;
    zipCode?: string;
    suite?: string;
    city?: string;
    state?: string;
}

export interface IDeletedUser {
    firstName: string;
    lastName: string;
    updatedAt: Moment;
    email: string;
}

export interface ISearchResponse extends ISearchResponseInfo {
    documentId: string;
    ownerId: string;
    ownerCompany: string;
    fileId: string;
    email: string;
    firstName: string;
    lastName: string;
    zip: string;
    phone: string;
    address: string;
    state: string;
    city: string;
    uploadedBy: string;
    uploadedAt: Moment;
    collectedFrom: string[];
    businessPurpose: string;
    sharedWith3rdPartyCategories: string[];
    optout?: IOptOutInfo;
}

export interface ISearchHeadersResponse {
    searchResponses: ISearchResponse[];
    headers: ISearchHeader[];
}

export interface ISearchHeader {
    outputColumn: string;
    friendlyOutputColumn: string;
    pii: string;
    isPartialMasked: boolean;
    type: string;
}

export interface ISearchResponseInfo {
    address1?: string;
    address2?: string;
    phone2?: string;
    phone3?: string;
    socialSecurityNumber?: string;
    driversLicenseNumber?: string;
    bankAccountNumber?: string;
    ipAddress?: string;
    stateIDNumber?: string;
    passportNumber?: string;
    insurancePolicyNumber?: string;
    creditDebitCardNumber?: string;
    creditRating?: string;
    healthInsuranceNumber?: string;
    biometricInformation?: string;
    browsingHistory?: string;
    searchHistory?: string;
    geolocation?: string;
    educationInformation?: string;
    vin?: string;
    custom?: string;
    masked?: string;
    headerElements?: string;
    headerElementsPII?: string;
}

export class SearchResponseInfo implements ISearchResponseInfo {
    address1?: string;
    address2?: string;
    phone2?: string;
    phone3?: string;
    socialSecurityNumber?: string;
    driversLicenseNumber?: string;
    bankAccountNumber?: string;
    ipAddress?: string;
    stateIDNumber?: string;
    passportNumber?: string;
    insurancePolicyNumber?: string;
    creditDebitCardNumber?: string;
    creditRating?: string;
    healthInsuranceNumber?: string;
    biometricInformation?: string;
    browsingHistory?: string;
    searchHistory?: string;
    geolocation?: string;
    educationInformation?: string;
    vin?: string;
    custom?: string;
    masked?: string;
    headerElements?: string;
    headerElementsPII?: string;

    constructor(info: ISearchResponseInfo) {
        this.address1 = info.address1;
        this.address2 = info.address2;
        this.phone2 = info.phone2;
        this.phone3 = info.phone3;
        this.socialSecurityNumber = info.socialSecurityNumber;
        this.driversLicenseNumber = info.driversLicenseNumber;
        this.bankAccountNumber = info.bankAccountNumber;
        this.ipAddress = info.ipAddress;
        this.stateIDNumber = info.stateIDNumber;
        this.passportNumber = info.passportNumber;
        this.insurancePolicyNumber = info.insurancePolicyNumber;
        this.creditDebitCardNumber = info.creditDebitCardNumber;
        this.creditRating = info.creditRating;
        this.healthInsuranceNumber = info.healthInsuranceNumber;
        this.biometricInformation = info.biometricInformation;
        this.browsingHistory = info.browsingHistory;
        this.searchHistory = info.searchHistory;
        this.geolocation = info.geolocation;
        this.educationInformation = info.educationInformation;
        this.vin = info.vin;
        this.custom = info.custom;
        this.masked = info.masked;
        this.headerElements = info.headerElements;
        this.headerElementsPII = info.headerElementsPII;
    }
}

export interface ISearchActions {
    setLoading: (isLoaded: boolean) => void;
    setSearchEmail: (searchEmail: string) => void;
    setSearchUser: (searchUser: ISearchUser) => void;
    setOptOutEmailSent: (isOptOutEmailSent: boolean) => void;
    setSearchResults: (searchResults: { [x: string]: ISearchResponse[] }) => void;
    setSelectedGroup: (group: string, value: boolean) => void;
    setSelectedGroups: (groups: string[]) => void;
    setShowDeletedModal: (uploadedBy?: string) => void;
    setShowThankYouModal: () => void;
    setDeletedUser: (user: IDeletedUser) => void;
    updateExpanded: (group: string, index: number) => void;
    setShowOptOutModal: (uploadedBy?: string) => void;
    setOptOuts: (optouts: { [x: string]: IOptOutInfo }) => void;
    setDeleteEmail: (email: string) => void;
    setCaptchaKey: (key: string) => void;
    setSearchHeaders: (headers: Dictionary<string>) => void;
    setSearchHeadersMasked: (headers: Dictionary<boolean>) => void;
    setSearchHeadersPII: (headers: Dictionary<boolean>) => void;

    optOutEmail: () => void;
    searchByEmail: (email: string) => void;
    searchByUserInfo: (userInfo: ISearchUser) => void;
    loadSearchResults: (guid: string) => void;
    loadSearchHistory: (guid: string) => void;
    confirmDelete: (token: string) => void;
    optOutInfo: (guid: string) => void;
    sendDeleteEmail: () => void;
    getCaptchaKey: () => void;
}