import { Reducer } from "redux";
import { AppThunkAction } from '../../../store';
import { typedAction } from "../../../helpers/action-helper";
import { showErrorNotification } from "../../../helpers/notification-helper";
import { IPagination } from "../../../assets/global/types";
import OptOutService from "./opt-out-service";
import { defaultPagination, OptOutActionTypes } from "./opt-out-constants";
import { IOptOutActions, IOptOutItem, IOptOutState, IOptOutType, OptOutItem } from "./opt-out-types";
import { STRING_EMPTY } from "../../../assets/constants/general";
import * as json2csv from "json2csv";
import saveFile from 'save-as-file';
import moment from "moment";
import { setLoading } from "../../search/search-reducer";
import { notification } from "antd";

const optOutService = new OptOutService();

const setUserOptOuts = (optOuts: any[]) => typedAction(OptOutActionTypes.setUserOptOuts, optOuts);
const setUserOptOutsIsLoading = (isLoading: boolean) => typedAction(OptOutActionTypes.setUserOptOutsIsLoading, isLoading);
const setUserOptOutsPagination = (pagination: IPagination) => typedAction(OptOutActionTypes.setUserOptOutsPagination, pagination);
const setUserOptOutsType = (type: IOptOutType) => typedAction(OptOutActionTypes.setUserOptOutsType, type);

const getOptOuts = (): AppThunkAction<OptOutAction> => (dispatch, getState) => {
    dispatch(setUserOptOutsIsLoading(true));
    const { userOptOutsPagination, userOptOutsType } = getState().optOut;
    const isDeleted = {
        [IOptOutType.optOut]: false,
        [IOptOutType.delete]: true,
        [IOptOutType.both]: null
    }[userOptOutsType];

    optOutService.getOptOuts(userOptOutsPagination, isDeleted)
        .then((response) => {
            if (!response.is_error && response.content) {
                const { optOuts, pagination } = response.content;
                dispatch(setUserOptOuts(optOuts.map(o => new OptOutItem(o))));
                dispatch(setUserOptOutsPagination(pagination))
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setUserOptOutsIsLoading(false)));
}

const downloadOuts = (): AppThunkAction<OptOutAction> => (dispatch, getState) => {
    dispatch(setUserOptOutsIsLoading(true));
    const { userOptOutsPagination, userOptOutsType } = getState().optOut;
    const isDeleted = {
        [IOptOutType.optOut]: false,
        [IOptOutType.delete]: true,
        [IOptOutType.both]: null
    }[userOptOutsType];

    optOutService.getOptOuts({ ...userOptOutsPagination, pageNumber: 0 }, isDeleted)
        .then(async (response) => {
            if (!response.is_error) {
                const optOutsList = response.content?.optOuts || [];
                const formattedOptOuts = optOutsList.map(o => new OptOutItem(o));

                await json2csv.parseAsync(formattedOptOuts)
                    .then(csv => {
                        return saveFile(new Blob([csv], { type: 'text/csv' }), `OptOuts/Deletes_(${moment().format('L')}).csv`);
                    });
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setUserOptOutsIsLoading(false)));
}

const importDocument = (type: string, file: File): AppThunkAction<OptOutAction> => async (dispatch) => {
    dispatch(setUserOptOutsIsLoading(true));
    await optOutService.importDocument(type, file)
        .then((response) => {
            if (response.ok) {
                notification.success({ message: `File [${file.name}] has been successfully imported!` });

            } else {
                response.json()?.then(error => {
                    showErrorNotification({ is_error: true, error_content: { errors: error.errors, message: error.message } });
                })
            }
        })
        .finally(() => {
            dispatch(setUserOptOutsIsLoading(false));
        })
}

type OptOutAction = ReturnType<typeof typedAction>

export const optOutActions: IOptOutActions = {
    setUserOptOuts,
    setUserOptOutsIsLoading,
    setUserOptOutsPagination,
    setUserOptOutsType,
    getOptOuts,
    downloadOuts,
    importDocument
}

const unloadedState: IOptOutState = {
    userOptOuts: [],
    userOptOutsIsLoading: false,
    userOptOutsPagination: defaultPagination,
    userOptOutsType: IOptOutType.optOut
};

export const reducer: Reducer<IOptOutState, OptOutAction> = (state: IOptOutState = unloadedState, action: OptOutAction): IOptOutState => {
    switch (action.type) {
        case OptOutActionTypes.setUserOptOuts:
            return {
                ...state,
                userOptOuts: action.payload as IOptOutItem[]
            }
        case OptOutActionTypes.setUserOptOutsIsLoading:
            return {
                ...state,
                userOptOutsIsLoading: action.payload as boolean
            }
        case OptOutActionTypes.setUserOptOutsPagination:
            return {
                ...state,
                userOptOutsPagination: action.payload as IPagination
            }
        case OptOutActionTypes.setUserOptOutsType:
            return {
                ...state,
                userOptOutsType: action.payload as IOptOutType
            }
        default: return state;
    }
}