import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withLayout } from "../../common/layout";
import { ApplicationState } from "../../store";
import { searchActions } from "./search-reducer";
import { ISearchProps } from "./search-types";
import LoadingOverlay from 'react-loading-overlay';
import moment from "moment";
import { Button, Table } from "antd";
import * as _ from 'lodash';
import { deleteModal, formatDataSource, hideEmail, optOutModal, searchDescription, searchResultsTableColumns, thankYouModal } from "./search-helper";
import { DEFAULT_ZERO_VALUE } from "../../assets/constants/general";
import { PAGE_LINK } from "../../assets/constants/page-links";

import './search.scss';

class SearchResultsByEmailPage extends React.Component<ISearchProps, {}> {
    public async componentDidMount() {
        const { match: { params: { guid } }, loadSearchResults } = this.props;

        await loadSearchResults(guid);
    }

    public render() {
        const {
            match: { params: { guid } }, searchHeaders,
            searchResults, isLoading, showDeleteModal,
            optOutInfo, setShowDeletedModal, showOptOutModal, searchHeadersPII,
            setShowOptOutModal, showThankYouModal, setShowThankYouModal,
            optouts, sendDeleteEmail, setDeleteEmail, deleteEmail
        } = this.props;
        const resultsCount = _.keys(searchResults).map(a => searchResults[a].length).reduce((a, b) => a + b, DEFAULT_ZERO_VALUE);

        return (
            <div className="d-flex">
                {deleteModal(showDeleteModal, deleteEmail, setShowDeletedModal, setDeleteEmail, () => { setShowDeletedModal(); sendDeleteEmail(); })}
                {optOutModal(showOptOutModal, setShowOptOutModal, () => optOutInfo(guid), isLoading)}
                {thankYouModal(showThankYouModal, setShowThankYouModal)}
                <LoadingOverlay className="search-by-email-page d-flex flex-column justify-content-start align-items-center w-100 p-4" active={isLoading} spinner>
                    <h3>Your information was found in the Database <strong>{resultsCount} times </strong>{resultsCount > DEFAULT_ZERO_VALUE && `, with the following information: `}</h3>
                    {resultsCount > DEFAULT_ZERO_VALUE && <h6 className='m-1'>{searchDescription}:</h6>}
                    {
                        resultsCount === DEFAULT_ZERO_VALUE && (
                            <div className='d-flex flex-column align-items-center'>
                                <Button className='search-button' href={PAGE_LINK.LANDING}>Go To Main page</Button>
                            </div>
                        )}
                    <div className="container-fluid">
                        {
                            _.keys(searchResults)
                                .map((searchResultKey, idx) => (
                                    <div key={`search_result_${searchResultKey}_${idx}`} className='d-flex flex-column search-block'>
                                        {
                                            searchResults[searchResultKey]
                                                .map((search, index) => {
                                                    const { uploadedAt, uploadedBy, firstName, lastName, zip, address, email } = search;
                                                    const dataSource = formatDataSource({ key: `_${searchResultKey}_${idx}_${index}`, ...search, searchHeaders });

                                                    return (
                                                        <div key={`search_result_${searchResultKey}_${idx}_${index}`}
                                                            className="d-flex flex-column justify-content-start align-items-start m-4">
                                                            <h4 className='mb-3'>Uploaded by {uploadedBy} on {moment(uploadedAt).format('LLL')}</h4>
                                                            <span><strong>{firstName} {lastName}</strong>, {zip || '-'}, {address || '-'}, <span className='email-link'>{hideEmail(email)}</span></span>
                                                            <Table className="w-100" dataSource={dataSource}
                                                                columns={searchResultsTableColumns}
                                                                pagination={false} />
                                                        </div>
                                                    )
                                                }
                                                )
                                        }
                                        {
                                            optouts[searchResultKey] && !optouts[searchResultKey].requestedToDelete ? (
                                                <div className="d-flex flex-column ml-4 mb-4 question">
                                                    <span><strong>Opt-Out Status:</strong></span>
                                                    <span>Opted-Out requested {moment(optouts[searchResultKey].createdAt).format('LL')} from IP Address {optouts[searchResultKey].requestedByIp} at {moment(optouts[searchResultKey].createdAt).format('LT')}</span>
                                                </div>
                                            ) : (
                                                    <div className='ml-4 mt-4 question'>Do you wish to Opt-Out from future marketing by this List Owner/Mailer? <span onClick={() => setShowOptOutModal(searchResultKey)} className='email-link'>YES</span></div>
                                                )
                                        }
                                        <div className='ml-4 mb-4 question'>Do you wish to have your record Deleted by this List Owner/Mailer? <span onClick={() => setShowDeletedModal(searchResultKey)} className='email-link'>YES</span></div>
                                    </div>
                                )
                                )
                        }
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({ ...state.search });

const connectedSearchPage = connect(mapStateToProps, searchActions)(withLayout(withRouter(SearchResultsByEmailPage)));
export { connectedSearchPage as SearchResultsByEmailPage };