import { Reducer } from "redux";
import { AppThunkAction } from '../../../store';
import ListClientsService from "./list-client-service";
import { typedAction } from "../../../helpers/action-helper";
import { ListClientsActionTypes } from "./list-clients-constants"
import { IListClientsState, IListClientsAction, IUser } from "./list-clients-types";
import { IPagination } from "../../../assets/global/types";
import { showErrorNotification } from "../../../helpers/notification-helper";

let listClientsService = new ListClientsService();

const setListClients = (listClients: any[]) => typedAction(ListClientsActionTypes.setListClients, listClients);
const setListClientsIsLoading = (isLoading: boolean) => typedAction(ListClientsActionTypes.setListClientsIsLoading, isLoading);
const setListClientsPagination = (pagination: IPagination) => typedAction(ListClientsActionTypes.setListClientsPagination, pagination);

const fetchUsers = (dispatch: any, getState: any) => {
    dispatch(setListClientsIsLoading(true));
    const { listClientsPagination } = getState().listClients;

    listClientsService.fetch(listClientsPagination)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setListClients(response.content?.users));
                dispatch(setListClientsPagination(response.content?.pagination));
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setListClientsIsLoading(false)))
}

const getListClients = (): AppThunkAction<ListClientsAction> => (dispatch, getState) => {
    fetchUsers(dispatch, getState);
}

const activateOrDeactivateUser = (userId: number, activate: boolean): AppThunkAction<ListClientsAction> => (dispatch, getState) => {
    dispatch(setListClientsIsLoading(true));

    listClientsService.activateDeactivateUser(userId, activate)
        .then((response) => {
            if (!response.is_error) {
                fetchUsers(dispatch, getState);
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setListClientsIsLoading(false)))
}

const deleteUser = (userId: number): AppThunkAction<ListClientsAction> => (dispatch, getState) => {
    dispatch(setListClientsIsLoading(true));

    listClientsService.deleteUserById(userId)
        .then((response) => {
            if (!response.is_error) {
                fetchUsers(dispatch, getState);
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setListClientsIsLoading(false)))
}

export const listClientsActions: IListClientsAction = {
    getListClients,
    setListClientsPagination,
    activateOrDeactivateUser,
    deleteUser
}

type ListClientsAction = ReturnType<
    typeof setListClients |
    typeof setListClientsIsLoading |
    typeof setListClientsPagination>

const unloadedState: IListClientsState = {
    listClients: [],
    listClientsIsLoading: false,
    listClientsPagination: {
        pageNumber: 1,
        pageSize: 10,
        searchCriteria: null,
        sortCriteria: 'FirstName',
        isAscend: false,
        total: 0
    }
};

export const reducer: Reducer<IListClientsState, ListClientsAction> = (state: IListClientsState = unloadedState, action: ListClientsAction): IListClientsState => {
    switch (action.type) {
        case ListClientsActionTypes.setListClients:
            return {
                ...state,
                listClients: action.payload as IUser[]
            }
        case ListClientsActionTypes.setListClientsIsLoading:
            return {
                ...state,
                listClientsIsLoading: action.payload as boolean
            }
        case ListClientsActionTypes.setListClientsPagination:
            return {
                ...state,
                listClientsPagination: action.payload as IPagination
            }
        default: return state;
    }
} 