import React, { useState } from 'react';
import { AutoComplete } from 'antd';
import SearchService from './search-service';
import { IRestResponse } from '../../helpers/rest-utilities';
import { DEFAULT_ZERO_VALUE } from '../../assets/constants/general';
import { IAutoCompleteAddress, IAddressResponse, IAddressValue, IAddress } from './search-types';

let searchService = new SearchService();

export const AutoCompleteAddress: React.FC<IAutoCompleteAddress> = (props: IAutoCompleteAddress) => {
    const [value, setValue] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addresses, setAddresses] = useState<IAddressValue[]>([]);
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const onSearch = (searchText: string) => {
        if (searchText) {
            addressesFound(searchText);
        } else {
            setOptions([]);
        }
    };

    const addressesFound = (searchText: string) => {
        if (searchText.length > 2) {
            setLoading(true);
            searchService.loadAddressResults(searchText)
                .then((response: IRestResponse<IAddress[]>) => {
                    if (!response.is_error) {
                        let addresses = response.content?.map(x => x.address) || [];

                        if (addresses.length > DEFAULT_ZERO_VALUE) {
                            setOpen(true);
                            setOptions(addresses.map(x => ({ value: x.address })));
                            setAddresses(addresses);
                        }
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const onSelect = (data: string) => {
        setOpen(false);
        const postalCode = addresses.find(x => x.address === data);

        props.onValueSelect({ 
            address: postalCode?.address1,
            postalCode: postalCode?.postalCode,
            subBuildings: postalCode?.subBuildings,
            city: postalCode?.city,
            state: postalCode?.state
        });
    };

    const onChange = (data: string) => {
        setValue(data);
    };

    return (
        <AutoComplete value={value}
            open={open}
            options={loading ? [{ value: 'Loading...' }] : options}
            onChange={onChange}
            onSelect={onSelect}
            onSearch={onSearch}
            className='autocomplete-input'
        />
    );
};