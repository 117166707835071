import RestUtilities from "../../../helpers/rest-utilities";
import { IMapping, IUploadResponse } from "./mapping-types";

export default class MappingService {
    getMappingProfiles() {
        return RestUtilities.get<IMapping[]>("/api/Mapping/get-companies-mapping-profiles");
    }

    deleteMapping(mappingId: number) {
        return RestUtilities.get<IMapping[]>(`/api/Mapping/delete-mapping/${mappingId}`);
    }

    uploadExcelFile(file: FormData) {
        return RestUtilities.post<IUploadResponse>(`/api/Mapping/save-mappings-from-file`, file);
    }
}