import { Button } from "antd";
import * as React from "react";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { withLayout } from "../../common/layout";

class SomethingWentWrongPage extends React.Component<{}, {}> {
    public render() {
        return (
            <div>
                <div id="not-found-block" style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <h1 className='text-center' style={{ fontSize: "250px" }}>404</h1>
                    <h2 className='text-center'>Sorry, we could not find this page</h2>
                    <Button type="link" style={{ fontSize: "25px", fontWeight: "bold", width: "100%" }} href={PAGE_LINK.LANDING}>Go To Main page</Button>
                </div>
            </div>
        )
    }
}

export default withLayout(SomethingWentWrongPage);