import { Reducer } from "redux";
import { AppThunkAction } from '../../store';
import AuthService from "../../helpers/auth";
import { typedAction } from "../../helpers/action-helper";
import { RegisterActionTypes, SigninActionTypes, RadioVerificationTypes, ERROR_MESSAGES } from "./auth-constants";
import { IRegisterActions, IAuthState, ISigninActions, IRegisterUser, IResetPasswordModel, PhoneStatus } from "./auth-types";
import { history } from "../../helpers/history-helper";
import { PAGE_LINK } from "../../assets/constants/page-links";
import { DEFAULT_PAGE_SIZE, DEFAULT_ZERO_VALUE, FIVE_MINS, STRING_EMPTY } from "../../assets/constants/general";
import { notification } from "antd";
import Auth, { IAuthUser } from "../../local-store/auth";
import { showErrorNotification, showErrorNotificationCallback } from "../../helpers/notification-helper";
import { EDITOR_ROLE } from "../../assets/constants/roles";

let authService = new AuthService();

const registerStart = () => typedAction(RegisterActionTypes.started);
const registerSuccess = () => typedAction(RegisterActionTypes.success);
const setRegisterEmail = (email: string) => typedAction(RegisterActionTypes.registerEmail, email);
const setLoading = (loading: boolean) => typedAction(RegisterActionTypes.setLoading, loading);
const registerFail = (errors: { [key: string]: string }) => typedAction(RegisterActionTypes.failed, errors);
const setEmail = (value: string) => typedAction(RegisterActionTypes.setEmail, value);
const setPhoneNumber = (value: string) => typedAction(RegisterActionTypes.setPhoneNumber, value);
const setVerificationType = (type: string) => typedAction(RegisterActionTypes.setVerificationType, type);
const setVerification = (verified: boolean) => typedAction(RegisterActionTypes.setVerification, verified);
const setErrors = (errors: { [key: string]: string }) => typedAction(RegisterActionTypes.setErrors, errors);
const setPhoneStatus = (status: PhoneStatus) => typedAction(RegisterActionTypes.setPhoneStatus, status);
const setTargetTime = (targetTime: number) => typedAction(RegisterActionTypes.setTargetTime, targetTime);
const setIsCodeSent = (isCodeSent: boolean) => typedAction(RegisterActionTypes.setIsCodeSent, isCodeSent);

const signInStart = () => typedAction(SigninActionTypes.started);
const signInSuccess = () => typedAction(SigninActionTypes.success);
const signInFail = (errors: { [key: string]: string }) => typedAction(SigninActionTypes.failed, errors);
const setSignInEmail = (value: string) => typedAction(SigninActionTypes.setSignInEmail, value);
const setSignInPassword = (value: string) => typedAction(SigninActionTypes.setSignInPassword, value);
const setSignInRemember = (value: boolean) => typedAction(SigninActionTypes.setSignInRemember, value);

export const setUserLoggedOut = () => typedAction(SigninActionTypes.setUserLoggedOut);

const signIn = (): AppThunkAction<AuthAction> => (dispatch, getState) => {
    const { signInEmail, signInPassword, signInRemember } = getState().auth;
    dispatch(signInStart());

    if (signInRemember) {
        Auth.setLoginEmail(signInEmail);
        Auth.setLoginPassword(signInPassword);
    }

    authService.signIn(signInEmail, signInPassword)
        .then((response) => {
            if (!response.is_error) {
                const { user, token } = response.content;
                Auth.setToken(token);
                Auth.setUser(user as IAuthUser);
                dispatch(setErrors({}));
                // redirect according to role
                dispatch(signInSuccess());
                history.push(user.role == EDITOR_ROLE ? PAGE_LINK.ADMIN_NEWS : PAGE_LINK.UPLOAD_FILE);
            } else {
                showErrorNotificationCallback(response, 'Smth went wrong', (errors: { [key: string]: string }) => dispatch(signInFail(errors)), true);
            }
        })
}

const register = (userProps: IRegisterUser): AppThunkAction<AuthAction> => (dispatch, getState) => {
    const { verified, verificationType } = getState().auth;
    dispatch(registerStart());

    authService.register({ ...userProps, verification: verificationType, isVerify: verificationType === RadioVerificationTypes.VOICE && verified })
        .then((response) => {
            if (!response.is_error) {
                const { content: { email } } = response;
                dispatch(setErrors({}));
                dispatch(registerSuccess());
                dispatch(setRegisterEmail(email));
                dispatch(setTargetTime(DEFAULT_ZERO_VALUE));
                history.push(`${PAGE_LINK.THANK_YOU}`);
            } else {
                showErrorNotificationCallback(response, 'Smth went wrong', (errors: { [key: string]: string }) => dispatch(registerFail(errors)), true);
            }
        })
}

const resendEmail = (): AppThunkAction<AuthAction> => (dispatch, getState) => {
    const { registerEmail } = getState().auth;
    dispatch(setLoading(true));

    authService.resendEmail(registerEmail)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setErrors({}));
                history.push(`${PAGE_LINK.THANK_YOU}`);
            } else {
                showErrorNotificationCallback(response, 'Smth went wrong with resending email', (errors: { [key: string]: string }) => dispatch(registerFail(errors)), true);
            }
        })
        .finally(() => dispatch(setLoading(false)))
}

const sendVerifyCode = (): AppThunkAction<AuthAction> => (dispatch, getState) => {
    const { email, phoneNumber, verificationType } = getState().auth;
    dispatch(setLoading(true));

    authService.sendVerifyCode({ email, channel: verificationType, phoneNumber })
        .then((response) => {
            if (!response.is_error) {
                const { content: { isValid } } = response;
                const targetTime = new Date().getTime() + FIVE_MINS;

                dispatch(setVerification(verificationType === RadioVerificationTypes.SMS ? isValid : true));
                dispatch(setIsCodeSent(true));
                dispatch(setErrors({}));
                dispatch(setPhoneStatus(PhoneStatus.success));
                dispatch(setTargetTime(targetTime));

                if (!isValid) {
                    notification.error({ message: ERROR_MESSAGES.PHONE_INVALID });
                    dispatch(setPhoneStatus(PhoneStatus.error));
                }
            } else {
                dispatch(setPhoneStatus(PhoneStatus.error));
                dispatch(setVerification(false));
                notification.error({ message: ERROR_MESSAGES.PHONE_INVALID });
            }
        })
        .finally(() => dispatch(setLoading(false)))
}

const resetPassword = (model: IResetPasswordModel): AppThunkAction<AuthAction> => (dispatch) => {
    dispatch(setLoading(true));

    authService.resetPassword(model)
        .then(response => {
            if (!response.is_error) {
                notification.success({
                    message: `Password successfully reseted!`
                });
                dispatch(setErrors({}));
                history.push(PAGE_LINK.LOGIN);
            } else {
                showErrorNotification(response);
            }
        })
        .finally(() => dispatch(setLoading(false)))
}

const forgotPassword = (): AppThunkAction<AuthAction> => (dispatch, getState) => {
    // todo: login email
    const { signInEmail } = getState().auth;

    if (signInEmail) {
        dispatch(setLoading(true));

        authService.forgotPassword({ email: signInEmail })
            .then(response => {
                if (!response.is_error) {
                    dispatch(setErrors({}));
                    history.push(PAGE_LINK.RESEND_PASSWORD);
                } else {
                    showErrorNotification(response);
                }
            })
            .finally(() => dispatch(setLoading(false)))
    }
}

const confirmUser = (userId: string, code: string): AppThunkAction<AuthAction> => (dispatch) => {
    dispatch(setLoading(true));

    authService.confirmAccount(userId, code)
        .then(response => {
            if (response.is_error) {
                showErrorNotificationCallback(response, 'Smth went wrong with confirming account', (errors: { [key: string]: string }) => dispatch(registerFail(errors)), true);
            } else {
                dispatch(setErrors({}));
            }
        })
        .finally(() => dispatch(setLoading(false)))
}

type AuthAction = ReturnType<typeof typedAction>

export const registerActions: IRegisterActions = {
    start: registerStart,
    fail: registerFail,
    success: registerSuccess,
    register,
    setRegisterEmail,
    resendEmail,
    setVerificationType,
    sendVerifyCode,
    setVerification,
    setEmail,
    setPhoneNumber,
    confirmUser,
    setPhoneStatus,
    setTargetTime,
    setIsCodeSent
}

export const signInActions: ISigninActions = {
    start: signInStart,
    fail: signInFail,
    success: signInSuccess,
    signIn,
    forgotPassword,
    resetPassword,
    setSignInEmail,
    setSignInPassword,
    setSignInRemember
}

const unloadedState: IAuthState = {
    errors: {},
    registerFailed: false,
    registerSuccess: false,
    isFailed: false,
    isLoading: false,
    isSuccess: false,
    registerEmail: STRING_EMPTY,
    verificationType: RadioVerificationTypes.SMS,
    verified: false,
    email: null,
    phoneNumber: null,
    signInEmail: Auth.getLoginEmail(),
    signInPassword: Auth.getLoginPassword(),
    signInRemember: false,
    resetPassword: null,
    confirmResetPassword: null,
    phoneStatus: PhoneStatus.success,
    targetTime: DEFAULT_ZERO_VALUE,
    isCodeSent: false
};

export const reducer: Reducer<IAuthState, AuthAction> = (state: IAuthState = unloadedState, action: AuthAction): IAuthState => {
    switch (action.type) {
        case SigninActionTypes.started:
            return {
                ...state,
                isLoading: true,
                isFailed: false,
                isSuccess: false,
                errors: {},
            };
        case SigninActionTypes.success:
            return {
                ...state,
                isLoading: false,
                isFailed: false,
                isSuccess: true,
            };
        case SigninActionTypes.failed:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
                isSuccess: false,
                errors: action.payload as { [key: string]: string }
            };
        case RegisterActionTypes.started:
            return {
                ...state,
                isLoading: true,
                isFailed: false,
                isSuccess: false,
                errors: {},
            };
        case RegisterActionTypes.success:
            return {
                ...state,
                isLoading: false,
                registerSuccess: true,
                registerFailed: false,
            };
        case RegisterActionTypes.failed:
            return {
                ...state,
                isLoading: false,
                registerSuccess: false,
                registerFailed: true,
                errors: action.payload as { [key: string]: string }
            };
        case RegisterActionTypes.setLoading:
            return {
                ...state,
                isLoading: action.payload as boolean
            }
        case RegisterActionTypes.registerEmail:
            return {
                ...state,
                registerEmail: action.payload as string
            }
        case RegisterActionTypes.setEmail:
            return {
                ...state,
                email: action.payload as string
            }
        case RegisterActionTypes.setPhoneNumber:
            return {
                ...state,
                phoneNumber: action.payload as string
            }
        case RegisterActionTypes.setVerification:
            return {
                ...state,
                verified: action.payload as boolean
            }
        case RegisterActionTypes.setVerificationType:
            return {
                ...state,
                verificationType: action.payload as string
            }
        case SigninActionTypes.setSignInEmail:
            return {
                ...state,
                signInEmail: action.payload as string
            }
        case SigninActionTypes.setSignInPassword:
            return {
                ...state,
                signInPassword: action.payload as string
            }
        case RegisterActionTypes.setTargetTime: {
            return {
                ...state,
                targetTime: action.payload as number
            }
        }
        case RegisterActionTypes.setIsCodeSent: {
            return {
                ...state,
                isCodeSent: action.payload as boolean
            }
        }
        case SigninActionTypes.setSignInRemember:
            return {
                ...state,
                signInRemember: action.payload as boolean
            }
        case RegisterActionTypes.setErrors:
            return {
                ...state,
                errors: action.payload as { [key: string]: string }
            }
        case RegisterActionTypes.setPhoneStatus:
            return {
                ...state,
                phoneStatus: action.payload as PhoneStatus
            }
        default: return state;
    }
}
