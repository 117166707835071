import * as React from "react";
import { connect } from "react-redux";
import { withLayout } from "../../common/layout";
import { RootReducer } from "../../store/configure-store";
import { Row, Col } from "antd";
import { HashLink } from 'react-router-hash-link';

import './faq.scss';
import { Helmet } from "react-helmet";



class FAQPage extends React.Component<{}, {}> {
    public render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Frequently Asked Questions</title>
                    <meta name="description"
                        content="Frequently asked questions" />
                </Helmet>
                <div className="faq-header mb-5">
                    <h1>Frequently asked questions</h1>
                </div>
                <div className="container faq-page">
                    <Row>
                        <section className="element" id='compliance'>
                            <div className="row">
                                <div className="col-12 col-lg-5">

                                    <div className="hash_liks_box d-flex flex-column px-5 py-4">
                                        <HashLink className="mb-3" smooth to="#compliance" >Compliance</HashLink>
                                        <HashLink className="mb-3" smooth to="#security">Security</HashLink>
                                        <HashLink className="mb-3" smooth to="#deleting">Deleting &amp; Opting Out</HashLink>
                                        <HashLink smooth to="#miscellaneous-questions">Miscellaneous Questions</HashLink>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7">
                                    <h2 className="pt-0">Compliance</h2>
                                    <h3>Do I need to comply with CCPA?</h3>
                                    <p className="mb-2">
                                        The CCPA applies to any for-profit business that collects consumers' personal data, that does business in California,
                                        and satisfies at least <strong>ONE</strong> of the following thresholds:
                                    </p>
                                    <ul className="mb-3">
                                        <li>Has annual gross revenues in excess of $25 million.</li>
                                        <li>
                                            Do you annually buy, receive for commercial purposes, sell or share for commercial purposes personal information of
                                            50,000 or more California consumers, households or devices?
                                        </li>
                                        <li className="or">
                                            <strong>OR</strong>
                                        </li>
                                        <li>Derives 50% or more of its’ annual revenue from the sale of consumer personal information.</li>
                                    </ul>
                                </div>

                            </div>

                            <h3>Does GDPR compliance cover CCPA compliance?</h3>
                            <p className="mb-3 mb-md-5">No. While some components are similar, CCPA requires specific compliance standards which are not part of GDPR.</p>
                            <h3>If the company we buy data from is CCPA compliant do we need to be?</h3>
                            <p className="mb-3 mb-md-5">Yes. If you are a covered company under CCPA, you are required to become CCPA compliant.</p>
                            <h3>How does your service ensure compliance?</h3>
                            <p className="mb-2">Broadly speaking, under the CCPA List Owners &amp; Mailers are required to:</p>
                            <ul className="mb-2">
                                <li>Inform California consumers of their rights under CCPA.</li>
                                <li>Disclose which categories and specific pieces of information are being collected.</li>
                                <li>Detail how that information is being used, including to whom it is shared or sold.</li>
                                <li>Provide access to personal information via a verified request mechanism</li>
                                <li>Honor opt-out and deletion requests.</li>
                            </ul>
                            <p>
                                mydataprivacy.com covers each of these compliance items and more. Notification, access, disclosure, reporting,
                                opt-out and deletion requests are all handled within the platform. It provides consumers with an easy-access, verified
                                request portal to access their personal information. It offers online reporting to satisfy disclosure requirements along
                                with opt-out and deletion request options.
                            </p>
                            <p className="mb-3 mb-md-5">
                                Ours is the only turnkey, fully automated consumer-facing CCPA solution for SMBs and Data Compilers/Brokers, Agencies &amp;
                                Marketing Services companies who maintain databases and/or rent prospect data.
                            </p>
                            <h3>If my company just serves as a data processing company for mailers that market into California, am I required to be compliant?</h3>
                            <p className="mb-3 mb-md-5">
                                Consult with an attorney regarding your specific situation. There are some instances in which a services provider who
                                doesn’t store or maintain consumer data will not be required to comply.
                            </p>
                            <h3>How does it work?</h3>
                            <p>
                                mydataprivacy.com provides a cost effective, easy-to-implement solution for List Owners and Mailers subject to the
                                CCPA, providing comprehensive data access and disclosure compliance. The site offers central repository for List Owners and
                                Mailers to maintain CCPA-related data points and transactions, relieving them of the burden of managing compliance in-house.
                            </p>
                            <p className="mb-3 mb-md-5">
                                Each list used or sold is uploaded to your account within mydataprivacy.com. Easy click navigation and drop-down
                                menus guide you through the order creation and data upload process. Compliance details such as categories of personal
                                information, specific pieces of information, data sources and categories are captured. File layouts are easily mapped
                                within the system and drag &amp; drop file uploads allow for quick and easy workflows. Suppression and Deletion files are
                                continuously updated and available for download at any time.
                            </p>
                        </section >
                        <section className="element" id='security'>
                            <div>

                                <h2>Security</h2>
                                <h3>Where are mydataprivacy.com’s servers hosted? / Where is my data hosted and maintained?</h3>
                                <p className="mb-3 mb-md-5">
                                    All data is hosted at Digital Ocean (<strong><a href="mailto:www.digitalocean.com">www.digitalocean.com</a></strong>)
                                    data centers and stored in a private clould environment with automatic backups and infrastructure redundancies, allowing
                                    guaranteed 99.99% uptime. Certifications include ISO/IEC 27001:2013, EU-U and Swiss-US Privacy Shield Certification -
                                    <strong>
                                        <a href="https://www.digitalocean.com/legal/certifications/" rel="noopener noreferrer" target="_blank">
                                            https://www.digitalocean.com/legal/certifications/
                                        </a>
                                    </strong>.
                                </p>
                                <h3>How do I know my data will be secure?</h3>
                                <p>
                                    mydataprivacy.com’s infrastructure is secured through a defense-in-depth layered approach. Access to the
                                    management
                                    network infrastructure is provided through multi-factor authentication points, which restrict network-level access to
                                    infrastructure based on job function utilizing the principle of least privilege. Our proprietary architecture permits
                                    only single, encrypted queries from our web-facing portal to your database. Systems are protected through key-based
                                    authentication and access is limited by Role-Based Access Control (RBAC). RBAC ensures that only users who require
                                    access to a system are able to login.
                                </p>
                                <p className="mb-3 mb-md-5">
                                    Personally Identifiable Information (PII) is not stored by mydataprivacy.com. As data is uploaded to the system,
                                    it is converted to masked data (using asterisks) except for the final digit. The original version of the data with full
                                    PII is immediately deleted and not stored on our servers.
                                </p>
                            </div>

                        </section >
                        <section className="element" id='deleting'>
                            <div>

                                <h2>Deleting &amp; Opting Out</h2>
                                <h3>If a customer currently is under contract, do we still have to delete them from our system upon request?</h3>
                                <p className="mb-2">
                                    No, there are nine (9) exceptions regarding deletion requests included in CCPA. As always, consult your attorney to ensure
                                    your specific use case is excluded under CCPA. Following are the nine exceptions found within the Act:
                                </p>
                                <p className="mb-2">
                                    1798.105 (d) A business or a service provider shall not be required to comply with a consumer’s request to delete the
                                    consumer’s personal information if it is necessary for the business or service provider to maintain the consumer’s personal
                                    information in order to:
                                </p>
                                <ol className="mb-3 mb-md-5">
                                    <li>
                                        Complete the transaction for which the personal information was collected, provide a good or service requested by the
                                        consumer, or reasonably anticipated within the context of a business’s ongoing business relationship with the consumer,
                                        or otherwise perform a contract between the business and the consumer.
                                    </li>
                                    <li>
                                        Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those
                                        responsible for that activity.
                                    </li>
                                    <li>
                                        Debug to identify and repair errors that impair existing intended functionality.
                                    </li>
                                    <li>
                                        Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise
                                        another right provided for by law.
                                    </li>
                                    <li>
                                        Comply with the California Electronic Communications Privacy Act pursuant to Chapter 3.6 (commencing with Section 1546)
                                        of Title 12 of Part 2 of the Penal Code.
                                    </li>
                                    <li>
                                        Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to
                                        all other applicable ethics and privacy laws, when the businesses’ deletion of the information is likely to render
                                        impossible or seriously impair the achievement of such research, if the consumer has provided informed consent.
                                    </li>
                                    <li>
                                        To enable solely internal uses that are reasonably aligned with the expectations of the consumer based on the consumer’s
                                        relationship with the business.
                                    </li>
                                    <li>
                                        Comply with a legal obligation.
                                    </li>
                                    <li>
                                        Otherwise use the consumer’s personal information, internally, in a lawful manner that is compatible with the context in
                                        which the consumer provided the information.
                                    </li>
                                </ol>
                                <h3>Is this service just for California or can it be used for residents in other states as well?</h3>
                                <p className="mb-3 mb-md-5">
                                    mydataprivacy.com can be used for residents throughout the USA, and we recommend doing so. You’ll be prepared for
                                    pending Privacy legislation that has been introduced in multiple state legislatures and which, we believe, will eventually
                                    lead to the passing of nationwide Privacy legislation.
                                </p>
                            </div>

                        </section>
                        <section className="element" id='miscellaneous-questions'>
                            <div>

                                <h2>Miscellaneous Questions</h2>
                                <h3>How do we determine that a request for information by a consumer is not being requested by another party?</h3>
                                <p className="mb-3 mb-md-5">
                                    We verify each consumer request utilizing a third-party verification service which leverages a large ID verification database
                                    to identify and reduce potential fraud. In order to verify a given individual, the system receives consumer-input information
                                    such as name, address, email and last 4-digits of Social Security Number and confirms that information with data in the ID
                                    verification database.
                                </p>
                                <h3>If our file is multi-sourced, do we need to list each sourcing or can we list the primary sourcing used?</h3>
                                <p className="mb-3 mb-md-5">We recommend listing each source.</p>
                                <h3>Do I have to upload all information I have on contacts or only certain headers?</h3>
                                <p className="mb-3 mb-md-5">
                                    We recommend that you upload all information. Consult with a privacy attorney before doing otherwise. The platform allows you
                                    to mask all sensitive Personally Identifiable Information (i.e. -Social Security Number becomes ***-**-***2 and discards the
                                    original data provided to ensure sensitive information isn’t compromised).
                                </p>
                                <h3>Will mydataprivacy.com be available via API?</h3>
                                <p className="mb-3 mb-md-5">
                                    Yes! We are currently developing API functionality along with a wide range of platform improvements.
                                </p>
                                <h3>Are we able to broker this site to our clients for profit?</h3>
                                <p>
                                    For legal and insurance purposes, no. However, we do have a lead referral program available only to current clients. Call
                                    your salesperson to learn more.
                                </p>
                            </div>
                        </section>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: RootReducer) => ({})

const connectedFAQPage = connect(mapStateToProps, {})(withLayout(FAQPage));
export { connectedFAQPage as FAQPage };