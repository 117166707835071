import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { Layout, Button, Menu, Avatar } from 'antd';
import { PAGE_LINK } from "../assets/constants/page-links";
import { Link } from "react-router-dom";
import AuthStore, { IAuthUser } from '../local-store/auth';
import AuthService from "../helpers/auth";
import userLogo from '../assets/images/user.svg';
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { setUserLoggedOut } from "../pages/auth/reducer";
import { ADMIN_ROLE, EDITOR_ROLE, LIST_OWNER_ROLE, SUPER_ADMIN_ROLE } from "../assets/constants/roles";
import { ROLE_NAMES } from "./routes/filtered-roles-constants";
import ConfigManager from "../pages/configs/config-manager"
import UploadSvg from "../assets/images/upload-li.svg"
import FormaSvg from "../assets/images/forma-li.svg"
import ShapeSvg from "../assets/images/shape-li.svg"
import NewsSvg from "../assets/images/news-li.svg"
import Pencil from "../assets/images/pencil.svg"
import { AreaChartOutlined } from '@ant-design/icons';
import { TableOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

const jwt = require('jsonwebtoken');
const { Header, Sider, Content } = Layout;
const { Item, Divider } = Menu;

let authService = new AuthService();

type IBaseLayoutState = {
    collapsed: boolean;
}

type IBaseLayoutProps = {
    setUserLoggedOutAction?: () => void;
}

export const withUserLayout = <P extends object>(Component: React.ComponentType<RouteComponentProps<P>>, selectedKey: string) => {
    class BaseLayout extends React.Component<RouteComponentProps<P> & IBaseLayoutProps, IBaseLayoutState> {
        constructor(props: RouteComponentProps<P> & IBaseLayoutProps) {
            super(props);
        }

        logout = () => {
            const { setUserLoggedOutAction } = this.props;

            authService.signOut();
            setUserLoggedOutAction();
            this.props.history.push(PAGE_LINK.LOGIN, { signedOut: true });
        }

        sideMenu = (loginUserRole: string, loginUserCompany: string) => {
            switch (loginUserRole) {
                case LIST_OWNER_ROLE: return (
                    <Menu mode="inline" selectedKeys={[selectedKey]} defaultSelectedKeys={[PAGE_LINK.UPLOAD_FILE]}>
                        <Item icon={<span><img src={UploadSvg} /></span>} key={PAGE_LINK.UPLOAD_FILE}>
                            <Link to={PAGE_LINK.UPLOAD_FILE}>Upload Data Files</Link>
                        </Item>
                        <Item className="mb-4" icon={<span />} key={PAGE_LINK.OPT_OUTS}>
                            <Link to={PAGE_LINK.OPT_OUTS}>Opt-Outs/Deletes</Link>
                        </Item>
                        <Item icon={<span><img src={ShapeSvg} /></span>} key={PAGE_LINK.ALERTS_CENTER}>
                            <Link to={PAGE_LINK.ALERTS_CENTER}>Notification Center</Link>
                        </Item>
                        <Item className="mb-4" icon={<span />} key={PAGE_LINK.UPLOAD_HISTORY}>
                            <Link to={PAGE_LINK.UPLOAD_HISTORY}>Upload History</Link>
                        </Item>
                    </Menu>
                )
                case ADMIN_ROLE: {
                    const mainCompany = ConfigManager.mainCompany();
                    return (
                        <Menu mode="inline" selectedKeys={[selectedKey]} defaultSelectedKeys={[PAGE_LINK.UPLOAD_FILE]}>
                            <Item icon={<span><img src={UploadSvg} /></span>} key={PAGE_LINK.UPLOAD_FILE}>
                                <Link to={PAGE_LINK.UPLOAD_FILE}>Upload Data Files</Link>
                            </Item>
                            <Item className="mb-4" icon={<span />} key={PAGE_LINK.OPT_OUTS}>
                                <Link to={PAGE_LINK.OPT_OUTS}>Opt-Outs/Deletes</Link>
                            </Item>
                            <Item className="mb-4" icon={<span><img src={FormaSvg} /></span>} key={PAGE_LINK.LIST_OF_CLIENTS}>
                                <Link to={PAGE_LINK.LIST_OF_CLIENTS}>List Owners</Link>
                            </Item>
                            <Item icon={<span><img src={ShapeSvg} /></span>} key={PAGE_LINK.ALERTS_CONTACTS}>
                                <Link to={PAGE_LINK.ALERTS_CONTACTS}>Notification Contacts</Link>
                            </Item>
                            <Item icon={<span />} key={PAGE_LINK.ALERTS_CENTER}>
                                <Link to={PAGE_LINK.ALERTS_CENTER}>Notification Center</Link>
                            </Item>
                            <Item className="mb-4" icon={<span />} key={PAGE_LINK.UPLOAD_HISTORY}>
                                <Link to={PAGE_LINK.UPLOAD_HISTORY}>Upload History</Link>
                            </Item>
                            {
                                mainCompany == loginUserCompany && (
                                    <React.Fragment>
                                        <Item icon={<span><img src={NewsSvg} /></span>} key={PAGE_LINK.ADMIN_NEWS}>
                                            <Link to={PAGE_LINK.ADMIN_NEWS}>Manage news</Link>
                                        </Item>
                                    </React.Fragment>
                                )
                            }
                            <Item icon={<TableOutlined />} key={PAGE_LINK.MANAGE_MAPPINGS}>
                                <Link to={PAGE_LINK.MANAGE_MAPPINGS}>Manage mappings</Link>
                            </Item>
                        </Menu >
                    )
                }
                case SUPER_ADMIN_ROLE: return (
                    <Menu mode="inline" selectedKeys={[selectedKey]} defaultSelectedKeys={[PAGE_LINK.UPLOAD_FILE]}>
                        <Item icon={<span><img src={UploadSvg} /></span>} key={PAGE_LINK.UPLOAD_FILE}>
                            <Link to={PAGE_LINK.UPLOAD_FILE}>Upload Data Files</Link>
                        </Item>
                        <Item className="mb-4" icon={<span />} key={PAGE_LINK.OPT_OUTS}>
                            <Link to={PAGE_LINK.OPT_OUTS}>Opt-Outs/Deletes</Link>
                        </Item>
                        <Item className="mb-4" icon={<span><img src={FormaSvg} /></span>} key={PAGE_LINK.LIST_OF_CLIENTS}>
                            <Link to={PAGE_LINK.LIST_OF_CLIENTS}>List Owners</Link>
                        </Item>
                        <Item icon={<span><img src={ShapeSvg} /></span>} key={PAGE_LINK.ALERTS_CONTACTS}>
                            <Link to={PAGE_LINK.ALERTS_CONTACTS}>Notification Contacts</Link>
                        </Item>
                        <Item icon={<span />} key={PAGE_LINK.ALERTS_CENTER}>
                            <Link to={PAGE_LINK.ALERTS_CENTER}>Notification Center</Link>
                        </Item>
                        <Item className="mb-4" icon={<span />} key={PAGE_LINK.UPLOAD_HISTORY}>
                            <Link to={PAGE_LINK.UPLOAD_HISTORY}>Upload History</Link>
                        </Item>
                        <Item className="mb-4" icon={<span><img src={NewsSvg} /></span>} key={PAGE_LINK.ADMIN_NEWS}>
                            <Link to={PAGE_LINK.ADMIN_NEWS}>Manage news</Link>
                        </Item>
                        <Item className="mb-4" icon={<TableOutlined />} key={PAGE_LINK.MANAGE_MAPPINGS}>
                            <Link to={PAGE_LINK.MANAGE_MAPPINGS}>Manage mappings</Link>
                        </Item>
                        <Item icon={<AreaChartOutlined style={{ fontSize: '20px' }} />}>
                            <a href={"/hangfire?token=" + AuthStore.getToken()}>Background jobs</a>
                        </Item>
                    </Menu>
                )
                case EDITOR_ROLE: return (
                    <Menu mode="inline" selectedKeys={[selectedKey]} defaultSelectedKeys={[PAGE_LINK.UPLOAD_FILE]}>
                        <Item icon={<span><img src={NewsSvg} /></span>} key={PAGE_LINK.ADMIN_NEWS}>
                            <Link to={PAGE_LINK.ADMIN_NEWS}>Manage news</Link>
                        </Item>
                    </Menu>
                )
                default: return <Redirect to="/" />
            }
        }

        menu = () => {
            const userStr = AuthStore.getUser();
            const user = userStr && JSON.parse(userStr);
            const userName = user && (user.fullName || user.email);

            const token = jwt.decode(AuthStore.getToken());
            const loginUserRole = token.role;
            const currentUserCompany = token.company;

            const userMenu = this.sideMenu(loginUserRole, currentUserCompany);

            return (
                <div className="d-flex flex-column h-100">
                    {
                        user && userName && (
                            <div className="d-flex flex-column align-items-center justify-content-center email-link user-name mt-4 mb-5">
                                <div className="d-flex w-100 flex-row-reverse">
                                    <Link to={PAGE_LINK.EDIT_PROFILE}>
                                        <Button shape="circle" type="default" className="edit-button mr-4" icon={<img src={Pencil} />}></Button>
                                    </Link>
                                </div>
                                <div className="avatar-border mb-3">
                                    <Avatar style={{
                                        verticalAlign: 'middle',
                                        backgroundColor: ' #f0fdff',
                                    }}
                                        size={100}
                                        icon={<img src={user.avatar ?
                                            `${ConfigManager.azureStorage()}/${ConfigManager.profilesContainer()}/${ConfigManager.avatarsDirectory()}/${user.avatar}` :
                                            userLogo} alt='user-logo' />} />
                                </div>
                                <span className="user-name">{userName}</span>
                                <span className="user-role">{
                                    user.role === LIST_OWNER_ROLE ? ROLE_NAMES.listOwner :
                                        user.role === SUPER_ADMIN_ROLE ? ROLE_NAMES.superAdmin :
                                            (user.role === ADMIN_ROLE && user.isCompanyOwner == true) ? ROLE_NAMES.companyOwner :
                                                user.role === ADMIN_ROLE ? ROLE_NAMES.admin : user.role
                                }</span>
                            </div>
                        )
                    }
                    {userMenu}
                    <div className="bottom-label mt-auto bd-highlight">
                        <hr className="mb-3 mb-md-5" />
                        <p className="mb-3 mb-md-5">Copyright © 2020 My Data Privacy</p>
                    </div>
                </div>
            )
        };

        render() {
            const userStr = AuthStore.getUser();
            const user = userStr && JSON.parse(userStr);
            const pageTitle = {
                [PAGE_LINK.LIST_OF_CLIENTS]: 'List Owners',
                [PAGE_LINK.UPLOAD_HISTORY]: 'Upload History',
                [PAGE_LINK.UPLOAD_FILE]: 'Upload Data Files',
                [PAGE_LINK.OPT_OUTS]: 'Opt-Outs/Deletes',
                [PAGE_LINK.ALERTS_CONTACTS]: 'Notification Contacts',
                [PAGE_LINK.ADMIN_NEWS]: 'News',
                [PAGE_LINK.ADD_ARTICLE]: 'Add article',
                [PAGE_LINK.EDIT_ARTICLE]: 'Edit article',
                [PAGE_LINK.ALERTS_CENTER]: 'Notification Center',
                [PAGE_LINK.MANAGE_MAPPINGS]: 'Manage mappings'
            }[selectedKey];

            return (
                AuthService.isSignedIn() ? (
                    <React.Fragment>
                        <Helmet>
                            <title>{pageTitle}</title>
                            <meta name="description"
                                content={pageTitle} />
                        </Helmet>
                        <Layout className='admin-layout'>
                            <Sider breakpoint="lg"
                                collapsedWidth="0"
                                width={280}
                            >
                                {this.menu()}

                            </Sider>
                            <Layout className="site-layout">
                                <Header className='admin-header'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className="d-flex">
                                            <h2 className="page-title ml-3">{pageTitle}</h2>
                                        </div>
                                        {
                                            user && (
                                                <div className='d-flex justify-content-end'>
                                                    <div className="d-flex align-items-center">
                                                        <Button type='link' onClick={this.logout}><span className="email-link log-out mr-3">Log Out</span></Button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </Header>
                                <Content>
                                    <Component {...this.props as RouteComponentProps<P>} />
                                </Content>
                            </Layout>
                        </Layout>
                    </React.Fragment>
                ) : (
                    <Redirect
                        to={{
                            pathname: PAGE_LINK.LOGIN,
                            state: { from: this.props.location },
                        }}
                    />
                )
            );
        }
    };

    const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
        setUserLoggedOutAction: () => dispatch(setUserLoggedOut())
    });

    return connect(null, mapDispatchToProps)(BaseLayout);
}