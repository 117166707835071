import { Reducer } from "redux";
import { AppThunkAction } from '../../../store';
import EditClientService from "./edit-client-service";
import { EditClientActionTypes } from "./edit-client-constants"
import { typedAction } from "../../../helpers/action-helper";
import { showErrorNotificationCallback } from "../../../helpers/notification-helper";
import { IEditClientActions, IEditClientState, IEditedUser } from "./edit-client-types";
import { history } from "../../../helpers/history-helper";
import { notification } from "antd";
import { PAGE_LINK } from "../../../assets/constants/page-links";

let editClientService = new EditClientService();

const start = () => typedAction(EditClientActionTypes.started);
const success = () => typedAction(EditClientActionTypes.success);
const fail = (errors: { [key: string]: string }) => typedAction(EditClientActionTypes.failed, errors);
const setClient = (client: IEditedUser) => typedAction(EditClientActionTypes.setClient, client);

const getClient = (clientId: number): AppThunkAction<EditClientAction> => (dispatch) => {
    editClientService.getClient(clientId)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setClient(response.content));
            } else {
                showErrorNotificationCallback(response, 'Smth went wrong when trying to get user');
            }
        })
}

const editClient = (editedClient: IEditedUser): AppThunkAction<EditClientAction> => (dispatch) => {
    dispatch(start());
    editClientService.editClient(editedClient)
        .then((response) => {
            if (!response.is_error) {
                dispatch(setClient(response.content));
                dispatch(success());
                notification.success({ message: "The user was edited successfully!" });
                history.push(PAGE_LINK.LIST_OF_CLIENTS);
            } else {
                dispatch(fail(response.error_content.errors));
                showErrorNotificationCallback(response, 'Smth went wrong when trying to edit user');
            }
        })
}


type EditClientAction = ReturnType<typeof typedAction>

export const editClientActions: IEditClientActions = {
    editClient,
    fail,
    start,
    success,
    setClient,
    getClient
}

const unloadedState: IEditClientState = {
    isFailed: false,
    isLoading: false,
    isSuccess: false,
    errors: {},
    editedUser: {} as IEditedUser
}

export const reducer: Reducer<IEditClientState, EditClientAction> = (state: IEditClientState = unloadedState, action: EditClientAction): IEditClientState => {
    switch (action.type) {
        case EditClientActionTypes.setClient:
            return {
                ...state,
                editedUser: action.payload as IEditedUser
            };
        case EditClientActionTypes.started:
            return {
                ...state,
                isLoading: true,
                isFailed: false,
                isSuccess: false,
                errors: {}
            }
        case EditClientActionTypes.success:
            return {
                ...state,
                isLoading: false,
                isFailed: false,
                isSuccess: true,
                errors: {}
            }
        case EditClientActionTypes.failed:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
                isSuccess: false,
                errors: {}
            }
        default: return state;
    }
}