import AuthStore, { IAuthUser } from "../local-store/auth";
import { IToken } from "../local-store/auth"
import { refresh } from "./refresh-token"
import { STRING_EMPTY } from "../assets/constants/general";
var jwt = require('jsonwebtoken');

export interface IErrorContent {
    errors: { [key: string]: string };
    message: string;
}

export interface IRestResponse<T> {
    is_error?: boolean;
    error_content?: IErrorContent;
    content?: T;
    isBadRequest?: boolean;
}

export interface IAuthResponse {
    token: IToken;
    user: IAuthUser;
}

export interface IRegisterResponse {
    message: string;
    email?: string;
}

export interface IVerifyResponse {
    isValid: boolean;
    sid: string;
    errors: string[];
}

export interface IUserProfile {
}

export default class RestUtilities {

    static get<T>(url: string): Promise<IRestResponse<T>> {
        return RestUtilities.request<T>("GET", url);
    }

    static delete(url: string): Promise<IRestResponse<void>> {
        return RestUtilities.request<void>("DELETE", url);
    }

    static put<T>(
        url: string,
        data: Object | string
    ): Promise<IRestResponse<T>> {
        return RestUtilities.request<T>("PUT", url, data);
    }

    static post<T>(
        url: string,
        data: Object | string
    ): Promise<IRestResponse<T>> {
        return RestUtilities.request<T>("POST", url, data);
    }

    private static request<T>(
        method: string,
        url: string,
        data?: Object | string
    ): Promise<IRestResponse<T>> {
        let isJsonResponse: boolean = false;
        let status = STRING_EMPTY;
        let isBadRequest = false;
        let isError = false;
        let body = data;
        let headers = new Headers();

        headers.set("Authorization", `Bearer ${AuthStore.getToken()}`);
        headers.set("Accept", "application/json, text/plain");

        if (data) {
            if (data instanceof FormData) {
                headers.delete("Content-Type");
            } else {
                if (typeof data === "object") {
                    headers.set("Content-Type", "application/json");
                    body = JSON.stringify(data);
                } else {
                    headers.set(
                        "Content-Type",
                        "application/x-www-form-urlencoded"
                    );
                }
            }
        }

        const token = jwt.decode(AuthStore.getToken());
        const tenMinutesFrame = 600;
        const timeToRefresh = Math.round(Date.now() / 1000) + tenMinutesFrame;

        if (token && (timeToRefresh > token.exp)) {
            refresh();
        }

        return fetch(url, {
            method: method,
            headers: headers,
            body: <string>body,
        })
            .then((response: any) => {
                if (response.status == 401) {
                    refresh();
                }

                isBadRequest = response.status == 400;
                isError = !response.ok;
                status = response.statusText;

                let responseContentType = response.headers.get("content-type");
                if (
                    responseContentType &&
                    responseContentType.indexOf("json") !== -1
                ) {
                    isJsonResponse = true;
                    return response.json();
                } else {
                    return response.text();
                }
            })
            .then((responseContent: any) => {
                let response: IRestResponse<T> = {
                    is_error: isError,
                    error_content: isBadRequest ? responseContent : { message: status },
                    content: isBadRequest ? null : responseContent,
                    isBadRequest
                };
                return response;
            });
    }
}