import { IPagination } from "../../../assets/global/types";
import RestUtilities from "../../../helpers/rest-utilities";
import { IPaginatedUsersModel } from "./list-clients-types";


export default class ListClientsService {
    fetch(pagindtion: IPagination) {
        return RestUtilities.post<IPaginatedUsersModel>(
            "/api/admin/get-users",
            pagindtion
        );
    }

    activateDeactivateUser(userId: number, activate: boolean) {
        return RestUtilities.get(`/api/admin/activate-deactivate-user/${userId}/${activate}`)
    }

    deleteUserById(userId: number) {
        return RestUtilities.get(`/api/admin/remove-user/${userId}`);
    }
}