import RestUtilities from '../../helpers/rest-utilities';
import { IPotentialClient } from './landing-types';

class LandingService {
    downloadGuide = (userInfo: any) => {
        return RestUtilities.post<boolean>(`/api/landing/add-potential-customer`, userInfo);
    }

    submitForm = (potentialClient: IPotentialClient) => {
        return RestUtilities.post<boolean>(`/api/landing/add-potential-customer`, potentialClient);
    }
}

export default LandingService;