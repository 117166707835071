import * as React from 'react';
import { Button, Upload } from 'antd';
import uploadImg from "../../../assets/images/upload-ico.svg"
import { DeleteOutlined } from "@ant-design/icons"
const { Dragger } = Upload;

export interface UploadProps {
    uploadProps: object,
    onNext: Function,
    onBack: Function,
    getMappings: Function
}

const UploadFile: React.FunctionComponent<UploadProps> = ({ uploadProps = {}, onNext, onBack, getMappings }) => {

    React.useEffect(() => {
        getMappings();
    }, []);

    return (
        <React.Fragment>
            <div className="container">
                <Dragger  {...uploadProps} showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined style={{ fontSize: "20px" }} /> }} className="mb-4">
                    <span className="m-3">
                        <p className="ant-upload-drag-icon">
                            <img src={uploadImg} height="50px" width="auto" />
                        </p>
                        <h5>Drag & Drop File Here</h5>
                        <h5>Or</h5>
                        <h5>Click to Browse files</h5>
                        <p className="ant-upload-hint">
                            Accepted files are .csv, .txt, .xls, .xlsx and .dbf
                        </p>
                    </span>
                </Dragger>
            </div>
            <div style={{ float: "right" }}>
                <div className="row">
                    <Button onClick={() => onBack()} className="back_btn" shape="round" type="text">Back</Button>
                    <Button onClick={() => onNext()} className="next_btn" shape="round" type="primary">Next</Button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default UploadFile;